import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'view/Header';
import { IProps as PrivateProps } from 'router/Private';

interface IProps {
  Private: FC<PrivateProps>;
}

export const MainLayout: FC<IProps> = ({ Private }) => {
  return (
    <Private>
      <div className="main-layout d-flex flex-column vh-100" style={{ backgroundColor: '#F7F8FA' }}>
        <Header />
        <Outlet />
      </div>
    </Private>
  );
};
