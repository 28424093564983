import React, { FC } from 'react';
import classes from '../Analytics.module.scss';
import arrowIcon from 'assets/icons/arrow.svg';
import { clsx } from 'utils/clsx';
import { Button } from 'components';
import exportIcon from 'assets/icons/export.svg';
import { useNavigate } from 'react-router-dom';
import { numberWithSpaces } from 'helper/numberWithComma';

interface Props {
  totalValue: number;
  onExport: () => void;
}

const SubHeader: FC<Props> = ({ totalValue, onExport }) => {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  return (
    <div>
      <div className="py-2 default-shadow mb-4 bg-white">
        <div className="py-1 container-fluid d-flex justify-content-between align-items-center">
          <div className={classes.back}>
            <img
              role="button"
              src={arrowIcon}
              alt=""
              className={clsx(classes.icon, 'me-2')}
              onClick={back}
            />
            <span className="font-kraftig">Project Favorites Analytics</span>
          </div>
          <Button
            type="secondary"
            onClick={onExport}
            className={clsx('px-3 py-1 d-flex align-items-center', classes.exportBtn)}>
            <span className={clsx(classes.export, 'font-kraftig')}>Export</span>
            <img src={exportIcon} alt="" className="ms-2" />
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-4 mx-3 default-color-text">
        <div>
          <span className="font-kraftig">Total Project Value</span>
        </div>
        <div>
          <span className="font-kraftig">Total Project Value: ${numberWithSpaces(totalValue)}</span>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
