import React, { PropsWithChildren, ReactNode } from 'react';
import { IExtendedOption, IOption } from 'components/Select/types';
import classes from './ProjectList.module.scss';
import { clsx } from 'utils/clsx';
import tickIcon from 'assets/icons/tick.svg';

interface IProps<T> {
  list: IExtendedOption[];
  children: ReactNode;
  childrenAlignment: 'top' | 'bottom';
  onChange: (item: T, option: IOption) => void;
  value: T | null;
}
const FavoriteList = <T,>({
  list,
  children,
  childrenAlignment,
  onChange,
  value
}: PropsWithChildren<IProps<T>>) => {
  const isSelected = (item: IExtendedOption, option: IOption): boolean => {
    if (!value) return false;
    return (
      (value as unknown as IExtendedOption).category === item.category &&
      (value as unknown as IExtendedOption).selected === option.id
    );
  };

  return (
    <div
      className={clsx(
        classes.optionWrapper,
        'd-flex',
        'mt-2',
        childrenAlignment === 'top' ? 'flex-column-reverse' : 'flex-column'
      )}>
      <div>
        {list.map((item, idx) => (
          <div key={item.category}>
            <div className={classes.category}>
              <span className="px-2">{item.category}</span>
            </div>
            {item.options.map((option, i) => (
              <div
                key={option.value}
                className={clsx(
                  classes.option,
                  'd-flex',
                  'justify-content-between',
                  'align-items-center'
                )}
                data-test-element={`dropdown-option-${idx + 1}-${i + 1}`}
                onClick={() => onChange(item as unknown as T, option)}>
                <span className="d-flex align-items-center">
                  {option.icon && (
                    <div className="me-2">
                      <option.icon />
                    </div>
                  )}
                  {option.text}
                </span>

                {isSelected(item, option) && <img src={tickIcon} alt="" />}
              </div>
            ))}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

export default FavoriteList;
