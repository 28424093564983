import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import classes from '../Auth.module.scss';
import { Button, Select, AnimatedInput } from 'components';
import { clsx } from 'utils/clsx';
import {
  IAxiosError,
  IRegisterData,
  IValidationErrorResponse,
  PreRegistrationUserData
} from '../types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SignupSchema } from 'utils/Formik/validation';
import AuthFooter from './AuthFooter';
import Checkbox from 'components/Checkbox';
import { configToOption } from './helpers/configToOption';
import { IOption } from 'components/Select/types';
import { observer } from 'mobx-react';
import { useNavigateAfterLogin } from 'hooks/useNavigateAfterLogin';

export const SignUp: FC = observer(() => {
  const { t } = useTranslation();
  const { authStore, alertStore, configStore, userStore } = useStore();
  const [params] = useSearchParams();
  const { navigateAfterLogin } = useNavigateAfterLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateState = location.state as PreRegistrationUserData;
  const isExtended = !!params.get('extended');

  const signUp = useCallback(async (values: IRegisterData) => {
    try {
      setIsLoading(true);
      await authStore.signUp(values);
      await authStore.signIn(values);
      await userStore.getCurrentUser();
      alertStore.successAlert(t('auth.successRegister'));
      navigateAfterLogin();
    } catch (e) {
      const response: IValidationErrorResponse = (e as IAxiosError<IValidationErrorResponse>)
        .response.data;
      if (response.validation) {
        formik.setErrors(response.validation);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const formik = useFormik<IRegisterData>({
    initialValues: {
      firstName: navigateState?.fullName.split(' ')[0] || '',
      lastName: navigateState?.fullName.split(' ')[1] || '',
      email: navigateState?.email || '',
      password: '',
      type: '',
      agree: false,
      socialUserToken: navigateState?.token || ''
    },
    onSubmit: signUp,
    validationSchema: SignupSchema
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const onCheckboxChange = useCallback((id: number, checked: boolean): void => {
    formik.setFieldTouched('agree');
    formik.handleChange({ target: { value: !checked, name: 'agree' } });
  }, []);

  const onSelectChange = useCallback(({ text }: IOption, name: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value: text, name } });
  }, []);

  const selectOptions = useMemo<IOption[]>(
    () => configToOption(configStore.enums?.propertyClasses),
    []
  );

  const selectedType = useMemo<IOption | null>(() => {
    return selectOptions.find((option) => option.text === formik.values.type) || null;
  }, [formik.values]);

  useEffect(() => {
    if (userStore.user) {
      navigate(location.pathname, { replace: true });
    }
  }, [userStore.user]);

  useEffect(() => {
    if (navigateState?.token) {
      formik.setValues({
        firstName: navigateState.fullName.split(' ')[0],
        lastName: navigateState.fullName.split(' ')[1],
        email: navigateState.email || '',
        password: '',
        type: '',
        agree: false,
        socialUserToken: navigateState.token
      });
    }
  }, [navigateState]);

  return (
    <div
      className={clsx(
        isExtended && 'd-flex',
        isExtended && 'align-items-stretch',
        isExtended && 'd-flex'
      )}>
      <form
        action="#"
        className={clsx(classes.form, 'bg-white', 'py-4')}
        onSubmit={formik.handleSubmit}>
        <div>
          <div className="d-flex align-items-center flex-column">
            <h1 className={clsx(classes.title, 'mb-4', 'pb-2')}>{t('auth.signUpTitle')}</h1>
            <div className={clsx(classes.px40, 'w-100')}>
              <div className="mb-4 d-flex justify-content-between pb-2">
                <div className="flex-grow-1 me-3">
                  <AnimatedInput
                    type="text"
                    name="firstName"
                    value={formik.values.firstName}
                    onInput={onInputChange}
                    isError={!!formik.touched.firstName && !!formik.errors.firstName?.length}
                    isSuccess={!formik.errors.firstName && formik.values.firstName.length > 0}
                    errorText={formik.errors.firstName}
                    dataTestElement="register-name-input">
                    <span>{t('form.firstName')}</span>
                  </AnimatedInput>
                </div>
                <div className="flex-grow-1">
                  <AnimatedInput
                    type="text"
                    name="lastName"
                    value={formik.values.lastName}
                    onInput={onInputChange}
                    isError={!!formik.touched.lastName && !!formik.errors.lastName?.length}
                    isSuccess={!formik.errors.lastName && formik.values.lastName.length > 0}
                    errorText={formik.errors.lastName}
                    dataTestElement="register-name-input">
                    <span>{t('form.lastName')}</span>
                  </AnimatedInput>
                </div>
              </div>
              <div className="mb-4 pb-2">
                <AnimatedInput
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onInput={onInputChange}
                  isError={!!formik.touched.email && !!formik.errors.email?.length}
                  isSuccess={!formik.errors.email && formik.values.email.length > 0}
                  errorText={formik.errors.email}
                  dataTestElement="register-email-input">
                  <span>{t('form.emailAddress')}</span>
                </AnimatedInput>
              </div>
              <div className="mb-4">
                <AnimatedInput
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onInput={onInputChange}
                  isError={!!formik.touched.password && !!formik.errors.password?.length}
                  isSuccess={!formik.errors.password && formik.values.password.length > 0}
                  errorText={formik.errors.password}
                  dataTestElement="register-password-input">
                  <span>{t('form.password')}</span>
                </AnimatedInput>
              </div>
              <div className="mb-4 position-relative">
                <Select
                  placeholder="What types of projects are you interested in?"
                  placeholderClassName={clsx(classes.placeholder, 'pe-none')}
                  className={clsx(classes.select, 'px-3')}
                  options={selectOptions}
                  value={selectedType}
                  onChange={onSelectChange}
                  name="type"
                />
                {!!formik.touched.type && formik.errors.type && (
                  <div
                    className={clsx(classes.errorMessage, 'position-absolute', 'top-100', 'w-100')}>
                    {formik.errors.type}
                  </div>
                )}
              </div>

              <div className="mb-4 pb-3 d-flex align-items-center">
                <Checkbox id={1} checked={formik.values.agree} onChange={onCheckboxChange}>
                  <div className={classes.checkboxText}>
                    <span>
                      {t('auth.agree')}&nbsp;
                      <span className={clsx(classes.link, classes.privacy)}>{t('auth.terms')}</span>
                      &nbsp;
                      {t('auth.and')}&nbsp;
                      <span className={clsx(classes.link, classes.privacy)}>
                        {t('auth.privacy')}
                      </span>
                    </span>
                    {!!formik.touched.agree && formik.errors.agree && (
                      <div
                        className={clsx(
                          classes.errorMessage,
                          'position-absolute',
                          'top-100',
                          'start-0',
                          'w-100'
                        )}>
                        {formik.errors.agree}
                      </div>
                    )}
                  </div>
                </Checkbox>
              </div>
            </div>

            <div className={clsx(classes.px40, 'w-100')}>
              <Button
                type="primary"
                className={clsx('w-100', classes.formBtn)}
                isLoading={isLoading}
                data-test-element="register-submit-button">
                {t('auth.signUp')}
              </Button>
            </div>
          </div>
          <AuthFooter step="sign-up" />
        </div>
      </form>
      {isExtended && (
        <div className={clsx(classes.extendedWrapper, 'position-relative')}>
          <div className={clsx('position-relative', classes.extendedBody)}>
            <h1 className={clsx('text-center', 'text-white', 'mb-5', classes.extendedTitle)}>
              {t('auth.extendedRegister')}
            </h1>
            <ul className={clsx('ms-auto', classes.extendedList)}>
              <li className={clsx('text-white', 'mb-4')}>{t('auth.unlockMarket')}</li>
              <li className={clsx('text-white', 'mb-4')}>{t('auth.getInfo')}</li>
              <li className={clsx('text-white', 'mb-4')}>{t('auth.winBetter')}</li>
              <li className={clsx('text-white')}>{t('auth.research')}</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
});
