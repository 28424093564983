export const ACCESS_TOKEN = 'accessToken';
export const CHECKSUM = 'checksum';
export const ENUMS = 'enums';
export const APP_VERSION = 'appVersion';
export const APP_CONFIG = 'appConfig';

export const LOGIN_PROVIDER_GOOGLE = 'google';

export const SIGN_IN = 'sign-in';
export const SIGN_UP = 'sign-up';

export const SUCCESS = 'success';
export const ERROR = 'error';

export const TIME_OUT_VIEW_ALERTS = 3000;

export const DEBOUNCE_ZERO_DELAY = 0;
export const DEBOUNCE_DELAY = 100;
export const DROPDOWN_DEBOUNCE_DELAY = 500;
export const VIEWPORT_DEBOUNCE_DELAY = 1000;

export const CURRENT_LOCATION = 'currentLocation';

export const ASC = 'asc';
export const DESC = 'desc';

export const BLUE_COLOR = '#0087FF';
export const PURPLE_COLOR = '#4540BB';
export const GREY_COLOR = '#757B8D';
export const GREY_LIGHT_COLOR = '#F7F9FF';
export const WHITE_COLOR = '#FFFFFF';

export const TEXT_COLOR = '#2F3139';
export const ERROR_COLOR = '#E35656';
export const GREEN_COLOR = '#04B78A';
export const ORANGE_COLOR = '#F88500';

export const FAVORITE = 'favorite';
export const DOCUMENT = 'document';
export const DOCUMENT_GREY = 'documentGrey';

export const NA = 'TBD';

//System type favorites
export const PROJECT = 'project';
export const COMPANY = 'company';
export const PERSON = 'person';

export const LOCATION = 'location';
export const DATE = 'date';
export const TYPE = 'types';
export const PROJECT_VALUE = 'projectValue';
export const PROJECT_COUNT = 'projectCount';
export const TOTAL_AMOUNT = 'totalAmount';

export const ALL = 'all';

export const UPGRADE = 'upgrade';
export const CANCEL = 'cancel';
export const RENEW = 'renew';

export const ALL_COUNTRIES = 'All counties';
