import {
  CreateSubscriptionToLocationBody,
  ISubscriptionAndPlansRepo,
  PaymentCustomer,
  Product,
  QuestionsAndAnswers,
  SubscriptionAction,
  UserSubscription
} from './types';
import { IAxiosResponse } from 'utils/types';
import { axiosInstance } from 'utils/API';

export class SubscriptionAndPlansRepo implements ISubscriptionAndPlansRepo {
  getProductsByLocation(locationId: number): Promise<IAxiosResponse<Product[]>> {
    return axiosInstance.get(`product/${locationId}`);
  }

  getSubscriptionPlanToLocation(
    subscriptionId: number,
    userId: number,
    subscriptionAction: SubscriptionAction
  ): Promise<IAxiosResponse<Product>> {
    return axiosInstance.get(
      `users/${userId}/subscription/${subscriptionId}/${subscriptionAction}`
    );
  }

  createSubscriptionToLocation(
    priceId: number,
    locationId: number[],
    paymentMethod: string | null
  ): Promise<IAxiosResponse<PaymentCustomer>> {
    const body: CreateSubscriptionToLocationBody = {
      locationId
    };
    if (paymentMethod) {
      body['paymentMethod'] = paymentMethod;
    }
    return axiosInstance.post(`product/${priceId}/subscribe`, body);
  }

  upgradeSubscriptionToLocation(
    userId: number,
    subscriptionId: number
  ): Promise<IAxiosResponse<PaymentCustomer>> {
    return axiosInstance.post(`users/${userId}/subscription/${subscriptionId}/upgrade`);
  }

  getUserSubscriptions(userId: number): Promise<IAxiosResponse<UserSubscription[]>> {
    return axiosInstance.get(`users/${userId}/subscriptions`);
  }

  unsubscription(
    subscriptionId: number,
    userId: number,
    questions: QuestionsAndAnswers[]
  ): Promise<void> {
    return axiosInstance.post(`users/${userId}/unsubscribe/${subscriptionId}`, {
      questions
    });
  }

  checkHasSubscriptionToLocation(locationId: number): Promise<IAxiosResponse<boolean>> {
    return axiosInstance.get(`subscriptions/${locationId}`);
  }

  removeIncompleteSubscription(subscriptionId: number): Promise<void> {
    return axiosInstance.delete(`subscriptions/${subscriptionId}/remove`);
  }
}
