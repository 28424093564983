import React, { FC, useEffect } from 'react';
import { Chart, ChartEvent, registerables } from 'chart.js';
import { DataAnalyticsDiagram } from './types';
import { clsx } from 'utils/clsx';
import classes from './Analytics.module.scss';
import { centerText } from './DiagramPlugins';
import { useTranslation } from 'react-i18next';
import { TEXT_COLOR } from 'utils/constants';

Chart.register(...registerables);

const divStyle = {
  height: '506px',
  width: '486px'
};

interface Props {
  diagramData: DataAnalyticsDiagram;
  totalValue: number;
}

const Diagram: FC<Props> = ({ diagramData, totalValue }) => {
  const { t } = useTranslation();

  const getPercent = (value: number): number => {
    return Math.round((Number(value) / totalValue) * 100);
  };
  useEffect(() => {
    const ctx: HTMLCanvasElement = document.getElementById('myChart') as HTMLCanvasElement;
    if (ctx) {
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: diagramData.labels,
          datasets: [
            {
              data: diagramData.values,
              backgroundColor: diagramData.colors,
              borderWidth: 0
            }
          ]
        },
        options: {
          cutout: 152,
          plugins: {
            legend: {
              position: 'bottom',
              onClick: (e: ChartEvent) => e.native?.stopPropagation(),
              labels: {
                padding: 32,
                usePointStyle: true,
                pointStyle: 'circle',
                font: {
                  family: 'Söhne-Kraftig',
                  size: 16
                },
                color: TEXT_COLOR
              }
            },
            tooltip: {
              enabled: false,
              external: (context) => {
                // Tooltip Element
                let tooltipEl = document.getElementById('chart-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chart-tooltip';
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  (tooltipEl as HTMLDivElement).style.opacity = '0';
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                const getBody = (bodyItem: {
                  before: string[];
                  lines: string[];
                  after: string[];
                }) => {
                  return bodyItem.lines;
                };

                // Set Text
                if (tooltipModel.body) {
                  const bodyLines = tooltipModel.body.map(getBody)[0][0].split(':');
                  const label = bodyLines[0];
                  const value = bodyLines[1].replace(/\s/g, '');
                  tooltipEl.innerHTML = `<div><span class="font-kraftig">${label}<span class="ms-4">$${value}</span> / ${getPercent(
                    Number(value.replace(/\D/g, ''))
                  )}%</span></div>`;
                }

                const position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = '1';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left =
                  position.left + window.pageXOffset + tooltipModel.caretX - 30 + 'px';
                tooltipEl.style.top =
                  position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.padding = '24px';
                tooltipEl.style.backgroundColor = '#FFFFFF';
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.zIndex = '99';
              }
            }
          },
          scales: {
            y: {
              grid: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false
              }
            }
          }
        },
        plugins: [centerText(t('favorites.analytics.totalProjectValue'), totalValue)]
      });
    }
  }, []);

  return (
    <div className="chart-container" style={divStyle}>
      <canvas id="myChart"></canvas>
      <div id="chart-tooltip" className={clsx(classes.tooltip)}></div>
    </div>
  );
};

export default Diagram;
