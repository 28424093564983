import {
  ISavedSearchStore,
  ISavedSearch,
  ISavedSearchService,
  SAVED_SEARCH_SERVICE
} from './types';
import { ISearchParams } from '../SearchProjects/components/SubHeader/types';
import { makeAutoObservable } from 'mobx';
import { injector } from '../../utils/injector';
import { FoundLocation } from '../Search/types';

export class SavedSearchManageStore implements ISavedSearchStore {
  private _savedSearchService: ISavedSearchService =
    injector.get<ISavedSearchService>(SAVED_SEARCH_SERVICE);

  constructor() {
    makeAutoObservable<SavedSearchManageStore>(this);
  }

  savedSearches: ISavedSearch[] = [];
  newProjectsCount = 0;

  setSavedSearch(searches: ISavedSearch[]): void {
    this.savedSearches = searches;
  }

  setNewProjectsCount(count: number): void {
    this.newProjectsCount = count;
  }

  getSavedSearchById(id: number): ISavedSearch | null {
    if (this.savedSearches.length) {
      return this.savedSearches.find((item: ISavedSearch) => item.id === id) as ISavedSearch;
    }
    return null;
  }

  async deleteSavedSearches(userId: number, searchId: number): Promise<void> {
    await this._savedSearchService.deleteSavedSearches(userId, searchId);
  }

  async editSavedSearches(userId: number, searchId: number, name: string): Promise<void> {
    const editedSearch = await this._savedSearchService.editSavedSearches(userId, searchId, name);
    this.setSavedSearch([
      ...this.savedSearches.filter((item) => item.id !== editedSearch.id),
      editedSearch
    ]);
  }

  async getSavedSearches(id: number): Promise<void> {
    this.setSavedSearch(await this._savedSearchService.getSavedSearches(id));
  }

  async saveSearch(id: number, name: string, filters: ISearchParams): Promise<ISavedSearch> {
    return await this._savedSearchService.saveSearch(id, name, filters);
  }

  async getNewProjectCount(userId: number): Promise<void> {
    this.setNewProjectsCount(await this._savedSearchService.getNewProjectCount(userId));
  }

  async getLocationInfo(locationId: number): Promise<FoundLocation> {
    return await this._savedSearchService.getLocationInfo(locationId);
  }
}
