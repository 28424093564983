import React, { FC, useEffect, useRef } from 'react';
import classes from './Modal.module.scss';
import closeIcon from 'assets/icons/close.svg';
import Button from '../Button';
import { IModalActions } from './types';
import { clsx } from 'utils/clsx';

interface Props {
  show: boolean;
  title: string;
  subTitle?: string;
  closeModal: () => void;
  onSubmit?: () => void;
  hideFooter?: boolean;
  actions?: IModalActions;
  modalClass?: string;
  bodyClass?: string;
  [key: string]: unknown;
}

const ConfirmModal: FC<Props> = ({
  show,
  title,
  subTitle,
  actions,
  closeModal,
  onSubmit,
  hideFooter,
  modalClass,
  bodyClass,
  ...rest
}) => {
  const modalWrapper = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (show) {
      window.addEventListener('click', clickOutside);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [show]);

  const clickOutside = (e: MouseEvent): void => {
    if (e.target === modalWrapper.current) {
      closeModal();
    }
  };
  return (
    <div
      className={clsx('modal', show ? 'd-block' : 'd-none', classes.overlay)}
      aria-hidden="true"
      ref={modalWrapper}
      {...rest}>
      <div className="modal-dialog modal-dialog-centered justify-content-center">
        <div className={clsx(classes.modal, 'modal-content border-0', modalClass)}>
          <div className="modal-header border-0 ps-4 pe-2 w-100 pb-0">
            <div className="w-100 d-flex justify-content-end">
              <img
                src={closeIcon}
                alt="close"
                className="position-relative"
                onClick={closeModal}
                role="button"
              />
            </div>
          </div>
          <h2 className="default-color-text text-center">{title}</h2>
          <div className={clsx(bodyClass, 'modal-body px-4 text-center m-auto')}>
            {subTitle && <p className={clsx(classes.modalSubTitle, 'm-0')}>{subTitle}</p>}
          </div>
          {!hideFooter && (
            <div className="modal-footer flex-nowrap p-4 m-auto border-0">
              <div className="d-flex w-100 justify-content-end m-0">
                {actions && (
                  <>
                    {actions.negative && (
                      <Button type="secondary" className="px-4 mx-3" onClick={closeModal}>
                        <span className="font-kraftig">{actions.negative}</span>
                      </Button>
                    )}
                    {actions.positive && (
                      <Button type="primary" className="px-4" onClick={onSubmit}>
                        <span className="font-kraftig">{actions.positive}</span>
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
