import React, { FC, useEffect, useState } from 'react';
import classes from './Subscription.module.scss';
import { clsx } from 'utils/clsx';
import { IColumn } from 'components/Table/types';
import SubscriptionTable from './components/SubscriptionTable';
import { SubscriptionTableType } from '../types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import UpgradePlanModal from 'view/SubscriptionsAndPlans/UpgradePlanModal';
import CancelSubscriptionModal from 'view/SubscriptionsAndPlans/CancelSubscriptionModal';
import { ConfirmModal } from 'components';
import moment from 'moment';
import { Location, PaymentType } from 'view/SubscriptionsAndPlans/types';

const Subscription: FC = observer(() => {
  const { t } = useTranslation();
  const { subscriptionAndPlansStore, userStore } = useStore();
  const [upgradePlan, setUpgradePlanModal] = useState<boolean>(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState<boolean>(false);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<number | null>(null);
  const [confirmModalShow, setConfirmModalShow] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.UPGRADE);

  const confirmModalActions = {
    positive: 'Okay'
  };

  const columns: IColumn[] = [
    {
      field: 'status',
      headerName: t('profile.subscription.table.status')
    },
    {
      field: 'location',
      headerName: t('profile.subscription.table.location')
    },
    {
      field: 'subscription',
      headerName: t('profile.subscription.table.subscription')
    },
    {
      field: 'amount',
      headerName: t('profile.subscription.table.amount')
    },
    {
      field: 'option',
      headerName: ''
    }
  ];

  useEffect(() => {
    (async () => await loadSubscription())();
  }, [userStore.user]);

  const loadSubscription = async (): Promise<void> => {
    if (userStore.user) {
      await subscriptionAndPlansStore.getUserSubscriptions(userStore.user.id);
    }
  };

  const handleAction = (id: number, type: SubscriptionTableType): void => {
    const types = {
      upgrade: () => {
        handleUpgrade(id);
      },
      cancel: () => {
        setCurrentSubscriptionId(id);
        setShowCancelSubscription(true);
      },
      renew: () => {
        setCurrentSubscriptionId(id);
        setPaymentType(PaymentType.RENEW);
        setUpgradePlanModal(true);
      }
    };
    types[type]();
  };

  const handleUpdateSubscription = async (): Promise<void> => {
    await loadSubscription();
  };

  const handleCancelSubscription = async (): Promise<void> => {
    if (userStore.user && currentSubscriptionId) {
      try {
        await subscriptionAndPlansStore.unsubscription(currentSubscriptionId, userStore.user.id);
        setConfirmModalShow(true);
      } finally {
        setShowCancelSubscription(false);
        await loadSubscription();
      }
    }
  };

  const getExpireDate = (): string => {
    if (!currentSubscriptionId) {
      return '';
    }
    const currentSubscription =
      subscriptionAndPlansStore.getSubscriptionById(currentSubscriptionId);
    return currentSubscription ? moment(currentSubscription.endsAt).format('MMMM DD, YYYY') : '';
  };

  const getCurrentLocationById = (): Location | null => {
    if (!currentSubscriptionId) {
      return null;
    }
    const currentSubscription =
      subscriptionAndPlansStore.getSubscriptionById(currentSubscriptionId);
    return currentSubscription ? currentSubscription.location : null;
  };

  const handleUpgrade = (subscriptionId: number) => {
    setCurrentSubscriptionId(subscriptionId);
    setPaymentType(PaymentType.UPGRADE);
    setUpgradePlanModal(true);
  };

  return (
    <div className={clsx(classes.container, 'h-100', 'px-4', 'default-color-text')}>
      <h1 className={clsx(classes.title, 'mb-3')}>{t('profile.subscription.subscriptions')}</h1>
      <h2 className={clsx(classes.subTitle, 'mb-3')}>{t('profile.subscription.monthly')}</h2>
      <SubscriptionTable
        columns={columns}
        rows={subscriptionAndPlansStore.subscriptionsForTable.month}
        action={handleAction}
        tableClass="mb-5"
        saveBtnShow={true}
      />

      <h2 className={clsx(classes.subTitle, 'mb-3')}>{t('profile.subscription.annually')}</h2>
      <SubscriptionTable
        columns={columns}
        rows={subscriptionAndPlansStore.subscriptionsForTable.year}
        action={handleAction}
        saveBtnShow={false}
      />

      <UpgradePlanModal
        show={upgradePlan}
        type={paymentType}
        location={getCurrentLocationById()}
        closeModal={() => setUpgradePlanModal(false)}
        subscriptionId={currentSubscriptionId}
        onUpdate={handleUpdateSubscription}
      />

      <CancelSubscriptionModal
        show={showCancelSubscription}
        closeModal={() => setShowCancelSubscription(false)}
        onCancelSubscription={handleCancelSubscription}
        userName={userStore.user ? userStore.user.firstName : ''}
        subscriptionId={currentSubscriptionId}
        onUpgrade={handleUpgrade}
      />

      <ConfirmModal
        title={`${userStore.user?.firstName}, we’re sorry to see you go!`}
        subTitle={`You subscription has been canceled. Access to your data will expire on ${getExpireDate()}`}
        actions={confirmModalActions}
        show={confirmModalShow}
        closeModal={() => setConfirmModalShow(false)}
        onSubmit={() => setConfirmModalShow(false)}
        modalClass={classes.confirmModal}
      />
    </div>
  );
});

export default Subscription;
