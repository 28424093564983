import { IAxiosResponse } from 'utils/types';

export type INotification = {
  id: number;
  type: string;
  data: {
    savedSearchId: number;
    savedSearchName: string;
    newProjectsCount: number;
  };
  seenAt?: Date;
  createdAt: Date;
};

export const NOTIFICATION_REPO = 'NOTIFICATION_REPO';
export interface INotificationRepo {
  getNotifications(): Promise<IAxiosResponse<INotification[]>>;
  markAsRead(): Promise<void>;
  clearNotifications(): Promise<void>;
}

export const NOTIFICATION_SERVICE = 'NOTIFICATION_SERVICE';
export interface INotificationService {
  getNotifications(): Promise<INotification[]>;
  markAsRead(): Promise<void>;
  clearNotifications(): Promise<void>;
}

export interface INotificationStore {
  getNotifications(): Promise<void>;
  markAsRead(): Promise<void>;
  clearNotifications(): Promise<void>;
}
