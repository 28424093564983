export const CONFIG_SERVICE = 'CONFIG_SERVICE';
export interface IConfigService {
  getAppConfig(): Promise<IAppConfig>;
  getNewChecksum(): Promise<ChecksumType>;
  getAppResource<T>(url: string): Promise<T>;
}

export const CONFIG_REPO = 'CONFIG_REPO';
export interface IConfigRepo {
  getAppConfig(): Promise<IDataResponse<IAppConfig>>;
  getNewChecksum(dateStamp: string): Promise<IDataResponse<ChecksumType>>;
  getAppResource<T>(url: string): Promise<IDataResponse<T>>;
}

export type ConfigType = {
  id: number;
  name: string;
  code?: string;
  value?: string;
  file?: string;
  classId?: number;
  typeId?: number;
};

export type ChecksumType = {
  contactDetailTypes: string;
  countries: string;
  contactTypes: string;
  keywordTypes: string;
  locationTypes: string;
  mediaTypes: string;
  propertySubTypes: string;
  projectRoles: string;
  projectStatuses: string;
  propertyTypes: string;
  propertyClasses: string;
  buildingPermitTypes: string;
  billingPeriods: string;
  paymentGateways: string;
  subscriptionStatuses: string;
};

export type ConfigKey = keyof ChecksumType;

export type IConfigs = {
  [key in keyof ChecksumType]: ConfigType[];
};

export interface IAppConfig {
  version: string;
  endpoints: {
    api: string;
  };
  configs: ChecksumType;
  media: {
    avatar: {
      original: string;
      s: string;
      xs: string;
    };
  };
}

export interface IDataResponse<T> {
  data: T;
}
