import {
  ISavedSearchResponse,
  ISavedSearchResultsRepo,
  ISavedSearchResultsService,
  SAVED_SEARCH_RESULTS_REPO
} from './types';
import { injector } from 'utils/injector';
import { IRow, Order } from 'components/Table/types';
import { tableRowGeneration } from 'view/SavedSearches/components/SavedSearchResults/TableRowGeneration';
import { ObjectValues } from 'utils/types';

export class SavedSearchResultsService implements ISavedSearchResultsService {
  private _dashboardRepo: ISavedSearchResultsRepo =
    injector.get<ISavedSearchResultsRepo>(SAVED_SEARCH_RESULTS_REPO);

  async getDashboardData(
    userId: number,
    ids: number[],
    page: number,
    orders: Order[]
  ): Promise<ISavedSearchResponse<IRow[]>> {
    const searchIds: ObjectValues<number> = {};
    ids.forEach((id, index) => {
      const key = `searchId[${index}]`;
      searchIds[key] = id;
    });
    const orderParams: ObjectValues<string> = {};
    orders.forEach((item, index) => {
      const keyField = `order[${index}][field]`;
      const keyOrder = `order[${index}][order]`;
      orderParams[keyField] = item.field;
      orderParams[keyOrder] = item.order;
    });
    const res = (await this._dashboardRepo.getDashboardData(userId, searchIds, page, orderParams))
      .data.result;
    return { ...res, data: tableRowGeneration(res.data) };
  }
}
