import React from 'react';
import { PreRegister } from 'view/Auth/components/PreRegister';
import { clsx } from 'utils/clsx';
import classes from './Footer.module.scss';
import logo from 'assets/icons/logo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.container, 'overflow-hidden')}>
      <div className="container">
        <div className={clsx('d-flex', 'justify-content-between', 'align-items-end', classes.form)}>
          <div>
            <div className="d-flex">
              <div className="ms-3 position-relative pb-3">
                <img src={logo} alt="" />
                <div
                  className={clsx(
                    'position-absolute',
                    'top-50',
                    'start-50',
                    'translate-middle',
                    'rounded-circle',
                    classes.round
                  )}
                />
                <div
                  className={clsx(
                    'position-absolute',
                    'top-50',
                    'start-50',
                    'translate-middle',
                    'rounded-circle',
                    classes.round
                  )}
                />
                <div
                  className={clsx(
                    'position-absolute',
                    'top-50',
                    'start-50',
                    'translate-middle',
                    'rounded-circle',
                    classes.round
                  )}
                />
              </div>
            </div>
            <div className={classes.textWrapper}>
              <h2 className={clsx('text-white', 'mb-4', classes.title)}>{t('home.getStared')}</h2>
              <p className={clsx('text-white', classes.subTitle)}>{t('home.footerText')}</p>
            </div>
            <p className={clsx('text-white', 'opacity-50', 'm-0', classes.copy)}>
              &copy;{new Date().getFullYear()} {t('home.rights')}
            </p>
          </div>
          <PreRegister />
        </div>
      </div>
    </div>
  );
};

export default Footer;
