import {
  CompanyTableData,
  Contacts,
  Detail,
  FavoriteData,
  IRowFavorite,
  Persons,
  PersonTableData,
  ProjectTableData
} from './types';
import { ConfigType, IConfigs } from 'store/ConfigStore/types';
import { IListIcon, IRow } from 'components/Table/types';
import { iconSrcListByNum } from 'utils/icons';
import { ObjectValues, SourceLink } from 'utils/types';
import { useStore } from 'storesProvider/storeContext';
import { BLUE_COLOR, COMPANY, NA, PERSON, PROJECT } from 'utils/constants';
import moment from 'moment';
import { AnalyticsDataRes } from './Analytics/types';
import { numberWithSpaces } from 'helper/numberWithComma';

const getDataFromConfig = (config: ConfigType[], configType: number): string => {
  if (config && config.length) {
    return config.find((item) => item.id === configType)?.name || '';
  }
  return '';
};

const getContactsPhone = (configs: IConfigs, details: Detail[]): string => {
  const phone = details.find((detail) => detail.type === SourceLink.PHONE);
  if (phone) {
    return phone.value;
  }
  return '';
};

const getDetails = (configs: IConfigs, details: Detail[]): IListIcon[] => {
  return details
    .filter((item) => item.type !== SourceLink.PHONE)
    .map((detail) => {
      return {
        icon: iconSrcListByNum[detail.type],
        label: detail.value,
        type: detail.type
      };
    });
};

export const tableRowGeneration = (data: FavoriteData[], systemType: string): IRowFavorite[] => {
  const { configStore } = useStore();
  if (systemType === PROJECT) {
    return data.map((item) => {
      const tableData = item as ProjectTableData;
      return {
        id: tableData.id,
        newItem: false,
        hasNote: tableData.hasNotes,
        role: tableData.role,
        list: tableData.favoritesList,
        fields: [
          {
            field: 'property',
            value: configStore.enums
              ? `${getDataFromConfig(configStore.enums.propertyClasses, tableData.class.id)} - ${
                  tableData.type.name
                }`
              : '',
            additionalValue: tableData.description,
            isTitle: true
          },
          {
            field: 'date',
            additionalValue: moment(tableData.updatedAt).format('MM/DD/YYYY')
          },
          {
            field: 'status',
            status: tableData.status
          },
          {
            field: 'location',
            value: `${tableData.location.city.title} ${tableData.location.state.stateCode}`
          },
          {
            field: 'contacts',
            values: tableData.contacts.map((contact: Contacts) => {
              return {
                label: configStore.enums
                  ? getDataFromConfig(configStore.enums.projectRoles, contact.role).toUpperCase()
                  : '',
                value: contact.name,
                phone: configStore.enums
                  ? getContactsPhone(configStore.enums, contact.details)
                  : '',
                iconItems: configStore.enums ? getDetails(configStore.enums, contact.details) : []
              };
            })
          },
          {
            field: 'value',
            value: `${tableData.value ? `$${numberWithSpaces(tableData.value)}` : NA}`
          }
        ]
      };
    });
  }
  if (systemType === COMPANY) {
    return data.map((item) => {
      const tableData = item as CompanyTableData;
      return {
        id: tableData.id,
        newItem: false,
        role: tableData.role,
        hasNote: tableData.hasNotes,
        list: tableData.favoritesList,
        fields: [
          {
            field: 'property',
            label: configStore.enums
              ? getDataFromConfig(configStore.enums.projectRoles, item.role).toUpperCase()
              : '',
            value: tableData.name,
            additionalValue: tableData.description
          },
          {
            field: 'location',
            value: tableData.location && tableData.location.title ? tableData.location.title : ''
          },
          {
            field: 'contacts',
            values: tableData.persons.map((person: Persons) => {
              return {
                value: person.name,
                phone: configStore.enums ? getContactsPhone(configStore.enums, person.details) : '',
                iconItems: configStore.enums ? getDetails(configStore.enums, person.details) : []
              };
            })
          },
          {
            field: 'projectCount',
            value: `${
              tableData.projectsCount ? `${numberWithSpaces(tableData.projectsCount)}` : NA
            }`
          },
          {
            field: 'totalAmount',
            value: `${
              tableData.projectsValue ? `$${numberWithSpaces(tableData.projectsValue)}` : NA
            }`
          }
        ]
      };
    });
  }
  if (systemType === PERSON) {
    return data.map((item) => {
      const tableData = item as PersonTableData;
      return {
        id: tableData.id,
        newItem: false,
        hasNote: tableData.hasNotes,
        role: tableData.role,
        list: tableData.favoritesList,
        fields: [
          {
            field: 'name',
            value: `${tableData.name}`
          },
          {
            field: 'projectCount',
            value: `${
              tableData.projectsCount ? `${numberWithSpaces(tableData.projectsCount)}` : NA
            }`
          },
          {
            field: 'totalAmount',
            value: `${
              tableData.projectsValue ? `$${numberWithSpaces(tableData.projectsValue)}` : NA
            }`
          }
        ]
      };
    });
  }
  return [];
};

export const tableAnalyticRowGeneration = (
  data: AnalyticsDataRes[],
  colorTypes: ObjectValues<string>,
  currentPage: number
): IRow[] => {
  return data.map((item, idx) => {
    return {
      id: `${currentPage}-${idx + 1}`,
      newItem: false,
      fields: [
        {
          field: 'details',
          value: `${item.propertyType} - ${item.propertySubType}`
        },
        {
          field: 'type',
          colorType: colorTypes[item.buildingPermitType] || BLUE_COLOR,
          value: item.buildingPermitType
        },
        {
          field: 'value',
          value: `$${numberWithSpaces(item.value)}`
        }
      ]
    };
  });
};
