import React, { FC, useCallback, useEffect, useId, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'utils/clsx';
import classes from './Search.module.scss';
import searchIcon from 'assets/icons/search.svg';
import illustrationIcon from 'assets/icons/illustration.svg';
import { Button, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { FoundLocation, SearchProjects } from './types';
import { useDebounce } from 'hooks/useDebounce';
import { useStore } from 'storesProvider/storeContext';
import { SearchList } from 'components';
import { DEBOUNCE_DELAY } from 'utils/constants';
import { useKeyboardCallbacks } from 'hooks/useKeyboardCallbacks';
import { usePrevious } from 'hooks/usePrevious';

export const Search: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alertStore, searchStore, filtersStore } = useStore();
  const [searchString, setSearchString] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchProjects | null>(null);
  const [showSearchList, setShowSearchList] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce<string>(searchString, DEBOUNCE_DELAY);
  const [lastInputValue, setLastInputValue] = useState<string>('');
  const [canLoadLocations, setCanLoadLocations] = useState<boolean>(true);

  const prevTerm = usePrevious(debouncedSearchTerm);

  const id = useId();

  const inputSearch = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleInput = useCallback((name: string, value: string) => {
    setCanLoadLocations(true);
    setSearchString(value);
    setLastInputValue(value);
  }, []);

  const setValueWithoutFetching = useCallback((value: string) => {
    setCanLoadLocations(false);
    setSearchString(value);
  }, []);

  const loadLocations = useCallback(
    async (loadAnyway = false) => {
      if (!prevTerm.length && !debouncedSearchTerm.length && !loadAnyway) return;
      await searchStore.searchProjects(debouncedSearchTerm);
      setSearchResults(searchStore.foundLocations);
      setShowSearchList(true);
      if (!canLoadLocations && !loadAnyway) {
        setCanLoadLocations(true);
        setShowSearchList(false);
      }
    },
    [debouncedSearchTerm, canLoadLocations, prevTerm]
  );

  const handleInputFocus = useCallback(async () => {
    if (showSearchList) return;
    setCanLoadLocations(true);
    await loadLocations(true);
  }, [loadLocations, showSearchList]);

  const onSearchListChange = (item: FoundLocation): void => {
    filtersStore.setSearchParams('area', []);
    navigate(`/search-projects/${item.stateCode.toLowerCase()}/${item.canonicalTitle}`);
    setShowSearchList(false);
    setLastInputValue(item.title);
  };

  const handleCloseList = (): void => {
    setShowSearchList(false);
    setSearchResults(null);
  };

  const searchProjectsAction = (): void => {
    alertStore.errorAlert(t('savedSearch.inputText'));
    const currentInput = inputSearch.current.querySelector('input');
    if (currentInput) {
      currentInput.focus();
    }
  };

  const onInputBlur = useCallback(() => {
    setTimeout(() => setShowSearchList(false), 150);
  }, []);

  const { handleKeyUp, handleKeyDown, handleKeyEnter, handleKeyEscape } = useKeyboardCallbacks({
    searchResults,
    setSearchResults,
    lastInputValue,
    setLastInputValue,
    setValueWithoutFetching,
    setShowSearchList
  });

  useEffect(() => {
    if (!canLoadLocations) return;
    (async () => await loadLocations())();
  }, [debouncedSearchTerm, canLoadLocations]);

  return (
    <div
      className={clsx(
        classes.wrapper,
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'flex-column',
        'bg-white'
      )}>
      <div className={classes.container}>
        <div className={clsx('position-relative', classes.searchTextWrapper)}>
          <div className="d-flex justify-content-center">
            <div className={classes.titleWrapper}>
              <h1 className={clsx(classes.title, 'text-center', 'mb-3')}>
                {t('savedSearch.search')}
              </h1>
              <p className={clsx(classes.subtitle, 'text-center')}>
                {t('savedSearch.searchTitle')}
              </p>
            </div>
          </div>
          <div className={clsx(classes.searchInputWrapper, 'd-flex', 'mb-5')}>
            <div className="flex-grow-1 position-relative" ref={inputSearch}>
              <Input
                data-input-id={id}
                name="search"
                type="text"
                autoComplete="off"
                value={searchString}
                onInput={handleInput}
                onFocus={handleInputFocus}
                onBlur={onInputBlur}
                inputClass="w-100 px-4 py-3"
                searchIcon={true}
                data-test-element="dashboard-search-input">
                <div
                  data-input-id={id}
                  className={clsx(
                    'd-flex',
                    'w-100',
                    'align-items-center',
                    'h-100',
                    'px-4',
                    'py-3',
                    classes.inputSearchOverlayText
                  )}>
                  <img src={searchIcon} alt="" />
                  &nbsp;{t('savedSearch.inputText')}
                </div>
              </Input>
              <SearchList
                listResponse={searchResults}
                selectedLocation={searchStore.selectedLocation}
                onChange={onSearchListChange}
                styleTop="58"
                show={showSearchList}
                closeList={handleCloseList}
                inputId={id}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onKeyEnter={handleKeyEnter}
                onKeyEscape={handleKeyEscape}
              />
            </div>
            <Button type="primary" className="px-4 py-3 ms-2" onClick={searchProjectsAction}>
              {t('savedSearch.searchForProjectsNow')}
            </Button>
          </div>
        </div>
        <div className={clsx('d-flex', 'justify-content-center', classes.searchImg)}>
          <img src={illustrationIcon} alt="" />
        </div>
      </div>
    </div>
  );
};
