import React, { FC } from 'react';
import classes from '../CancelSubscriptionModal.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const List: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.wrapperBlock, 'd-flex', 'flex-column')}>
      <div className="px-4 py-3">
        <span className="d-block mb-3 default-color-text font-kraftig">
          {t('cancelSubscription.whatLose')}
        </span>
        <ul className={clsx(classes.list, 'list-unstyled', 'mt-2')}>
          <li className="d-flex align-items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.allSaved')}
          </li>
          <li className="d-flex align-items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.notifications')}
          </li>
          <li className="d-flex align-items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.unlimitedProject')}
          </li>
          <li className="d-flex align-items-center mb-2">
            <CloseIcon /> {t('cancelSubscription.advantage')}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default List;
