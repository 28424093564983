import React, { FC } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';
import { Button } from 'components';

interface IProps {
  title: string;
  info: string;
  buttonText: string;
  onBtnClick: () => void;
  isLoading?: boolean;
}

const InfoModal: FC<IProps> = ({ title, info, onBtnClick, buttonText, isLoading }) => {
  return (
    <div className={clsx(classes.form, 'bg-white', 'py-4')}>
      <div className="d-flex align-items-center flex-column">
        {isLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <h1 className={classes.title}>{title}</h1>
            <p className={clsx(classes.subTitle, classes.resetSubtitle, 'mb-4', 'text-center')}>
              {info}
            </p>
            <div className="px-4 w-100">
              <Button
                type="primary"
                onClick={onBtnClick}
                className={clsx('w-100', classes.formBtn)}
                data-test-element="reset-password-continue-button">
                {buttonText}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoModal;
