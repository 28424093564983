import React, { FC, ReactNode } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Alert.module.scss';
import { ReactComponent as TickIcon } from 'assets/icons/tick-white.svg';
import { ReactComponent as CloseWhiteIcon } from 'assets/icons/close-white.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ERROR, SUCCESS } from 'utils/constants';

interface Props {
  text: string | ReactNode;
  type: string;
  closeAlert?: () => void;
}

export const Alert: FC<Props> = ({ text, type, closeAlert }) => {
  return (
    <div
      className={clsx(
        'alert',
        'd-flex',
        'align-items-center',
        'm-auto',
        'mb-2',
        classes.customAlert
      )}
      role="alert">
      {type === SUCCESS && (
        <div
          className={clsx(
            classes.success,
            classes.iconItem,
            'rounded-circle',
            'me-3',
            'position-relative'
          )}>
          <span className="position-absolute top-50 start-50 translate-middle lh-1">
            <TickIcon style={{ width: '14px' }} />
          </span>
        </div>
      )}
      {type === ERROR && (
        <div
          className={clsx(
            classes.error,
            classes.iconItem,
            'rounded-circle',
            'me-3',
            'position-relative'
          )}>
          {type === ERROR && (
            <span className="position-absolute top-50 start-50 translate-middle">
              <CloseWhiteIcon style={{ width: '22px' }} />
            </span>
          )}
        </div>
      )}
      <p className="mb-1">{text}</p>
      <CloseIcon
        className={clsx('position-relative', classes.close, 'ms-3')}
        onClick={closeAlert}
        role="button"
      />
    </div>
  );
};
