import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { clsx } from 'utils/clsx';
import classes from 'components/Select/Select.module.scss';
import styles from '../SubHeader.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'components';
import { getConfigs } from '../helpers/getConfigs';
import { IOptionsConfig } from '../../../types';
import { IMinMax, IMoreValues } from '../types';
import { maxOptions, minOptions } from '../mock';
import { IOption } from 'components/Select/types';
import { getInitialMoreValues } from '../helpers/getInitialMoreValues';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { useSearchParams } from 'react-router-dom';
import { Input } from 'components';
import { ENTER } from 'components/SearchList/constants';
import { childOf } from 'helper/childOf';

const MoreDropdown = observer(() => {
  const { filtersStore, userStore } = useStore();
  const { t } = useTranslation();
  const configs: IOptionsConfig = getConfigs();
  const initValues = getInitialMoreValues();
  const [, setParams] = useSearchParams();

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IMoreValues>(initValues);

  const selectRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const params = filtersStore.searchParams;
    setFormValues({
      units: {
        min: minOptions.find((option) => option.value === String(params.units.min)) || null,
        max: minOptions.find((option) => option.value === String(params.units.max)) || null
      },
      levels: {
        min: minOptions.find((option) => option.value === String(params.levels.min)) || null,
        max: minOptions.find((option) => option.value === String(params.levels.max)) || null
      },
      status: configs.projectStatuses.find((status) => status.id === params.statuses[0]) || null,
      keywords: params.keywords
    });
  }, [filtersStore.searchParams]);

  const clickOutside = useCallback((e: MouseEvent): void => {
    if (!e.composedPath().includes(selectRef.current as EventTarget)) {
      setIsOpened(false);
    }
  }, []);

  const toggleSelect = useCallback((e: React.MouseEvent): void => {
    if (e.nativeEvent.composedPath().includes(formRef.current as EventTarget)) return;
    setIsOpened((prev) => !prev);
  }, []);

  const handleFormChange = useCallback((option: IOption, name: string) => {
    if (!userStore.user) {
      setParams('auth=sign-up&extended=true');
      return;
    }
    const [key, subKey]: string[] = name.split('-');
    if (!subKey) setFormValues((prev) => ({ ...prev, [key]: option }));
    if (subKey)
      setFormValues((prev) => ({
        ...prev,
        [key]: { ...prev[key as keyof Omit<IMoreValues, 'keywords'>], [subKey]: option }
      }));
  }, []);

  const handleSubmit = useCallback(() => {
    for (const key in formValues) {
      switch (key) {
        case 'keywords':
          filtersStore.setSearchParams(key, formValues[key]);
          break;
        case 'status':
          filtersStore.onSelectChange(formValues[key] as IOption, 'statuses');
          break;
        default: {
          const { min, max } = formValues[key as keyof IMoreValues] as IMinMax;
          filtersStore.onInputChange(
            {
              min: min?.value ? Number(min?.value) : null,
              max: max?.value ? Number(max?.value) : null
            },
            key
          );
        }
      }
    }
    setIsOpened(false);
  }, [formValues]);

  const handleResetField = useCallback((name: string) => {
    const [key, subKey]: string[] = name.split('-');
    if (!subKey) setFormValues((prev) => ({ ...prev, [key]: null }));
    if (subKey)
      setFormValues((prev) => ({
        ...prev,
        [key]: { ...prev[key as keyof Omit<IMoreValues, 'keywords'>], [subKey]: null }
      }));
  }, []);

  const handleKeywordChange = useCallback((name: string, val: string) => {
    setFormValues((prev) => ({ ...prev, [name]: val }));
  }, []);

  const keyPress = useCallback((e: KeyboardEvent): void => {
    if (e.code === ENTER) {
      if (
        !childOf(document.activeElement, selectRef.current) &&
        document.activeElement !== selectRef.current
      )
        setIsOpened(false);
      if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
    }
  }, []);

  const isActive = useMemo<boolean>(() => {
    return (
      !!filtersStore.searchParams.statuses.length ||
      !!filtersStore.searchParams.units.min ||
      !!filtersStore.searchParams.units.max ||
      !!filtersStore.searchParams.levels.max ||
      !!filtersStore.searchParams.levels.min ||
      !!filtersStore.searchParams.keywords?.length
    );
  }, [filtersStore.searchParams]);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    if (!isOpened)
      return () => {
        window.removeEventListener('keydown', keyPress);
      };
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
    };
  }, [isOpened]);

  return (
    <div
      role="button"
      className={clsx(
        classes.select,
        'position-relative',
        'd-flex',
        'align-items-center',
        'justify-content-between',
        'me-2',
        isOpened && classes.activeSelect,
        styles.searchSelect,
        isActive && styles.active
      )}
      tabIndex={0}
      data-test-element="search-projects-valuation-dropdown"
      onClick={toggleSelect}
      ref={selectRef}>
      <>
        <span role="button" className={clsx('d-block w-100', styles.text, 'font-kraftig')}>
          {t('searchProject.more')}
        </span>
        {isOpened && (
          <div
            className={clsx(classes.optionWrapper, styles.moreContent, 'position-absolute')}
            ref={formRef}>
            <div className="py-4">
              <div className="d-flex px-4 mb-4">
                <h3 className={clsx(styles.moreTitle, 'm-0')}>{t('searchProject.numberUnits')}</h3>
                <Select
                  options={minOptions}
                  value={formValues.units.min}
                  disableMore={formValues.units.max?.value}
                  onChange={handleFormChange}
                  placeholder="No Min"
                  placeholderClassName={styles.placeholder}
                  name="units-min"
                  className={clsx('default-color-text', styles.moreSelect, styles.moreField)}
                  onReset={handleResetField}
                  data-test-element="search-projects-minUnits-select"
                />
                <div className={styles.moreInputDelimiter} />
                <Select
                  options={maxOptions}
                  value={formValues.units.max}
                  disableLess={formValues.units.min?.value}
                  onChange={handleFormChange}
                  placeholder="No Max"
                  placeholderClassName={styles.placeholder}
                  name="units-max"
                  className={clsx('default-color-text', styles.moreSelect, styles.moreField)}
                  onReset={handleResetField}
                  data-test-element="search-projects-maxUnits-select"
                />
              </div>
              <div className="d-flex px-4 mb-4">
                <h3 className={clsx(styles.moreTitle, 'm-0')}>{t('searchProject.levels')}</h3>
                <Select
                  options={minOptions}
                  value={formValues.levels.min}
                  disableMore={formValues.levels.max?.value}
                  onChange={handleFormChange}
                  placeholder="No Min"
                  placeholderClassName={styles.placeholder}
                  name="levels-min"
                  className={clsx('default-color-text', styles.moreSelect, styles.moreField)}
                  onReset={handleResetField}
                  data-test-element="search-projects-minLevels-select"
                />
                <div className={styles.moreInputDelimiter} />
                <Select
                  options={maxOptions}
                  value={formValues.levels.max}
                  disableLess={formValues.levels.min?.value}
                  onChange={handleFormChange}
                  placeholder="No Max"
                  placeholderClassName={styles.placeholder}
                  name="levels-max"
                  className={clsx('default-color-text', styles.moreSelect, styles.moreField)}
                  onReset={handleResetField}
                  data-test-element="search-projects-maxLevels-select"
                />
              </div>
              <div className="d-flex px-4 mb-4">
                <h3 className={clsx(styles.moreTitle, 'm-0')}>{t('searchProject.status')}</h3>
                <Select
                  options={configs.projectStatuses}
                  value={formValues.status}
                  onChange={handleFormChange}
                  data-test-element="search-project-status-select"
                  placeholder="Any"
                  placeholderClassName={styles.placeholder}
                  name="status"
                  className={clsx(
                    'default-color-text',
                    'w-100',
                    styles.statusSelect,
                    styles.moreField
                  )}
                  onReset={handleResetField}
                />
              </div>
              <div className="d-flex px-4">
                <h3 className={clsx(styles.moreTitle, 'm-0')}>{t('searchProject.keywords')}</h3>
                <Input
                  type="text"
                  value={formValues.keywords}
                  onInput={handleKeywordChange}
                  name="keywords"
                  inputClass={clsx(
                    'default-color-text',
                    styles.moreField,
                    styles.keywordsInput,
                    'ms-auto'
                  )}
                />
              </div>
            </div>
            <div className={styles.divider} />
            <div className="d-flex justify-content-between p-4">
              <Button
                type="primary"
                className={clsx('px-4', styles.moreBtn)}
                onClick={handleSubmit}>
                <span className="font-kraftig">{t('searchProject.done')}</span>
              </Button>
            </div>
          </div>
        )}
      </>
      <div
        className={clsx(
          classes.icon,
          'position-absolute',
          'top-50',
          'd-flex',
          'align-items-center',
          isOpened && classes.active
        )}
        role="button">
        <ArrowIcon />
      </div>
    </div>
  );
});

export default MoreDropdown;
