import { IProject, Location } from '../../types';
import { DivIcon, LatLngExpression, LeafletMouseEvent, Polygon, LatLngTuple } from 'leaflet';
import { IAxiosResponse } from 'utils/types';

export type MarkerData = {
  projectId: number;
  location: Location;
  value: number;
  status: string;
};

export type Shape = LatLngExpression[][] | LatLngExpression[][][];
export type ShapeItem = LatLngTuple;

export type DetailInfo = {
  id: number;
  title: string;
  fullTitle: string;
  type: number;
  coordinates: [number, number];
  canonicalTitle: string;
  stateCode: string;
  shape: Shape;
};

export type SelectMarker = {
  event: LeafletMouseEvent;
  data: MarkerData;
};

export interface IObserverMarkerChange extends SelectMarker {
  type: string;
}

export interface IMapStore {
  initMap(): void;
  initCluster(): void;
  initMarkers(projects: IProject[]): void;
  customMarker(value: number, statusColor: string): DivIcon;
  generateMarkerData(projects: IProject[]): void;
  markersCoordinate(): [number, number][];
  addMarkersOnTheMap(): Promise<void>;
  getPolygonById(): Promise<void>;
  getPolygonsNeighbour(): Promise<void>;
  createPolygon(location: DetailInfo, color: string): Polygon;
}

export const MAP_REPO = 'MAP_REPO';
export interface IMapRepo {
  getLocationInfo(locationId: number): Promise<IAxiosResponse<DetailInfo>>;
  getNeighbourLocations(locationId: number): Promise<IAxiosResponse<DetailInfo[]>>;
}

export const MAP_SERVICE = 'MAP_SERVICE';
export interface IMapService {
  getLocationInfo(locationId: number): Promise<DetailInfo>;
  getNeighbourLocations(locationId: number): Promise<DetailInfo[]>;
}
