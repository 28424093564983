export interface IFavoriteEntity {
  id: number;
  coordinates: DOMRect;
  type: EntityType;
  role: FavoriteRole;
  key: IFavoriteKey;
  name?: string;
}

export type EntityType = 10 | 20 | 30;
export type FavoriteRole = 10 | 20 | 30 | 40;

export enum FavoriteTypes {
  Person = 10,
  Company = 20,
  Project = 30
}

export enum FavoriteRoles {
  Architect = 10,
  Owner = 20,
  Contractor = 30,
  Project = 40
}

export const entityTypes: { [key: number]: string } = {
  10: 'person',
  20: 'company',
  30: 'project'
};

export enum FavoriteSubTypes {
  recent = 'recent',
  regular = 'regular'
}

export type IFavoriteKey =
  | 'projects'
  | 'table'
  | 'contacts'
  | 'recentContacts'
  | 'sidebar'
  | 'recentProjects'
  | 'openProjects'
  | 'fullInfoModal'
  | 'closedProjects';
