import React, { FC, useCallback, useEffect, useRef } from 'react';
import classes from './SearchList.module.scss';
import { clsx } from 'utils/clsx';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { useTranslation } from 'react-i18next';
import SearchItem from './components/SearchItem';
import { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE } from './constants';

interface Props {
  listResponse: SearchProjects | null;
  selectedLocation: FoundLocation | null;
  show: boolean;
  styleTop?: string;
  onChange: (item: FoundLocation) => void;
  closeList: () => void;
  inputId: string;
  onKeyUp: () => void;
  onKeyDown: () => void;
  onKeyEnter: () => void;
  onKeyEscape: () => void;
}

const SearchList: FC<Props> = ({
  listResponse,
  selectedLocation,
  show,
  styleTop = '0',
  onChange,
  inputId,
  closeList,
  onKeyUp,
  onKeyDown,
  onKeyEnter,
  onKeyEscape
}) => {
  const { t } = useTranslation();
  const searchList = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (show) {
      window.addEventListener('click', clickOutside);
      window.addEventListener('keydown', keyPress);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
      window.removeEventListener('keydown', keyPress);
    };
  }, [show]);

  const keyPress = useCallback(
    (e: KeyboardEvent): void => {
      if (e.code === ARROW_UP) {
        onKeyUp();
      } else if (e.code === ARROW_DOWN) {
        onKeyDown();
      } else if (e.code === ENTER) {
        onKeyEnter();
      } else if (e.code === ESCAPE) {
        onKeyEscape();
      }
    },
    [onKeyUp, onKeyDown, onKeyEnter, onKeyEscape]
  );

  const clickOutside = (e: MouseEvent): void => {
    const searchRefs = document.querySelectorAll(`[data-input-id="${inputId}"]`);
    if (e.target !== searchList.current && ![...searchRefs].includes(e.target as Element)) {
      closeList();
    }
  };

  if (!show || !listResponse || (!listResponse.subscriptionCount && !listResponse.count)) {
    return <></>;
  }

  return (
    <div
      className={clsx(classes.wrapper, 'position-absolute', 'w-100', 'z-index-20', 'bg-white')}
      style={{ top: `${styleTop}px` }}
      ref={searchList}>
      {listResponse.subscriptionCount > 0 && (
        <>
          <div
            className={clsx(
              classes.listInfo,
              'text-uppercase',
              'px-2',
              'py-2',
              listResponse.subscriptionCount > 0 && 'mb-2'
            )}>
            <b className="font-kraftig">
              {t('savedSearch.searchSubscriptionsResults')}
              <span className="ms-2">{listResponse.subscriptionCount}</span>
            </b>
          </div>
          <ul
            className={clsx(
              classes.list,
              'w-100',
              'p-0',
              'pe-0',
              'm-0',
              'list-unstyled',
              listResponse.subscriptionCount > 0 && 'pb-2'
            )}
            style={{ top: `${styleTop}px` }}
            data-test-element="search-list">
            {listResponse.subscriptionData.map((item: FoundLocation) => (
              <SearchItem
                item={item}
                key={`search-item-${item.id}-${item.targetTitle
                  ?.toLowerCase()
                  .split(' ')
                  .join('')}`}
                handleClick={onChange}
                selectedLocation={selectedLocation}
                focused={item.focused}
              />
            ))}
          </ul>
        </>
      )}
      {listResponse.count > 0 && (
        <>
          <div className={clsx(classes.listInfo, 'text-uppercase', 'px-2', 'py-2', 'mb-2')}>
            <b className="font-kraftig">
              {t('savedSearch.searchResults')} <span className="ms-2">{listResponse.count}</span>
            </b>
          </div>
          <ul
            className={clsx(classes.list, 'w-100', 'p-0', 'pe-0', 'm-0', 'list-unstyled', 'pb-2')}
            style={{ top: `${styleTop}px` }}
            data-test-element="search-list">
            {listResponse.data.map((item: FoundLocation) => (
              <SearchItem
                item={item}
                key={`search-item-${item.id}-${item.targetTitle
                  ?.toLowerCase()
                  .split(' ')
                  .join('')}`}
                handleClick={onChange}
                selectedLocation={selectedLocation}
                focused={item.focused}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default SearchList;
