import {
  DetailInfo,
  IMapService,
  IMapStore,
  IObserverMarkerChange,
  MAP_SERVICE,
  MarkerData,
  SelectMarker,
  Shape
} from './types';
import { makeAutoObservable, observe } from 'mobx';
import L, {
  CircleMarker,
  DivIcon,
  LatLngBoundsLiteral,
  Layer,
  LayerGroup,
  LeafletMouseEvent,
  Map,
  Marker,
  MarkerClusterGroup,
  Polygon
} from 'leaflet';
import { IProject } from '../../types';
import { valueFormatter } from 'helper/valueFormatter';
import { injector } from 'utils/injector';
import { FoundLocation } from 'view/Search/types';
import { BLUE_COLOR, GREY_COLOR, NA, PURPLE_COLOR } from 'utils/constants';
import { ConfigType } from 'store/ConfigStore/types';
import {
  clusterSizeByCount,
  getMarkerColorByStatus,
  getTooltipPosition,
  markerRadiusByPrice,
  markerSizeByPrice
} from './helper';
import MarkerRed from 'assets/icons/marker-red.svg';
import MarkerBlue from 'assets/icons/marker-blue.svg';
import { getProjectById } from 'view/SearchProjects/helpers/getProjectById';
import { numberWithSpaces } from 'helper/numberWithComma';

export class MapStore implements IMapStore {
  private _mapService: IMapService = injector.get<IMapService>(MAP_SERVICE);

  constructor() {
    makeAutoObservable<MapStore>(this);
    observe(this, 'selectMarker', (change): void => {
      if (change.oldValue) {
        const { event, data } = change.oldValue as IObserverMarkerChange;
        this.setPrevSelectMarker(event, data);
      }
    });
  }

  map: Map | null = null;
  cluster: MarkerClusterGroup | null = null;
  layer: Marker[] = [];
  markerData: MarkerData[] = [];
  currentLocation: FoundLocation | null = null;
  layerGroup: LayerGroup | null = null;
  allLocations: DetailInfo[] = [];
  selectMarker: SelectMarker | null = null;
  prevSelectMarker: SelectMarker | null = null;
  selectedLocation: FoundLocation | null = null;
  useFitBounds = true;
  viewArea: [number, number][] = [];

  setMap(map: Map): void {
    this.map = map;
  }

  setCluster(cluster: MarkerClusterGroup): void {
    this.cluster = cluster;
  }

  setLayer(layer: Marker): void {
    this.layer.push(layer);
  }

  setMarkerData(markers: MarkerData[]): void {
    this.markerData = markers;
  }

  setCurrentLocation(location: FoundLocation | null): void {
    this.currentLocation = location;
  }

  setLayerGroup(layerGroup: LayerGroup): void {
    this.layerGroup = layerGroup;
  }

  setAllLocations(location: DetailInfo[]): void {
    this.allLocations = this.allLocations.concat(location);
  }

  setUpdateLocations(location: DetailInfo[]): void {
    this.allLocations = location;
  }

  setSelectMarker(e: LeafletMouseEvent, data: MarkerData): void {
    this.selectMarker = {
      event: e,
      data
    };
  }

  setPrevSelectMarker(e: LeafletMouseEvent, data: MarkerData): void {
    this.prevSelectMarker = {
      event: e,
      data
    };
  }

  setSelectedLocation(location: FoundLocation | null): void {
    this.selectedLocation = location;
  }

  setViewArea(area: [number, number][]): void {
    this.viewArea = area;
  }

  clearSelectMarker(): void {
    this.selectMarker = null;
  }

  initMap(): void {
    const map = L.map('mapId', {
      zoom: 13,
      preferCanvas: true
    });
    map.on('zoomend', () => this.getViewPort());
    map.on('moveend', () => this.getViewPort());
    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution:
        '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    this.setMap(map);
  }

  initCluster(): void {
    this.setCluster(
      L.markerClusterGroup({
        // TODO: remove after testing
        // spiderfyOnMaxZoom: false,
        // showCoverageOnHover: false,
        // zoomToBoundsOnClick: false,
        chunkedLoading: true,
        iconCreateFunction: (cluster) => {
          return L.divIcon({
            className: `${clusterSizeByCount(
              cluster.getChildCount()
            )} custom-circle-cluster d-flex rounded-circle justify-content-center align-items-center font-kraftig`,
            html: '<span>' + cluster.getChildCount() + '</span>'
          });
        }
      })
      // TODO: remove after testing
      //   // L.markerClusterGroup({
      //   //   iconCreateFunction: (cluster) => {
      //   //     const clusterMarkers: Marker[] = cluster.getAllChildMarkers();
      //   //     let totalValue = 0;
      //   //     for (let i = 0; i < clusterMarkers.length; i++) {
      //   //       totalValue += Number(clusterMarkers[i].options.alt);
      //   //     }
      //   //     return L.divIcon({
      //   //       className: 'd-inline-block p-2 bg-white default-shadow customCluster',
      //   //       html: `<span>Total count: </span><b class="font-kraftig">${cluster.getChildCount()}</b><br /><span>Total value: </span><b class="font-kraftig">${
      //   //         totalValue ? '$' : ''
      //   //       }${valueFormatter(totalValue)}</b>`
      //   //     });
      //   //   }
      //   // })
    );
    if (!this.cluster) return;
    // this.map?.addLayer(this.cluster);
  }

  async initMarkers(projects: IProject[]): Promise<void> {
    if (projects.length) {
      this.generateMarkerData(projects);
      await this.addMarkersOnTheMap();
      return;
    }
    this.cluster?.clearLayers();
    if (this.layer) {
      this.layer.forEach((marker) => marker.remove());
    }
  }

  async initPolygons(): Promise<void> {
    if (this.allLocations.length) {
      if (this.allLocations[0].type !== this.getCurrentType()) {
        this.setUpdateLocations([]);
        this.layerGroup?.remove();
      }
      if (!this.locationOnTheList()) {
        await this.getPolygonById();
      }
      await this.getPolygonsNeighbour();
      this.deletedDuplicateLocations();
      this.setPolygons();
    } else {
      await this.getPolygonById();
      await this.getPolygonsNeighbour();
      this.setPolygons();
    }
  }

  getViewPort(): void {
    if (this.map) {
      this.setViewArea([
        [this.map.getBounds().getNorthWest().lng, this.map.getBounds().getNorthWest().lat],
        [this.map.getBounds().getNorthEast().lng, this.map.getBounds().getNorthEast().lat],
        [this.map.getBounds().getSouthEast().lng, this.map.getBounds().getSouthEast().lat],
        [this.map.getBounds().getSouthWest().lng, this.map.getBounds().getSouthWest().lat],
        [this.map.getBounds().getNorthWest().lng, this.map.getBounds().getNorthWest().lat]
      ]);
    }
  }

  getCurrentType(): number {
    return typeof this.currentLocation?.type === 'number'
      ? this.currentLocation?.type
      : (this.currentLocation?.type as ConfigType).id;
  }

  locationOnTheList(): boolean {
    return !!this.allLocations.find((location) => location.id === this.currentLocation?.id);
  }

  // TODO: probably move to helper
  deletedDuplicateLocations(): void {
    const used: { [key: number]: number } = {};
    this.setUpdateLocations(
      this.allLocations.filter((obj) => {
        return obj.id in used ? 0 : (used[obj.id] = 1);
      })
    );
  }

  // TODO: remove after testing
  // customMarker(value: number): DivIcon {
  //   return L.divIcon({
  //     className: 'customMarker',
  //     html: `${value ? '$' : ''}${valueFormatter(value)}`
  //   });
  // }
  // customMarker(value: number): DivIcon {
  //   return L.divIcon({
  //     className: `${markerSizeByPrice(value)}`,
  //     html: `${MarkerRed}`
  //   });
  // }
  customMarker(value: number): DivIcon {
    if (this.map && this.map.getZoom() === 18) {
      return this.getMarkerMax(value);
    }
    return this.getMarkerRed(value);
  }

  //TODO: Update to class in the future
  getMarkerRed(value: number): DivIcon {
    return L.icon({
      iconUrl: MarkerRed,
      className: `${markerSizeByPrice(value)}`
    });
  }

  //TODO: Update to class in the future
  getMarkerBlue(value: number): DivIcon {
    return L.icon({
      iconUrl: MarkerBlue,
      className: `${markerSizeByPrice(value)}`
    });
  }

  //TODO: Update to class in the future
  getMarkerMax(value: number): DivIcon {
    return L.divIcon({
      // html: `${MarkerMax}`,
      className: 'custom-marker-max font-kraftig',
      html: `${valueFormatter(value)}`
    });
    // return L.icon({
    //   iconUrl: MarkerMax
    //   // className: `${markerSizeByPrice(value)}`
    // });
  }

  //TODO: Update to class in the future
  getMarkerMaxBlue(value: number): DivIcon {
    return L.divIcon({
      // html: `${MarkerMax}`,
      className: 'custom-marker-max font-kraftig',
      html: `${valueFormatter(value)}`
    });
  }

  generateMarkerData(projects: IProject[]): void {
    this.setMarkerData(
      projects.map((project) => ({
        projectId: project.id,
        location: {
          ...project.location.address,
          coordinates: [...project.location.address.coordinates].reverse() as [number, number]
        },
        value: project.value,
        status: project.status.name
      }))
    );
  }

  markersCoordinate(): [number, number][] {
    return this.markerData.map((data) => data.location.coordinates);
  }

  async addMarkersOnTheMap(): Promise<void> {
    if (this.markerData.length && this.map && this.cluster) {
      this.cluster.clearLayers();
      if (this.layer) {
        this.layer.forEach((marker) => marker.remove());
      }
      // const markers = [];
      this.markerData.forEach((data) => {
        // TODO: remove after testing
        // markers.push(
        //   L.circleMarker(data.location.coordinates, {
        //     fillOpacity: 1,
        //     radius: markerRadiusByPrice(data.value),
        //     color: PURPLE_COLOR
        //   }).on('click', (e) => this.setSelectMarker(e, data))
        // );
        // this.setLayer(
        //   L.circleMarker(data.location.coordinates, {
        //     fillOpacity: 1,
        //     radius: markerRadiusByPrice(data.value),
        //     color: PURPLE_COLOR
        //   }).on('click', (e) => this.setSelectMarker(e, data))
        // );
        // const marker = L.circleMarker(data.location.coordinates, {
        //   fillOpacity: 1,
        //   radius: markerRadiusByPrice(data.value),
        //   color: PURPLE_COLOR
        // }).on('click', (e) => this.setSelectMarker(e, data));
        const project = getProjectById(data.projectId);
        const marker = L.marker(data.location.coordinates, {
          icon: this.customMarker(data.value)
        })
          .on('click', (e) => {
            this.setSelectMarker(e, data);
            this.updateIconByClick();
          })
          .on('mouseover', (e) => {
            if (this.map?.getZoom() === 18) {
              e.target.getElement().classList.add('select-marker-max');
            } else {
              e.target.setIcon(this.getMarkerBlue(data.value));
            }
            marker
              .bindTooltip(
                `<div> 
                <h1 class="tooltip-value font-kraftig mb-2">${
                  project?.value ? `$${numberWithSpaces(project.value)}` : NA
                }</h1>
                <h2 class="tooltip-title font-kraftig">${project?.class.name} - ${
                  project?.type.name
                }</h2>
                <p class="tooltip-description">${
                  project?.permitType?.name || project?.description || ''
                }</p>
              </div>`,
                {
                  direction: e.containerPoint.y >= 110 ? 'top' : 'bottom',
                  opacity: 1,
                  className: `${
                    e.containerPoint.y >= 110 ? 'custom-top-tooltip' : 'custom-bottom-tooltip'
                  } ${
                    this.map?.getZoom() !== 18
                      ? getTooltipPosition(data.value)
                      : 'tooltip-for-marker-max'
                  }`
                }
              )
              .openTooltip();
          })
          .on('mouseout', (e) => {
            if (this.map?.getZoom() === 18) {
              if (e.target.getElement() !== this.selectMarker?.event.target.getElement()) {
                e.target.getElement().classList.remove('select-marker-max');
              }
            } else {
              if (e.target.getElement() !== this.selectMarker?.event.target.getElement()) {
                e.target.setIcon(this.getMarkerRed(data.value));
              }
            }
          });
        // this.cluster?.addLayer(marker);
        if (this.map?.getZoom() === 18) {
          this.cluster?.addLayer(marker);
          // this.layer.forEach((marker) => this.cluster?.addLayer(marker));
        } else {
          if (this.map) {
            const layer = marker.addTo(this.map);
            layer.addTo(this.map);
            this.setLayer(layer);
          }
        }

        // TODO: remove after testing
        // const marker = L.marker(data.location.coordinates, {
        //   icon: this.customMarker(data.value),
        //   alt: String(data.value)
        // }).on('click', (e) => this.setSelectMarker(e, data));
        // this.cluster?.addLayer(marker);
      });
      // if (markers.length) {
      //   if (this.map?.getZoom() === 18) {
      //     markers.forEach((marker) => )
      //     this.cluster?.addLayer(markers);
      //   } else {
      //     const layer = this.map?.addLayer(markers);
      //     this.setLayer(layer || null);
      //   }
      // }
      if (this.map.getZoom() === 18) {
        this.map.addLayer(this.cluster);
      }
      // this.map?.addLayer(this.cluster);
      return;
    }
  }

  updateIconByClick(): void {
    const currentMarker = this.layer.find(
      (marker) => marker.getElement() === this.selectMarker?.event.target.getElement()
    );
    const prevMarker = this.layer.find(
      (marker) => marker.getElement() === this.prevSelectMarker?.event.target.getElement()
    );
    if (this.map && this.map.getZoom() === 18) {
      this.selectMarker?.event.target.getElement().classList.add('select-marker-max');
      this.prevSelectMarker?.event.target.getElement().classList.remove('select-marker-max');
    } else {
      if (currentMarker) {
        currentMarker.setIcon(this.getMarkerBlue(this.selectMarker?.data.value || 0));
      }
      if (prevMarker) {
        prevMarker.setIcon(this.getMarkerRed(this.prevSelectMarker?.data.value || 0));
      }
    }
  }

  async getPolygonById(): Promise<void> {
    if (this.currentLocation) {
      try {
        const res = await this._mapService.getLocationInfo(this.currentLocation.id);
        this.setAllLocations([res]);
      } catch (e) {
        console.log(e);
      }
    }
  }

  async getPolygonsNeighbour(): Promise<void> {
    if (this.currentLocation) {
      try {
        this.setAllLocations(await this._mapService.getNeighbourLocations(this.currentLocation.id));
      } catch (e) {
        console.log(e);
      }
    }
  }

  setPolygons(): void {
    if (this.layerGroup) {
      this.layerGroup.remove();
    }
    this.setLayerGroup(new L.FeatureGroup());
    if (this.layerGroup) {
      this.map?.addLayer(this.layerGroup);
    }
    for (const location of this.allLocations) {
      if (location.id === this.currentLocation?.id) {
        if (this.useFitBounds) {
          this.fitBoundsLocation(location.shape);
        }
        this.layerGroup?.addLayer(this.createPolygon(location, BLUE_COLOR));
      } else {
        this.layerGroup?.addLayer(this.createPolygon(location, GREY_COLOR));
      }
    }
    this.useFitBounds = true;
  }

  createPolygon(location: DetailInfo, color: string): Polygon {
    const layer = L.polygon(location.shape, { color, interactive: color !== BLUE_COLOR })
      .on('click', () => this.clickByPolygon(location))
      .bindTooltip(location.title, {
        permanent: false,
        direction: 'center',
        className: 'custom-label'
      })
      .openTooltip();
    layer.on('mouseover', () => {
      if (layer.options.color !== BLUE_COLOR) {
        layer.setStyle({
          color: PURPLE_COLOR
        });
      }
    });
    layer.on('mouseout', () => {
      layer.setStyle({
        color
      });
    });
    return layer;
  }

  clickByPolygon(location: DetailInfo): void {
    if (this.selectedLocation && this.selectedLocation.id === location.id) {
      return;
    }
    this.setSelectedLocation({
      id: location.id,
      title: location.fullTitle,
      type: location.type,
      canonicalTitle: location.canonicalTitle,
      stateCode: location.stateCode
    });
    this.useFitBounds = false;
  }

  fitBoundsLocation(shapes: Shape): void {
    //TODO: need refactoring in the future
    let allShapes: LatLngBoundsLiteral[] = [];
    for (const shape of shapes) {
      for (const points of shape) {
        if (Array.isArray((points as LatLngBoundsLiteral)[0])) {
          allShapes = allShapes.concat(shape as LatLngBoundsLiteral);
          continue;
        } else {
          allShapes = [(shapes as LatLngBoundsLiteral[])[0]];
        }
      }
    }
    const bigShape: number = allShapes.reduce((p, c, i, a) => (a[p].length > c.length ? p : i), 0);
    this.map?.fitBounds(allShapes[bigShape]);
  }
}
