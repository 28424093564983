import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from './ContactList.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { IContact } from '../../types';
import { observer } from 'mobx-react';
import { Skeleton } from 'components';
import { mockContacts, skeletonsToShow } from './mock';
import { FavoriteSubTypes } from 'view/AddToFavorites/types';
import Contact from './components/Contact';

interface IProps {
  projectId: number;
  position: 'left' | 'right';
  onLoadMore: () => void;
  closeList: () => void;
  type: keyof typeof FavoriteSubTypes;
  handleUnlock: () => void;
  isLocked: boolean;
}

const ContactList: FC<IProps> = observer(
  ({ projectId, position, onLoadMore, closeList, type, handleUnlock, isLocked }) => {
    const { projectSearchStore } = useStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const listRef = useRef<HTMLDivElement | null>(null);

    const showMore = useCallback(
      (contact: IContact) => {
        projectSearchStore.setCurrentContact({
          ...contact,
          projectId
        });
        onLoadMore();
      },
      [projectId]
    );

    const positionList = useCallback((): string => {
      return position === 'left' ? `end-100 ${classes.leftWrapper}` : 'start-100';
    }, [position]);

    const loadContacts = useCallback(async () => {
      try {
        setIsLoading(true);
        type === 'regular'
          ? await projectSearchStore.getContacts(projectId)
          : await projectSearchStore.getRecentContacts(projectId);
      } finally {
        setIsLoading(false);
      }
    }, [projectId]);

    const arrayToShow = useMemo<IContact[]>(() => {
      if (isLocked) return mockContacts;
      return type === FavoriteSubTypes.regular
        ? projectSearchStore.contacts
        : projectSearchStore.recentContacts;
    }, [projectSearchStore.contacts, projectSearchStore.recentContacts]);

    const clickOutside = (e: MouseEvent): void => {
      const favoriteModals = document.querySelector('#modal-root')?.children.length;
      if (favoriteModals) return;
      if (
        listRef.current &&
        !e.composedPath().includes(listRef.current) &&
        (e.target as HTMLDivElement).dataset.testElement !== 'search-project-contacts-button'
      ) {
        closeList();
      }
    };

    useEffect(() => {
      if (!closeList) return;
      window.addEventListener('click', clickOutside);
      return () => {
        window.removeEventListener('click', clickOutside);
      };
    }, []);

    useEffect(() => {
      loadContacts().then();
    }, [projectId]);

    return (
      <>
        <div
          ref={listRef}
          className={clsx(
            'position-absolute',
            positionList(),
            'top-0',
            'bg-white',
            classes.wrapper
          )}
          data-test-element="contacts-list">
          {isLoading ? (
            <Skeleton type="contact" itemsToShow={skeletonsToShow} />
          ) : (
            <>
              {arrayToShow.map((contact) => (
                <Contact
                  type={type}
                  listRef={listRef}
                  key={`${contact.id}-${contact.type}-${contact.role}`}
                  contact={contact}
                  showMore={showMore}
                  isLocked={isLocked}
                  handleUnlock={handleUnlock}
                />
              ))}
            </>
          )}
        </div>
      </>
    );
  }
);

export default ContactList;
