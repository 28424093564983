import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Button, Input } from 'components';
import classes from '../SubHeader.module.scss';
import { clsx } from 'utils/clsx';
import closeIcon from 'assets/icons/close.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSaveClick: () => void;
  saveSearchVisible: boolean;
  hideSaveSearch: () => void;
  handleSaveSearch: () => void;
  value: string;
  onInput: (name: string, value: string) => void;
}

const SaveSearchButton: FC<IProps> = ({
  onSaveClick,
  saveSearchVisible,
  hideSaveSearch,
  handleSaveSearch,
  value,
  onInput
}) => {
  const { t } = useTranslation();

  const modalRef = useRef<null | HTMLDivElement>(null);

  const clickOutside = useCallback((e: MouseEvent): void => {
    if (!e.composedPath().includes(modalRef.current as EventTarget)) {
      hideSaveSearch();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
    };
  }, []);

  return (
    <div className="position-relative" ref={modalRef}>
      <Button
        tabIndex={0}
        type="primary"
        onClick={onSaveClick}
        className={classes.saveBtn}
        data-test-element="search-project-save-button">
        <span className="font-kraftig">{t('searchProject.saveSearch')}</span>
      </Button>
      <div
        className={clsx(
          'position-absolute',
          'top-100',
          'start-0',
          'pt-2',
          classes.saveModal,
          saveSearchVisible && classes.active
        )}>
        <div className={clsx(classes.triangle, 'bg-white', 'position-relative')} />
        <div className={clsx(classes.saveWindow, 'bg-white')}>
          <div className="d-flex justify-content-between align-items-start p-3">
            <h2 className={clsx(classes.saveTitle, 'm-0')}>Save Search</h2>
            <img
              onClick={hideSaveSearch}
              src={closeIcon}
              alt="close"
              className="position-relative"
              role="button"
            />
          </div>
          <div className="p-3">
            <Input
              type="text"
              value={value}
              onInput={onInput}
              name="searchName"
              label="Name your search"
              data-test-element="search-project-save-search-input"
            />
          </div>
          <div className={classes.divider} />
          <div className="d-flex align-items-center justify-content-end p-3">
            <Button
              type="secondary"
              className={classes.btn}
              onClick={hideSaveSearch}
              data-test-element="search-project-cancel-save-button">
              <span className="font-kraftig">{t('savedSearch.cancel')}</span>
            </Button>
            <Button
              type="primary"
              className={classes.btn}
              onClick={handleSaveSearch}
              data-test-element="search-project-submit-save-button">
              <span className="font-kraftig">{t('savedSearch.save')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveSearchButton;
