import React, { FC, useId } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/icons/logo-header.svg';
import { Button, Input, SearchList } from 'components';
import { clsx } from 'utils/clsx';
import classes from '../Header.module.scss';
import searchIcon from 'assets/icons/search.svg';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { SIGN_IN, SIGN_UP } from 'utils/constants';

interface Props {
  inputValue: string;
  inputChange: (name: string, value: string) => void;
  searchResults: SearchProjects | null;
  selectedLocation: FoundLocation | null;
  searchListChange: (item: FoundLocation) => void;
  showSearchList: boolean;
  closeList: () => void;
  authAction: (type: string) => void;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onKeyUp: () => void;
  onKeyDown: () => void;
  onKeyEnter: () => void;
  onKeyEscape: () => void;
}

const GuestBody: FC<Props> = observer(
  ({
    inputValue,
    inputChange,
    searchResults,
    searchListChange,
    selectedLocation,
    showSearchList,
    closeList,
    authAction,
    onInputFocus,
    onInputBlur,
    onKeyUp,
    onKeyDown,
    onKeyEnter,
    onKeyEscape
  }) => {
    const { t } = useTranslation();
    const id = useId();
    const [, setParams] = useSearchParams();

    return (
      <div className="container-fluid px-3">
        <div className="d-flex align-items-center flex-fill">
          <Link className={clsx('navbar-brand', classes.link)} to="/" tabIndex={-1}>
            <img src={Logo} alt="close" className="position-relative" role="button" />
          </Link>
          <div className="flex-grow-1 d-flex">
            <div className={clsx('d-flex position-relative', classes.inputWrapper)}>
              <Input
                tabIndex={0}
                data-input-id={id}
                autoComplete="off"
                name="search"
                type="text"
                value={inputValue}
                onInput={inputChange}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                data-test-element="header-search-input"
                inputClass={classes.searchInput}
                searchIcon>
                <div
                  data-input-id={id}
                  className={`d-flex w-100 align-items-center justify-content-center ${classes.inputSearchOverlayText}`}>
                  <img src={searchIcon} alt="" />
                  &nbsp;{t('components.header.search')}
                </div>
              </Input>
              <SearchList
                listResponse={searchResults}
                selectedLocation={selectedLocation}
                onChange={searchListChange}
                styleTop="42"
                show={showSearchList}
                closeList={closeList}
                inputId={id}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onKeyEnter={onKeyEnter}
                onKeyEscape={onKeyEscape}
              />
            </div>
            <Button
              type="primary"
              data-test-element="header-purchase-btn"
              background={'#00C47D'}
              className={clsx(classes.button, 'px-4 ms-2', classes.purchase)}
              onClick={() => setParams('?auth=sign-up&extended=true')}>
              <span className="font-halbfett">{t('components.header.purchase')}</span>
            </Button>
          </div>
        </div>
        <div className="d-flex">
          <Button
            type="light"
            color="#8E94BC"
            data-test-element="header-login-btn"
            className={clsx(classes.button, 'px-4 me-2')}
            onClick={() => authAction(SIGN_IN)}>
            <span className="font-halbfett">{t('components.header.login')}</span>
          </Button>
          <Button
            type="primary"
            data-test-element="header-sign-up-btn"
            className={clsx(classes.button, 'px-3')}
            onClick={() => authAction(SIGN_UP)}>
            <span className="font-halbfett">{t('components.header.signUp')}</span>
          </Button>
        </div>
      </div>
    );
  }
);

export default GuestBody;
