import React, {
  FC,
  useCallback,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
  useEffect,
  MouseEvent
} from 'react';
import { clsx } from 'utils/clsx';
import classes from '../ContactList.module.scss';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { Button } from 'components';
import { Detail, IContact } from 'view/SearchProjects/types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { SourceLink } from 'utils/types';
import AddToFavorites from 'view/AddToFavorites';
import { FavoriteRole, FavoriteSubTypes, IFavoriteEntity } from 'view/AddToFavorites/types';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  contact: IContact;
  showMore: (contact: IContact) => void;
  listRef: MutableRefObject<HTMLDivElement | null>;
  type: keyof typeof FavoriteSubTypes;
  isLocked?: boolean;
  handleUnlock?: () => void;
}

const Contact: FC<IProps> = observer(
  ({ contact, showMore, listRef, type, isLocked, handleUnlock }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { configStore, projectSearchStore, userStore } = useStore();
    const favoriteRef = useRef<null | SVGSVGElement>(null);

    const [isFavorite, setIsFavorite] = useState<boolean>(contact.favourite);

    useEffect(() => {
      setIsFavorite(projectSearchStore.checkContactIsFavorite(contact));
    }, [projectSearchStore.favoriteContacts]);

    const openModal = useCallback((e: MouseEvent): void => {
      if (!userStore.user) {
        navigate('?auth=sign-in');
        return;
      }
      const thisModal = document.getElementById(
        `favorite-${entity.type}-${entity.role}-${entity.id}-${entity.key}`
      );
      projectSearchStore.setActiveFavorite(
        thisModal
          ? null
          : {
              id: entity.id,
              type: type === 'recent' ? 'recentContacts' : 'contacts',
              role: entity.role
            }
      );
      e.stopPropagation();
    }, []);

    const entity = useMemo<IFavoriteEntity>(
      () => ({
        id: contact.id,
        coordinates: {} as DOMRect,
        type: contact.type,
        role: contact.role as FavoriteRole,
        key: type === 'recent' ? 'recentContacts' : 'contacts',
        name: contact.name
      }),
      [contact.id]
    );

    const closeFavorite = useCallback(() => projectSearchStore.setActiveFavorite(null), []);

    const getRole = useCallback(
      (id: number): string => {
        return configStore.enums?.projectRoles.find((role) => role.id === id)?.name || '';
      },
      [configStore.enums]
    );

    // TODO: move this function to separate component
    const getDetail = useCallback((detail: Detail) => {
      if (isLocked)
        return (
          <div
            className="d-flex align-items-center"
            onClick={isLocked && handleUnlock ? handleUnlock : () => null}
            role={isLocked ? 'button' : undefined}>
            {isLocked && <LockIcon className={classes.lockIcon} />}
            <span className={clsx(isLocked && classes.locked)}>
              {detail.type === SourceLink.LINKEDIN
                ? detail.value.split('linkedin.com/')[1]
                : detail.value}
            </span>
          </div>
        );
      if (detail.type === SourceLink.LINKEDIN || detail.type === SourceLink.WEB) {
        return (
          <a
            href={detail.value}
            target={'_blank'}
            rel="noreferrer"
            className="text-decoration-none default-color-text">
            {detail.type === SourceLink.LINKEDIN
              ? detail.value.split('linkedin.com/')[1]
              : detail.value}
          </a>
        );
      }
      if (detail.type === SourceLink.PHONE) {
        return (
          <a href={`tel:${detail.value}`} className="text-decoration-none default-color-text">
            {detail.value}
          </a>
        );
      }
      if (detail.type === SourceLink.EMAIL) {
        return (
          <a href={`mailto:${detail.value}`} className="text-decoration-none default-color-text">
            {detail.value}
          </a>
        );
      }
      return <span>{detail.value}</span>;
    }, []);

    const isModalShown = useMemo<boolean>(() => {
      return (
        projectSearchStore.activeFavorite?.id === entity.id &&
        projectSearchStore.activeFavorite?.role === entity.role &&
        projectSearchStore.activeFavorite?.type ===
          (type === 'recent' ? 'recentContacts' : 'contacts')
      );
    }, [entity, projectSearchStore.activeFavorite]);

    useEffect(() => {
      listRef.current?.addEventListener('scroll', closeFavorite);
      return () => {
        listRef.current?.removeEventListener('scroll', closeFavorite);
      };
    }, []);

    const handleFavorite = useCallback((isActive: boolean, id: number): void => {
      projectSearchStore.updateFavoriteContacts(isActive, id, contact.type, contact.role);
    }, []);

    return (
      <div key={`${contact.id}-${contact.role}-${contact.type}`} className={clsx(classes.contact)}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className={classes.role}>{getRole(contact.role)}</span>
          <FavoriteIcon
            onClick={isLocked && handleUnlock ? handleUnlock : openModal}
            className={clsx(classes.favoriteIcon, isFavorite && classes.filled)}
            role="button"
            ref={favoriteRef}
            data-test-element="favorite-icon"
          />

          {isModalShown && favoriteRef.current && (
            <AddToFavorites
              entity={{ ...entity, coordinates: favoriteRef.current.getBoundingClientRect() }}
              closeModal={closeFavorite}
              toggleCallback={handleFavorite}
            />
          )}
        </div>
        <div
          className={clsx(classes.name, 'mb-3', 'd-flex', 'align-items-center')}
          onClick={isLocked && handleUnlock ? handleUnlock : () => null}
          role={isLocked ? 'button' : undefined}>
          {isLocked && <LockIcon className={classes.lockIcon} />}
          <span className={clsx(isLocked && classes.locked, 'font-kraftig')}>{contact.name}</span>
        </div>
        {contact.details.map((detail) => (
          <div
            key={detail.type}
            className={clsx('mb-2', classes.role, 'd-flex', 'align-items-center')}>
            <span className="me-2">{<detail.icon />}</span>
            {getDetail(detail)}
          </div>
        ))}
        <Button
          type="light"
          color="#0087FF"
          className={classes.more}
          onClick={isLocked && handleUnlock ? handleUnlock : () => showMore(contact)}>
          {t('searchProject.moreDetails')}
        </Button>
      </div>
    );
  }
);

export default Contact;
