import React, { FC, MouseEvent, ReactNode } from 'react';
import { ButtonType } from './types';
import classes from './Button.module.scss';
import { clsx } from 'utils/clsx';

interface IProps {
  children: ReactNode;
  type: ButtonType;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  color?: string;
  background?: string;
  className?: string;
  isLoading?: boolean;
  disabledClass?: string;
  [key: string]: unknown;
}

const Button: FC<IProps> = ({
  children,
  type,
  disabled,
  color,
  className,
  onClick,
  isLoading,
  background,
  disabledClass,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        classes[type],
        classes.btn,
        'btn',
        'd-block',
        className,
        'position-relative',
        disabled && classes.disabled,
        disabled && disabledClass
      )}
      style={{ color, ...{ [type === 'primary' ? 'backgroundColor' : 'borderColor']: background } }}
      onClick={onClick}
      {...rest}>
      {children}
      {isLoading && <div className={classes.buttonLoading} />}
    </button>
  );
};

export default Button;
