import React, { FC, useCallback } from 'react';
import { IColumn, Order } from '../types';
import classes from '../Table.module.scss';
import { clsx } from 'utils/clsx';
import arrowRedIcon from 'assets/icons/arrow-red.svg';
import arrowIcon from 'assets/icons/arrow.svg';
import { ASC, DESC } from 'utils/constants';

interface Props {
  column: IColumn;
  orders?: Order[];
  sortAction?: (field: string) => void;
}

export const ColumnHeader: FC<Props> = ({ column, orders, sortAction }) => {
  const sortingColumn = useCallback(
    (field: string): boolean => {
      return !!orders?.find((order) => order.field === field);
    },
    [orders]
  );

  const sortIconPosition = useCallback(
    (field: string): string => {
      const currentOrder = orders?.find((order) => order.field === field);
      if (currentOrder && currentOrder.order === ASC) return classes.sortIconUp;
      if (currentOrder && currentOrder.order === DESC) return classes.sortIconDown;
      return classes.sortIconUp;
    },
    [orders]
  );

  return (
    <div className={clsx('d-flex', 'align-items-center', classes.cellHeader)}>
      <span className="font-kraftig">{column.headerName}</span>
      {column.sort && (
        <img
          src={sortingColumn(column.field) ? arrowRedIcon : arrowIcon}
          alt="sort"
          className={clsx(
            sortingColumn(column.field) && sortIconPosition(column.field),
            'position-relative',
            'ms-2'
          )}
          onClick={() => sortAction && sortAction(column.field)}
          role="button"
        />
      )}
    </div>
  );
};
