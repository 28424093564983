import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'utils/clsx';
import classes from './RightSidebar.module.scss';
import closeIcon from 'assets/icons/close-dark.svg';
import { Tab } from 'components';
import { ITabsItems } from 'components/Tab/types';
import GeneralInfo from '../GeneralInfo';
import { useStore } from 'storesProvider/storeContext';
import RecentProjects from '../RecentProjects';
import { FavoriteTypes, IFavoriteEntity } from 'view/AddToFavorites/types';
import AddToFavorites from 'view/AddToFavorites';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import { useNavigate } from 'react-router-dom';
import { NA } from 'utils/constants';
import { COMPANY } from '../../constants';
import { numberWithSpaces } from 'helper/numberWithComma';

interface Props {
  show: boolean;
  closeAction: () => void;
  onLoadMore: () => void;
}

const RightSidebar: FC<Props> = observer(({ show, closeAction, onLoadMore }) => {
  const { projectSearchStore, configStore, userStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);
  const [isRecentLoading, setIsRecentLoading] = useState<boolean>(false);
  const [tabItems, setTabItems] = useState<ITabsItems[]>([]);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const favoriteRef = useRef<null | SVGSVGElement>(null);

  const closeFavorite = useCallback(() => {
    projectSearchStore.setActiveFavorite(null);
  }, []);

  const handleFavorite = useCallback(
    (isActive: boolean, id: number): void => {
      if (projectSearchStore.currentProjectContact) {
        setIsFavorite(isActive);
        projectSearchStore.updateFavoriteContacts(
          isActive,
          id,
          projectSearchStore.currentProjectContact?.type,
          projectSearchStore.currentProjectContact?.role
        );
      }
    },
    [projectSearchStore.currentProjectContact]
  );

  const handleTitleClick = useCallback(() => {
    setActiveTab(1);
    closeAction();
  }, []);

  useEffect(() => {
    if (projectSearchStore.currentProjectContact) {
      setIsFavorite(projectSearchStore.currentProjectContact?.favourite);
    }
  }, [projectSearchStore.currentProjectContact]);

  useEffect(() => {
    if (projectSearchStore.currentProjectContact) {
      setIsFavorite(
        projectSearchStore.checkContactIsFavorite(projectSearchStore.currentProjectContact)
      );
    }
  }, [projectSearchStore.favoriteContacts, projectSearchStore.currentProjectContact]);

  const getContactType = useCallback(
    (id: number): string => {
      return configStore.enums?.contactTypes.find((type) => type.id === id)?.name || '';
    },
    [configStore.enums]
  );

  const role = useMemo(() => {
    const projectContact = projectSearchStore.currentProjectContact;
    if (!projectContact || getContactType(projectContact.type) !== COMPANY) return null;
    return configStore.enums?.projectRoles.find((role) => role.id === projectContact.role)?.value;
  }, [configStore.enums, projectSearchStore.currentProjectContact]);

  const entity = useMemo<IFavoriteEntity | null>(() => {
    const projectContact = projectSearchStore.currentProjectContact;
    if (!projectContact) return null;
    const entity: IFavoriteEntity = {
      id: projectContact.id,
      coordinates: {} as DOMRect,
      type: projectContact.type,
      role: projectContact.role,
      key: 'sidebar',
      name: projectContact ? projectContact.name : 'Contact'
    };
    return entity;
  }, [projectSearchStore.currentProjectContact]);

  const openModal = useCallback(
    (e: MouseEvent): void => {
      if (!userStore.user) {
        navigate('?auth=sign-in');
        return;
      }
      const thisModal = document.getElementById(
        `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
      );
      if (!entity) return;
      projectSearchStore.setActiveFavorite(thisModal ? null : { id: entity.id, type: 'sidebar' });
      e.stopPropagation();
    },
    [entity]
  );

  const isModalShown = useMemo<boolean>(() => {
    return (
      projectSearchStore.activeFavorite?.id === entity?.id &&
      projectSearchStore.activeFavorite?.type === 'sidebar'
    );
  }, [entity, projectSearchStore.activeFavorite]);

  const handleUpdateProjects = async (): Promise<void> => {
    await getRecentProjects(1);
  };

  useEffect(() => {
    const projectContact = projectSearchStore.currentProjectContact;
    (async () => {
      if (projectContact && role) {
        try {
          setIsCompanyLoading(true);
          await projectSearchStore.getCompany(projectContact.projectId, role, projectContact.id);
          setTabItems([
            {
              name: t('searchProject.generalInfo'),
              active: activeTab === 1,
              tabId: 1
            },
            {
              name: t('searchProject.recentProjects'),
              active: activeTab === 2,
              tabId: 2
            }
          ]);
        } finally {
          setIsCompanyLoading(false);
        }
      } else {
        projectSearchStore.setCompany(null);
        setTabItems([
          {
            name: t('searchProject.recentProjects'),
            active: activeTab === 1,
            tabId: 1
          }
        ]);
      }
    })();
  }, [projectSearchStore.currentProjectContact, role]);

  useEffect(() => {
    setTabItems((prev) => {
      return prev.map((tab) => {
        return {
          ...tab,
          active: activeTab === tab.tabId
        };
      });
    });
  }, [activeTab]);

  useEffect(() => {
    (async () => await getRecentProjects(1))();
  }, [projectSearchStore.currentProjectContact]);

  const getRecentProjects = useCallback(
    async (page: number): Promise<void> => {
      const projectContact = projectSearchStore.currentProjectContact;
      if (projectContact) {
        try {
          setIsRecentLoading(true);
          await projectSearchStore.getRecentProjects(
            getContactType(projectContact.type).toLowerCase(),
            projectContact.id,
            page
          );
        } finally {
          setIsRecentLoading(false);
        }
      }
    },
    [projectSearchStore.currentProjectContact]
  );

  const clickOutside = (e: MouseEvent): void => {
    const favoriteModals = document.querySelector('#modal-root')?.children.length;
    if (!favoriteModals) e.stopPropagation();
    if (e.target === e.currentTarget) {
      e.stopPropagation();
      projectSearchStore.setActiveFavorite(null);
      setActiveTab(1);
      closeAction();
    }
  };

  const getContactName = useCallback((): string => {
    const projectContact = projectSearchStore.currentProjectContact;
    return projectContact ? projectContact.name : '';
  }, [projectSearchStore.currentProjectContact]);

  const tabConditions = (tab: number): boolean => {
    const projectContact = projectSearchStore.currentProjectContact;
    if (projectContact && projectContact.type === FavoriteTypes.Company) {
      return activeTab === tab;
    }
    return true;
  };

  const handleTabAction = (tabId: number): void => setActiveTab(tabId);

  const getRecentProjectsValue = useCallback((): string => {
    return projectSearchStore.recentProjects.value
      ? `$${numberWithSpaces(projectSearchStore.recentProjects.value)}`
      : NA;
  }, [projectSearchStore.recentProjects]);

  if (!show) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        classes.rightSidebarWrapper,
        'position-absolute',
        'top-0',
        'end-0',
        'start-0',
        'bottom-0'
      )}>
      <div
        onClick={clickOutside}
        className={clsx('d-flex', 'justify-content-end', 'vh-100', 'default-color-text')}>
        <div className={clsx(classes.rightSidebar)}>
          {/*TODO: create RightSidebarHeader*/}
          <div className={clsx(classes.rightSidebarHeader, 'p-4')}>
            <div className="d-flex justify-content-between mb-4">
              <h5 className={clsx(classes.title, 'm-0')}>{getContactName()}</h5>
              <div>
                <FavoriteIcon
                  onClick={openModal}
                  ref={favoriteRef}
                  className={clsx(classes.favoriteIcon, 'me-4', isFavorite && classes.filled)}
                  data-test-element="favorite-icon"
                  role="button"
                />
                {isModalShown && favoriteRef.current && entity && (
                  <AddToFavorites
                    entity={{
                      ...entity,
                      coordinates: favoriteRef.current.getBoundingClientRect()
                    }}
                    closeModal={closeFavorite}
                    toggleCallback={handleFavorite}
                  />
                )}
                <img
                  src={closeIcon}
                  alt="close"
                  className={clsx('position-relative', classes.close)}
                  onClick={closeAction}
                  role="button"
                />
              </div>
            </div>
            <span className="mb-2 font-kraftig">
              {t('searchProject.analyticsForTheLastMonths')}
            </span>
            <div className="d-flex justify-content-between mb-2">
              <span>{t('searchProject.totalProjects')}</span>
              <span className="font-kraftig">{projectSearchStore.recentProjects.count}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>{t('searchProject.totalAmount')}</span>
              <span className="font-kraftig">{getRecentProjectsValue()}</span>
            </div>
          </div>
          <Tab
            items={tabItems}
            mode="light"
            navClass={classes.tab}
            action={handleTabAction}
            stopPropagation
          />
          {tabConditions(1) && <GeneralInfo isLoading={isCompanyLoading} />}
          {tabConditions(2) && (
            <RecentProjects
              onLoadMore={onLoadMore}
              isLoading={isRecentLoading}
              closeFavorite={closeFavorite}
              moreProjects={getRecentProjects}
              onLoadProjectInfo={handleTitleClick}
              handleUpdateProjects={handleUpdateProjects}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default RightSidebar;
