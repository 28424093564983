import { axiosInstance } from 'utils/API';
import { ISavedSearchResponse, ISavedSearchResultsProject, ISavedSearchResultsRepo } from './types';
import { IAxiosResponse, ObjectValues } from 'utils/types';

export class SavedSearchResultsRepo implements ISavedSearchResultsRepo {
  async getDashboardData(
    userId: number,
    ids: ObjectValues<number>,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<ISavedSearchResponse<ISavedSearchResultsProject[]>>> {
    return await axiosInstance.get(`/users/${userId}/dashboard`, {
      params: {
        //TODO: can be changed after be fixes
        ...ids,
        page,
        ...orderParams
      }
    });
  }
}
