import React, { FC } from 'react';
import { IReport } from '../types';
import classes from '../Reports.module.scss';
import { clsx } from 'utils/clsx';
import reporterImage from 'assets/img/home/reporter.jpg';

const ReportItem: FC<IReport> = ({ name, photo, comment, position }) => {
  return (
    <>
      <img
        src={photo || reporterImage}
        alt=""
        className={clsx('m-auto', 'd-block', 'position-relative', classes.photo)}
      />
      <div className="pb-5">
        <div className={clsx(classes.report, 'position-relative')}>
          <blockquote className={clsx(classes.quote, 'mb-4', 'text-center')}>{comment}</blockquote>
          <h2 className={clsx(classes.reportTitle, 'mb-2', 'text-center')}>{name}</h2>
          <p className={clsx(classes.position, 'text-center', 'm-0')}>{position}</p>
        </div>
        <div className={clsx('bg-white', classes.shadow)}></div>
        <div className={clsx('bg-white', classes.shadow2)}></div>
      </div>
    </>
  );
};

export default ReportItem;
