import React, { FC, useCallback } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';
import LinkedInAuth from './LinkedInAuth';
import GoogleAuth from './GoogleAuth';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import {
  IGoogleResponse,
  ILinkedinResponse,
  IOpenIdLogin,
  PreRegistrationUserData
} from '../types';
import { LOGIN_PROVIDER_GOOGLE } from 'utils/constants';
import { Button } from 'components';
import { useNavigateAfterLogin } from 'hooks/useNavigateAfterLogin';

interface IProps {
  step: 'sign-in' | 'sign-up';
  light?: boolean;
}

const AuthFooter: FC<IProps> = ({ step, light }) => {
  const { t } = useTranslation();
  const [, setParams] = useSearchParams();
  const { authStore, alertStore, userStore } = useStore();
  const { navigateAfterLogin } = useNavigateAfterLogin();

  const googleAuth = useCallback(async (user: IGoogleResponse) => {
    const response = await authStore.openIdLogin(user.credential, LOGIN_PROVIDER_GOOGLE);
    await handleUserAction(response);
  }, []);

  const linkedInAuth = useCallback(async (response: ILinkedinResponse) => {
    const res: IOpenIdLogin = {
      token: response.type === 'token' ? (response.data as unknown as string) : '',
      result: response.type === 'token' ? null : response.data
    };
    await handleUserAction(res);
  }, []);

  const handleUserAction = useCallback(async (response: IOpenIdLogin) => {
    if (response.result) {
      if (!response.result.firstName || !response.result.lastName) return;
      const preRegistrationUser: PreRegistrationUserData = {
        fullName: `${response.result.firstName} ${response.result.lastName}`,
        email: response.result.email,
        token: response.result.token
      };
      setParams('auth=sign-up', { state: preRegistrationUser });
    } else {
      authStore.setToken(response.token);
      await userStore.getCurrentUser();
      alertStore.successAlert(t('auth.successLogin'));
      navigateAfterLogin();
    }
  }, []);

  const openLogin = useCallback(() => setParams('auth=sign-in'), []);
  const openRegister = useCallback(() => setParams('auth=sign-up'), []);

  return (
    <div className={clsx(!light && 'px-4')}>
      <div className="py-5">
        <div className={clsx(classes.divider, 'position-relative')}>
          <span
            className={clsx(
              classes.use,
              'text-center',
              'mb-4',
              'position-absolute',
              light && 'text-white'
            )}>
            <b className={clsx('px-3', light && classes.bgDark, 'font-kraftig')}>
              {t('auth.orUse')}
            </b>
          </span>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <div className={classes.buttonWrapper}>
          <LinkedInAuth step={step} linkedinAction={linkedInAuth} light={!!light} />
        </div>
        <div className={classes.buttonWrapper}>
          <GoogleAuth step={step} googleAction={googleAuth} light={!!light} />
        </div>
      </div>
      {step === 'sign-in' ? (
        <span
          className={clsx(
            'text-center',
            classes.text,
            'd-flex',
            'align-items-center',
            'justify-content-center',
            light && 'text-white'
          )}>
          {t('auth.toRegister')}
          <Button
            type="light"
            className={clsx('text-secondary bg-white border-0 ms-2 py-0', classes.link)}
            onClick={openRegister}>
            {t('auth.signUp')}
          </Button>
        </span>
      ) : (
        <span
          className={clsx(
            'text-center',
            classes.text,
            'd-flex',
            'align-items-center',
            'justify-content-center',
            light && 'text-white'
          )}>
          {t('auth.toLogin')}
          <Button
            type="light"
            className={clsx(
              'text-secondary border-0 ms-2 py-0',
              classes.link,
              !light && 'bg-white'
            )}
            onClick={openLogin}>
            {t('auth.login')}
          </Button>
        </span>
      )}
    </div>
  );
};

export default AuthFooter;
