import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classes from './AddToFavorites.module.scss';
import plusIcon from 'assets/icons/plus.svg';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { Input, Checkbox, PortalModal, Modal } from 'components';
import { useStore } from 'storesProvider/storeContext';
import { IFavoriteEntity } from './types';
import { IFavoriteData } from '../Favorites/types';
import { entityTypes } from './types';
import { observer } from 'mobx-react';
import { IModalActions } from 'components/Modal/types';
import { Link } from 'react-router-dom';

interface IProps {
  entity: IFavoriteEntity;
  closeModal: () => void;
  toggleCallback: (isActive: boolean, id: number, listId: number) => void;
}
const roles = {
  10: 'architect',
  20: 'owner',
  30: 'contractor',
  40: 'project'
};

const AddToFavorites: FC<IProps> = observer(({ closeModal, entity, toggleCallback }) => {
  const { t } = useTranslation();
  const { alertStore, favoritesStore, userStore } = useStore();

  const [newListName, setNewListName] = useState<string>('');
  const [checkedFavorites, setCheckedFavorites] = useState<number[]>([]);
  const [show, setShowModal] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleInput = useCallback((name: string, value: string) => setNewListName(value), []);

  const handleCreateList = useCallback(
    async (e?: React.MouseEvent) => {
      e?.stopPropagation();
      if (newListName.length < 3) {
        alertStore.errorAlert(t('addToFavorites.errorCreateList'));
        return;
      }
      if (!userStore.user) return;
      closeModal();
      const newList = await favoritesStore.createFavoritesList(userStore.user.id, newListName);
      setNewListName('');
      await toggleFavorite(newList.id);
      handleCloseModal();
    },
    [newListName]
  );

  const toggleFavorite = useCallback(
    async (listId: number) => {
      const body: IFavoriteData = {
        entityId: entity.id,
        entityType: entity.type,
        role: entity.role,
        lists: [listId]
      };
      if (!userStore.user) return;
      const listName = favoritesStore.list.find((list) => list.id === listId)?.name;
      if (checkedFavorites.includes(listId)) {
        await favoritesStore.deleteFromFavorites(userStore.user.id, body);
        setCheckedFavorites((prev) => prev.filter((id) => id !== listId));
        toggleCallback(!!(checkedFavorites.length - 1), entity.id, listId);
        if (entity.name) {
          alertStore.successAlert(
            <>
              <span className="font-kraftig">{entity.name}</span> {t('addToFavorites.removedFrom')}{' '}
              <Link to={`/favorites/${listId}`} className="font-kraftig">
                {listName}
              </Link>
              .
            </>
          );
        } else {
          alertStore.successAlert(t('addToFavorites.successDeleteFromFavorite'));
        }
        return;
      }
      await favoritesStore.addToFavorites(userStore.user.id, body);
      setCheckedFavorites((prev) => [...prev, listId]);
      toggleCallback(true, entity.id, listId);
      if (entity.name) {
        alertStore.successAlert(
          <>
            <span className="font-kraftig">{entity.name}</span> {t('addToFavorites.savedTo')}{' '}
            <Link to={`/favorites/${listId}`} className="font-kraftig">
              {listName}
            </Link>
            .
          </>
        );
      } else {
        alertStore.successAlert(t('addToFavorites.successAddToFavorite'));
      }
    },
    [entity, userStore.user, checkedFavorites]
  );

  const handleCloseModal = useCallback(() => setShowModal(false), []);
  const handleOpenModal = useCallback(() => setShowModal(true), []);

  const modalActions = useMemo<IModalActions>(
    () => ({
      negative: t('favorites.cancel'),
      positive: t('favorites.save')
    }),
    []
  );

  useEffect(() => {
    (async () => {
      if (!userStore.user) return;
      setCheckedFavorites(
        await favoritesStore.getListsByEntity(
          userStore.user.id,
          entityTypes[entity.type],
          entity.id,
          roles[entity.role]
        )
      );
    })();
  }, [entity]);

  useLayoutEffect(() => {
    (async () => {
      if (favoritesStore.list.length || !userStore.user) return;
      setIsListLoading(true);
      await favoritesStore.getFavoritesList(userStore.user.id);
      setIsListLoading(false);
    })();
  }, []);

  return (
    <PortalModal
      closeModal={closeModal}
      coordinates={entity.coordinates}
      closeOnClickOut
      width={370}>
      <div
        className="p-4"
        ref={modalRef}
        id={`favorite-${entity.type}-${entity.role}-${entity.id}-${entity.key}`}>
        {isListLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {favoritesStore.list.length > 0 && (
              <>
                <h2 className={clsx(classes.title, 'mb-3')}>
                  {t('addToFavorites.save')} {entityTypes[entity.type]} {t('addToFavorites.toList')}
                </h2>
                <div className="mb-3">
                  <h2 className={clsx(classes.listTitle)}>{t('addToFavorites.yourLists')}</h2>
                </div>
                <ul
                  className={clsx(
                    'p-0',
                    'list-unstyled',
                    favoritesStore.list.length > 5 && classes.scrollable
                  )}>
                  {favoritesStore.list.map((list) => (
                    <li key={list.id} className="mb-3">
                      <Checkbox
                        checked={checkedFavorites.includes(list.id)}
                        id={list.id}
                        onChange={() => toggleFavorite(list.id)}>
                        {list.name}
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className="d-flex">
              <div className="d-flex align-items-center" role="button" onClick={handleOpenModal}>
                <img src={plusIcon} alt="" className="me-2" />
                <span className={classes.create}>{t('addToFavorites.create')}</span>
              </div>
            </div>
          </>
        )}

        <Modal
          show={show}
          title={t('addToFavorites.create')}
          actions={modalActions}
          closeModal={handleCloseModal}
          onSubmit={handleCreateList}>
          <Input
            name=""
            type="text"
            label={t('favorites.listName')}
            value={newListName}
            onInput={handleInput}
          />
        </Modal>
      </div>
    </PortalModal>
  );
});

export default AddToFavorites;
