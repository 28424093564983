import React, {
  FC,
  useCallback,
  useState,
  useMemo,
  useEffect,
  MouseEvent,
  MutableRefObject
} from 'react';
import { IProject, ProjectType, IListRef, Location } from '../../types';
import classes from './ProjectInfo.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';

import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { FavoriteRoles, FavoriteTypes, IFavoriteEntity } from 'view/AddToFavorites/types';
import AddToFavorites from 'view/AddToFavorites';
import { observer } from 'mobx-react';
import { NA } from 'utils/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { numberWithSpaces } from 'helper/numberWithComma';

interface IProps {
  project: IProject;
  active: boolean;
  onSelectItem?: (id: number) => void;
  style?: {
    [key: string]: string;
  };
  index?: number;
  onTitleClick?: () => void;
  listRef: MutableRefObject<IListRef>;
  type: ProjectType;
  isLocked: boolean;
  hideContacts?: boolean;
  handleUnlock: ((location: Location | null) => void) | null;
}
const ProjectInfo: FC<IProps> = observer(
  ({
    project,
    active,
    onSelectItem,
    style,
    index,
    onTitleClick,
    listRef,
    isLocked,
    handleUnlock,
    hideContacts,
    type
  }) => {
    const { projectSearchStore, userStore } = useStore();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [, setParams] = useSearchParams();
    const [favoriteIcon, setFavoriteIcon] = useState<SVGSVGElement | null>(null);

    const [isFavorite, setIsFavorite] = useState<boolean>(
      type === 'recentProjects'
        ? projectSearchStore.checkRecentProjectIsFavorite(project.id)
        : projectSearchStore.checkProjectIsFavorite(project.id)
    );

    // TODO: check why this component re-render after click contact
    useEffect(() => {
      if (type == 'recentProjects') {
        setIsFavorite(projectSearchStore.checkRecentProjectIsFavorite(project.id));
      } else {
        setIsFavorite(projectSearchStore.checkProjectIsFavorite(project.id));
      }
    }, [projectSearchStore.favoriteProjects]);

    const entity = useMemo<IFavoriteEntity>(
      () => ({
        id: project.id,
        coordinates: {} as DOMRect,
        type: FavoriteTypes.Project,
        role: FavoriteRoles.Project,
        key: type,
        name: `${project.class.name} - ${project.type.name}`
      }),
      [project]
    );

    const handleFavorite = useCallback((e: MouseEvent): void => {
      if (!userStore.user) {
        navigate('?auth=sign-in');
        return;
      }
      const thisModal = document.getElementById(
        `favorite-${entity.type}-${entity.role}-${entity.id}-${entity.key}`
      );
      if (thisModal) e.stopPropagation();
      projectSearchStore.setActiveFavorite(thisModal ? null : { id: entity.id, type });
    }, []);

    const handleRef = (el: SVGSVGElement | null): void => {
      setFavoriteIcon(el);
    };

    const closeFavorite = useCallback(() => {
      projectSearchStore.setActiveFavorite(null);
    }, []);

    const favoriteCallback = useCallback((isActive: boolean, id: number) => {
      projectSearchStore.updateFavoriteProjects(isActive, id);
      projectSearchStore.updateFavoriteRecentProjects(isActive, id);
    }, []);

    const handleClick = useCallback(() => onSelectItem && onSelectItem(project.id), []);
    const handleTitleClick = useCallback(() => {
      setParams(`details=${project.id}`);
      if (onTitleClick) {
        onTitleClick();
      }
    }, []);

    const selectProject = useMemo<string>(() => {
      if (
        projectSearchStore.selectedProjectOnTheMap !== null &&
        projectSearchStore.selectedProjectOnTheMap === index
      ) {
        return classes.selectProject;
      }
      return '';
    }, [projectSearchStore.selectedProjectOnTheMap]);

    const handleUnlockClick = useCallback(() => {
      if (handleUnlock) {
        handleUnlock(project.location.county);
      }
    }, []);

    const isModalShown = useMemo<boolean>(() => {
      return (
        projectSearchStore.activeFavorite?.id === entity.id &&
        projectSearchStore.activeFavorite?.type === type
      );
    }, [entity, projectSearchStore.activeFavorite]);

    useEffect(() => {
      if (!listRef.current?._outerRef) return;
      listRef.current._outerRef.addEventListener('scroll', closeFavorite);
      return () => {
        if (!listRef.current?._outerRef) return;
        listRef.current._outerRef.removeEventListener('scroll', closeFavorite);
      };
    }, []);

    return (
      <div style={style} className={clsx(classes.wrapper, active && classes.active, selectProject)}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className={classes.status} style={{ color: project.status.color }}>
            {project.status.name}
          </div>
          <span style={{ marginRight: '3px' }}>
            <FavoriteIcon
              ref={handleRef}
              onClick={handleUnlock ? handleUnlockClick : handleFavorite}
              className={clsx(classes.favoriteIcon, isFavorite && classes.filled)}
              data-test-element="favorite-icon"
            />
          </span>

          {isModalShown && favoriteIcon && (
            <AddToFavorites
              entity={{ ...entity, coordinates: favoriteIcon.getBoundingClientRect() }}
              closeModal={closeFavorite}
              toggleCallback={favoriteCallback}
            />
          )}
        </div>
        <span
          className={clsx(
            'd-flex',
            'justify-content-between',
            'align-items-center',
            'mb-2',
            classes.bold,
            'font-kraftig'
          )}>
          <span
            className={clsx(classes.overflowText, 'pe-2')}
            role="button"
            onClick={handleUnlock ? handleUnlockClick : handleTitleClick}>
            {project.class.name} - {project.type.name}
          </span>
          <div className="position-relative">
            <span>{project.value ? `$${numberWithSpaces(project.value)}` : NA}</span>
            {project.estimatedValue && (
              <span className={clsx(classes.estimated, 'position-absolute', 'bottom-100', 'end-0')}>
                EST.
              </span>
            )}
          </div>
        </span>
        <div
          className={clsx(
            'd-flex',
            'justify-content-between',
            'align-items-center',
            'mb-2',
            classes.light
          )}>
          <span className={clsx(classes.overflowText, 'pe-2', classes.description)}>
            {project.permitType?.name || project.description}
          </span>
          <span>{project.effectiveDate}</span>
        </div>

        {!hideContacts && (
          <div className="d-flex justify-content-between align-items-center">
            <span className={clsx(classes.overflowText, 'd-flex', 'align-items-center')}>
              <span className="me-2 d-flex align-items-center">
                <LocationIcon />
              </span>
              <span>{project.locationToShow}</span>
            </span>

            <button
              onClick={handleClick}
              className={clsx(classes.button, active && classes.active)}
              data-test-element="search-project-contacts-button">
              {t('searchProject.contacts')}
            </button>
          </div>
        )}

        {isLocked && handleUnlock && (
          <div
            role="button"
            onClick={handleUnlockClick}
            className={clsx(
              'position-absolute',
              'top-0',
              'start-0',
              'bottom-0',
              'end-0',
              'd-flex',
              'justify-content-center',
              'align-items-center',
              classes.lockedWrapper
            )}>
            <div className={classes.lockIcon}>
              <LockIcon className="w-100 h-auto" />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ProjectInfo;
