import React, { FC, ReactNode, useCallback, MouseEvent } from 'react';
import classes from './Checkbox.module.scss';
import { clsx } from 'utils/clsx';

interface IProps {
  id: number;
  checked: boolean;
  onChange: (id: number, checked: boolean) => void;
  children: ReactNode;
}

const Checkbox: FC<IProps> = ({ id, checked, onChange, children }) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onChange(id, checked);
    },
    [checked, onChange]
  );
  return (
    <div className="d-flex position-relative user-select-none">
      <div className="d-flex align-items-center" onClick={handleClick} role="button">
        <div
          className={clsx(
            classes.wrapper,
            checked && classes.active,
            'd-flex',
            'align-items-center',
            'justify-content-center',
            'me-2'
          )}>
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 7L5.5 11L10 5L12.25 2L12.8125 1.25"
              stroke="white"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Checkbox;
