import React, { FC } from 'react';
import Button from 'components/Button';
import penIcon from 'assets/icons/pen.svg';
import trashIcon from 'assets/icons/trash.svg';
import { ReactComponent as PlaceIcon } from 'assets/icons/place.svg';
import classes from './ListItem.module.scss';
import { useTranslation } from 'react-i18next';
import { ISavedSearch } from 'view/SavedSearches/types';
import { Link } from 'react-router-dom';
import { clsx } from 'utils/clsx';

interface Props {
  data: ISavedSearch;
  deleteClick: (id: number) => void;
  editClick: (id: number) => void;
  dataTestId: number;
}

const ListItem: FC<Props> = ({ data, deleteClick, editClick, dataTestId }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.listItemWrapper} d-flex justify-content-between w-100 py-3 px-4 mb-2`}>
      <div className={`info ${classes.listItem}`}>
        <div className="d-flex pb-3 align-items-center">
          <Link
            to={`/saved-search/${data.id}`}
            replace={true}
            className={`${classes.title} default-color-text m-0 text-decoration-none`}>
            <span className="font-kraftig">{data.name}</span>
          </Link>
          {data.newProjects > 0 && (
            <span className="font-halbfett badge bg-secondary default-badge mx-1 d-flex align-items-center justify-content-center px-1">
              {data.newProjects}
            </span>
          )}
        </div>
        <div className="location d-flex align-items-center">
          <div className={`${classes.placeIcon} pe-3`}>
            <PlaceIcon />
          </div>
          <span className={clsx(classes.locationName, 'pe-3')}>{data.description}</span>
        </div>
      </div>
      <div className="actions d-flex">
        <Button
          disabled={false}
          type="light"
          className="me-4 default-color-text px-1"
          data-test-element={`saved-search-edit-button-${dataTestId}`}
          onClick={() => editClick(data.id)}>
          <img src={penIcon} alt="" />
          &nbsp;{t('components.listItem.edit')}
        </Button>
        <Button
          disabled={false}
          type="light"
          className="default-color-text pe-1"
          data-test-element={`saved-search-delete-button-${dataTestId}`}
          onClick={() => deleteClick(data.id)}>
          <img src={trashIcon} alt="" />
          &nbsp;{t('components.listItem.delete')}
        </Button>
      </div>
    </div>
  );
};

export default ListItem;
