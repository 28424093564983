import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MainLayout } from 'view/Layout/MainLayout';
import { Components } from 'view/ComponentsPage/Components';
import { Settings, Subscription } from 'view/Profile';
import Private from './Private';
import ProfileLayout from 'view/Layout/ProfileLayout';
import SavedSearches from 'view/SavedSearches';
import Favorites from 'view/Favorites';
import Error404 from 'view/Error404';
import UnderConstruction from 'view/UnderConstruction';
import Analytics from 'view/Favorites/Analytics';
import SearchProjects from 'view/SearchProjects';
import SavedSearchResults from 'view/SavedSearches/components/SavedSearchResults';
import Home from 'view/Home';
import AuthContainer from 'view/Auth/AuthContainer';

const ReactLinks: FC = () => {
  return (
    <Routes>
      <Route element={<AuthContainer />} path="/">
        <Route element={<Home />} path="/" />
        <Route element={<MainLayout Private={Private} />}>
          <Route element={<ProfileLayout />} path="/profile">
            <Route element={<Settings />} path="settings" />
            <Route element={<Subscription />} path="subscription" />
            <Route element={<UnderConstruction />} path="company" />
            <Route element={<UnderConstruction />} path="notifications" />
          </Route>
          <Route element={<SavedSearches />} path="/saved-search" />
          <Route element={<SavedSearchResults />} path="/saved-search/:id" />
          <Route element={<Navigate to="/favorites/:id" replace />} path="/favorites" />
          <Route element={<Favorites />} path="/favorites/:id" />
          <Route element={<Analytics />} path="/favorites/analytics" />
          <Route element={<SearchProjects />} path="search-projects/:code/:canonicalTitle" />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route element={<Components />} path="/components" />
      </Route>
    </Routes>
  );
};

export default ReactLinks;
