import React, { FC, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import classes from '../Auth.module.scss';
import { Button, AnimatedInput } from 'components';
import { clsx } from 'utils/clsx';
import { IPreRegisterData } from '../types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PreRegisterSchema } from 'utils/Formik/validation';
import AuthFooter from './AuthFooter';

export const PreRegister: FC = () => {
  const { t } = useTranslation();
  const [, setParams] = useSearchParams();

  const signUp = useCallback(async (values: IPreRegisterData) => {
    setParams('auth=sign-up', { state: values });
  }, []);

  const formik = useFormik<IPreRegisterData>({
    initialValues: {
      fullName: '',
      email: ''
    },
    onSubmit: signUp,
    validationSchema: PreRegisterSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const isFormFilled = useMemo<boolean>(
    () => !!Object.values(formik.errors).length,
    [formik.values, formik.errors]
  );

  return (
    <div>
      <form action="#" className={clsx(classes.preRegisterForm)} onSubmit={formik.handleSubmit}>
        <div className="d-flex align-items-center flex-column bg-white py-4">
          <h1
            className={clsx(
              classes.title,
              classes.preRegisterTitle,
              'pb-2',
              'text-right',
              'w-100',
              'px-4'
            )}>
            {t('home.signUp')}
          </h1>
          <p className={clsx(classes.subTitle, 'mb-4', 'text-right', 'w-100', 'px-4')}>
            {t('home.search')}
          </p>
          <div className="px-4 w-100">
            <div className="mb-4 pb-2">
              <AnimatedInput
                type="text"
                name="fullName"
                value={formik.values.fullName}
                onInput={onInputChange}
                isError={!!formik.touched.fullName && !!formik.errors.fullName?.length}
                isSuccess={!!formik.touched.fullName && !formik.errors.fullName?.length}
                errorText={formik.errors.fullName}
                dataTestElement="footer-register-name-input">
                Full name
              </AnimatedInput>
            </div>
            <div className="mb-4">
              <AnimatedInput
                type="text"
                name="email"
                value={formik.values.email}
                onInput={onInputChange}
                isError={!!formik.touched.email && !!formik.errors.email?.length}
                isSuccess={!!formik.touched.email && !formik.errors.email?.length}
                errorText={formik.errors.email}
                dataTestElement="register-email-input">
                Email
              </AnimatedInput>
            </div>
          </div>

          <div className="px-4 w-100">
            <Button
              type="primary"
              disabled={isFormFilled}
              className={clsx('w-100', classes.formBtn)}
              data-test-element="register-submit-button">
              {t('auth.signUp')}
            </Button>
          </div>
        </div>
      </form>
      <div>
        <AuthFooter step="sign-up" light />
      </div>
    </div>
  );
};
