import React, { Children, FC, ReactElement, ReactNode } from 'react';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow.svg';
import { clsx } from 'utils/clsx';
import classes from '../Reports.module.scss';

interface IButtonsProps {
  next?: () => void;
  previous?: () => void;
  goToSlide?: (slide: number) => void;
  children: ReactNode;
}

const ButtonGroup: FC<IButtonsProps> = (props) => {
  if (!props.next || !props.goToSlide || !props.previous) return <></>;
  const { next, previous, goToSlide, children } = props;

  const currentSlide = Children.toArray(children).reduce(
    (previousValue: number, currentValue: ReactNode, currentIndex: number): number => {
      return (currentValue as ReactElement).props.className.includes('slick-active')
        ? currentIndex
        : previousValue;
    },
    0
  );
  const totalItems = Children.count(children);
  const dots = new Array(totalItems)
    .fill(null)
    .map((_, idx) => idx)
    .reduce((accum: number[][], item: number) => {
      const newIdx = Math.floor(item / 3);
      accum[newIdx] = [...(accum[newIdx] || []), item];
      return accum;
    }, []);
  const dotsToShow = dots.find((arr) => arr.includes(currentSlide));

  return (
    <div className="carousel-button-group position-absolute top-100 d-flex w-100 align-items-center justify-content-center">
      <LeftArrowIcon
        role="button"
        className={clsx(classes.arrow, currentSlide === 0 && classes.disabled)}
        onClick={previous}
      />
      <div className="d-flex align-items-center">
        {dotsToShow?.map((idx) => (
          <div
            key={idx}
            role="button"
            onClick={() => goToSlide(idx)}
            className={clsx(classes.dot, 'rounded', currentSlide === idx && classes.active)}
          />
        ))}
      </div>
      <RightArrowIcon
        className={clsx(classes.arrow, currentSlide + 1 === totalItems && classes.disabled)}
        role="button"
        onClick={next}
      />
    </div>
  );
};

export { ButtonGroup };
