import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'utils/clsx';
import classes from '../Favorites.module.scss';
import { Button, Dropdown } from 'components';
import { FavoritesOptions } from '../types';
import { IDropdownOption } from 'components/Dropdown/types';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-grey.svg';

interface Props {
  dropdownSelect: string;
  dropdownOptions: FavoritesOptions[];
  onDropdownChange: (option: FavoritesOptions) => void;
  onShare: () => void;
  onCreateList: () => void;
  onEditList: () => void;
  onDeleteList: () => void;
  editableFavorite: boolean;
}

export const SubHeader: FC<Props> = ({
  dropdownSelect,
  dropdownOptions,
  onDropdownChange,
  onShare,
  onCreateList,
  onEditList,
  onDeleteList,
  editableFavorite
}) => {
  const { t } = useTranslation();
  const [crudListOptions] = useState<IDropdownOption[]>([
    {
      selected: false,
      content: 'Edit list details',
      icon: PenIcon,
      id: 1,
      attr: 'favorite-list-edit-button'
    },
    {
      selected: false,
      content: 'Delete this list',
      icon: TrashIcon,
      id: 2,
      attr: 'favorite-list-delete-button'
    }
  ]);

  const handleDropdownChange = (value: IDropdownOption): void => {
    if (value.id === 1) {
      onEditList();
      return;
    }
    if (value.id === 2) {
      onDeleteList();
      return;
    }
  };

  return (
    <div
      className={clsx(
        classes.subHeader,
        'default-shadow default-color-text px-4 py-2 bg-white mb-4 d-flex align-items-center'
      )}>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center">
          <div className="w-25 me-5">
            <Dropdown
              title={dropdownSelect}
              options={dropdownOptions}
              onSelect={onDropdownChange}
              float="start"
              activeClass={classes.dropdownActiveTitle}
              titleClass={classes.dropdownTitle}
              extendedPosition="start">
              <Button
                type="light"
                color="#0087FF"
                onClick={onCreateList}
                className="w-100 default-shadow py-2 mt-2">
                <span>{t('favorites.createPersonalList')}</span>
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Button
            type="secondary"
            background="#DBDFEA"
            color="#2F3139"
            className={clsx(classes.subHeaderButton, 'px-4')}
            onClick={onShare}>
            <span className="font-kraftig">{t('favorites.shareThisList')}</span>
          </Button>
          {editableFavorite && (
            <div className="ms-3">
              <Dropdown
                title={<DotsIcon />}
                options={crudListOptions}
                onSelect={handleDropdownChange}
                float="start"
                activeClass={classes.dropdownActiveIcon}
                showArrow={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
