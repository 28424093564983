import { IAxiosResponse } from 'utils/types';
import {
  FavoriteRole,
  FavoriteRoles,
  EntityType,
  IFavoriteEntity
} from 'view/AddToFavorites/types';
import { IInputChangeItem } from '../SearchProjects/components/SubHeader/types';
import { IOption } from 'components/Select/types';
import { IRow, Order } from 'components/Table/types';
import { ILocation } from '../SearchProjects/types';

export const FAVORITES_REPO = 'FAVORITES_REPO';
export interface IFavoritesRepo {
  createFavoritesList(userId: number, name: string): Promise<IAxiosResponse<FavoritesList>>;
  addToFavorites(userId: number, data: IFavoriteData): Promise<void>;
  deleteFromFavorites(userId: number, data: IFavoriteData): Promise<void>;
  getListsByEntity(
    userId: number,
    type: string,
    entityId: number,
    role: string
  ): Promise<IAxiosResponse<FavoritesList[]>>;
  getFavoritesList(userId: number): Promise<IAxiosResponse<FavoritesList[]>>;
  getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<IAxiosResponse<number>>;
  getFavoritesData(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<IAxiosResponse<IFavoriteDataRes<FavoriteData>>>;
  deleteFavoriteList(userId: number, listId: number): Promise<void>;
  editFavoriteList(
    userId: number,
    listId: number,
    name: string
  ): Promise<IAxiosResponse<FavoritesList>>;
  getFavoriteLocations(
    userId: number,
    systemType: string,
    body: IFavoriteLocationBody
  ): Promise<IAxiosResponse<FavoriteLocation[]>>;
}

export const FAVORITES_SERVICE = 'FAVORITES_SERVICE';
export interface IFavoritesService {
  createFavoritesList(userId: number, name: string): Promise<FavoritesList>;
  addToFavorites(userId: number, data: IFavoriteData): Promise<void>;
  deleteFromFavorites(userId: number, data: IFavoriteData): Promise<void>;
  getListsByEntity(userId: number, type: string, entityId: number, role: string): Promise<number[]>;
  getFavoritesList(userId: number): Promise<FavoritesList[]>;
  getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<number>;
  getFavoritesData(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<IFavoriteDataRes<IRowFavorite>>; //IRowFavorite
  deleteFavoriteList(userId: number, listId: number): Promise<void>;
  editFavoriteList(userId: number, listId: number, name: string): Promise<FavoritesList>;
  getFavoriteLocations(
    userId: number,
    systemType: string,
    listId: number,
    favoriteFilters: IFavoriteFilters
  ): Promise<IOption[]>;
}

export interface IFavoritesStore {
  list: FavoritesList[];
  totalValue: number;
  totalPages: number;
  createFavoritesList(userId: number, name: string): Promise<FavoritesList>;
  addToFavorites(userId: number, data: IFavoriteData): Promise<void>;
  deleteFromFavorites(userId: number, data: IFavoriteData): Promise<void>;
  getListsByEntity(userId: number, type: string, entityId: number, role: string): Promise<number[]>;
  getFavoritesList(userId: number): Promise<void>;
  getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<void>;
  getFavoritesData(userId: number, systemType: string, body: IFavoriteDataBody): Promise<void>;
  deleteFavoriteList(userId: number, listId: number): Promise<void>;
  editFavoriteList(userId: number, listId: number, name: string): Promise<void>;
  getFavoriteLocations(
    userId: number,
    systemType: string,
    listId: number,
    favoriteFilters: IFavoriteFilters
  ): Promise<IOption[]>;
  updateNoteStatus(id: number, hasNote: boolean): void;
}

export type FavoritesOptions = {
  id: number;
  selected: boolean;
  content: string;
};

export type FavoritesList = {
  id: number;
  name: string;
};

export interface IFavoriteData {
  entityType: EntityType;
  entityId: number;
  lists: number[];
  role: number;
}

export enum ModalType {
  CREATE,
  EDIT
}

export interface IFavoriteDataBody extends IFavoriteFilters {
  lists: number[];
  order: Order[];
  search?: string;
  page: number;
}

export type Detail = {
  type: number;
  value: string;
};

export type Contacts = {
  id: number;
  type: number;
  name: string;
  details: Detail[];
  role: number;
};

export interface IFavoriteDataRes<T> {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: T[];
}

export type FavoriteData = ProjectTableData | CompanyTableData | PersonTableData;

export type Status = {
  id: number;
  name: string;
  color: string;
};

export type DefaultTypeObj = {
  id: number;
  name: string;
};

export type Location = {
  id: number;
  title: string;
  fullTitle: string;
  type: number;
  coordinates: [number, number];
  shape: [number, number][][][];
};

export type ProjectTableData = {
  hasNotes: boolean;
  id: number;
  class: DefaultTypeObj;
  type: DefaultTypeObj;
  subType: DefaultTypeObj;
  description: string;
  status: Status;
  updatedAt: string;
  value: number;
  location: ILocation;
  contacts: Contacts[];
  role: FavoriteRole;
  favoritesList: number[];
};

export type Persons = {
  id: number;
  details: Detail[];
  firstName: string;
  lastName: string;
  middleName: string;
  name: string;
};

export type CompanyTableData = {
  hasNotes: boolean;
  id: number;
  description: string;
  location: Location;
  name: string;
  persons: Persons[];
  projectsCount: number;
  projectsValue: number;
  role: FavoriteRole;
  favoritesList: number[];
};

export type PersonTableData = {
  hasNotes: boolean;
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  name: string;
  projectsCount: number;
  projectsValue: number;
  type: { [key: string]: string };
  role: FavoriteRole;
  favoritesList: number[];
};

export interface IFavoriteFilters {
  classes: number[];
  types: number[];
  subTypes: number[];
  projectValue?: IInputChangeItem;
  date?: IInputChangeItem;
  location?: number[];
  projectCount?: IInputChangeItem;
  totalAmount?: IInputChangeItem;
}

export type FavoriteLocation = {
  id: number;
  title: string;
};

export interface IFavoriteLocationBody extends IFavoriteFilters {
  lists: number[];
  search?: string;
}

export type FavoriteFormValue = IInputChangeItem | number[] | null | { min: string; max: string };

export type FavoriteTabSystemType = 'project' | 'company' | 'person';

export interface IRowFavorite extends IRow {
  role: FavoriteRoles;
}

export interface IFavoriteState {
  entity: IFavoriteEntity | null;
  showAddToFavorite: boolean;
  favoriteId: number | null;
}
