import React, { FC, useCallback, useState } from 'react';
import { ICell, ICellContact, IListIcon } from '../types';
import classes from '../Table.module.scss';
import { clsx } from 'utils/clsx';
import { iconListHoverByNum } from 'utils/icons';
import { SourceLink } from 'utils/types';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';

interface Props {
  data: ICell;
  cellClass?: string;
  onTitleClick: (() => void) | null;
}

export const Cell: FC<Props> = ({ data, cellClass, onTitleClick }) => {
  const [hoverKey, setHoverKey] = useState<string | null>(null);
  const { alertStore } = useStore();
  const { t } = useTranslation();

  const currentIcon = useCallback(
    (iconItem: IListIcon, currentHoverKey: string): string => {
      return hoverKey === currentHoverKey
        ? iconListHoverByNum[iconItem.type]
        : (iconItem.icon as string);
    },
    [hoverKey]
  );

  const handleIconClick = (iconItem: IListIcon): void => {
    if (iconItem.type === SourceLink.LINKEDIN || iconItem.type === SourceLink.WEB) {
      window.open(iconItem.label, '_blank');
      return;
    }
    if (iconItem.type === SourceLink.EMAIL) {
      window.open(`mailto:${iconItem.label}`, '_blank');
      return;
    }
    navigator.clipboard
      .writeText(iconItem.label)
      .then(() => alertStore.successAlert(t('savedSearch.copied')))
      .catch(() => alertStore.errorAlert('savedSearch.errorCopy'));
  };

  return (
    <div className={clsx(classes.cellWidth, cellClass)}>
      {data.label && <label className={classes.label}>{data.label}</label>}
      <div className={data.colorType ? 'd-flex' : 'd-block'}>
        {data.colorType && (
          <div
            className={clsx(classes.colorType, 'rounded-circle', 'me-2', 'mt-1')}
            style={{ backgroundColor: data.colorType }}></div>
        )}
        {data.value && (
          <h6
            className={clsx(
              'm-0',
              (data.field === 'property' || data.field === 'details') && classes.selectText
            )}
            role={onTitleClick ? 'button' : 'note'}
            onClick={onTitleClick ? onTitleClick : () => null}>
            {data.value}
          </h6>
        )}
      </div>
      {data.status && (
        <span
          style={{ color: data.status.color }}
          className={clsx(
            classes.status,
            classes['status' + data.status],
            'd-block',
            'py-1',
            'px-2'
          )}>
          {data.status.name}
        </span>
      )}
      {data.additionalValue && (
        <span
          className={clsx(
            classes.subTitle,
            classes.additionalValue,
            (data.field === 'property' || data.field === 'details') && classes.description,
            (data.field === 'property' || data.field === 'details') && 'mb-4'
          )}>
          {data.additionalValue}
        </span>
      )}
      <>
        <div className={clsx(classes.contactRow, 'row')}>
          {data.values &&
            data.values.map((contactItem: ICellContact, valueIdx: number) => (
              <div key={`contact-${valueIdx}`} className="col-4 mb-4 position-relative">
                {contactItem.label && (
                  <span className={clsx(classes.contactLabel, 'font-kraftig')}>
                    {contactItem.label}
                  </span>
                )}
                <h6 className="m-0">{contactItem.value}</h6>
                {contactItem.phone && <span className={classes.subTitle}>{contactItem.phone}</span>}
                <div className="d-flex">
                  {contactItem.iconItems?.map((iconItem: IListIcon, iconIdx: number) => (
                    <img
                      src={currentIcon(iconItem, `${valueIdx}-${iconIdx}`)}
                      alt=""
                      className={clsx(classes.contactIcon, 'position-relative', 'me-1')}
                      key={`icon-contact-${iconIdx}`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={iconItem.label}
                      onClick={() => handleIconClick(iconItem)}
                      onMouseEnter={() => setHoverKey(`${valueIdx}-${iconIdx}`)}
                      onMouseLeave={() => setHoverKey(null)}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </>
    </div>
  );
};
