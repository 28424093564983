import { IOption } from 'components/Select/types';
import { ISearchParams } from './components/SubHeader/types';
import { IFullProject } from 'view/ProjectInfoModal/types';
import { FavoriteRole, EntityType, IFavoriteKey } from 'view/AddToFavorites/types';
import { FunctionComponent } from 'react';
import { IAxiosResponse } from 'utils/types';

export interface IInputsData {
  valueMin: number | null;
  valueMax: number | null;
  levels: number | null;
  units: number | null;
  location: string;
  keyword: string;
}
export type IActiveInputs = {
  [key in keyof IInputsData]: boolean;
};

export interface IKeyword {
  title?: string;
  type: number;
  value: number;
}

export interface IRecentProjectsData {
  count: number;
  currentPage: number;
  data: IProject[];
  limit: number;
  totalPages: number;
  value: number;
  hasSubscription: boolean;
}

export interface IProjectsData {
  currentPage: number;
  data: IProject[];
  limit: number;
  hasMore: boolean;
  hasSubscription: boolean;
}

export interface IProject {
  id: number;
  class: IProjectField;
  subType: IProjectField;
  type: IProjectField;
  permitType: IProjectField;
  effectiveDate: string;
  description: string;
  status: {
    id: number;
    name: string;
    color: string;
  };
  createdAt: string;
  value: number;
  location: ILocation;
  locationToShow: string;
  favourite: boolean;
  hasFullData: boolean;
  estimatedValue: boolean;
  isLocked: boolean;
}

interface IProjectField {
  id: number;
  name: string;
}

export interface IContact {
  id: number;
  type: EntityType;
  role: EntityType;
  name: string;
  details: IDetail[];
  favourite: boolean;
}

export interface IDetail {
  type: number;
  value: string;
  icon: FunctionComponent;
}

export interface IProjectContact extends IContact {
  projectId: number;
}

export type formInputChangeEventType = 'levels' | 'units' | 'value';

export type Statistics = {
  count: number;
  value: number;
};

export const SEARCH_PROJECTS_REPO = 'SEARCH_PROJECTS_REPO';
export interface ISearchProjectsRepo {
  getKeywords(keyword: string): Promise<IAxiosResponse<IKeyword[]>>;
  getProjects(
    params: ISearchParams,
    controller?: AbortController
  ): Promise<IAxiosResponse<IProjectsData>>;
  getStatistics(locationId: number): Promise<IAxiosResponse<Statistics>>;
  getContacts(id: number): Promise<IAxiosResponse<IContact[]>>;
  getCompany(
    projectId: number,
    role: string,
    contactId: number
  ): Promise<IAxiosResponse<ICompanyResponse>>;
  getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>>;
  getAllProjectInfo(projectId: number): Promise<IAxiosResponse<IFullProject>>;
  getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>>;
}
export const SEARCH_PROJECTS_SERVICE = 'SEARCH_PROJECTS_SERVICE';
export interface ISearchProjectService {
  getKeywords(keyword: string): Promise<IKeyword[]>;
  getProjects(params: ISearchParams, controller?: AbortController): Promise<IProjectsData>;
  getStatistics(locationId: number): Promise<Statistics>;
  getContacts(id: number): Promise<IContact[]>;
  getCompany(projectId: number, role: string, contactId: number): Promise<ICompanyResponse>;
  getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IRecentProjectsData>;
  getAllProjectInfo(projectId: number): Promise<IFullProject>;
  getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IRecentProjectsData>;
}

export interface ISearchStore {
  getKeywords(keyword: string): Promise<IKeyword[]>;
  getProjects(params: ISearchParams, controller?: AbortController): Promise<void>;
  getStatistics(locationId: number): Promise<void>;
  getContacts(id: number): Promise<void>;
  setProjects(projectsData: IProjectsData): void;
  projectsData: IProjectsData;
  isValueLoading: boolean;
  contacts: IContact[];
  recentContacts: IContact[];
  activeFavorite: IActiveFavorite | null;
  getCompany(projectId: number, role: string, contactId: number): Promise<void>;
  getRecentProjects(contactType: string, contactId: number, page: number): Promise<void>;
  getAllProjectInfo(projectId: number): Promise<IFullProject>;
  getRelatedProjects(projectId: number): Promise<void>;
}

export interface IOptionsConfig {
  projectClasses: IOption[];
  projectStatuses: IOption[];
  projectTypes: IOption[];
}

export type Detail = {
  type: number;
  value: string;
  icon: FunctionComponent;
};

export type Location = {
  id: number;
  title: string;
  type: number;
  stateCode: string;
  coordinates: [number, number];
};

type LocationKey = 'address' | 'city' | 'county' | 'state' | 'zip';

export type ILocation = {
  [key in LocationKey]: Location;
};

export type Person = {
  id: number;
  type: number;
  name: string;
  details: Detail[];
  role?: number;
  favorite: boolean;
};

export interface ICompanyResponse {
  id: number;
  name: string;
  description: string;
  details: Detail[];
  location: Location;
  projectPersons: Person[];
  otherPersons: Person[];
  favorite: boolean;
}

export type FavoriteContacts = {
  id: number;
  type: EntityType;
  role: FavoriteRole;
};

export interface IActiveFavorite {
  id: number;
  type: IFavoriteKey;
  role?: number;
}

export type RelatedProjectType = 'openProjects' | 'closedProjects';
export type RelatedProjectKey = 'open' | 'closed';
export type ProjectType = 'recentProjects' | 'openProjects' | 'closedProjects' | 'projects';

export interface IListRef {
  _outerRef: HTMLDivElement;
  scrollToItem: (id: number | null, type: string) => void;
}
