import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import classes from './SavedSearchResults.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { IAction, IColumn, Order } from 'components/Table/types';
import { Table } from 'components';
import SubHeader from './components/SubHeader';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isNaN } from 'formik';
import { useIntersectionObserverEffect } from 'hooks/useIntersectionObserverEffect';
import AddToFavorites from 'view/AddToFavorites';
import { FavoriteTypes, IFavoriteEntity, FavoriteRoles } from 'view/AddToFavorites/types';
import { orderProcessing } from 'helper/orderProcessing';
import { IFavoriteState } from 'view/Favorites/types';
import AddNoteModal from 'view/AddNoteModal';
import { INoteEntity, INoteState } from 'view/AddNoteModal/types';
import ProjectInfoModal from 'view/ProjectInfoModal';
import { useProgress } from 'hooks/useProgress';
import ProgressBar from 'components/ProgressBar';

const SavedSearchResults: FC = observer(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { savedSearchesStore, savedSearchResultsStore, userStore } = useStore();
  const searchId = Number(id);
  const [params, setParams] = useSearchParams();
  const details = params.get('details');
  const [page, setPage] = useState<number>(1);
  const [isSearchesLoading, setIsSearchesLoading] = useState<boolean>(false);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [isMoreProjectsLoading, setIsMoreProjectsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const observableDiv = useRef<HTMLDivElement | null>(null);
  const [favoriteState, setFavoriteState] = useState<IFavoriteState>({
    entity: null,
    showAddToFavorite: false,
    favoriteId: null
  });
  const [noteState, setNoteState] = useState<INoteState>({
    entity: {
      coordinates: null,
      name: '',
      description: '',
      id: null,
      type: 'project'
    },
    showAddNote: false
  });

  const {
    progress: projectsProgress,
    visible: projectsVisible,
    reload: projectsReloadProgress
  } = useProgress(isProjectsLoading);
  const { progress: searchesProgress, visible: searchesVisible } = useProgress(isSearchesLoading);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        field: 'property',
        headerName: t('savedSearch.table.details'),
        sort: true
      },
      {
        field: 'date',
        headerName: t('savedSearch.table.date'),
        sort: false
      },
      {
        field: 'status',
        headerName: t('savedSearch.table.status'),
        sort: true
      },
      {
        field: 'location',
        headerName: t('savedSearch.table.location')
      },
      {
        field: 'contacts',
        headerName: t('savedSearch.table.contacts')
      },
      {
        field: 'value',
        headerName: t('savedSearch.table.value'),
        sort: true
      },
      {
        field: 'actions',
        headerName: t('savedSearch.table.actions')
      }
    ];
  }, []);

  const tableAction = useMemo((): IAction[] => {
    return [
      {
        icon: 'favorite',
        callback: (id) => {
          const coordinates: DOMRect | null =
            document.getElementById(`table-action-favorite-${id}`)?.getBoundingClientRect() || null;
          if (!coordinates) return;
          const currentCell = savedSearchResultsStore.getCellByFieldNameInRow(id, 'details');
          const entity: IFavoriteEntity = {
            id,
            coordinates,
            type: FavoriteTypes.Project,
            role: FavoriteRoles.Project,
            key: 'table',
            name: currentCell ? (currentCell.value as string) : 'Project'
          };
          setFavoriteState((prev) => ({ ...prev, entity, favoriteId: id }));
          showAddFavoriteModal(entity);
        }
      },
      {
        icon: 'document',
        callback: (id) => {
          const coordinates: DOMRect | null =
            document.getElementById(`table-action-document-${id}`)?.getBoundingClientRect() || null;
          const details = savedSearchResultsStore.tableRows
            .find((row) => row.id === id)
            ?.fields.find((item) => item.field === 'details');
          if (!details?.value || !details.additionalValue) return;
          const entity: INoteEntity = {
            coordinates,
            name: details.value as string,
            description: details.additionalValue,
            id,
            type: 'project'
          };
          setNoteState((prev) => ({ ...prev, entity }));
          showAddNoteModal(id);
        }
      },
      {
        icon: 'share',
        callback: (id) => actionClick(id)
      }
    ];
  }, []);

  const showAddNoteModal = useCallback((id: number) => {
    const thisModal = document.getElementById(`note-${id}`);
    setNoteState((prev) => ({ ...prev, showAddNote: !thisModal }));
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: false }));
  }, []);

  const handleOrder = useCallback(
    (field: string): void => {
      projectsReloadProgress();
      setOrders(orderProcessing(field, orders));
    },
    [projectsReloadProgress, orders]
  );

  const actionClick = (id: number): void => {
    console.log('Click row: ', id);
  };

  const showAddFavoriteModal = (entity: IFavoriteEntity): void => {
    const thisModal = document.getElementById(
      `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
    );
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: !thisModal }));
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
  };

  const handleTableScroll = useCallback((): void => {
    closeAddFavoriteModal();
    closeNoteModal();
  }, []);

  const handleOpenProjectInfo = useCallback(
    (id: number) => {
      closeNoteModal();
      setParams(`details=${id}`);
    },
    [pathname]
  );

  const closeAddFavoriteModal = (): void => {
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: false }));
  };
  const navigate = useNavigate();

  const onSearchChange = useCallback(
    (id: number) => {
      projectsReloadProgress();
      const path = isNaN(id) ? 'all' : String(id);
      setPage(1);
      navigate('/saved-search/' + path, { replace: true });
    },
    [projectsReloadProgress]
  );

  const loadSearches = useCallback(async () => {
    if (savedSearchesStore.savedSearches.length || !userStore.user) return;
    try {
      setIsSearchesLoading(true);
      await savedSearchesStore.getSavedSearches(userStore.user.id);
    } finally {
      setIsSearchesLoading(false);
    }
  }, []);

  const loadProjects = useCallback(async () => {
    if (!userStore.user) return;
    try {
      const ids = isNaN(searchId)
        ? savedSearchesStore.savedSearches.map((search) => search.id)
        : [searchId];
      setIsProjectsLoading(true);
      await savedSearchResultsStore.getDashboardData(userStore.user.id, ids, 1, orders);
    } catch (e) {
      navigate('/saved-search/all', { replace: true });
    } finally {
      setIsProjectsLoading(false);
      await savedSearchesStore.getNewProjectCount(userStore.user.id);
    }
  }, [searchId, orders]);

  const loadMoreProjects = useCallback(async () => {
    if (!userStore.user) return;
    try {
      const ids = isNaN(searchId)
        ? savedSearchesStore.savedSearches.map((search) => search.id)
        : [searchId];
      setIsMoreProjectsLoading(true);
      await savedSearchResultsStore.getMoreData(userStore.user.id, ids, page, orders);
    } finally {
      setIsMoreProjectsLoading(false);
      await savedSearchesStore.getNewProjectCount(userStore.user.id);
    }
  }, [searchId, page]);

  const closeNoteModal = useCallback(
    () => setNoteState((prev) => ({ ...prev, showAddNote: false })),
    []
  );

  const handleUpdateNote = useCallback((id: number, hasNote: boolean) => {
    savedSearchResultsStore.setNoteStatus(id, hasNote);
  }, []);

  useLayoutEffect(() => {
    (async () => await loadProjects())();
  }, [searchId, orders]);

  useEffect(() => {
    if (page === 1 || isMoreProjectsLoading) return;
    loadMoreProjects().then();
  }, [page]);

  useEffect(() => {
    if (isNaN(Number(id)) && id !== 'all') {
      navigate('/saved-search/all', { replace: true });
    }
    loadSearches().then();
    return () => {
      savedSearchResultsStore.setTableRows([]);
    };
  }, []);

  useIntersectionObserverEffect(
    observableDiv,
    (entry: IntersectionObserverEntry) => {
      setPage((prev) => {
        if (
          entry.isIntersecting &&
          prev < savedSearchResultsStore.totalPages &&
          !isMoreProjectsLoading
        ) {
          return prev + 1;
        }
        return prev;
      });
    },
    [observableDiv.current, savedSearchResultsStore.tableRows, savedSearchResultsStore.totalPages]
  );

  const favoriteCallback = useCallback(
    (isActive: boolean) => {
      if (favoriteState.favoriteId) {
        savedSearchResultsStore.updateFavoriteRow(favoriteState.favoriteId, isActive);
      }
    },
    [favoriteState.favoriteId]
  );

  if (!searchesVisible)
    return (
      <div className={clsx(classes.progressBar, 'position-relative')}>
        <ProgressBar progress={searchesProgress} />
      </div>
    );

  return (
    <div>
      <SubHeader onSelectSearch={onSearchChange} searchId={searchId} />
      <div className={clsx(classes.tableWrapper, 'my-3', 'container-fluid')}>
        {!projectsVisible ? (
          <ProgressBar progress={projectsProgress} thin />
        ) : (
          <>
            {savedSearchResultsStore.tableRows.length > 0 ? (
              <>
                <Table
                  columns={columns}
                  rows={savedSearchResultsStore.tableRows}
                  actions={tableAction}
                  orders={orders}
                  orderAction={handleOrder}
                  onScroll={handleTableScroll}
                  onTitleClick={handleOpenProjectInfo}>
                  <div>
                    <div ref={observableDiv}></div>
                    <div
                      className={clsx(
                        'loader',
                        'd-flex',
                        'justify-content-center',
                        'py-3',
                        !isMoreProjectsLoading && 'loader-hidden'
                      )}>
                      <div />
                    </div>
                  </div>
                </Table>
              </>
            ) : (
              <div className="p-4 bg-white">
                <h4 className="text-center default-color-text">{t('savedSearch.noResults')}</h4>
              </div>
            )}
          </>
        )}
      </div>
      {favoriteState.showAddToFavorite && favoriteState.entity && (
        <AddToFavorites
          entity={favoriteState.entity}
          closeModal={closeAddFavoriteModal}
          toggleCallback={favoriteCallback}
        />
      )}
      {noteState.showAddNote && noteState.entity && (
        <AddNoteModal
          onNoteChange={handleUpdateNote}
          entity={noteState.entity}
          closeModal={closeNoteModal}
        />
      )}

      {details && <ProjectInfoModal />}
    </div>
  );
});

export default SavedSearchResults;
