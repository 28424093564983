import React, { FC, useCallback } from 'react';
import { clsx } from 'utils/clsx';
import classes from './PersonItem.module.scss';
import { Person, Detail } from '../../types';
import avatar from 'assets/icons/default-avatar.svg';
import { SourceLink } from 'utils/types';

//TODO: move to SearchProjects service and mutation persons array
const personRole: { [key: number]: string } = {
  10: 'Architect',
  20: 'Owner',
  30: 'Contractor'
};

interface Props {
  person: Person;
}

const PersonItem: FC<Props> = ({ person }) => {
  const getDetail = useCallback((detail: Detail) => {
    if (detail.type === SourceLink.LINKEDIN || detail.type === SourceLink.WEB) {
      return (
        <a
          href={detail.value}
          target={'_blank'}
          rel="noreferrer"
          className="text-decoration-none default-color-text">
          {detail.type === SourceLink.LINKEDIN
            ? detail.value.split('linkedin.com/')[1]
            : detail.value}
        </a>
      );
    }
    if (detail.type === SourceLink.PHONE) {
      return (
        <a href={`tel:${detail.value}`} className="text-decoration-none default-color-text">
          {detail.value}
        </a>
      );
    }
    if (detail.type === SourceLink.EMAIL) {
      return (
        <a href={`mailto:${detail.value}`} className="text-decoration-none default-color-text">
          {detail.value}
        </a>
      );
    }
    return <span>{detail.value}</span>;
  }, []);
  return (
    <div className={clsx(classes.personItem, 'w-50', 'mb-5', 'd-flex')}>
      <div className="me-3">
        <div
          className={clsx(
            classes.avatar,
            'rounded-circle',
            'd-flex',
            'align-items-center',
            'justify-content-center'
          )}>
          <img src={avatar} alt="avatar" />
        </div>
      </div>
      <div className={clsx(classes.personInfo)}>
        <span
          className={clsx(classes.personRole, 'mb-2', 'd-block', 'text-uppercase', 'font-kraftig')}>
          {personRole[person.role as number]}
        </span>
        <h6>{person.name}</h6>
        <ul className="p-0">
          {person.details.map((detail, idx: number) => (
            <li
              className={clsx(classes.detailItem, 'mb-2', 'overflow-hidden')}
              key={`detail-item-${idx}`}>
              <span className="me-2">
                <detail.icon />
              </span>
              {getDetail(detail)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PersonItem;
