import React, { FC, useCallback, MouseEvent } from 'react';
import classes from '../Auth.module.scss';
import { clsx } from 'utils/clsx';
import closeIcon from 'assets/icons/close.svg';

interface IProps {
  children: JSX.Element;
  show: boolean;
  onClose: () => void;
}

const AuthModal: FC<IProps> = ({ children, show, onClose }) => {
  const handleModalClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <div
      className={clsx(
        classes.modalWrapper,
        'position-fixed',
        'top-0',
        'bottom-0',
        'start-0',
        'end-0',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'overflow-hidden',
        'vh-100',
        show && classes.active
      )}
      onMouseDown={handleModalClick}>
      <div className={clsx(classes.modalBody, 'position-relative')}>
        <img
          src={closeIcon}
          alt="close"
          className={clsx('position-absolute', classes.closeIcon)}
          onClick={onClose}
          role="button"
        />
        {children}
      </div>
    </div>
  );
};

export default AuthModal;
