import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import classes from './AnimatedInput.module.scss';
import { clsx } from 'utils/clsx';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
interface IProps {
  type: string;
  value: string;
  placeholder?: string;
  onInput: (name: string, val: string) => void;
  isError?: boolean;
  isSuccess?: boolean;
  errorText?: string;
  name: string;
  dataTestElement: string;
  children: ReactNode;
}

const AnimatedInput: FC<IProps> = ({
  type,
  value,
  onInput,
  isError,
  errorText,
  name,
  dataTestElement,
  isSuccess,
  children
}) => {
  const [inputType, setInputType] = useState<string>(type);
  const inputRef = useRef({} as HTMLInputElement);

  const toggleType = useCallback((): void => {
    setInputType((prev: string) => (prev === 'password' ? 'text' : 'password'));
    handleInputFocus();
  }, []);

  const handleInputFocus = useCallback((): void => {
    inputRef.current.focus();
  }, []);

  const onInputChange = useCallback((event: React.SyntheticEvent): void => {
    onInput(name, (event.target as HTMLInputElement).value);
  }, []);

  return (
    <div>
      <div className="position-relative">
        <input
          ref={inputRef}
          type={inputType}
          className={clsx(
            classes.animatedInput,
            isError && classes.inputError,
            isSuccess && classes.inputSuccess,
            'form-control',
            'position-relative',
            'py-2'
          )}
          aria-describedby="passwordHelpBlock"
          value={value}
          onInput={onInputChange}
          role="button"
          name={name}
          data-test-element={dataTestElement}
        />
        <div
          role="button"
          className={clsx(classes.animatedLabel, 'position-absolute')}
          onClick={handleInputFocus}>
          {children}
        </div>

        {type === 'password' && (
          <div
            role="button"
            className={clsx(
              inputType === 'text' && classes.filledIcon,
              'position-absolute top-50 end-0 translate-middle'
            )}
            onClick={toggleType}>
            <EyeIcon />
          </div>
        )}

        {isError && (
          <span className={clsx(classes.errorText, 'position-absolute')}>{errorText}</span>
        )}
      </div>
    </div>
  );
};

export default AnimatedInput;
