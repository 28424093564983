import {
  IChangeEmailData,
  IMedia,
  IChangePasswordData,
  IUserService,
  IUserStore,
  USER_SERVICE,
  UserData,
  IChangeEmailResponse
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { IEditProfileData } from './Settings/types';

export class UserStore implements IUserStore {
  private _userService: IUserService = injector.get<IUserService>(USER_SERVICE);

  constructor() {
    makeAutoObservable<UserStore>(this);
  }

  user: UserData | null = null;
  isUserLoading = true;

  editUserPassword(data: IChangePasswordData): Promise<void> {
    return this._userService.editUserPassword(data);
  }

  async getCurrentUser(): Promise<void> {
    this.setIsUserLoading(true);
    try {
      this.setUser(await this._userService.getCurrentUser());
    } finally {
      this.setIsUserLoading(false);
    }
  }

  setUser(user: UserData | null) {
    this.user = user;
  }

  setUserAvatar(avatar: IMedia) {
    if (!this.user) return;
    this.user.avatar = avatar;
  }

  setIsUserLoading(isLoading: boolean): void {
    this.isUserLoading = isLoading;
  }

  async editUserInfo(data: IEditProfileData): Promise<void> {
    this.setUser(await this._userService.editUserInfo(data));
  }

  async editUserEmail(data: IChangeEmailData): Promise<void> {
    await this._userService.editUserEmail(data);
  }

  async confirmEmailToken(token: string): Promise<IChangeEmailResponse> {
    return await this._userService.confirmEmailToken(token);
  }

  async setAvatar(avatar: File): Promise<void> {
    this.setUserAvatar(await this._userService.setAvatar(avatar));
  }
}
