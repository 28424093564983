import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classes from './Nav.module.scss';
import { INavItems } from './types';
import { clsx } from 'utils/clsx';

interface Props {
  items: INavItems[];
  mode: 'light' | 'dark';
  navClass?: string;
}

const Nav: FC<Props> = ({ items, mode, navClass }) => {
  const showBadge = (badge: number | undefined): ReactNode => {
    if (!badge) return <></>;
    return (
      <span className="badge bg-secondary default-badge ms-2 px-1 d-flex align-items-center justify-content-center">
        {badge}
      </span>
    );
  };

  const listItem = (items: INavItems[]) => {
    return items.map((item: INavItems, index: number) => {
      return (
        <li
          className={clsx('nav-item', 'flex-fill', 'text-center', classes.navItem)}
          key={`tab-` + index}>
          <Link
            to={item.link}
            tabIndex={-1}
            className={clsx(
              'nav-link px-4',
              'd-flex',
              'align-items-center',
              item.active && classes.active,
              classes[mode + 'NavLink']
            )}
            aria-current="page">
            <span className="font-halbfett">{item.name}</span>
            {showBadge(item.badge)}
          </Link>
        </li>
      );
    });
  };
  return <ul className={clsx('nav', navClass)}>{items.length && listItem(items)}</ul>;
};
export default Nav;
