import React, { FC, useMemo, useState } from 'react';
import {
  Nav,
  Modal,
  Button,
  Select,
  Input,
  Dropdown,
  Table,
  ListItem,
  UnderConstructionModal
} from 'components';
import { INavItems } from 'components/Nav/types';
import eyeIcon from 'assets/icons/eye.svg';
import { IModalActions } from 'components/Modal/types';
import { IExtendedOption, IOption } from 'components/Select/types';
import { IDropdownOption } from 'components/Dropdown/types';
import { IAction, IColumn, IRow } from 'components/Table/types';
import { useStore } from 'storesProvider/storeContext';
import Alerts from 'view/Alerts';
import { ISavedSearch } from '../SavedSearches/types';
import { Skeleton } from 'components';
import AddToFavoriteModal from '../SavedSearches/components/SavedSearchResults/components/AddToFavoriteModal';
import { initialSearchParams } from 'view/SearchProjects/mock';
import ChoosePlanModal from '../SubscriptionsAndPlans/ChoosePlanModal';
import { useSearchParams } from 'react-router-dom';
import Header from 'view/Header';
import UpgradePlanModal from '../SubscriptionsAndPlans/UpgradePlanModal';
import CancelSubscriptionModal from 'view/SubscriptionsAndPlans/CancelSubscriptionModal';
import { PaymentType } from '../SubscriptionsAndPlans/types';

const tabsItems: INavItems[] = [
  {
    name: 'Profile settings',
    link: '/profile/settings',
    active: true
  },
  {
    name: 'Company settings',
    link: '/profile/company',
    active: false
  },
  {
    name: 'Subscription',
    link: '/profile/subscription',
    active: false
  },
  {
    name: 'Notifications',
    link: '/profile/notifications',
    active: false
  }
];

const modalActions: IModalActions = {
  negative: 'Cancel',
  positive: 'Update'
};

const options: IOption[] = [
  { value: 'english', text: 'English', id: 1 },
  { value: 'france', text: 'France', id: 2 },
  { value: 'german', text: 'German', id: 3 },
  { value: 'english', text: 'English', id: 4 },
  { value: 'france', text: 'France', id: 5 },
  { value: 'german', text: 'German', id: 6 },
  { value: 'english', text: 'English', id: 7 },
  { value: 'france', text: 'France', id: 8 },
  { value: 'german', text: 'German', id: 9 }
];

const extendedOptions: IExtendedOption[] = [
  {
    category: 'SYSTEM PROJECT LIST',
    options: [
      { value: 'projects', text: 'Projects', id: 1 },
      { value: 'companies', text: 'Companies', id: 2 }
    ],
    selected: null
  },
  {
    category: 'PERSONAL PROJECT LIST',
    options: [
      { value: 'projects 1', text: 'My Projects 1', id: 1 },
      { value: 'projects 2', text: 'My Projects 2', id: 2 }
    ],
    selected: null
  }
];

const columns: IColumn[] = [
  {
    field: 'details',
    headerName: 'Details',
    sort: true
  },
  {
    field: 'status',
    headerName: 'Status',
    sort: true
  },
  {
    field: 'location',
    headerName: 'Location',
    sort: true
  },
  {
    field: 'contacts',
    headerName: 'Contacts'
  },
  {
    field: 'value',
    headerName: 'Project Value'
  },
  {
    field: 'actions',
    headerName: 'Actions'
  }
];

const rows: IRow[] = [
  {
    id: 1,
    newItem: false,
    fields: [
      {
        field: 'details',
        value: 'Commercial - New RV Park',
        additionalValue: 'Septic, 11.1 acres subdivded to 9 lots',
        isTitle: false
      },
      {
        field: 'status',
        status: {
          id: 1,
          name: 'EXPIRED',
          color: '#454545'
        },
        additionalValue: '4/25/2022',
        isTitle: false
      },
      {
        field: 'location',
        value: 'Colvile WA',
        additionalValue: '398 Hwy 395 N',
        isTitle: false
      },
      {
        field: 'contacts',
        values: [
          {
            label: 'OWNER: Really Very Big Block Realty, INC',
            value: 'John Madigan',
            phone: '704-444-3135',
            iconItems: [
              {
                icon: 'mail',
                label: 'test@mail.com',
                type: 30
              },
              {
                icon: 'web',
                label: 'test.com',
                type: 30
              }
            ]
          },
          {
            label: 'ARCHITECT: Big Block Realty, INC',
            value: 'Martin Bratik',
            phone: '704-111-1111',
            iconItems: [
              {
                icon: 'mail',
                label: 'test@mail.com',
                type: 30
              }
            ]
          }
        ],
        isTitle: false
      },
      {
        field: 'value',
        value: '$7,114,500',
        isTitle: false
      }
    ]
  },
  {
    id: 2,
    newItem: false,
    fields: [
      {
        field: 'details',
        value: 'Residential - Subdivision',
        additionalValue: 'Septic, 11.1 acres subdivded to 9 lots',
        isTitle: false
      },
      {
        field: 'status',
        status: {
          id: 1,
          name: 'EXPIRED',
          color: '#454545'
        },
        additionalValue: '4/25/2022',
        isTitle: false
      },
      {
        field: 'location',
        value: 'Aolvile WA',
        additionalValue: '398 Hwy 395 N',
        isTitle: false
      },
      {
        field: 'contacts',
        values: [
          {
            label: 'OWNER: Big Block Realty, INC',
            value: 'John Madigan',
            phone: '704-444-3135',
            iconItems: [
              {
                icon: 'mail',
                label: 'test@mail.com',
                type: 30
              },
              {
                icon: 'web',
                label: 'test.com',
                type: 30
              }
            ]
          },
          {
            label: 'CONTRACTOR: Big Block Realty, INC',
            value: 'Bill Smith'
          },
          {
            label: 'ARCHITECT: Big Block Realty, INC',
            value: 'Martin Bratik22',
            phone: '704-444-3135',
            iconItems: [
              {
                icon: 'web',
                label: 'test.com',
                type: 30
              }
            ]
          }
        ],
        isTitle: false
      },
      {
        field: 'value',
        value: '$114,500',
        isTitle: false
      }
    ]
  }
];

const locationItem = {
  id: 4,
  title: 'Blacksburg'
};

export const Components: FC = () => {
  const { alertStore } = useStore();
  const [str, setStr] = useState<string>('');
  const [show, setShowModal] = useState<boolean>(false);
  const [showPlan, setShowPlanModal] = useState<boolean>(false);
  const [upgradePlan, setUpgradePlanModal] = useState<boolean>(false);
  const [showAddToFavorite, setShowAddToFavorite] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [selected, setSelected] = useState<IOption | null>(null);
  const [extendedSelected, setExtendedSelected] = useState<IExtendedOption>(extendedOptions[0]);
  const [showCancelSubscription, setShowCancelSubscription] = useState<boolean>(false);
  const [, setParams] = useSearchParams();

  const [dropdownOptions1, setDropdownOptions1] = useState<IDropdownOption[]>([
    { selected: true, content: 'Project 1', id: 1 },
    { selected: false, content: 'Project 2', id: 2 },
    { selected: false, content: 'Project 3', id: 3 }
  ]);
  const dropdownOptions2 = useMemo<IDropdownOption[]>(
    () => [
      {
        content: (
          <span>
            <img src={eyeIcon} className="me-2" alt="" />
            Profile
          </span>
        ),
        id: 1,
        target: 'profile'
      },
      {
        content: (
          <span>
            <img src={eyeIcon} className="me-2" alt="" />
            Saved Searches
          </span>
        ),
        id: 2,
        target: 'searches'
      },
      {
        content: (
          <span>
            <img src={eyeIcon} className="me-2" alt="" />
            Exit
          </span>
        ),
        id: 3,
        target: 'exit'
      }
    ],
    []
  );
  const handleDropdownChange1 = (value: IDropdownOption): void => {
    setDropdownOptions1((options) =>
      options.map((option) => ({ ...option, selected: option.id === value.id }))
    );
  };
  const handleDropdownChange2 = (value: IDropdownOption): void => {
    console.log('you have chosen ' + value?.target);
  };

  const onStrChange = (name: string, str: string): void => {
    setStr(str);
  };

  const onSelectChange = (selected: IOption): void => {
    setSelected(selected);
  };

  const onExtendedSelectChange = (selected: IExtendedOption): void => {
    setExtendedSelected(selected);
  };

  const closeUpgradeModal = (): void => {
    setUpgradePlanModal(false);
  };

  const showUpgradeModal = (): void => {
    setUpgradePlanModal(true);
  };

  const showModal = (): void => {
    setShowModal(true);
  };

  const showPlanModal = (): void => {
    setShowPlanModal(true);
  };

  const closePlanModal = (): void => {
    setShowPlanModal(false);
  };

  const closeModal = (): void => {
    setShowModal(false);
  };

  const showAddFavoriteModal = (): void => {
    setShowAddToFavorite(true);
  };

  const closeAddFavoriteModal = (): void => {
    setShowAddToFavorite(false);
  };

  const actionClick = (id: number): void => {
    showUnderConstructionModal();
    console.log('Click row: ', id);
  };
  const [showUnderConstruction, setShowUnderConstruction] = useState<boolean>(false);

  const showUnderConstructionModal = (): void => {
    setShowUnderConstruction(true);
  };

  const closeUnderConstructionModal = (): void => {
    setShowUnderConstruction(false);
  };

  const tableAction: IAction[] = [
    {
      icon: 'favorite',
      callback: (id) => {
        showAddFavoriteModal();
        setProjectId(id);
      }
    },
    {
      icon: 'document',
      callback: (id) => actionClick(id)
    },
    {
      icon: 'share',
      callback: (id) => actionClick(id)
    }
  ];

  const list: ISavedSearch[] = [
    {
      id: 1,
      name: 'Saved Search 1',
      viewedAt: 'Unincorporated,Septic,Well,-Sewer',
      filters: initialSearchParams,
      newProjects: 4,
      description: 'description'
    }
  ];

  const deleteClick = (id: number): void => {
    console.log('id list deleteClick: ', id);
  };

  const editClick = (id: number): void => {
    console.log('id list editClick: ', id);
  };

  const successAlert = (): void => {
    alertStore.successAlert('Success alert');
  };

  const errorAlert = (): void => {
    alertStore.errorAlert('Error alert');
  };

  return (
    <div>
      <Alerts />
      <h2>Components lib</h2>

      <Button type="primary" className="px-4" onClick={() => setParams('auth=sign-in')}>
        Login
      </Button>

      <h4>Navs</h4>
      <Nav items={tabsItems} mode="light" navClass="default-shadow" />

      <h4>Inputs</h4>
      <div className="px-5 d-flex align-items-end mb-4">
        <Input name="" type="text" label="Full Name" value={str} onInput={onStrChange} /> &nbsp;
        <Input name="" type="password" label="Password" value={str} onInput={onStrChange} /> &nbsp;
        <Input
          name=""
          type="text"
          label="Email"
          value={str}
          onInput={onStrChange}
          isError={str.length <= 6}
          errorText={'Should be at least 6 symbols'}
        />{' '}
        &nbsp;
        <Input name="" type="text" value={str} onInput={onStrChange}>
          <div className="d-flex w-100 justify-content-center">
            <img src={eyeIcon} alt="" />
            &nbsp;some content with icon
          </div>
        </Input>
      </div>
      <h4>Buttons</h4>
      <div className="px-5 d-flex align-items-center">
        <Button type="primary" className="px-4" onClick={() => console.log('click')}>
          Text
        </Button>
        <Button type="secondary" className="px-4" onClick={() => console.log('click')}>
          Sign up with Google
        </Button>
        <Button type="light" color="red" className="px-4" onClick={() => console.log('click')}>
          Delete
        </Button>
        <Button
          type="primary"
          className="px-4"
          isLoading={true}
          onClick={() => console.log('click')}>
          Text
        </Button>
        <Button type="secondary" background="#DBDFEA" color="#2F3139" className="px-4 mx-2">
          Login
        </Button>
        <Button type="secondary" background="#0087FF" color="#0087FF" className="px-4 mx-2">
          Get started
        </Button>
      </div>

      <h4>Modals</h4>
      <div className="d-flex">
        <Button type="primary" className="px-4 me-2" onClick={showModal}>
          Open modal
        </Button>
        <Button type="primary" className="px-4 me-2" onClick={showPlanModal}>
          Open plan modal
        </Button>
        <Button type="primary" className="px-4 me-2" onClick={showUpgradeModal}>
          Open upgrade modal
        </Button>
        <Button
          type="primary"
          className="px-4 me-2"
          onClick={() => setShowCancelSubscription(true)}>
          Open close subscription
        </Button>
        <Button
          type="primary"
          className="px-4"
          onClick={() => setParams('auth=sign-up&extended=true')}>
          Open extended register modal
        </Button>
      </div>
      <Modal
        show={show}
        title="Modal title"
        subTitle="Modal subtitle"
        deleteBtn={true}
        actions={modalActions}
        closeModal={closeModal}
        onSubmit={() => console.log('submit')}>
        <Input name="" type="text" label="Full Name" value={str} onInput={onStrChange} />
      </Modal>
      <ChoosePlanModal show={showPlan} location={locationItem} closeModal={closePlanModal} />
      <CancelSubscriptionModal
        show={showCancelSubscription}
        closeModal={() => setShowCancelSubscription(false)}
        onCancelSubscription={() => setShowCancelSubscription(false)}
        userName="Zach"
        subscriptionId={null}
        onUpgrade={() => setShowCancelSubscription(false)}
      />
      <UpgradePlanModal
        show={upgradePlan}
        location={locationItem}
        closeModal={closeUpgradeModal}
        subscriptionId={1}
        type={PaymentType.UPGRADE}
      />

      <h4>Header</h4>
      <Header />

      <h4>Selects</h4>
      <div className="d-flex">
        <div className="ms-2 w-25">
          <Select
            name=""
            options={options}
            label="Language"
            value={selected}
            onChange={onSelectChange}
          />
        </div>

        <div className="ms-2 w-50">
          <Select<IExtendedOption>
            name=""
            options={extendedOptions}
            label="Select List"
            extended={true}
            value={extendedSelected}
            onChange={onExtendedSelectChange}
          />
        </div>
      </div>

      <h4>Dropdowns</h4>
      <div className="d-flex">
        <div className="ms-2 w-25">
          <Dropdown
            title="Projects"
            options={dropdownOptions1}
            onSelect={handleDropdownChange1}
            float="start"
            activeClass="active"
          />
        </div>
        <div className="ms-2 w-25">
          <Dropdown
            activeClass="active"
            changeIconColor
            title={<img src={eyeIcon} alt="" />}
            options={dropdownOptions2}
            onSelect={handleDropdownChange2}
            float="end"
          />
        </div>
        <div className="ms-2 w-50">
          <Dropdown<IExtendedOption>
            extended
            activeClass="active"
            title="Projects"
            options={extendedOptions}
            onSelect={onExtendedSelectChange}
            float="end"
            value={extendedSelected}
          />
        </div>
      </div>

      <h4>Table</h4>
      <div className="m-2">
        <Table columns={columns} rows={rows} actions={tableAction} />
      </div>

      <h4>List item</h4>
      {list.map((item: ISavedSearch, idx: number) => (
        <ListItem
          data={item}
          deleteClick={deleteClick}
          editClick={editClick}
          key={`item-list-${item.name}`}
          dataTestId={idx}
        />
      ))}

      <UnderConstructionModal
        title="Table action"
        subTitle=""
        closeModal={closeUnderConstructionModal}
        show={showUnderConstruction}
      />

      <h4>Alerts</h4>
      <div className="d-flex">
        <Button type="primary" className="px-4" onClick={successAlert}>
          Success alert
        </Button>
        <Button type="primary" className="px-4" onClick={errorAlert}>
          Error alert
        </Button>
      </div>
      <div className="p-4" style={{ width: '303px' }}>
        <Skeleton type="project" itemsToShow={1} />
      </div>
      <AddToFavoriteModal
        show={showAddToFavorite}
        closeModal={closeAddFavoriteModal}
        id={projectId}
      />
    </div>
  );
};
