import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import classes from './Favorites.module.scss';
import { clsx } from 'utils/clsx';
import { Button, ConfirmModal, Input, Modal, Table, UnderConstructionModal } from 'components';
import { IAction, IColumn, Order } from 'components/Table/types';
import { SubHeader } from './components/SubHeader';
import { IOption } from 'components/Select/types';
import {
  IFavoriteDataBody,
  FavoritesOptions,
  IFavoriteFilters,
  ModalType,
  FavoriteFormValue,
  IFavoriteState
} from './types';
import TableHeader from './components/TableHeader';
import { IDropdownOption } from 'components/Dropdown/types';
import { isNaN } from 'formik';
import { IModalActions } from 'components/Modal/types';
import {
  TYPE,
  DATE,
  LOCATION,
  ALL,
  COMPANY,
  DEBOUNCE_DELAY,
  PERSON,
  PROJECT,
  PROJECT_VALUE,
  PROJECT_COUNT,
  TOTAL_AMOUNT
} from 'utils/constants';
import { IFavoriteTabsItems } from 'components/Tab/types';
import { orderProcessing } from 'helper/orderProcessing';
import { initialFavoriteFilters } from './mock';
import { useDebounce } from 'hooks/useDebounce';
import { FavoriteRoles, FavoriteTypes, IFavoriteEntity } from 'view/AddToFavorites/types';
import AddToFavorites from 'view/AddToFavorites';
import { usePrevious } from 'hooks/usePrevious';
import { useIntersectionObserverEffect } from 'hooks/useIntersectionObserverEffect';
import AddNoteModal from '../AddNoteModal';
import { INoteEntity, INoteState } from 'view/AddNoteModal/types';
import { IDropCategories, IDropdownEvent } from '../SearchProjects/components/SubHeader/types';
import ProjectInfoModal from '../ProjectInfoModal';
import { useProgress } from 'hooks/useProgress';
import ProgressBar from 'components/ProgressBar';

const Favorites: FC = observer(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchId = Number(id);
  const { pathname } = useLocation();
  const [params, setParams] = useSearchParams();
  const details = params.get('details');
  const observableDiv = useRef<HTMLDivElement | null>(null);
  const { favoritesStore, userStore, alertStore } = useStore();
  const [showUnderConstruction, setShowUnderConstruction] = useState<boolean>(false);
  const [locationOptions, setLocationOptions] = useState<IOption[]>([]);
  const [favoriteFilters, setFavoriteFilters] = useState<IFavoriteFilters>(initialFavoriteFilters);
  const [activeFilters, setActiveFilters] = useState<(keyof IFavoriteFilters)[]>([
    TYPE,
    DATE,
    PROJECT_VALUE,
    LOCATION
  ]);
  const [currentFavorite, setCurrentFavorite] = useState<IDropdownOption | null>(null);
  const [show, setShowModal] = useState<boolean>(false);
  const [confirmModalShow, setConfirmModalShow] = useState<boolean>(false);
  const [favoriteListName, setFavoriteListName] = useState<string>('');
  const [editableFavorite, setEditableFavorite] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalActions, setModalActions] = useState<IModalActions>({
    negative: t('favorites.cancel'),
    positive: t('favorites.save')
  });
  const [modalDeleteBtn, setModalDeleteBtn] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.CREATE);
  const [activeTabId, setActiveTabId] = useState<number>(1);
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMoreFavoritesLoading, setIsMoreFavoritesLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [noteState, setNoteState] = useState<INoteState>({
    entity: {
      coordinates: null,
      name: null,
      description: null,
      id: null,
      type: null
    },
    showAddNote: false
  });
  const [favoriteState, setFavoriteState] = useState<IFavoriteState>({
    entity: null,
    showAddToFavorite: false,
    favoriteId: null
  });
  const [categories, setCategories] = useState<IDropCategories>({
    classes: [],
    types: [],
    subTypes: []
  });

  const modalRef = useRef<HTMLDivElement | null>(null);

  const debouncedCategories = useDebounce(categories, DEBOUNCE_DELAY * 3);
  const debouncedSearchTerm = useDebounce<string>(searchValue, DEBOUNCE_DELAY);
  const prevSearchValue: string = usePrevious<string>(debouncedSearchTerm);
  const { progress, visible, reload: reloadProgress } = useProgress(isLoading);

  const tabItems = useMemo((): IFavoriteTabsItems[] => {
    return [
      {
        name: 'Projects',
        active: activeTabId === 1,
        tabId: 1,
        systemType: PROJECT,
        type: FavoriteTypes.Project,
        attr: 'favorite-tab-projects'
      },
      {
        name: 'Companies',
        active: activeTabId === 2,
        tabId: 2,
        systemType: COMPANY,
        type: FavoriteTypes.Company,
        attr: 'favorite-tab-companies'
      },
      {
        name: 'Persons',
        active: activeTabId === 3,
        tabId: 3,
        systemType: PERSON,
        type: FavoriteTypes.Person,
        attr: 'favorite-tab-persons'
      }
    ];
  }, [activeTabId]);

  useLayoutEffect(() => {
    (async () => {
      if (!userStore.user) return;
      await favoritesStore.getFavoritesList(userStore.user.id);
    })();
  }, []);

  useEffect(() => {
    if (isNaN(Number(id)) && id !== ALL) {
      navigate('/favorites/all', { replace: true });
    }
    return () => {
      favoritesStore.resetCategories();
    };
  }, []);

  useEffect(() => {
    if (id !== ALL) {
      setCurrentFavorite(favoriteOptions.find((option) => option.id === Number(id)) || null);
    }
  }, [favoritesStore.list]);

  useEffect(() => {
    (async () => await getTableData())();
  }, [searchId, activeTabId, orders, favoriteFilters]);

  useEffect(() => {
    (async () => {
      if (
        (debouncedSearchTerm.length >= 3 && debouncedSearchTerm) ||
        (prevSearchValue && prevSearchValue.length === 3 && debouncedSearchTerm.length < 3) ||
        (debouncedSearchTerm.length < 3 && prevSearchValue.length >= 3)
      ) {
        await getTableData();
      }
    })();
  }, [debouncedSearchTerm]);

  const favoriteBody = useMemo<IFavoriteDataBody>(
    () => ({
      ...favoriteFilters,
      lists: searchId ? [searchId] : [],
      order: orders,
      ...(searchValue.length >= 3 && { search: searchValue }),
      page
    }),
    [favoriteFilters, searchId, orders, searchValue, page]
  );

  const handleOrder = useCallback(
    (field: string): void => {
      setPage(1);
      setOrders(orderProcessing(field, orders));
    },
    [orders]
  );

  useEffect(() => {
    favoritesStore.resetCategories();
    setFavoriteFilters(initialFavoriteFilters);
    if (tabItems.length) {
      (async () => {
        if (!userStore.user) return;
        await favoritesStore.getFavoritesProjectsTotalValue(
          userStore.user.id,
          getCurrentTab.systemType,
          favoriteBody.lists
        );
      })();
    }
  }, [searchId, activeTabId]);

  const getCurrentTab = useMemo<IFavoriteTabsItems>(() => {
    return tabItems.find((item) => item.tabId === activeTabId) || tabItems[0];
  }, [activeTabId]);

  const getTableData = async (): Promise<void> => {
    setIsLoading(true);
    reloadProgress();
    setEditableFavorite(!!searchId);
    const systemType = getCurrentTab.systemType;
    if (!userStore.user) return;
    await favoritesStore.getFavoritesData(userStore.user.id, systemType, {
      ...favoriteBody,
      page: 1
    });
    setIsLoading(false);
  };

  const getMoreTableData = useCallback(async (): Promise<void> => {
    try {
      setIsMoreFavoritesLoading(true);
      setEditableFavorite(!!searchId);
      const systemType = getCurrentTab.systemType;
      if (!userStore.user) return;
      await favoritesStore.getFavoritesData(userStore.user.id, systemType, favoriteBody);
    } finally {
      setIsMoreFavoritesLoading(false);
    }
  }, [favoriteBody, page, searchId, getCurrentTab.systemType]);

  // TODO: move this code to store
  const favoriteOptions = useMemo<FavoritesOptions[]>(
    () =>
      favoritesStore.list.map((search) => ({
        content: search.name,
        selected: searchId === search.id,
        id: search.id
      })),
    [searchId, favoritesStore.list]
  );
  const allFavoritesOption = useMemo<FavoritesOptions>(
    () => ({
      content: t('favorites.allFavorites'),
      selected: isNaN(searchId),
      id: 0
    }),
    [searchId, favoritesStore.list]
  );
  const allFavoritesOptions = [allFavoritesOption, ...favoriteOptions];

  const showUnderConstructionModal = (): void => {
    setShowUnderConstruction(true);
  };

  const closeUnderConstructionModal = (): void => {
    setShowUnderConstruction(false);
  };

  const actionClick = (id: number): void => {
    showUnderConstructionModal();
    console.log('Click row: ', id);
  };

  const showAddNoteModal = useCallback((id: number) => {
    const thisModal = document.getElementById(`note-${id}`);
    setNoteState((prev) => ({ ...prev, showAddNote: !thisModal }));
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: false }));
  }, []);

  const closeNoteModal = useCallback(
    () => setNoteState((prev) => ({ ...prev, showAddNote: false })),
    []
  );

  const tableAction: IAction[] = [
    {
      icon: 'documentGrey',
      callback: (id) => {
        const coordinates: DOMRect | null =
          document.getElementById(`table-action-documentGrey-${id}`)?.getBoundingClientRect() ||
          null;
        const details = favoritesStore.dataTable
          .find((row) => row.id === id)
          ?.fields.find((item) => item.field === 'property');
        const personDetails = favoritesStore.dataTable
          .find((row) => row.id === id)
          ?.fields.find((item) => item.field === 'name');
        const entity: INoteEntity = {
          coordinates,
          name: (details?.value as string) || (personDetails?.value as string) || null,
          description: details?.additionalValue || null,
          id,
          type: getCurrentTab.systemType
        };
        setNoteState((prev) => ({ ...prev, entity }));
        showAddNoteModal(id);
      }
    },
    {
      icon: 'shareGrey',
      callback: (id) => actionClick(id)
    },
    {
      icon: 'favoriteGrey',
      callback: (id) => {
        const currentRow = favoritesStore.getFavoriteById(id);
        const coordinates: DOMRect | null =
          document.getElementById(`table-action-favoriteGrey-${id}`)?.getBoundingClientRect() ||
          null;
        if (!coordinates) return;
        const entity: IFavoriteEntity = {
          id,
          coordinates,
          type: getCurrentTab.type,
          key: 'table',
          role: currentRow?.role || FavoriteRoles.Project
        };
        setFavoriteState((prev) => ({ ...prev, entity, favoriteId: id }));
        showAddFavoriteModal(entity);
      }
    }
  ];

  const showAddFavoriteModal = (entity: IFavoriteEntity): void => {
    const thisModal = document.getElementById(
      `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
    );
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: !thisModal }));
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
  };

  const columnsProject = useMemo((): IColumn[] => {
    return [
      {
        field: 'property',
        headerName: t('favorites.table.details'),
        sort: true
      },
      {
        field: 'date',
        headerName: t('favorites.table.date'),
        sort: false
      },
      {
        field: 'status',
        headerName: t('favorites.table.status'),
        sort: true
      },
      {
        field: 'location',
        headerName: t('favorites.table.location')
      },
      {
        field: 'contacts',
        headerName: t('favorites.table.contacts')
      },
      {
        field: 'projectValue',
        headerName: t('favorites.table.value'),
        sort: true
      },
      {
        field: 'actions',
        headerName: t('favorites.table.actions')
      }
    ];
  }, []);

  const columnsCompany = useMemo((): IColumn[] => {
    return [
      {
        field: 'name',
        headerName: t('favorites.table.details'),
        sort: true
      },
      {
        field: 'location',
        headerName: t('favorites.table.location')
      },
      {
        field: 'contacts',
        headerName: t('favorites.table.contacts')
      },
      {
        field: 'projectCount',
        headerName: t('favorites.table.projectCount'),
        sort: true
      },
      {
        field: 'totalAmount',
        headerName: t('favorites.table.totalAmount'),
        sort: true
      },
      {
        field: 'actions',
        headerName: t('favorites.table.actions')
      }
    ];
  }, []);

  const columnsPerson = useMemo((): IColumn[] => {
    return [
      {
        field: 'name',
        headerName: t('favorites.table.details'),
        sort: true
      },
      {
        field: 'projectCount',
        headerName: t('favorites.table.projectCount'),
        sort: true
      },
      {
        field: 'totalAmount',
        headerName: t('favorites.table.totalAmount'),
        sort: true
      },
      {
        field: 'actions',
        headerName: t('favorites.table.actions')
      }
    ];
  }, []);

  const getColumn = useMemo<IColumn[]>(() => {
    const column = {
      project: columnsProject,
      company: columnsCompany,
      person: columnsPerson
    };
    return column[getCurrentTab.systemType];
  }, [activeTabId]);

  const handleProjectDropdownChange = useCallback((selected: IDropdownOption) => {
    setCurrentFavorite(selected);
    setOrders([]);
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
    const path = !selected.id ? ALL : String(selected.id);
    setPage(1);
    navigate('/favorites/' + path, { replace: true });
  }, []);

  const getSelectedFavoriteOption = useCallback((): string => {
    const selectedFavorite = allFavoritesOptions.find((option) => option.selected);
    return selectedFavorite ? selectedFavorite.content : allFavoritesOptions[0].content;
  }, [searchId, favoritesStore.list]);

  const submitModal = async (): Promise<void> => {
    if (favoriteListName.length < 3) {
      alertStore.errorAlert(t('addToFavorites.errorCreateList'));
      return;
    }
    if (modalType === ModalType.CREATE) {
      await createFavoriteList();
      return;
    }
    if (modalType === ModalType.EDIT) {
      await editFavoriteList();
      return;
    }
  };

  const createFavoriteList = async (): Promise<void> => {
    if (!userStore.user) return;
    await favoritesStore.createFavoritesList(userStore.user.id, favoriteListName);
    setFavoriteListName('');
    setShowModal(false);
    if (favoritesStore.list.length === 1) {
      navigate('/favorites/' + favoritesStore.list[favoritesStore.list.length - 1].id, {
        replace: true
      });
    }
    alertStore.successAlert(t('addToFavorites.successCreateList'));
  };

  const editFavoriteList = async (): Promise<void> => {
    if (!userStore.user || !currentFavorite?.id) return;
    await favoritesStore.editFavoriteList(
      // TODO: move to separate function
      userStore.user.id,
      currentFavorite.id,
      favoriteListName
    );
    setFavoriteListName('');
    setShowModal(false);
    alertStore.successAlert(t('favorites.editSuccess'));
  };

  const onStrChange = (name: string, str: string): void => {
    setFavoriteListName(str);
  };

  const openModal = (): void => {
    setShowModal(true);
    const target: HTMLButtonElement | null = modalRef?.current?.querySelector('button') || null;
    setTimeout(() => target?.focus(), 0);
  };

  const openCreateModal = (): void => {
    setModalActions({
      negative: t('favorites.cancel'),
      positive: t('favorites.save')
    });
    setModalTitle(t('favorites.createFavoriteList'));
    setModalDeleteBtn(false);
    setModalType(ModalType.CREATE);
    openModal();
  };

  const closeModal = (): void => {
    setFavoriteListName('');
    setShowModal(false);
  };

  const handleShare = (): void => {
    setShowUnderConstruction(true);
  };

  const handleEditList = (): void => {
    setFavoriteListName(currentFavorite?.content as string);
    setModalActions({
      negative: t('favorites.cancel'),
      positive: t('favorites.saveList')
    });
    setModalTitle(t('favorites.editList'));
    setModalDeleteBtn(true);
    setModalType(ModalType.EDIT);
    openModal();
  };

  const handleDeleteList = (): void => {
    closeModal();
    setModalActions({
      negative: t('favorites.cancel'),
      positive: t('favorites.delete')
    });
    setConfirmModalShow(true);
  };

  const deleteFavorite = useCallback(async (): Promise<void> => {
    try {
      if (!userStore.user || !currentFavorite?.id) return;
      await favoritesStore.deleteFavoriteList(userStore.user.id, currentFavorite.id);
      alertStore.successAlert(t('favorites.deleteSuccess'));
      favoritesStore.resetCategories();
      setFavoriteFilters(initialFavoriteFilters);
      navigate('/favorites/all', { replace: true });
    } finally {
      setConfirmModalShow(false);
    }
  }, [currentFavorite]);

  const handleTabAction = useCallback((tabId: number): void => {
    setNoteState((prev) => ({ ...prev, showAddNote: false }));
    setActiveTabId(tabId);
    setSearchValue('');
    setOrders([]);
    handleChangeFilters(tabId);
  }, []);

  const handleChangeFilters = useCallback((tabId: number) => {
    setOrders([]);
    setPage(1);
    if (tabId !== 1) {
      favoritesStore.resetCategories();
      setCategories({
        classes: [],
        types: [],
        subTypes: []
      });
    }
    setActiveFilters(() => {
      switch (tabId) {
        case 1:
          return [TYPE, DATE, PROJECT_VALUE, LOCATION];
        case 2:
          return [PROJECT_COUNT, TOTAL_AMOUNT, LOCATION];
        case 3:
          return [TOTAL_AMOUNT, PROJECT_COUNT];
        default:
          return [];
      }
    });
    setFavoriteFilters(initialFavoriteFilters);
  }, []);

  const handleFiltersSave = useCallback(
    (name: keyof IFavoriteFilters, value: FavoriteFormValue): void => {
      setFavoriteFilters((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleFiltersReset = useCallback((name: keyof IFavoriteFilters): void => {
    setFavoriteFilters((prev) => ({ ...prev, [name]: null }));
  }, []);

  const handleCategoryChange = useCallback(
    ({ activeClasses, activeTypes, activeSubtypes }: IDropdownEvent) => {
      setCategories((prev) => ({
        classes: activeClasses || prev.classes,
        types: activeTypes || prev.types,
        subTypes: activeSubtypes || prev.subTypes
      }));
    },
    []
  );

  useEffect(() => {
    favoritesStore.onCategoryChange(categories.classes, categories.types, categories.subTypes);
    setFavoriteFilters((prev) => ({
      ...prev,
      classes: categories.classes,
      types: categories.types,
      subTypes: categories.subTypes
    }));
  }, [debouncedCategories]);

  useEffect(() => {
    if (getCurrentTab.systemType === PERSON) return;
    setFavoriteFilters(initialFavoriteFilters);
    (async () => {
      if (!userStore.user) return;
      setLocationOptions(
        await favoritesStore.getFavoriteLocations(
          userStore.user.id,
          getCurrentTab.systemType,
          searchId,
          favoriteFilters
        )
      );
    })();
  }, [getCurrentTab.systemType, id]);

  useEffect(() => {
    if (page === 1 || isMoreFavoritesLoading) return;
    (async () => {
      await getMoreTableData();
    })();
  }, [page]);

  useIntersectionObserverEffect(
    observableDiv,
    (entry: IntersectionObserverEntry) => {
      setPage((prev) => {
        if (entry.isIntersecting && prev < favoritesStore.totalPages && !isMoreFavoritesLoading) {
          return prev + 1;
        }
        return prev;
      });
    },
    [observableDiv.current, favoritesStore.dataTable, favoritesStore.totalPages]
  );

  const handleSearch = (name: string, value: string): void => {
    setSearchValue(value);
  };

  const closeAddFavoriteModal = (): void => {
    setFavoriteState((prev) => ({ ...prev, showAddToFavorite: false }));
  };

  const handleTableScroll = useCallback((): void => {
    closeAddFavoriteModal();
    closeNoteModal();
  }, []);

  const handleOpenProjectInfo = useCallback(
    (idProject: number) => {
      if (getCurrentTab.systemType !== 'project') return;
      closeNoteModal();
      setParams(`details=${idProject}`);
    },
    [pathname]
  );

  const favoriteCallback = useCallback(
    (isActive: boolean, entityId: number, listId: number) => {
      setPage(1);
      if ((id === ALL && !isActive) || listId === Number(id)) {
        (async () => await getTableData())();
        closeAddFavoriteModal();
        return;
      }
      return;
    },
    [favoriteState.favoriteId, activeTabId]
  );

  const handleAnalyticsAction = (): void => {
    navigate('/favorites/analytics', { state: searchId ? [searchId] : [] });
  };

  const handleUpdateNote = (id: number, hasNote: boolean) => {
    favoritesStore.updateNoteStatus(id, hasNote);
  };

  return (
    <div>
      <SubHeader
        dropdownSelect={getSelectedFavoriteOption()}
        dropdownOptions={allFavoritesOptions}
        onDropdownChange={handleProjectDropdownChange}
        onShare={handleShare}
        onCreateList={openCreateModal}
        onEditList={handleEditList}
        onDeleteList={handleDeleteList}
        editableFavorite={editableFavorite}
      />
      <TableHeader
        tabItems={tabItems}
        favoriteFilters={favoriteFilters}
        activeFilters={activeFilters}
        onDropdownSave={handleFiltersSave}
        onDropdownReset={handleFiltersReset}
        locations={locationOptions}
        totalProjectValue={favoritesStore.totalValue}
        onButtonClick={showUnderConstructionModal}
        onTabClick={handleTabAction}
        onSearchInput={handleSearch}
        onCategoryChange={handleCategoryChange}
        searchValue={searchValue}
        analyticsAction={handleAnalyticsAction}
      />
      {!visible ? (
        <div className="my-4 mx-4">
          <ProgressBar progress={progress} thin />
        </div>
      ) : (
        <div className={clsx(classes.tableWrapper, 'my-4', 'mx-4')}>
          {!!favoritesStore.dataTable.length && (
            <Table
              columns={getColumn}
              rows={favoritesStore.dataTable}
              actions={tableAction}
              orders={orders}
              orderAction={handleOrder}
              onScroll={handleTableScroll}
              onTitleClick={handleOpenProjectInfo}
              tableClass={classes.table}>
              <div>
                <div ref={observableDiv}></div>
                <div
                  className={clsx(
                    'loader',
                    'd-flex',
                    'justify-content-center',
                    'py-3',
                    !isMoreFavoritesLoading && 'loader-hidden'
                  )}>
                  <div />
                </div>
              </div>
            </Table>
          )}
          {!!favoritesStore.list.length && !favoritesStore.dataTable.length && (
            <div className="p-4">
              <h4 className="text-center default-color-text">
                {t('favorites.notFavoritesInList')}
              </h4>
            </div>
          )}
          {!favoritesStore.list.length && (
            <div className="p-4">
              <h4 className="text-center default-color-text">{t('favorites.listStillEmpty')}</h4>
              <Button
                type="primary"
                className="px-4 m-auto mt-3 font-kraftig"
                onClick={openCreateModal}>
                {t('favorites.createList')}
              </Button>
            </div>
          )}
        </div>
      )}
      {favoriteState.showAddToFavorite && favoriteState.entity && (
        <AddToFavorites
          entity={favoriteState.entity}
          closeModal={closeAddFavoriteModal}
          toggleCallback={favoriteCallback}
        />
      )}

      {noteState.showAddNote && noteState.entity && (
        <AddNoteModal
          entity={noteState.entity}
          closeModal={closeNoteModal}
          onNoteChange={handleUpdateNote}
        />
      )}
      <div ref={modalRef}>
        <Modal
          show={show}
          title={modalTitle}
          actions={modalActions}
          closeModal={closeModal}
          onDelete={handleDeleteList}
          onSubmit={submitModal}
          deleteBtn={modalDeleteBtn}>
          <Input
            name=""
            type="text"
            label={t('favorites.listName')}
            value={favoriteListName}
            onInput={onStrChange}
          />
        </Modal>
      </div>
      <ConfirmModal
        title={t('favorites.deleteList')}
        subTitle={t('favorites.deleteListInfo')}
        actions={modalActions}
        show={confirmModalShow}
        closeModal={() => setConfirmModalShow(false)}
        onSubmit={deleteFavorite}
        modalClass={classes.confirmModal}
        bodyClass={classes.modalBody}
      />
      <UnderConstructionModal
        title="Action"
        subTitle=""
        closeModal={closeUnderConstructionModal}
        show={showUnderConstruction}
      />
      {details && <ProjectInfoModal />}
    </div>
  );
});

export default Favorites;
