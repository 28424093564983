import {
  ISavedSearchResultsStore,
  SAVED_SEARCH_RESULTS_SERVICE,
  ISavedSearchResultsService,
  ISavedSearchResponse
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
import { ICell, IRow, Order } from 'components/Table/types';

export class SavedSearchResultsStore implements ISavedSearchResultsStore {
  private _dashboardService: ISavedSearchResultsService = injector.get<ISavedSearchResultsService>(
    SAVED_SEARCH_RESULTS_SERVICE
  );

  constructor() {
    makeAutoObservable<SavedSearchResultsStore>(this);
  }

  tableRows: IRow[] = [];
  totalPages = 1;

  setTableRows(rows: IRow[]): void {
    this.tableRows = rows;
  }

  setTotalPages(pages: number): void {
    this.totalPages = pages;
  }

  async getProjects(
    userId: number,
    ids: number[],
    page: number,
    orders: Order[]
  ): Promise<ISavedSearchResponse<IRow[]>> {
    return await this._dashboardService.getDashboardData(userId, ids, page, orders);
  }

  setNoteStatus(id: number, hasNote: boolean): void {
    this.tableRows = this.tableRows.map((data) => ({
      ...data,
      hasNote: data.id === id ? hasNote : data.hasNote
    }));
  }

  async getDashboardData(
    userId: number,
    ids: number[],
    page: number,
    orders: Order[]
  ): Promise<void> {
    const res = await this.getProjects(userId, ids, page, orders);
    this.setTableRows([...res.data]);
    this.setTotalPages(res.totalPages);
  }

  async getMoreData(userId: number, ids: number[], page: number, orders: Order[]): Promise<void> {
    const res = await this.getProjects(userId, ids, page, orders);
    this.setTableRows([...this.tableRows, ...res.data]);
    this.setTotalPages(res.totalPages);
  }

  updateFavoriteRow(id: number, isActive: boolean): void {
    this.setTableRows(
      this.tableRows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            favorite: row.favorite !== isActive ? !row.favorite : row.favorite
          };
        }
        return row;
      })
    );
  }

  getCellByFieldNameInRow(id: number, field: string): ICell | null {
    const row = this.tableRows.find((row) => row.id === id);
    if (!row) return null;
    const cell = row.fields.find((cell) => cell.field === field);
    return cell ? cell : null;
  }
}
