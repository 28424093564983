import React, { FC, useEffect, useRef, MutableRefObject, useState } from 'react';
import 'leaflet.markercluster';
import { observer } from 'mobx-react';
// TODO: remove after testing
import classes from './Map.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { useDebounce } from 'hooks/useDebounce';
import { DEBOUNCE_ZERO_DELAY, VIEWPORT_DEBOUNCE_DELAY } from 'utils/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const MapProjects: FC = observer(() => {
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();
  const { mapStore, projectSearchStore, searchStore, filtersStore, userStore } = useStore();
  const [delay, setDelay] = useState<number>(VIEWPORT_DEBOUNCE_DELAY);
  // TODO: remove after testing
  // const selectedMarker = useRef() as MutableRefObject<HTMLDivElement>;

  const debouncedViewPort = useDebounce<[number, number][]>(mapStore.viewArea, delay);

  useEffect(() => {
    const container = document.getElementsByClassName('leaflet-container')[0];
    if (!container) {
      mapStore.initMap();
      mapStore.initCluster();
    }
    return () => {
      mapStore.setCurrentLocation(null);
      mapStore.setSelectedLocation(null);
      mapStore.setUpdateLocations([]);
      mapStore.clearSelectMarker();
      mapStore.setViewArea([]);
    };
  }, []);

  useEffect(() => {
    (async () => await mapStore.initMarkers(projectSearchStore.projectsData.data))();
  }, [projectSearchStore.projectsData]);

  useEffect(() => {
    if (searchStore.selectedLocation) {
      if (searchStore.selectedLocation.id !== mapStore.currentLocation?.id) {
        setDelay(DEBOUNCE_ZERO_DELAY);
      }
      mapStore.setCurrentLocation(searchStore.selectedLocation);
      if (
        mapStore.selectedLocation &&
        mapStore.selectedLocation.id !== searchStore.selectedLocation.id
      ) {
        mapStore.setSelectedLocation(searchStore.selectedLocation);
      }
      (async () => await mapStore.initPolygons())();
    }
  }, [searchStore.selectedLocation]);

  useEffect(() => {
    if (mapStore.selectedLocation) {
      navigate(
        `/search-projects/${mapStore.selectedLocation.stateCode.toLowerCase()}/${
          mapStore.selectedLocation.canonicalTitle
        }`
      );
    }
  }, [mapStore.selectedLocation]);

  useEffect(() => {
    if (mapStore.selectMarker) {
      if (userStore.user) {
        const { data } = mapStore.selectMarker;
        projectSearchStore.selectProjectOnTheMap(data.projectId);
        setParams(`details=${data.projectId}`);
      } else {
        setParams('auth=sign-up&extended=true');
      }
      // TODO: remove after testing
      // if (selectedMarker.current) {
      //   selectedMarker.current.classList?.remove(classes.selectMarker);
      // }
      // if (selectedMarker.current === event.target._icon) {
      //   projectSearchStore.setSelectedProjectOnTheMap(null);
      //   selectedMarker.current.classList.remove(classes.selectMarker);
      //   selectedMarker.current = {} as HTMLDivElement;
      //   return;
      // }
      // selectedMarker.current = event.target._icon;
      // selectedMarker.current.classList.add(classes.selectMarker);
    }
  }, [mapStore.selectMarker]);

  useEffect(() => {
    if (mapStore.viewArea.length) {
      projectSearchStore.setActiveFavorite(null);
      filtersStore.setSearchParams('area', mapStore.viewArea);
      setDelay(VIEWPORT_DEBOUNCE_DELAY);
    }
  }, [debouncedViewPort]);

  return <div id="mapId"></div>;
});

export default MapProjects;
