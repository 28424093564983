import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'utils/clsx';
import { useStore } from 'storesProvider/storeContext';
import classes from './SubHeader.module.scss';
import { IDropdownOption } from 'components/Dropdown/types';
import { Button, Dropdown } from 'components';
import {
  mapPermitTypesToOptions,
  mapPropertyTypesToOptions
} from 'view/SearchProjects/components/SubHeader/helpers/mapConfigsToOptions';

interface IProps {
  onSelectSearch: (id: number) => void;
  searchId: number;
}

const SubHeader: FC<IProps> = ({ onSelectSearch, searchId }) => {
  const { savedSearchesStore, filtersStore, searchStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const options = useMemo<IDropdownOption[]>(
    () =>
      savedSearchesStore.savedSearches.map((search) => ({
        content: search.name,
        selected: searchId === search.id,
        id: search.id
      })),
    [searchId]
  );
  const allSearchesOption = useMemo<IDropdownOption>(
    () => ({
      content: t('savedSearch.allSearches'),
      selected: isNaN(searchId),
      id: 0
    }),
    [searchId]
  );
  const dropdownOptions = [allSearchesOption, ...options];

  const selected = useMemo<IDropdownOption | null>(
    () => dropdownOptions.find((option) => option.selected) || null,
    [searchId]
  );

  const onSelect = useCallback((option: IDropdownOption) => {
    onSelectSearch(option.id ? option.id : NaN);
  }, []);

  const openMap = async (): Promise<void> => {
    if (!selected) return;
    const currentSavedSearches = savedSearchesStore.getSavedSearchById(selected.id);
    if (currentSavedSearches) {
      filtersStore.setAllSearchParams(currentSavedSearches.filters);
      const location = await savedSearchesStore.getLocationInfo(
        Number(currentSavedSearches.filters.location)
      );
      searchStore.setSelectedLocation(location);
      const newCategories = mapPropertyTypesToOptions(
        currentSavedSearches.filters.classes,
        currentSavedSearches.filters.types,
        currentSavedSearches.filters.subTypes
      );
      const newPermitTypes = mapPermitTypesToOptions(currentSavedSearches.filters.permitTypes);
      filtersStore.setCategories(newCategories);
      filtersStore.setPermitTypes(newPermitTypes);
      navigate(`/search-projects/${location.stateCode.toLowerCase()}/${location.canonicalTitle}`, {
        replace: true
      });
    }
  };

  if (!selected) {
    return <></>;
  }

  return (
    <div className="bg-white">
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          <div className="me-4 py-3">
            <Dropdown
              title={selected.content}
              options={dropdownOptions}
              onSelect={onSelect}
              float="start"
              activeClass={classes.dropdownActiveTitle}
              titleClass={classes.dropdownTitle}
              extendedPosition="start"
            />
          </div>
          <div>
            <Button
              type="secondary"
              className={clsx(classes.subHeaderButton, 'px-3')}
              onClick={openMap}>
              <span className="font-kraftig">{t('savedSearch.showOnTheMap')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
