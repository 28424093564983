import {
  IContact,
  IKeyword,
  ISearchProjectService,
  ISearchStore,
  SEARCH_PROJECTS_SERVICE,
  ICompanyResponse,
  FavoriteContacts,
  IProjectsData,
  IActiveFavorite,
  RelatedProjectType,
  IProjectContact,
  IRecentProjectsData,
  Statistics
} from './types';
import { ISearchParams } from './components/SubHeader/types';

import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { IFullProject } from 'view/ProjectInfoModal/types';
import { FavoriteRole, EntityType } from 'view/AddToFavorites/types';
import { initProjectsData, initRecentProjectsData } from './mock';

export class ProjectStore implements ISearchStore {
  private _projectService: ISearchProjectService =
    injector.get<ISearchProjectService>(SEARCH_PROJECTS_SERVICE);

  constructor() {
    makeAutoObservable<ProjectStore>(this);
  }

  projectsData: IProjectsData = initProjectsData;
  statistic: Statistics | null = null;
  contacts: IContact[] = [];
  recentContacts: IContact[] = [];
  isValueLoading = true;
  isProjectsLoadingHeader = false;

  company: ICompanyResponse | null = null;
  recentProjects: IRecentProjectsData = initRecentProjectsData;

  relatedOpenProjects: IRecentProjectsData = initRecentProjectsData;

  relatedClosedProjects: IRecentProjectsData = initRecentProjectsData;

  currentProjectContact: IProjectContact | null = null;

  selectedProjectOnTheMap: number | null = null;

  favoriteProjects: number[] = [];
  favoriteContacts: FavoriteContacts[] = [];
  favoriteRecentProjects: number[] = [];
  activeFavorite: IActiveFavorite | null = null;

  setActiveFavorite(favorite: IActiveFavorite | null) {
    this.activeFavorite = favorite;
  }

  setCompany(company: ICompanyResponse | null): void {
    this.company = company;
  }

  setRecentProjects(recentProjects: IRecentProjectsData): void {
    this.recentProjects = recentProjects;
  }

  setRelatedOpenProjects(recentProjects: IRecentProjectsData): void {
    this.relatedOpenProjects = recentProjects;
  }

  setRelatedClosedProjects(recentProjects: IRecentProjectsData): void {
    this.relatedClosedProjects = recentProjects;
  }

  resetRelatedProjects() {
    this.relatedOpenProjects = initRecentProjectsData;
    this.relatedClosedProjects = initRecentProjectsData;
  }

  setCurrentContact(projectContact: IProjectContact | null): void {
    this.currentProjectContact = projectContact;
  }

  setSelectedProjectOnTheMap(idx: number | null): void {
    this.selectedProjectOnTheMap = idx;
  }

  async getKeywords(keyword: string): Promise<IKeyword[]> {
    return await this._projectService.getKeywords(keyword);
  }

  setProjects(projectsData: IProjectsData) {
    this.projectsData = projectsData;
  }

  setStatistics(statistics: Statistics) {
    this.statistic = statistics;
  }

  setProjectsValueLoading(status: boolean) {
    this.isValueLoading = status;
  }

  setProjectsLoadingHeader(status: boolean) {
    this.isProjectsLoadingHeader = status;
  }

  setContacts(contacts: IContact[]) {
    this.contacts = contacts;
  }

  setRecentContacts(contacts: IContact[]) {
    this.recentContacts = contacts;
  }

  setFavoriteProjects(favoriteProjects: number[]): void {
    this.favoriteProjects = favoriteProjects;
  }

  setFavoriteContacts(favoriteContacts: FavoriteContacts[]): void {
    this.favoriteContacts = favoriteContacts;
  }

  setFavoriteRecentProjects(favoriteRecentProjects: number[]): void {
    this.favoriteRecentProjects = favoriteRecentProjects;
  }

  async getProjects(params: ISearchParams, controller?: AbortController): Promise<void> {
    if (params.area && params.area.length) {
      this.setProjects(await this._projectService.getProjects(params, controller));
      this.setFavoriteProjects(
        this.projectsData.data
          .filter((project) => project.favourite)
          .map((favoriteProject) => favoriteProject.id)
      );
    }
  }

  async getStatistics(locationId: number): Promise<void> {
    this.setProjectsValueLoading(true);
    this.setStatistics(await this._projectService.getStatistics(locationId));
    this.setProjectsValueLoading(false);
  }

  async getContacts(id: number): Promise<void> {
    this.setContacts(await this._projectService.getContacts(id));
    this.setFavoriteContacts(
      this.contacts
        .filter((contact) => contact.favourite)
        .map((favoriteContact) => ({
          id: favoriteContact.id,
          type: favoriteContact.type,
          role: favoriteContact.role
        }))
    );
  }

  async getRecentContacts(id: number): Promise<void> {
    this.setRecentContacts(await this._projectService.getContacts(id));
  }

  async getCompany(projectId: number, role: string, contactId: number): Promise<void> {
    this.setCompany(await this._projectService.getCompany(projectId, role, contactId));
  }

  async getRecentProjects(contactType: string, contactId: number, page: number): Promise<void> {
    this.setRecentProjects(
      await this._projectService.getRecentProjects(contactType, contactId, page)
    );
    this.setFavoriteRecentProjects(
      this.recentProjects.data
        .filter((project) => project.favourite)
        .map((favoriteProject) => favoriteProject.id)
    );
  }

  selectProjectOnTheMap(id: number): void {
    const projectIndex = this.projectsData?.data.findIndex((project) => project.id === id);
    this.setSelectedProjectOnTheMap(projectIndex);
  }

  clearSelectedProjectOnTheMap(): void {
    this.setSelectedProjectOnTheMap(null);
  }

  async getAllProjectInfo(projectId: number): Promise<IFullProject> {
    return await this._projectService.getAllProjectInfo(projectId);
  }

  async getRelatedProjects(projectId: number): Promise<void> {
    this.setRelatedOpenProjects(
      await this._projectService.getRelatedProjects(projectId, 'open', 1)
    );
    this.setRelatedClosedProjects(
      await this._projectService.getRelatedProjects(projectId, 'closed', 1)
    );
  }

  updateFavoriteContacts(
    isActive: boolean,
    id: number,
    type: EntityType,
    role: FavoriteRole
  ): void {
    const isFavorite = !!this.favoriteContacts.find(
      (contact) => contact.id === id && contact.type === type && contact.role === role
    );
    if (isFavorite && isActive) {
      return;
    }
    if (isFavorite && !isActive) {
      this.setFavoriteContacts(
        this.favoriteContacts.filter(
          (contact) => !(contact.id === id && contact.type === type && contact.role === role)
        )
      );
      return;
    }
    if (!isFavorite) {
      this.setFavoriteContacts([...this.favoriteContacts, { id, type, role }]);
    }
  }

  updateFavoriteProjects(isActive: boolean, id: number): void {
    const isFavorite = this.favoriteProjects.includes(id);
    if (isFavorite && isActive) {
      return;
    }
    if (isFavorite && !isActive) {
      this.setFavoriteProjects(this.favoriteProjects.filter((projectId) => projectId !== id));
      return;
    }
    if (!isFavorite) {
      this.setFavoriteProjects([...this.favoriteProjects, id]);
    }
  }

  updateFavoriteRecentProjects(isActive: boolean, id: number): void {
    const isFavorite = this.favoriteRecentProjects.includes(id);
    if (isFavorite && isActive) {
      return;
    }
    if (isFavorite && !isActive) {
      this.setFavoriteRecentProjects(
        this.favoriteRecentProjects.filter((projectId) => projectId !== id)
      );
      return;
    }
    if (!isFavorite) {
      this.setFavoriteRecentProjects([...this.favoriteRecentProjects, id]);
    }
  }

  checkContactIsFavorite(contact: IContact): boolean {
    return !!this.favoriteContacts.find(
      (item) => item.id === contact.id && item.type === contact.type && item.role === contact.role
    );
  }

  checkProjectIsFavorite(id: number): boolean {
    return this.favoriteProjects.includes(id);
  }

  checkRecentProjectIsFavorite(id: number): boolean {
    return this.favoriteRecentProjects.includes(id);
  }

  async loadMore(projectId: number, type: RelatedProjectType, page: number): Promise<void> {
    if (type === 'openProjects') {
      this.setRelatedOpenProjects(
        await this._projectService.getRelatedProjects(projectId, 'open', page)
      );
    } else {
      this.setRelatedClosedProjects(
        await this._projectService.getRelatedProjects(projectId, 'closed', page)
      );
    }
  }
}
