import React, { useCallback, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';
import { AnimatedInput, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { IResetData } from '../types';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ResetSchema } from 'utils/Formik/validation';
import backIcon from 'assets/icons/back-arrow.svg';

const Reset = () => {
  const { t } = useTranslation();
  const [, setParams] = useSearchParams();
  const { authStore, alertStore } = useStore();

  const [success, setSuccess] = useState<boolean>(false);

  const sendPassword = useCallback(async (values: IResetData) => {
    await authStore.sendResetCode(values.email);
    alertStore.successAlert(`${t('auth.resetEmailSent')} ${formik.values.email}`);
    setSuccess(true);
  }, []);

  const formik = useFormik<IResetData>({
    initialValues: {
      email: ''
    },
    onSubmit: sendPassword,
    validationSchema: ResetSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const openLogin = useCallback(() => setParams('auth=sign-in'), []);

  if (success) {
    return (
      <div className={clsx(classes.form, 'bg-white', 'py-4')}>
        <div className="d-flex align-items-center flex-column">
          <h1 className={classes.title}>{t('auth.resetSuccessTitle')}</h1>
          <p className={clsx(classes.subTitle, classes.resetSubtitle, 'mb-4', 'text-center')}>
            {t('auth.resetSuccessSubTitle')} {formik.values.email}
          </p>
          <div
            className={clsx(
              'px-4',
              'w-100',
              'd-flex',
              'justify-content-center',
              'align-items-center',
              classes.mt100
            )}>
            <span>{t('auth.didntReceive')}</span>
            <Button
              type="light"
              onClick={() => sendPassword({ email: formik.values.email })}
              className={clsx('text-secondary', classes.forgot, 'py-0', 'm-0', 'ms-2')}>
              <span className="font-kraftig">{t('auth.resend')}</span>
            </Button>
          </div>
          <div className="d-flex justify-content-center pt-4">
            <Button
              type="light"
              onClick={openLogin}
              className={clsx(
                'text-secondary',
                classes.forgot,
                'py-0',
                'm-0',
                'd-flex',
                'align-items-center'
              )}>
              <div className="me-2 d-flex align-items-center">
                <img src={backIcon} alt="" />
              </div>
              <span className="font-kraftig">{t('auth.backLogin')}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      action="#"
      className={clsx(classes.form, 'bg-white', 'mb-4', 'py-4')}>
      <div className="d-flex align-items-center flex-column">
        <h1 className={classes.title}>{t('auth.resetTitle')}</h1>
        <p className={clsx(classes.subTitle, 'mb-4')}>{t('auth.resetSubTitle')}</p>
        <div className="px-4 w-100 mb-4">
          <AnimatedInput
            type="text"
            name="email"
            value={formik.values.email}
            onInput={onInputChange}
            isError={!!formik.errors.email}
            isSuccess={!formik.errors.email && formik.touched.email}
            errorText={formik.errors.email}
            dataTestElement="send-reset-email-input">
            <span>{t('auth.enterEmail')}</span>
          </AnimatedInput>
        </div>

        <div className="px-4 w-100">
          <Button
            type="primary"
            className={clsx('w-100', classes.formBtn)}
            data-test-element="send-reset-code-button">
            <span>{t('auth.reset')}</span>
          </Button>
        </div>
        <div className="d-flex justify-content-center pt-4">
          <Button
            type="light"
            onClick={openLogin}
            className={clsx(
              'text-secondary',
              classes.forgot,
              'py-0',
              'm-0',
              'd-flex',
              'align-items-center'
            )}>
            <div className="me-2 d-flex align-items-center">
              <img src={backIcon} alt="" />
            </div>
            <span className="font-kraftig">{t('auth.backLogin')}</span>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Reset;
