import React, { FC, useEffect } from 'react';
import { Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { ACCESS_TOKEN } from 'utils/constants';

export interface IProps {
  children: JSX.Element;
}

const Private: FC<IProps> = observer(({ children }) => {
  const { authStore } = useStore();
  const [, setParams] = useSearchParams();
  const location = useLocation();

  const pathAvailableToTheGuest = 'search-projects';
  const token: string | null = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    if (authStore.isRedirected) {
      setParams('auth=sign-in');
      authStore.setIsRedirected(false);
    }
  }, [authStore.isRedirected]);

  if (!token && !location.pathname.split('/').includes(pathAvailableToTheGuest)) {
    return <Navigate to="/?auth=sign-in" replace />;
  }

  if (location.pathname.split('/').includes(pathAvailableToTheGuest)) {
    const path = location.pathname.split('/');
    if (path.length < 4) {
      return <Navigate to="/" replace />;
    }
    if (path.length === 4 && (!path[2].length || !path[3].length)) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
});

export default Private;
