import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export const useListHeight = (listRef: MutableRefObject<HTMLDivElement>): number => {
  const [listHeight, setListHeight] = useState<number>(0);
  const onResize = useCallback(() => setListHeight(listRef.current.clientHeight), []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    setListHeight(listRef.current.clientHeight);
  }, [listRef.current]);
  return listHeight;
};
