import { getLocal } from 'utils/localStorageOperation';
import { FoundLocation } from 'view/Search/types';
import { CURRENT_LOCATION } from 'utils/constants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateAfterLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const navigateAfterLogin = () => {
    if (location.pathname.split('/').includes('search-projects')) {
      params.delete('auth');
      return;
    }
    if (location.pathname === '/') {
      const localLocation = getLocal<FoundLocation>(CURRENT_LOCATION);
      if (localLocation) {
        navigate(
          `/search-projects/${localLocation.stateCode.toLowerCase()}/${
            localLocation.canonicalTitle
          }`,
          { replace: true }
        );
        return;
      }
    }
    navigate('/saved-search', { replace: true });
  };

  return { navigateAfterLogin };
};
