import { useStore } from 'storesProvider/storeContext';
import { ACCESS_TOKEN } from 'utils/constants';

export const useAppInit = async () => {
  const { configStore, userStore, savedSearchesStore, notificationStore } = useStore();
  await configStore.updateConfigs();
  const token: string | null = localStorage.getItem(ACCESS_TOKEN);
  if (!token) {
    userStore.setIsUserLoading(false);
    return;
  }
  try {
    await userStore.getCurrentUser();
    if (!userStore.user) return;
    await savedSearchesStore.getNewProjectCount(userStore.user.id);
    await notificationStore.getNotifications();
  } catch (e) {
    localStorage.removeItem(ACCESS_TOKEN);
  }
};
