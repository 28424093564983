import { IOption } from 'components/Select/types';
import { ISearchParams } from './components/SubHeader/types';
import { IProjectsData, IRecentProjectsData } from './types';

export const dateOptions: IOption[] = [
  { value: '7', text: 'Past 7 days', id: 1 },
  { value: '30', text: 'Past 30 days', id: 2 },
  { value: '92', text: 'Past 3 months', id: 3 },
  { value: '183', text: 'Past 6 months', id: 4 },
  { value: 'custom', text: 'Custom', id: 5 }
];

export const initialSearchParams: ISearchParams = {
  permitTypes: [],
  statuses: [],
  classes: [],
  types: [],
  subTypes: [],
  location: null,
  value: {
    max: null,
    min: null
  },
  levels: {
    max: null,
    min: null
  },
  units: {
    max: null,
    min: null
  },
  date: {
    max: null,
    min: null
  },
  order: [
    {
      field: 'effectiveDate',
      order: 'desc'
    }
  ],
  page: 1,
  keywords: ''
};

export const initProjectsData: IProjectsData = {
  currentPage: 0,
  data: [],
  limit: 0,
  hasMore: false,
  hasSubscription: false
};

export const initRecentProjectsData: IRecentProjectsData = {
  count: 0,
  currentPage: 0,
  data: [],
  limit: 0,
  totalPages: 0,
  value: 0,
  hasSubscription: false
};
