import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from '../LeftSidebar.module.scss';
import { Select } from 'components';
import { sortOptions, sorts } from '../mock';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import { IOption } from 'components/Select/types';
import { SortKey } from '../types';
import { observer } from 'mobx-react';
import { NA } from 'utils/constants';
import { valueFormatter } from 'helper/valueFormatter';

const LeftSidebarHeader = observer(() => {
  const { projectSearchStore, filtersStore, searchStore } = useStore();
  const { t } = useTranslation();
  const [selectedSort, setSelectedSort] = useState<IOption>(sortOptions[0]);

  const onSortChange = useCallback((option: IOption) => {
    setSelectedSort(option);
    filtersStore.setSearchParams('order', [sorts[option.value as SortKey]]);
  }, []);

  useEffect(() => {
    const key = Object.entries(sorts).find((item) => {
      const [, value] = item;
      if (!filtersStore.searchParams.order) return;
      return (
        filtersStore.searchParams.order[0].field === value.field &&
        filtersStore.searchParams.order[0].order === value.order
      );
    });
    const selectedOption = key
      ? sortOptions.find((option) => option.value === key[0])
      : sortOptions[0];
    if (!selectedOption) return;
    setSelectedSort(selectedOption);
    filtersStore.setSearchParams('order', [sorts[selectedOption.value as SortKey]]);
  }, [filtersStore.searchParams.order]);

  const getProjectCount = useMemo((): string => {
    if (projectSearchStore.statistic) {
      const value = valueFormatter(projectSearchStore.statistic.count);
      return (
        (projectSearchStore.statistic.count > 1000 ? value + '+ ' : value + ' ') +
        t('searchProject.projects')
      );
    }
    return '';
  }, [projectSearchStore.statistic]);

  return (
    <div>
      <div className={clsx('d-flex', 'justify-content-between', 'py-3', classes.general)}>
        <div className={classes.infoWrapper}>
          <div className={classes.location}>
            <span className="font-halbfett">{searchStore.selectedLocation?.title}</span>
          </div>
          <div className={clsx(classes.value, 'd-flex', 'align-items-center', 'text-nowrap')}>
            {getProjectCount} | {t('searchProject.projectValue')}:
            {projectSearchStore.isValueLoading ? (
              <div className={clsx(classes.loader, 'ms-2')}>
                <div></div>
              </div>
            ) : (
              <span>
                &nbsp;
                {projectSearchStore.statistic
                  ? `$${valueFormatter(projectSearchStore.statistic.value)}`
                  : NA}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span className={clsx(classes.sort, 'me-2', 'font-halbfett')}>
            {t('searchProject.sortBy')}:
          </span>
          <Select
            name="sort"
            onChange={onSortChange}
            options={sortOptions}
            value={selectedSort}
            className={clsx(classes.sortSelect, 'default-color-text')}
            data-test-element="search-projects-sort-select"
            optionWidth={120}
          />
        </div>
      </div>
    </div>
  );
});

export default LeftSidebarHeader;
