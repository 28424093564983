import {
  ISubscriptionAndPlansService,
  ISubscriptionAndPlansStore,
  PaymentCustomer,
  PaymentData,
  PaymentType,
  Plans,
  Product,
  QuestionsAndAnswers,
  SUBSCRIPTION_PLANS_SERVICE,
  SubscriptionAction,
  UserSubscription,
  UserSubscriptionData
} from './types';
import { makeAutoObservable } from 'mobx';
import { injector } from 'utils/injector';
import { questionAndAnswer } from './CancelSubscriptionModal/mock';
import { RENEW, UPGRADE } from './constants';
import { Location } from 'view/SubscriptionsAndPlans/types';

export class SubscriptionAndPlansStore implements ISubscriptionAndPlansStore {
  private _subscriptionService: ISubscriptionAndPlansService =
    injector.get<ISubscriptionAndPlansService>(SUBSCRIPTION_PLANS_SERVICE);

  constructor() {
    makeAutoObservable<SubscriptionAndPlansStore>(this);
  }

  monthProducts: Product[] = [];
  yearProducts: Product[] = [];
  upgradeRenewProduct: Product | null = null;
  userSubscriptions: UserSubscription[] = [];
  subscriptionsForTable: UserSubscriptionData = {
    month: [],
    year: []
  };

  paymentProcessing = false;
  paymentData: PaymentData | null = null;

  questionsAndAnswers: QuestionsAndAnswers[] = questionAndAnswer;

  setProducts(products: Product[]): void {
    this.monthProducts = products.filter((product) => product.period === Plans.MONTH);
    this.yearProducts = products.filter((product) => product.period === Plans.YEAR);
  }

  setUpgradeRenewProduct(upgradeRenewProduct: Product | null): void {
    this.upgradeRenewProduct = upgradeRenewProduct;
  }

  setPaymentProcessing(paymentProcessing: boolean): void {
    this.paymentProcessing = paymentProcessing;
  }

  setUserSubscriptions(userSubscriptions: UserSubscription[]): void {
    this.userSubscriptions = userSubscriptions;
  }

  setSubscriptionsForTable(subscriptions: UserSubscriptionData): void {
    this.subscriptionsForTable = subscriptions;
  }

  setAnswer(num: number, type: string, answer: string): void {
    this.questionsAndAnswers = this.questionsAndAnswers.map((question, idx) => {
      if (idx === num) {
        return {
          ...question,
          [type]: answer
        };
      }
      return question;
    });
  }

  setPaymentData(paymentData: PaymentData | null): void {
    this.paymentData = paymentData;
  }

  async getProductsByLocation(locationId: number): Promise<void> {
    this.setProducts(await this._subscriptionService.getProductsByLocation(locationId));
  }

  async getSubscriptionPlanToLocation(
    subscriptionId: number,
    userId: number,
    subscriptionAction: SubscriptionAction
  ): Promise<void> {
    this.setUpgradeRenewProduct(
      await this._subscriptionService.getSubscriptionPlanToLocation(
        subscriptionId,
        userId,
        subscriptionAction
      )
    );
  }

  async createSubscriptionToLocation(
    priceId: number,
    locationId: number[],
    paymentMethod: string | null
  ): Promise<PaymentCustomer> {
    return await this._subscriptionService.createSubscriptionToLocation(
      priceId,
      locationId,
      paymentMethod
    );
  }

  async upgradeSubscriptionToLocation(
    userId: number,
    subscriptionId: number
  ): Promise<PaymentCustomer> {
    return await this._subscriptionService.upgradeSubscriptionToLocation(userId, subscriptionId);
  }

  async getUserSubscriptions(userId: number): Promise<void> {
    const res = await this._subscriptionService.getUserSubscriptions(userId);
    this.setUserSubscriptions(res.data);
    this.setSubscriptionsForTable(res.tableData);
  }

  async unsubscription(subscriptionId: number, userId: number): Promise<void> {
    await this._subscriptionService.unsubscription(
      subscriptionId,
      userId,
      this.questionsAndAnswers
    );
  }

  async checkHasSubscriptionToLocation(locationId: number): Promise<boolean> {
    let attempt = 0;
    let result = false;

    const hasSubscription = async (): Promise<boolean> => {
      if (attempt <= 4) {
        attempt += 1;
        await this.wait();
        result = await this._subscriptionService.checkHasSubscriptionToLocation(locationId);

        if (result) {
          return result;
        }

        if (!result) {
          await hasSubscription();
        }
      }
      return result;
    };
    return await hasSubscription();
  }

  async wait() {
    return new Promise((resolve) => setTimeout(resolve, 1500));
  }

  getSubscriptionById(id: number): UserSubscription | null {
    const foundSubscription = this.userSubscriptions.find((subscription) => subscription.id === id);
    return foundSubscription ? foundSubscription : null;
  }

  async initProductForUpgradeOrRenewModal(
    type: PaymentType,
    subscriptionId: number,
    userId: number
  ): Promise<void> {
    if (type === PaymentType.UPGRADE) {
      await this.getSubscriptionPlanToLocation(subscriptionId, userId, UPGRADE);
    }
    if (type === PaymentType.RENEW) {
      await this.getSubscriptionPlanToLocation(subscriptionId, userId, RENEW);
    }
  }

  async removeIncompleteSubscription(subscriptionId: number): Promise<void> {
    await this._subscriptionService.removeIncompleteSubscription(subscriptionId);
  }

  createPaymentData(product: Product, location: Location | null): void {
    if (location) {
      this.setPaymentData({
        ...product,
        location
      });
    }
  }

  clearAnswers(): void {
    this.questionsAndAnswers = questionAndAnswer;
  }
}
