import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classes from './Header.module.scss';
import { clsx } from 'utils/clsx';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import UserBody from './components/UserBody';
import GuestBody from './components/GuestBody';
import { DEBOUNCE_DELAY, SIGN_IN, SIGN_UP } from 'utils/constants';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { useDebounce } from 'hooks/useDebounce';
import { usePrevious } from 'hooks/usePrevious';
import { useKeyboardCallbacks } from 'hooks/useKeyboardCallbacks';

const Header: FC = observer(() => {
  const navigate = useNavigate();
  const { searchStore, userStore, filtersStore } = useStore();
  const [, setParams] = useSearchParams();

  const [inputValue, setInputValue] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const [showSearchList, setShowSearchList] = useState<boolean>(false);
  const [canLoadLocations, setCanLoadLocations] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<SearchProjects | null>(null);
  const [lastInputValue, setLastInputValue] = useState<string>('');

  const debouncedSearchTerm = useDebounce<string>(searchString, DEBOUNCE_DELAY);
  const prevTerm = usePrevious(debouncedSearchTerm);

  const handleInput = useCallback((name: string, value: string) => {
    setCanLoadLocations(true);
    setSearchString(value);
    setInputValue(value);
    setLastInputValue(value);
  }, []);

  const handleCloseList = (): void => {
    setShowSearchList(false);
    setSearchResults(null);
  };

  const onSearchListChange = (item: FoundLocation): void => {
    filtersStore.setSearchParams('area', []);
    navigate(`/search-projects/${item.stateCode.toLowerCase()}/${item.canonicalTitle}`);
    setShowSearchList(false);
    setLastInputValue(item.title);
  };

  const handleAuthAction = useCallback((type: string) => {
    if (type === SIGN_IN) {
      setParams('auth=sign-in');
      return;
    }
    if (type === SIGN_UP) {
      setParams('auth=sign-up');
      return;
    }
  }, []);

  const loadLocations = useCallback(
    async (loadAnyway = false) => {
      if (!prevTerm.length && !debouncedSearchTerm.length && !loadAnyway) return;
      await searchStore.searchProjects(debouncedSearchTerm);
      setSearchResults(searchStore.foundLocations);
      setShowSearchList(true);
      if (!canLoadLocations && !loadAnyway) {
        setCanLoadLocations(true);
        setShowSearchList(false);
      }
    },
    [debouncedSearchTerm, canLoadLocations, prevTerm]
  );

  const handleInputFocus = useCallback(async () => {
    if (showSearchList) return;
    setCanLoadLocations(true);
    await loadLocations(true);
  }, [loadLocations, showSearchList]);

  const onInputBlur = useCallback(() => {
    setTimeout(() => setShowSearchList(false), 150);
  }, []);

  const setValueWithoutFetching = useCallback((value: string) => {
    setCanLoadLocations(false);
    setInputValue(value);
  }, []);

  const { handleKeyUp, handleKeyDown, handleKeyEnter, handleKeyEscape } = useKeyboardCallbacks({
    searchResults,
    setSearchResults,
    lastInputValue,
    setLastInputValue,
    setValueWithoutFetching,
    setShowSearchList
  });

  useEffect(() => {
    setCanLoadLocations(false);
    if (searchStore.selectedLocation) {
      setInputValue(searchStore.selectedLocation.title);
      setSearchString(searchStore.selectedLocation.title);
      setLastInputValue(searchStore.selectedLocation.title);
    } else {
      setInputValue('');
      setSearchString('');
      setLastInputValue('');
    }
  }, [searchStore.selectedLocation]);

  useEffect(() => {
    (async () => await loadLocations())();
  }, [debouncedSearchTerm]);

  return (
    <>
      <nav className={clsx('navbar', 'navbar-light', 'p-0', 'position-relative', classes.nav)}>
        {userStore.user ? (
          <UserBody
            inputValue={inputValue}
            onInputFocus={handleInputFocus}
            inputChange={handleInput}
            searchResults={searchResults}
            selectedLocation={searchStore.selectedLocation}
            searchListChange={onSearchListChange}
            showSearchList={showSearchList}
            closeList={handleCloseList}
            onInputBlur={onInputBlur}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onKeyEnter={handleKeyEnter}
            onKeyEscape={handleKeyEscape}
          />
        ) : (
          <GuestBody
            onInputFocus={handleInputFocus}
            inputValue={inputValue}
            inputChange={handleInput}
            searchResults={searchResults}
            selectedLocation={searchStore.selectedLocation}
            searchListChange={onSearchListChange}
            showSearchList={showSearchList}
            closeList={handleCloseList}
            authAction={handleAuthAction}
            onInputBlur={onInputBlur}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onKeyEnter={handleKeyEnter}
            onKeyEscape={handleKeyEscape}
          />
        )}
      </nav>
    </>
  );
});
export default Header;
