import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classes from './ChoosePlanModal.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton } from 'components';
import PlanCard from './components/PlanCard';
import closeIcon from 'assets/icons/close.svg';
import { useStore } from 'storesProvider/storeContext';
import { PaymentType, Plans, Product } from '../types';
import { observer } from 'mobx-react';
import Payment from 'view/PaymentForm';
import { Location } from 'view/SubscriptionsAndPlans/types';

interface IProps {
  show: boolean;
  closeModal: () => void;
  location: Location | null;
  onUpdate?: () => void;
}

const ChoosePlanModal: FC<IProps> = observer(({ show, closeModal, location, onUpdate }) => {
  const { t } = useTranslation();
  const { subscriptionAndPlansStore } = useStore();
  const [loadPlans, setLoadPlans] = useState<boolean>(false);
  const [activePlan, setActivePlan] = useState<Plans | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const clickOutside = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (e.target === e.currentTarget && !subscriptionAndPlansStore.paymentProcessing) closeModal();
  }, []);

  const toggleType = useCallback((product: Product) => {
    setSelectedProduct(product);
  }, []);

  useEffect(() => {
    if (location && show) {
      (async () => {
        setLoadPlans(true);
        await subscriptionAndPlansStore.getProductsByLocation(location.id);
        initSelectedPlan();
        setLoadPlans(false);
      })();
    }
  }, [show, location]);

  const initSelectedPlan = useCallback((): void => {
    setActivePlan(subscriptionAndPlansStore.monthProducts.length ? Plans.MONTH : Plans.YEAR);
    setSelectedProduct(getProductByActivePlan[0]);
  }, [subscriptionAndPlansStore.monthProducts, subscriptionAndPlansStore.yearProducts]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      if (prev) {
        const newTabPlan = getProductByActivePlan.find((plan) => plan.unlimited === prev.unlimited);
        return newTabPlan ? newTabPlan : getProductByActivePlan[0];
      }
      return getProductByActivePlan[0];
    });
  }, [activePlan]);

  useEffect(() => {
    if (selectedProduct) {
      subscriptionAndPlansStore.createPaymentData(selectedProduct, location);
    }
  }, [selectedProduct, location]);

  const getProductByActivePlan = useMemo((): Product[] => {
    if (activePlan === Plans.MONTH) {
      return subscriptionAndPlansStore.monthProducts;
    }
    if (activePlan === Plans.YEAR) {
      return subscriptionAndPlansStore.yearProducts;
    }
    return [];
  }, [activePlan, subscriptionAndPlansStore.monthProducts, subscriptionAndPlansStore.yearProducts]);

  const onClose = (): void => {
    if (!subscriptionAndPlansStore.paymentProcessing) {
      closeModal();
    }
  };

  const handlePurchase = (): void => {
    if (onUpdate) {
      onUpdate();
    }

    closeModal();
  };

  return (
    <div
      className={clsx(
        'position-fixed',
        'top-0',
        'bottom-0',
        'start-0',
        'end-0',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        show ? 'd-block' : 'd-none',
        classes.modal
      )}
      onClick={clickOutside}>
      <div className={clsx(classes.body, 'py-4', 'px-5', 'position-relative')}>
        <div className={clsx(classes.close, 'position-absolute')} role="button" onClick={onClose}>
          <img src={closeIcon} alt="" />
        </div>
        <h2 className={clsx(classes.title, 'mb-2', 'text-center')}>{t('choosePlan.title')}</h2>
        <p className={clsx(classes.subTitle, 'mb-2', 'text-center')}>{t('choosePlan.subTitle')}</p>
        <div className="d-flex align-items-center justify-content-center mb-4">
          {!!subscriptionAndPlansStore.monthProducts.length && (
            <Button
              type={activePlan === Plans.MONTH ? 'primary' : 'secondary'}
              onClick={() => setActivePlan(Plans.MONTH)}
              className={clsx(
                classes.btn,
                'px-3',
                activePlan === Plans.MONTH ? 'text-white' : 'bg-white'
              )}>
              {t('choosePlan.monthly')}
            </Button>
          )}
          {!!subscriptionAndPlansStore.yearProducts.length && (
            <Button
              type={activePlan === Plans.YEAR ? 'primary' : 'secondary'}
              onClick={() => setActivePlan(Plans.YEAR)}
              className={clsx(
                classes.btn,
                'px-3',
                activePlan === Plans.YEAR ? 'text-white' : 'bg-white'
              )}>
              {t('choosePlan.annually')}
            </Button>
          )}
          <span className={clsx('ms-2', classes.save)}>{t('choosePlan.save')}</span>
        </div>
        <div className="d-flex justify-content-between">
          {loadPlans && <Skeleton type="plan" itemsToShow={2} />}
          {!loadPlans &&
            selectedProduct &&
            getProductByActivePlan.map((product) => (
              <PlanCard
                key={product.id}
                active={selectedProduct.id === product.id}
                product={product}
                onToggle={toggleType}
                locationName={location ? location.title : ''}
              />
            ))}
        </div>
        <div className="mt-3">
          {selectedProduct && subscriptionAndPlansStore.paymentData ? (
            <Payment direction="row" type={PaymentType.NEW} onPurchase={handlePurchase} />
          ) : (
            <div className="w-100 d-flex justify-content-center">
              <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ChoosePlanModal;
