import React, { FC, ReactNode, SyntheticEvent, useCallback } from 'react';
import classes from '../CancelSubscriptionModal.module.scss';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { surveyList } from '../mock';
import { CustomAnswer, SurveyList } from '../types';
import { QuestionsAndAnswers } from '../../types';
import { observer } from 'mobx-react';

interface Props {
  checkedAnswer: SurveyList | null;
  onCheckAnswer: (answer: SurveyList) => void;
  onCommentChange: (answer: CustomAnswer) => void;
  answers: QuestionsAndAnswers[];
}

const Survey: FC<Props> = observer(({ checkedAnswer, onCheckAnswer, onCommentChange, answers }) => {
  const { t } = useTranslation();

  const answerList = (): ReactNode => {
    return (
      <ul className="list-unstyled">
        {surveyList.map(({ id, label }) => (
          <li key={`answer-${id}`} className="mb-2 cursor-pointer">
            <div className="form-check" onClick={() => onCheckAnswer({ id, label })}>
              <input
                className="form-check-input cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                checked={checkedAnswer ? id === checkedAnswer.id : false}
                onChange={() => onCheckAnswer({ id, label })}
              />
              <label className="form-check-label font-size-14">{label}</label>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const customAnswer = useCallback((index: number, e: SyntheticEvent): void => {
    onCommentChange({
      index,
      text: (e.target as HTMLTextAreaElement).value
    });
  }, []);

  return (
    <div>
      <div className={clsx(classes.survey, 'default-shadow', 'bg-white', 'p-4')}>
        <form className={clsx('d-flex', 'justify-content-between')}>
          <div className={clsx(classes.question)}>
            <p className="m-0">{t('cancelSubscription.question')}</p>
            <span className="d-block">{t('cancelSubscription.required')}</span>
          </div>
          <div className={clsx(classes.answers)}>
            {answerList()}
            <textarea
              className={clsx(classes.textarea, 'd-block', 'w-100', 'py-2')}
              rows={3}
              name="note"
              onInput={(e) => customAnswer(0, e)}
              value={answers[0].custom}
              placeholder={t('cancelSubscription.yourComment')}
            />
          </div>
        </form>
      </div>
      <div className={clsx(classes.survey, 'default-shadow', 'bg-white', 'p-4', 'mt-3')}>
        <form className={clsx('d-flex', 'justify-content-between')}>
          <div className={clsx(classes.question)}>
            <p className="m-0">{t('cancelSubscription.question2')}</p>
          </div>
          <div className={clsx(classes.answers)}>
            <textarea
              className={clsx(classes.textarea, 'd-block', 'w-100', 'py-2')}
              rows={3}
              name="note"
              onInput={(e) => customAnswer(1, e)}
              value={answers[1].custom}
              placeholder={t('cancelSubscription.enterYourAnswer')}
            />
          </div>
        </form>
      </div>
    </div>
  );
});

export default Survey;
