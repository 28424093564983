import React, { FC, useCallback, useId, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Logo from 'assets/icons/logo-header.svg';
import { Button, Dropdown, Input, Nav, SearchList } from 'components';
import classes from '../Header.module.scss';
import searchIcon from 'assets/icons/search.svg';
import { IExtendedOption } from 'components/Select/types';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { FoundLocation, SearchProjects } from 'view/Search/types';
import { INavItems } from 'components/Nav/types';
import { ReactComponent as UserDarkIcon } from 'assets/icons/user-dark.svg';
import { ReactComponent as SavedSearchIcon } from 'assets/icons/saved-search.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { IUserDropdown, UserDropdownType } from '../types';
import Notification from 'view/Notification';
import { axiosInstance } from 'utils/API';
import { clsx } from 'utils/clsx';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { IDropdownOption } from '../../../components/Dropdown/types';

interface Props {
  inputValue: string;
  inputChange: (name: string, value: string) => void;
  searchResults: SearchProjects | null;
  selectedLocation: FoundLocation | null;
  searchListChange: (item: FoundLocation) => void;
  showSearchList: boolean;
  closeList: () => void;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onKeyUp: () => void;
  onKeyDown: () => void;
  onKeyEnter: () => void;
  onKeyEscape: () => void;
}

const UserBody: FC<Props> = observer(
  ({
    inputValue,
    inputChange,
    searchResults,
    selectedLocation,
    searchListChange,
    showSearchList,
    closeList,
    onInputFocus,
    onInputBlur,
    onKeyUp,
    onKeyDown,
    onKeyEnter,
    onKeyEscape
  }) => {
    const { t } = useTranslation();
    const { authStore, userStore, savedSearchesStore, projectSearchStore, searchStore } =
      useStore();
    const [showPlan, setShowPlan] = useState<boolean>(false);

    const navigate = useNavigate();
    const [, setParams] = useSearchParams();
    const location = useLocation();
    const id = useId();

    const isShowcase =
      process.env.REACT_APP_API_URL === 'https://radar-showcase.dev.dnc.pp.ua/api/v1';
    const isDev = process.env.REACT_APP_API_URL === 'https://radar.dev.dnc.pp.ua/api/v1';

    const navItems: INavItems[] = [
      {
        name: t('components.header.nav.savedSearches'),
        link: '/saved-search',
        active: location.pathname.includes('/saved-search'),
        badge: savedSearchesStore.newProjectsCount
      },
      {
        name: t('components.header.nav.favorites'),
        link: '/favorites',
        active: location.pathname.includes('/favorites')
      }
    ];

    // TODO: remove import, remove, clear after testing
    const userDropdownOptions = useMemo<IDropdownOption[]>(
      () => [
        {
          content: t('components.header.menu.profile'),
          value: 'profile',
          id: 1,
          icon: UserDarkIcon
        },
        {
          content: t('components.header.menu.searches'),
          value: 'searches',
          id: 2,
          icon: SavedSearchIcon
        },
        {
          content: t('components.header.menu.logout'),
          value: 'logout',
          id: 3,
          icon: LogoutIcon
        }
      ],
      []
    );

    // TODO: remove import, remove, clear after testing
    const handleDropdownChange = (selected: IDropdownOption): void => {
      const currentTarget = selected.value;
      const targets: IUserDropdown = {
        profile: () => navigate('/profile/settings', { replace: true }),
        searches: () => navigate('/saved-search', { replace: true }),
        logout: () => logout(),
        auth: () => setParams('auth=sign-in'),
        components: () => navigate('/components', { replace: true }),
        subscription: () => navigate('/profile/subscription', { replace: true }),
        favorites: () => navigate('/favorites', { replace: true }),
        analytics: () => navigate('/favorites/analytics', { replace: true }),
        import: () => importData(),
        remove: () => removeData(),
        clear: () => removeSub()
      };
      targets[currentTarget as UserDropdownType]();
    };

    // TODO: remove this function after testing
    const removeSub = async (): Promise<void> => {
      await axiosInstance.get(`subscriptions/remove/data`);
    };
    const importData = async (): Promise<void> => {
      await axiosInstance.get(`subscriptions/import/data`);
    };
    const removeData = async (): Promise<void> => {
      await axiosInstance.get(`subscriptions/remove/all-data`);
    };

    const logout = (): void => {
      navigate('/');
      authStore.logout();
      userStore.setUser(null);
    };

    const showSubscribeModal = useCallback(() => setShowPlan(true), []);
    const hideSubscribeModal = useCallback(() => setShowPlan(false), []);

    const handleLoadProjects = useCallback(() => {
      projectSearchStore.setProjectsLoadingHeader(true);
    }, []);

    const handleKeyEnter = useCallback(() => {
      onKeyEnter();
      const input: HTMLInputElement | null = document.querySelector(`[data-input-id="${id}"]`);
      input?.blur();
    }, [onKeyEnter]);

    const showPurchase = useMemo<boolean>(() => {
      return !!searchStore.selectedLocation && !projectSearchStore.projectsData.hasSubscription;
    }, [searchStore.selectedLocation, projectSearchStore.projectsData]);

    return (
      <div className="container-fluid px-3">
        <div className="d-flex align-items-center">
          <Link className={clsx('navbar-brand', classes.link)} to="/saved-search" tabIndex={-1}>
            <img src={Logo} alt="close" className="position-relative" role="button" />
          </Link>
          <Nav items={navItems} mode="dark" navClass="mx-4" />
        </div>
        <div className="flex-grow-1 d-flex">
          <div
            className={clsx(
              'd-flex position-relative',
              showPurchase ? classes.inputWrapper : 'w-100'
            )}>
            <Input
              tabIndex={0}
              data-input-id={id}
              autoComplete="off"
              name="search"
              type="text"
              value={inputValue}
              onInput={inputChange}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              data-test-element="header-search-input"
              inputClass={classes.searchInput}
              searchIcon>
              <div
                data-input-id={id}
                className={`d-flex w-100 align-items-center justify-content-center ${classes.inputSearchOverlayText}`}>
                <img src={searchIcon} alt="" />
                &nbsp;{t('components.header.search')}
              </div>
            </Input>
            <SearchList
              listResponse={searchResults}
              selectedLocation={selectedLocation}
              onChange={searchListChange}
              styleTop="42"
              show={showSearchList}
              closeList={closeList}
              inputId={id}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onKeyEnter={handleKeyEnter}
              onKeyEscape={onKeyEscape}
            />
          </div>
          {showPurchase && (
            <Button
              type="primary"
              data-test-element="header-purchase-btn"
              background={'#00C47D'}
              className={clsx(classes.button, 'px-4 ms-2', classes.purchase)}
              disabled={projectSearchStore.isValueLoading}
              onClick={showSubscribeModal}>
              <span className="font-halbfett">{t('components.header.purchase')}</span>
            </Button>
          )}
        </div>
        <div className="d-flex ms-5 align-items-center">
          <Notification />
          <Dropdown
            activeClass={classes.activeDropdown}
            changeIconColor
            title={<UserIcon />}
            titleClass={classes.dropdownTitle}
            options={userDropdownOptions}
            onSelect={handleDropdownChange}
            arrowIconColor="#FFFFFF"
            isFiltering={false}
            width={216}
          />
        </div>

        {projectSearchStore.projectsData.data[0]?.location?.county && (
          <ChoosePlanModal
            show={showPlan}
            location={projectSearchStore.projectsData.data[0].location.county}
            closeModal={hideSubscribeModal}
            onUpdate={handleLoadProjects}
          />
        )}
      </div>
    );
  }
);

export default UserBody;
