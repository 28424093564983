import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
  MutableRefObject,
  useMemo
} from 'react';
import { IExtendedOption, IOption, option } from './types';
import classes from './Select.module.scss';
import { clsx } from 'utils/clsx';
import { Button, FavoriteList } from 'components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { ENTER } from '../SearchList/constants';
import { childOf } from 'helper/childOf';

interface IProps<OptionType> {
  options: OptionType[];
  label?: string;
  value: OptionType | null;
  onChange: (value: OptionType, name: string) => void;
  name: string;
  onReset?: (name: string) => void;
  extended?: boolean;
  className?: string;
  placeholder?: string;
  placeholderClassName?: string;
  onCreateList?: () => void;
  optionWidth?: number;
  disableLess?: string | null;
  disableMore?: string | null;
  [key: string]: unknown;
}

const Select = <T extends option = IOption>({
  options,
  label,
  value,
  onChange,
  className,
  name,
  extended,
  placeholder,
  placeholderClassName,
  onCreateList,
  onReset,
  optionWidth,
  disableLess,
  disableMore,
  ...rest
}: PropsWithChildren<IProps<T>>) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const selectRef = useRef() as MutableRefObject<HTMLDivElement>;

  const toggleOpened = useCallback((): void => {
    setIsOpened((prev) => !prev);
  }, []);

  const clickOutside = (e: MouseEvent): void => {
    if (!e.composedPath().includes(selectRef.current)) {
      setIsOpened(false);
    }
  };

  const handleExtendedSelect = useCallback((item: T, option: IOption): void => {
    onChange({ ...item, selected: option.id }, name);
  }, []);

  const selectedValue = useMemo<string | null>(() => {
    if (!value) return null;
    if (extended) {
      const selectedOption = value as IExtendedOption;
      if (!selectedOption.selected) return null;
      const selected = selectedOption.options.find((item) => item.id === selectedOption.selected);
      return (selected && selected.text) || null;
    }
    return (value as IOption)?.text || null;
  }, [value]);

  const handleReset = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onReset && onReset(name);
      setIsOpened(false);
    },
    [name]
  );

  const handlePressEnter = useCallback((e: React.KeyboardEvent, value: T, name: string) => {
    if (e.code === ENTER && document.activeElement === e.target) {
      e.stopPropagation();
      onChange(value, name);
      selectRef.current.focus();
      setIsOpened(false);
    }
  }, []);

  const keyPress = useCallback((e: KeyboardEvent): void => {
    if (e.code === ENTER) {
      if (
        !childOf(document.activeElement, selectRef.current) &&
        document.activeElement !== selectRef.current
      )
        setIsOpened(false);
      if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
    }
  }, []);

  const handleResetPressEnter = useCallback((e: React.KeyboardEvent) => {
    if (e.code === ENTER && document.activeElement === e.target) {
      e.stopPropagation();
      onReset && onReset(name);
      setIsOpened(false);
      selectRef.current.focus();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    if (!isOpened)
      return () => {
        window.removeEventListener('keydown', keyPress);
      };
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
      window.removeEventListener('keydown', keyPress);
    };
  }, [isOpened]);

  return (
    <>
      <p className={classes.label}>{label}</p>
      <div
        className={clsx(
          classes.select,
          className,
          'position-relative',
          'd-flex',
          'align-items-center',
          'justify-content-between',
          isOpened && classes.activeSelect
        )}
        role="button"
        onClick={toggleOpened}
        ref={selectRef}
        tabIndex={0}
        {...rest}>
        <>
          {selectedValue ? (
            <span className={clsx('font-kraftig', classes.value)}>{selectedValue}</span>
          ) : (
            <span
              className={clsx(
                placeholderClassName,
                isOpened && 'text-white',
                'p-0',
                'font-kraftig'
              )}>
              {placeholder}
            </span>
          )}
          &nbsp;
          {isOpened &&
            (extended ? (
              <FavoriteList<T>
                list={options as IExtendedOption[]}
                value={value}
                childrenAlignment="bottom"
                onChange={handleExtendedSelect}>
                <Button
                  type="light"
                  color="#0087FF"
                  onClick={onCreateList}
                  className="w-100 default-shadow py-3">
                  <span>Create personal Project list</span>
                </Button>
              </FavoriteList>
            ) : (
              <div
                data-scrollable={options.length > 5}
                className={clsx(
                  classes.optionWrapper,
                  'position-absolute',
                  'bg-white',
                  'p-2',
                  !optionWidth && 'w-100'
                )}>
                {options.map((option, idx) => {
                  const item = option as IOption;
                  return (
                    <div
                      key={idx}
                      data-value={(option as IOption).value}
                      tabIndex={0}
                      className={clsx(
                        classes.option,
                        !optionWidth && 'w-100',
                        'd-flex',
                        'default-color-text',
                        'justify-content-between',
                        'align-items-center',
                        idx !== options.length - 1 && 'mb-2',
                        (value as IOption)?.id === item.id && classes.active,
                        !!disableLess &&
                          !!item.value &&
                          Number(item?.value) < Number(disableLess) &&
                          classes.disabled,
                        !!disableMore &&
                          Number(item?.value) > Number(disableMore) &&
                          classes.disabled
                      )}
                      style={{ width: `${optionWidth}px` }}
                      onKeyDown={(e) => handlePressEnter(e, option, name)}
                      onClick={() => onChange(option, name)}>
                      {(option as IOption).text}
                      {(value as IOption)?.id === (option as IOption).id && (
                        <TickIcon
                          data-value={(option as IOption).value}
                          className={classes.tickIcon}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
        </>
        {onReset && value ? (
          <div onClick={handleReset} className="d-flex align-items-center">
            <CloseIcon
              className={clsx(classes.resetIcon, isOpened && classes.resetOpened)}
              tabIndex={0}
              onKeyDown={handleResetPressEnter}
            />
          </div>
        ) : (
          <div
            className={clsx(
              classes.icon,
              'position-absolute',
              'top-50',
              'd-flex',
              'align-items-center',
              isOpened && classes.active,
              extended && isOpened && classes.activeColored
            )}>
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1 0.5L6 5.5L11 0.5" stroke="#2F3139" strokeWidth="1.4" />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default Select;
