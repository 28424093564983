import React, { FC, useEffect } from 'react';
import googleIcon from 'assets/icons/google.svg';
import googleLightIcon from 'assets/icons/google-light.svg';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';
import { IGoogle, IGoogleResponse } from '../types';

declare global {
  interface Window {
    google: IGoogle;
  }
}

interface IProps {
  step: string;
  googleAction: (user: IGoogleResponse) => void;
  light: boolean;
}

const GoogleAuth: FC<IProps> = ({ step, googleAction, light }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: String(process.env.REACT_APP_GOOGLE_CLIENT_ID),
        callback: googleAction
      });
      window.google.accounts.id.renderButton(
        document.getElementById('buttonDiv'),
        {
          theme: 'outline',
          size: 'large',
          type: 'standard',
          locale: 'en',
          shape: 'rectangular',
          logo_alignment: 'left',
          width: 234
        } // customization attributes
      );
      window.google.accounts.id.prompt();
    }
  }, []);

  return (
    <div className="position-relative">
      <Button
        type="secondary"
        background={light ? '#3A437B' : '#DBDFEA'}
        className={clsx(
          'w-100',
          classes.socialAuthBtn,
          light && 'text-white',
          light && classes.googleBtn
        )}
        data-test-element="login-google-button">
        <div
          className={clsx(
            classes.customBtn,
            'd-flex',
            'align-items-center',
            'justify-content-center',
            'w-100'
          )}>
          <img
            src={light ? googleLightIcon : googleIcon}
            alt=""
            className={light ? 'me-2' : 'me-1'}
          />
          <span className="font-kraftig">
            {step === 'sign-in'
              ? t('auth.login') + ' ' + t('auth.with') + ' Google'
              : t('auth.signUp') + ' ' + t('auth.with') + ' Google'}
          </span>
        </div>
      </Button>
      <div
        id="buttonDiv"
        className={clsx(classes.googleBtnOrigin, 'position-absolute', 'opacity-0')}></div>
    </div>
  );
};

export default GoogleAuth;
