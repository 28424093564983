import React, { FC, ReactNode, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from './Reports.module.scss';
import { IReport } from './types';
import ReportItem from './components/ReportItem';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { ButtonGroup } from './components/ButtonGroup';

const reports: IReport[] = [
  {
    id: 1,
    comment:
      '“Your leads publications have more information on new construction projects that is easier to read, than any of the other publications we receive.”',
    name: 'D.M.',
    position: 'Contractor, Arlington'
  },
  {
    id: 2,
    comment:
      '” Our sales cycle has shortened from 8 weeks to 2 weeks and our conversion rate has gone up over 200% since using the leads report. We get early intel which helps us stay ahead of the competition and give the customer a quicker bid.”',
    name: 'T.S.',
    position: 'Materials Manufacturer, Issaquah'
  },
  {
    id: 3,
    comment:
      '“New Construction Projects Leads Reports are well summarized. These are good reports, we look forward to them, we save them, and we use them. They are a nice reminder of projects coming down the road. ”',
    name: 'P.C.',
    position: 'Concrete Construction Firm, Seattle'
  },
  {
    id: 4,
    comment:
      '“We get dozens of publications and construction reports. We only save 2. One is New Project News. It’s a great publication. There is nothing else like it. No one else tracks projects in this area like New Project News.”',
    name: 'D.B.',
    position: 'Construction Company Project Manager, Bellingham'
  },
  {
    id: 5,
    comment:
      '“I have been a long time subscriber. I am changing jobs. Please make sure that you update my new information so that I do not miss an issue.”',
    name: 'S.L.',
    position: 'General Contractor, Issaquah'
  },
  {
    id: 6,
    comment:
      '“We read your leads listings regularly. They have the best projects overview of any publication in this area.”',
    name: 'L.H.',
    position: 'Construction Company President, Puyallup'
  },
  {
    id: 7,
    comment:
      '“We pour over each of your leads reports and we use the information in our marketing effort. We can’t afford to miss an issue.”',
    name: 'T.G.',
    position: 'Construction Manager, Seattle'
  },
  {
    id: 8,
    comment:
      '“We’ve been reviewing your magazine for the last several months. We have used your info to get new work. What does the separate leads report cost?”',
    name: 'H.H.',
    position: 'Construction Company President, Lakewood'
  },
  {
    id: 9,
    comment: '“Great summary of the market. Lets me know what’s going on.”',
    name: 'M.O.',
    position: 'Construction Company Owner, Seattle'
  },
  {
    id: 10,
    comment:
      '“We want to keep receiving your magazine. We look through every issue. Is it free on line or will we need to subscribe to it? Also, how do we get access to the leads report”',
    name: 'M.O.',
    position: 'Construction Supplies Installation Company President, Seattle'
  },
  {
    id: 11,
    comment:
      '“Your magazine leads report was at the top of the stack on my desk, and if you saw how tall that stack was, you’d appreciate your position in the stack. Seriously, we read it, we kept it, and we hope to keep receiving it now online.”',
    name: 'S.H.',
    position: 'Contractor Services Owner, Seattle'
  },
  {
    id: 12,
    comment:
      '“We like and use your magazine and other leads report information. We are adding two new people. Please make sure to add them to your records.”',
    name: 'R.C. & J.H.',
    position: 'Construction Company Owners, Woodinville'
  },
  {
    id: 13,
    comment:
      '“You have good leads information. We highlight projects that we want to track from each report. Keep them coming.”',
    name: 'B.W.',
    position: 'Specialty Construction Company Chairman, Snohomish'
  },
  {
    id: 14,
    comment:
      '“We like your publication, nothing else like it, how much does it cost for a subscription?”',
    name: 'B.W.',
    position: 'Construction Company President, Longview'
  },
  {
    id: 15,
    comment: '“Your publication has valuable, positive, information.”',
    name: 'T.S.',
    position: 'Construction Company President, Kent'
  },
  {
    id: 16,
    comment: '“We are fans of New Project News! Please make sure to update our email address.”',
    name: 'B.L.',
    position: 'Construction Company, Mercer Island'
  },
  {
    id: 17,
    comment: '“We read your reports regularly, and we do get leads from them.”',
    name: 'G.T.',
    position: 'Construction Company Owner, Buckley'
  },
  {
    id: 18,
    comment:
      '“We find your publications are an excellent source for general information on construction projects in this area.”',
    name: 'C.P.',
    position: 'Construction Company Manager, Bonney Lake'
  }
];

const Reports: FC = () => {
  const { t } = useTranslation();

  const [sliderRef, setSliderRef] = useState<null | Slider>(null);

  const dotsState = {
    next: sliderRef?.slickNext,
    previous: sliderRef?.slickPrev,
    goToSlide: (page: number) => sliderRef?.slickGoTo(page)
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: ReactNode) => <ButtonGroup {...dotsState}>{dots}</ButtonGroup>
  };

  return (
    <div className={clsx('container', classes.container)}>
      <h2 className={clsx(classes.title, 'text-center', 'mb-5')}>{t('home.reportsTitle')}</h2>
      <div className="position-relative">
        <Slider {...settings} ref={setSliderRef}>
          {reports.map((report) => (
            <ReportItem key={report.id} {...report} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reports;
