import React, { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, Location, Navigate } from 'react-router-dom';
import classes from './ProfileLayout.module.scss';
import { clsx } from 'utils/clsx';
import { Nav } from 'components';
import { INavItems } from 'components/Nav/types';

const ProfileLayout: FC = () => {
  const location: Location = useLocation();
  const [tabs, setTabs] = useState<INavItems[]>([
    {
      name: 'Profile settings',
      link: '/profile/settings',
      active: true
    },
    {
      name: 'Subscription',
      link: '/profile/subscription',
      active: false
    }
  ]);

  useEffect(() => {
    setTabs((tabs) =>
      tabs.map((tab) => ({ ...tab, active: location.pathname.includes(tab.link) }))
    );
  }, [location]);

  if (location.pathname === '/profile') {
    return <Navigate to="settings" />;
  }

  return (
    <>
      <div className="default-shadow bg-white position-relative">
        <div className={clsx(classes.nav)}>
          <Nav items={tabs} mode="light" />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default ProfileLayout;
