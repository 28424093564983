import {
  FoundLocation,
  ISearchService,
  ISearchStore,
  SEARCH_SERVICE,
  SearchProjects
} from './types';
import { injector } from 'utils/injector';
import { makeAutoObservable } from 'mobx';
import { setLocal } from 'utils/localStorageOperation';
import { CURRENT_LOCATION } from 'utils/constants';

export class SearchStore implements ISearchStore {
  _searchService: ISearchService = injector.get<ISearchService>(SEARCH_SERVICE);

  constructor() {
    makeAutoObservable<SearchStore>(this);
  }

  foundLocations: SearchProjects | null = null;
  selectedLocation: FoundLocation | null = null;

  setFoundLocations(locations: SearchProjects | null): void {
    this.foundLocations = locations;
  }

  setSelectedLocation(location: FoundLocation | null): void {
    this.selectedLocation = location;
    if (location) {
      setLocal(CURRENT_LOCATION, location);
    }
  }

  async searchProjects(value: string): Promise<void> {
    this.setFoundLocations(await this._searchService.searchProjects(value));
  }

  async lookupLocation(state: string, title: string): Promise<void> {
    this.setSelectedLocation(await this._searchService.lookupLocation(state, title));
  }
}
