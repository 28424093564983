import { useStore } from 'storesProvider/storeContext';
import { IMoreValues } from '../types';
import { maxOptions, minOptions } from '../mock';
import { IOptionsConfig } from '../../../types';
import { getConfigs } from './getConfigs';

export const getInitialMoreValues = () => {
  const { filtersStore } = useStore();
  const configs: IOptionsConfig = getConfigs();

  const values: IMoreValues = {
    units: {
      min: filtersStore.searchParams.units.min
        ? minOptions.find((option) => option.value == filtersStore.searchParams.units.min) || null
        : null,
      max: filtersStore.searchParams.units.max
        ? maxOptions.find((option) => option.value == filtersStore.searchParams.units.max) || null
        : null
    },
    levels: {
      min: filtersStore.searchParams.levels.min
        ? minOptions.find((option) => option.value == filtersStore.searchParams.levels.min) || null
        : null,
      max: filtersStore.searchParams.levels.max
        ? maxOptions.find((option) => option.value == filtersStore.searchParams.levels.max) || null
        : null
    },
    status:
      configs.projectStatuses.find(
        (status) => status.id === filtersStore.searchParams.statuses[0]
      ) || null,
    keywords: filtersStore.searchParams.keywords || ''
  };
  return values;
};
