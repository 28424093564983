import React, { FC, useCallback, useEffect, useState } from 'react';
import classes from '../Auth.module.scss';
import { AnimatedInput, Button } from 'components';
import { clsx } from 'utils/clsx';
import { ILoginData } from '../types';
import AuthFooter from './AuthFooter';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoginSchema } from 'utils/Formik/validation';
import { useNavigateAfterLogin } from 'hooks/useNavigateAfterLogin';
import { observer } from 'mobx-react';

export const SignIn: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();
  const { navigateAfterLogin } = useNavigateAfterLogin();
  const { authStore, alertStore, userStore } = useStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signIn = useCallback(async (values: ILoginData): Promise<void> => {
    try {
      setIsLoading(true);
      await authStore.signIn(values);
      await userStore.getCurrentUser();
      alertStore.successAlert(t('auth.successLogin'));
      navigateAfterLogin();
    } finally {
      setIsLoading(false);
    }
  }, []);

  const formik = useFormik<ILoginData>({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: signIn,
    validationSchema: LoginSchema
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const openReset = useCallback(() => setParams('auth=reset'), []);

  useEffect(() => {
    if (userStore.user) {
      navigate(location.pathname, { replace: true });
    }
  }, [userStore.user]);

  return (
    <form
      action="#"
      onSubmit={formik.handleSubmit}
      className={clsx(classes.form, 'bg-white', 'py-4')}>
      <div className="d-flex align-items-center flex-column">
        <h1 className={clsx(classes.title, classes.loginTitle)}>{t('auth.login')}</h1>
        <div className={clsx(classes.px40, 'w-100')}>
          <div className="mb-4 pb-2">
            <AnimatedInput
              type="text"
              name="email"
              value={formik.values.email}
              onInput={onInputChange}
              isError={!!formik.errors.email}
              isSuccess={!formik.errors.email && formik.values.email.length > 0}
              errorText={formik.errors.email}
              dataTestElement="login-name-input">
              <span>{t('form.emailAddress')}</span>
            </AnimatedInput>
          </div>
          <div className="mb-4 pb-2">
            <AnimatedInput
              type="password"
              name="password"
              value={formik.values.password}
              isError={!!formik.errors.password}
              errorText={formik.errors.password}
              isSuccess={!formik.errors.password && formik.values.password.length > 0}
              onInput={onInputChange}
              dataTestElement="login-password-input">
              <span>{t('form.password')}</span>
            </AnimatedInput>
          </div>
        </div>
        <div className={clsx(classes.px40, 'w-100')}>
          <Button
            type="primary"
            isLoading={isLoading}
            className={clsx('w-100', classes.formBtn)}
            data-test-element="login-submit-button">
            {t('auth.login')}
          </Button>

          <div className="d-flex justify-content-center pt-4">
            <Button
              type="light"
              onClick={openReset}
              className={clsx('text-secondary', classes.forgot, 'py-0', 'm-0')}>
              <span className="font-kraftig">{t('auth.forgotPassword')}</span>
            </Button>
          </div>
        </div>
      </div>
      <AuthFooter step="sign-in" />
    </form>
  );
});
