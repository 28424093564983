import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './SavedSearchManage.module.scss';
import { clsx } from 'utils/clsx';
import { Input, ListItem, Modal } from 'components';
import { ActionType } from './enums';
import { IModalData } from './types';
import { useStore } from 'storesProvider/storeContext';
import { ISavedSearch } from './types';
import { observer } from 'mobx-react';
import { Search } from '../Search';
import ProgressBar from 'components/ProgressBar';
import { useProgress } from 'hooks/useProgress';
import { ESCAPE } from 'components/SearchList/constants';

const SavedSearches: FC = observer(() => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSearchesLoading, setIsSearchesLoading] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>('');
  const [itemToEdit, setItemToEdit] = useState<null | number>(null);
  const [itemToDelete, setItemToDelete] = useState<null | number>(null);
  const [modalData, setModalData] = useState<IModalData>({
    title: '',
    subtitle: '',
    negativeAction: t('savedSearch.cancel'),
    positiveAction: t('savedSearch.update')
  });
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { progress, visible } = useProgress(isSearchesLoading);

  const [actionType, setActionType] = useState<ActionType>(ActionType.EDIT);
  const { savedSearchesStore, alertStore, userStore } = useStore();

  const onModalDataChange = useCallback((name: string, value: string): void => {
    setModalData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const savedSearches = savedSearchesStore.savedSearches;

  const findItemById = (id: number): ISavedSearch | null =>
    savedSearches.find((item: ISavedSearch) => item.id === id) || null;

  const deleteClick = (id: number): void => {
    const currentItem = findItemById(id);
    if (!currentItem) return;
    onModalDataChange('title', `${t('savedSearch.wantDelete')} ${currentItem.name}`);
    onModalDataChange('subtitle', t('savedSearch.cantUndo'));
    onModalDataChange('positiveAction', t('savedSearch.delete'));
    setActionType(ActionType.DELETE);
    setShowModal(true);
    setItemToDelete(id);
    const target: HTMLButtonElement | null = modalRef?.current?.querySelector('button') || null;
    setTimeout(() => target?.focus(), 0);
  };

  const editClick = (id: number): void => {
    const currentItem = findItemById(id);
    if (!currentItem) return;
    onModalDataChange('title', t('savedSearch.edit'));
    onModalDataChange('subtitle', '');
    onModalDataChange('positiveAction', t('savedSearch.update'));
    setCurrentName(currentItem.name);
    setActionType(ActionType.EDIT);
    setShowModal(true);
    setItemToEdit(id);
    const target: HTMLButtonElement | null = modalRef?.current?.querySelector('button') || null;
    setTimeout(() => target?.focus(), 0);
  };

  const closeModal = (): void => {
    setItemToEdit(null);
    setItemToDelete(null);
    setShowModal(false);
  };

  const onTitleSavedChange = (name: string, str: string): void => {
    setCurrentName(str);
  };

  const handleEditSearch = useCallback(async () => {
    try {
      if (!userStore.user || !itemToEdit) return;
      await savedSearchesStore.editSavedSearches(userStore.user.id, itemToEdit, currentName);
      alertStore.successAlert(t('savedSearch.editSuccess'));
    } finally {
      closeModal();
    }
  }, [currentName, itemToEdit]);

  const handleDeleteSearch = useCallback(async () => {
    try {
      if (!userStore.user || !itemToDelete) return;
      await savedSearchesStore.deleteSavedSearches(userStore.user.id, itemToDelete);
      alertStore.successAlert(t('savedSearch.deleteSuccess'));
      await loadSearches();
    } finally {
      closeModal();
    }
  }, [itemToDelete]);

  const loadSearches = useCallback(async () => {
    try {
      if (!userStore.user) return;
      setIsSearchesLoading(true);
      await savedSearchesStore.getSavedSearches(userStore.user.id);
    } finally {
      setIsSearchesLoading(false);
    }
  }, []);

  const keyPress = (e: KeyboardEvent) => {
    if (e.code === ESCAPE && showModal) setShowModal(false);
  };

  useLayoutEffect(() => {
    loadSearches().then();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    return () => window.removeEventListener('keydown', keyPress);
  }, []);

  if (!visible)
    return (
      <div className={clsx(classes.progressBar, 'position-relative')}>
        <ProgressBar progress={progress} />
      </div>
    );
  if (!savedSearchesStore.savedSearches.length) return <Search />;

  return (
    <div>
      <div className="default-shadow default-color-text px-4 bg-white">
        <h2 className={clsx(classes.title, 'm-0')}>{t('savedSearchManage.savedSearches')}</h2>
      </div>
      <div className="mx-4 my-3">
        {savedSearches.map((item: ISavedSearch, idx: number) => (
          <ListItem
            data={item}
            deleteClick={deleteClick}
            editClick={editClick}
            key={`item-list-${item.id}`}
            dataTestId={idx + 1}
          />
        ))}
      </div>
      <div ref={modalRef}>
        <Modal
          show={showModal}
          title={modalData.title}
          subTitle={modalData.subtitle}
          deleteBtn={false}
          actions={{
            negative: modalData.negativeAction,
            positive: modalData.positiveAction
          }}
          onSubmit={itemToEdit ? handleEditSearch : handleDeleteSearch}
          data-test-element="saved-search-modal"
          closeModal={closeModal}>
          {actionType === ActionType.EDIT && (
            <Input
              name=""
              type="text"
              label={t('savedSearchManage.editLabel')}
              placeholder={t('savedSearchManage.editPlaceholder')}
              value={currentName}
              onInput={onTitleSavedChange}
              data-test-element="saved-search-edit-input"
            />
          )}
        </Modal>
      </div>
    </div>
  );
});

export default SavedSearches;
