import { ISavedSearchRepo, ISavedSearchService, SAVED_SEARCH_REPO, ISavedSearch } from './types';
import { ISearchParams } from '../SearchProjects/components/SubHeader/types';
import { injector } from 'utils/injector';
import { FoundLocation } from '../Search/types';

export class SavedSearchManageService implements ISavedSearchService {
  private _savedSearchRepo: ISavedSearchRepo = injector.get<ISavedSearchRepo>(SAVED_SEARCH_REPO);

  async saveSearch(id: number, name: string, filters: ISearchParams): Promise<ISavedSearch> {
    let copy: ISearchParams = {} as ISearchParams;
    for (const key in filters) {
      const k = key as keyof ISearchParams;
      if (key !== 'date' && key !== 'page' && !(key === 'keywords' && !filters.keywords))
        copy = { ...copy, [k]: filters[k] };
    }
    return (await this._savedSearchRepo.saveSearch(id, name, copy)).data.result;
  }

  async getSavedSearches(id: number): Promise<ISavedSearch[]> {
    return (await this._savedSearchRepo.getSavedSearches(id)).data.result;
  }

  async editSavedSearches(userId: number, searchId: number, name: string): Promise<ISavedSearch> {
    return (await this._savedSearchRepo.editSavedSearches(userId, searchId, name)).data.result;
  }
  async deleteSavedSearches(userId: number, searchId: number): Promise<void> {
    return await this._savedSearchRepo.deleteSavedSearches(userId, searchId);
  }

  async getNewProjectCount(userId: number): Promise<number> {
    return (await this._savedSearchRepo.getNewProjectCount(userId)).data.result;
  }

  async getLocationInfo(locationId: number): Promise<FoundLocation> {
    const { id, title, type, canonicalTitle, stateCode } = (
      await this._savedSearchRepo.getLocationInfo(locationId)
    ).data.result;
    return {
      id,
      title,
      type,
      canonicalTitle,
      stateCode
    };
  }
}
