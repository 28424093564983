import { Contacts, Detail, ISavedSearchResultsProject } from './types';
import { ConfigType, IConfigs } from 'store/ConfigStore/types';
import { IListIcon, IRow } from 'components/Table/types';
import { iconSrcListByNum } from 'utils/icons';
import { SourceLink } from 'utils/types';
import { useStore } from 'storesProvider/storeContext';
import { NA } from 'utils/constants';
import moment from 'moment';
import { numberWithSpaces } from 'helper/numberWithComma';

const getDataFromConfig = (config: ConfigType[], configType: number): string => {
  if (config && config.length) {
    return config.find((item) => item.id === configType)?.name || '';
  }
  return '';
};

const getContactsPhone = (configs: IConfigs, details: Detail[]): string => {
  const phone = details.find((detail) => detail.type === SourceLink.PHONE);
  if (phone) {
    return phone.value;
  }
  return '';
};

const getDetails = (configs: IConfigs, details: Detail[]): IListIcon[] => {
  return details
    .filter((item) => item.type !== SourceLink.PHONE)
    .map((detail) => {
      return {
        icon: iconSrcListByNum[detail.type],
        label: detail.value,
        type: detail.type
      };
    });
};

export const tableRowGeneration = (data: ISavedSearchResultsProject[]): IRow[] => {
  const { configStore } = useStore();
  return data.map((item: ISavedSearchResultsProject) => {
    return {
      id: item.id,
      newItem: item.new,
      hasNote: item.hasNotes,
      favorite: item.favourite,
      fields: [
        {
          field: 'details',
          value: configStore.enums
            ? `${getDataFromConfig(configStore.enums.propertyClasses, item.class.id)} - ${
                item.type.name
              }`
            : '',
          additionalValue: item.description,
          isTitle: true
        },
        {
          field: 'date',
          additionalValue: moment(item.createdAt).format('MM/DD/YYYY')
        },
        {
          field: 'status',
          status: item.status
        },
        {
          field: 'location',
          value: item.location.city
            ? `${item.location.city.title} ${item.location.state.stateCode}`
            : item.location.county.title
        },
        {
          field: 'contacts',
          values: item.contacts.map((contact: Contacts) => {
            return {
              label: configStore.enums
                ? getDataFromConfig(configStore.enums.projectRoles, contact.role).toUpperCase()
                : '',
              value: contact.name,
              phone: configStore.enums ? getContactsPhone(configStore.enums, contact.details) : '',
              iconItems: configStore.enums ? getDetails(configStore.enums, contact.details) : []
            };
          })
        },
        {
          field: 'value',
          value: `${item.value ? `$${numberWithSpaces(item.value)}` : NA}`
        }
      ]
    };
  });
};
