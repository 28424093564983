import React, { FC, useEffect, useRef, MutableRefObject, useCallback, MouseEvent } from 'react';
import linkedinIcon from 'assets/icons/linkedin.svg';
import linkedinLightIcon from 'assets/icons/linkedin-light.svg';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { ILinkedinResponse } from '../types';
import { clsx } from 'utils/clsx';
import classes from '../Auth.module.scss';

interface IProps {
  step: string;
  linkedinAction: (response: ILinkedinResponse) => Promise<void>;
  light: boolean;
}

const LinkedInAuth: FC<IProps> = ({ step, linkedinAction, light }) => {
  const { t } = useTranslation();
  const LINKEDIN_URL =
    String(process.env.REACT_APP_API_URL) + process.env.REACT_APP_LINKEDIN_REDIRECT_URI;
  const popupRef = useRef(null) as MutableRefObject<WindowProxy | null>;
  useEffect(() => {
    window.addEventListener('message', receiveLinkedInMessage);
    return () => {
      window.removeEventListener('message', receiveLinkedInMessage);
    };
  });
  const receiveLinkedInMessage = (event: MessageEvent) => {
    if (event.origin !== new URL(String(process.env.REACT_APP_API_URL)).origin) return;
    linkedinAction(event.data).then();
    popupRef.current?.close && popupRef.current.close();
  };
  const linkedInLogin = useCallback((e: MouseEvent) => {
    e.preventDefault();
    popupRef.current = window.open(LINKEDIN_URL, '_blank', 'width=600,height=600');
  }, []);

  return (
    <>
      <Button
        type="secondary"
        disabled={false}
        background={light ? '#3A437B' : '#DBDFEA'}
        onClick={linkedInLogin}
        className={clsx(
          'w-100',
          light && 'text-white',
          light && classes.light,
          classes.socialAuthBtn
        )}>
        <div className="d-flex align-items-center justify-content-center w-100">
          <img
            src={light ? linkedinLightIcon : linkedinIcon}
            alt=""
            className={light ? 'me-2' : 'me-1'}
          />
          <span className="font-kraftig">
            {step === 'sign-in'
              ? t('auth.login') + ' ' + t('auth.with') + ' Linkedin'
              : t('auth.signUp') + ' ' + t('auth.with') + ' Linkedin'}
          </span>
        </div>
      </Button>
    </>
  );
};

export default LinkedInAuth;
