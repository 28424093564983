import { FunctionComponent } from 'react';

export interface IAxiosResponse<T> {
  data: {
    result: T;
  };
}

export interface ITableData<T> {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: T[];
}

export interface IIconConfig {
  [key: string]: FunctionComponent;
}

export enum SourceLink {
  PHONE = 10,
  EMAIL = 20,
  LINKEDIN = 30,
  WEB = 40
}

export type ClickEvent = {
  index: number | null;
  selected: number;
  nextSelectedPage: number | undefined;
  event: object;
  isPrevious: boolean;
  isNext: boolean;
  isBreak: boolean;
  isActive: boolean;
};

export type ObjectValues<T> = { [key: string]: T };
