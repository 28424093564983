import React, { FC, useRef } from 'react';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import classes from 'view/ProjectInfoModal/ProjectInfoModal.module.scss';
import PersonItem from 'view/SearchProjects/components/PersonItem';
import { IFullProject } from 'view/ProjectInfoModal/types';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import { numberWithSpaces } from 'helper/numberWithComma';
import { NA } from 'utils/constants';

interface IProps {
  project: IFullProject;
  isFavorite: boolean;
  onFavorite: (e: React.MouseEvent, ref: SVGSVGElement | null) => void;
  onScroll: () => void;
}

const Details: FC<IProps> = ({ project, isFavorite, onFavorite, onScroll }) => {
  const { t } = useTranslation();
  const favoriteRef = useRef<null | SVGSVGElement>(null);

  return (
    <div className={clsx(classes.generalInfo, 'p-4')} onScroll={onScroll}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className={classes.status} style={{ color: project.status.color }}>
          {project.status.name}
        </div>
        <span style={{ marginRight: '3px' }}>
          <FavoriteIcon
            ref={favoriteRef}
            onClick={(e) => onFavorite(e, favoriteRef ? favoriteRef.current : null)}
            className={clsx(classes.favoriteIcon, 'me-1', isFavorite && classes.filled)}
            data-test-element="favorite-icon"
          />
        </span>
      </div>
      <span
        className={clsx(
          'd-flex',
          'justify-content-between',
          'align-items-center',
          'mb-1',
          classes.bold,
          'font-kraftig'
        )}>
        <span className={clsx(classes.overflowText, 'pe-2')}>
          {project.propertyClass.name} - {project.propertyType.name}
        </span>
        <div className="position-relative">
          <span>{project.value ? `$${numberWithSpaces(project.value)}` : NA}</span>
          {project.estimatedValue && (
            <span className={clsx(classes.estimated, 'position-absolute', 'bottom-100', 'end-0')}>
              EST.
            </span>
          )}
        </div>
      </span>
      <div
        className={clsx(
          'd-flex',
          'justify-content-between',
          'align-items-center',
          'mb-5',
          classes.light
        )}>
        <span className={clsx(classes.overflowText, 'pe-2', classes.permitType)}>
          {project.permitType?.name}
        </span>
        <span>{project.effectiveDate}</span>
      </div>
      {project.description && (
        <div className="mb-5">
          <h6>{t('searchProject.description')}</h6>
          <p className={classes.description}>{project.description}</p>
        </div>
      )}
      {project.contacts.length > 0 && (
        <div>
          <h6 className="mb-4">{t('searchProject.projectContacts')}</h6>
          <div className="flex-wrap d-flex">
            {project.contacts.map((person, idx) => (
              <PersonItem key={idx} person={person} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
