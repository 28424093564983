import {
  ISearchProjectService,
  IKeyword,
  ISearchProjectsRepo,
  SEARCH_PROJECTS_REPO,
  IContact,
  ICompanyResponse,
  IProjectsData,
  RelatedProjectKey,
  IRecentProjectsData,
  Statistics
} from './types';
import { ISearchParams } from './components/SubHeader/types';
import { injector } from 'utils/injector';
import { detailIcons, iconListByNum } from 'utils/icons';
import { IFullProject } from 'view/ProjectInfoModal/types';
import moment from 'moment';

export class SearchProjectsService implements ISearchProjectService {
  private _searchRepo: ISearchProjectsRepo =
    injector.get<ISearchProjectsRepo>(SEARCH_PROJECTS_REPO);

  async getKeywords(keyword: string): Promise<IKeyword[]> {
    return (await this._searchRepo.getKeywords(keyword)).data.result;
  }
  async getProjects(params: ISearchParams, controller: AbortController): Promise<IProjectsData> {
    let copy: ISearchParams = {} as ISearchParams;
    for (const key in params) {
      const k = key as keyof ISearchParams;
      if (!(key === 'keywords' && !params.keywords)) copy = { ...copy, [k]: params[k] };
    }
    const res = (await this._searchRepo.getProjects(copy, controller)).data.result;
    return {
      ...res,
      data: res.data.map((project, index) => ({
        ...project,
        createdAt: moment(project.createdAt).format('MM/DD/YYYY'),
        effectiveDate: moment(project.effectiveDate).format('MM/DD/YYYY'),
        locationToShow: `${project.location.city ? project.location.city.title : ''} ${
          project.location.state.stateCode
        }`,
        isLocked: res.hasSubscription ? false : !(params.page === 1 && index < 3)
      }))
    };
  }

  async getStatistics(locationId: number): Promise<Statistics> {
    return (await this._searchRepo.getStatistics(locationId)).data.result;
  }

  async getContacts(id: number): Promise<IContact[]> {
    return (await this._searchRepo.getContacts(id)).data.result.map((contact) => ({
      ...contact,
      details: contact.details.map((detail) => ({
        ...detail,
        icon: detailIcons[String(detail.type)]
      }))
    }));
  }

  async getCompany(projectId: number, role: string, contactId: number): Promise<ICompanyResponse> {
    const res = (await this._searchRepo.getCompany(projectId, role, contactId)).data.result;
    return {
      ...res,
      details: res.details.map((detail) => ({ ...detail, icon: iconListByNum[detail.type] })),
      projectPersons: res.projectPersons.map((person) => ({
        ...person,
        details: person.details.map((detail) => ({
          ...detail,
          icon: iconListByNum[detail.type]
        }))
      })),
      otherPersons: res.otherPersons.map((person) => ({
        ...person,
        details: person.details.map((detail) => ({
          ...detail,
          icon: iconListByNum[detail.type]
        }))
      }))
    };
  }

  async getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IRecentProjectsData> {
    const res = (await this._searchRepo.getRecentProjects(contactType, contactId, page)).data
      .result;
    return {
      ...res,
      data: res.data.map((project) => ({
        ...project,
        createdAt: moment(project.createdAt).format('MM/DD/YYYY'),
        effectiveDate: moment(project.effectiveDate).format('MM/DD/YYYY'),
        locationToShow: `${project.location.city ? project.location.city.title : ''} ${
          project.location.state.stateCode
        }`
      }))
    };
  }

  async getAllProjectInfo(projectId: number): Promise<IFullProject> {
    const response = (await this._searchRepo.getAllProjectInfo(projectId)).data.result;
    return {
      ...response,
      effectiveDate: moment(response.effectiveDate).format('MM/DD/YYYY'),
      contacts: response.contacts.map((person) => ({
        ...person,
        details: person.details.map((detail) => ({ ...detail, icon: iconListByNum[detail.type] }))
      }))
    };
  }

  async getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IRecentProjectsData> {
    const res = (await this._searchRepo.getRelatedProjects(projectId, type, page)).data.result;
    return {
      ...res,
      data: res.data.map((project) => ({
        ...project,
        createdAt: moment(project.createdAt).format('MM/DD/YYYY'),
        effectiveDate: moment(project.effectiveDate).format('MM/DD/YYYY')
      }))
    };
  }
}
