import React, { FC, useCallback } from 'react';
import classes from '../SearchList.module.scss';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { FoundLocation } from 'view/Search/types';
import { clsx } from 'utils/clsx';

interface IProps {
  item: FoundLocation;
  handleClick: (item: FoundLocation) => void;
  selectedLocation: FoundLocation | null;
  focused?: boolean;
}

const SearchItem: FC<IProps> = ({ item, handleClick, selectedLocation, focused }) => {
  const isSelectedLocation = useCallback(
    (locationId: number): string => {
      return selectedLocation?.id === locationId ? classes.activeRow : '';
    },
    [selectedLocation]
  );
  return (
    <li
      onClick={() => handleClick(item)}
      className={clsx(
        'mx-2',
        'px-2',
        'py-3',
        'd-flex',
        'justify-content-between',
        'align-items-center',
        isSelectedLocation(item.id),
        focused && classes.focusedRow
      )}>
      <div>
        {item.title} <span className={classes.type}>- {item.targetTitle}</span>
      </div>
      {!!isSelectedLocation(item.id) && <TickIcon className={classes.tickIcon} />}
    </li>
  );
};

export default SearchItem;
