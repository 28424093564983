import React, { FC, useCallback, useState } from 'react';
import classes from './PaymentForm.module.scss';
import { clsx } from 'utils/clsx';
import { Button } from 'components';
import lockIcon from 'assets/icons/lock.svg';
import { useTranslation } from 'react-i18next';
import { PaymentType, Plans } from '../SubscriptionsAndPlans/types';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { options } from './StripeOptions';
import { REQUIRES_ACTION, SUCCEEDED } from './constants';
import { PaymentMethodResult } from '@stripe/stripe-js/types/stripe-js/stripe';

interface IProps {
  direction: 'row' | 'column';
  type: PaymentType;
  onPurchase: () => void;
}

const PaymentForm: FC<IProps> = observer(({ direction, type, onPurchase }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { subscriptionAndPlansStore, alertStore } = useStore();
  const [formComplete, setFormComplete] = useState<boolean>(false);

  const paymentData = subscriptionAndPlansStore.paymentData;

  const processingAfterPayment = async (): Promise<void> => {
    if (paymentData && paymentData.location) {
      const hasSubscription = await subscriptionAndPlansStore.checkHasSubscriptionToLocation(
        paymentData.location.id
      );
      if (hasSubscription) {
        alertStore.successAlert(t('choosePlan.successfulSubscription'));
        subscriptionAndPlansStore.setPaymentData(null);
        onPurchase();
      } else {
        alertStore.errorAlert('Error');
      }
    }
  };

  const handlePurchase = async () => {
    if (elements === null) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (stripe && cardElement && paymentData && formComplete) {
      subscriptionAndPlansStore.setPaymentProcessing(true);
      let paymentMethodResult: PaymentMethodResult | null = null;
      if (type === PaymentType.UPGRADE) {
        paymentMethodResult = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement
        });
        if (paymentMethodResult.error && paymentMethodResult.error.message) {
          alertStore.errorAlert(paymentMethodResult.error.message);
          return;
        }
      }
      try {
        const paymentCustomer = await subscriptionAndPlansStore.createSubscriptionToLocation(
          paymentData.id,
          [paymentData.location.id],
          paymentMethodResult && paymentMethodResult.paymentMethod
            ? paymentMethodResult.paymentMethod.id
            : null
        );
        if (!paymentCustomer) return;
        const result = await stripe.confirmCardPayment(
          paymentCustomer.clientSecret,
          {
            payment_method: {
              card: cardElement
            }
          },
          { handleActions: false }
        );
        if (result.error?.payment_intent && result.error?.payment_intent.status === SUCCEEDED) {
          await processingAfterPayment();
          return;
        }
        if (result.error && result.error.message) {
          await subscriptionAndPlansStore.removeIncompleteSubscription(paymentCustomer.id);
          alertStore.errorAlert(result.error.message);
        } else {
          if (result.paymentIntent && result.paymentIntent.status === REQUIRES_ACTION) {
            const result = await stripe.confirmCardPayment(paymentCustomer.clientSecret, {
              payment_method: {
                card: cardElement
              }
            });
            if (result.error && result.error.message) {
              await subscriptionAndPlansStore.removeIncompleteSubscription(paymentCustomer.id);
              alertStore.errorAlert(result.error.message);
              return;
            } else {
              await processingAfterPayment();
              return;
            }
          }
          await processingAfterPayment();
        }
      } finally {
        subscriptionAndPlansStore.setPaymentProcessing(false);
      }
    }
  };

  const getPrice = useCallback((): string => {
    if (paymentData) {
      return (paymentData.price / 100).toFixed(2);
    }
    return '';
  }, [paymentData]);

  if (!paymentData) {
    return null;
  }

  return (
    <div
      className={clsx(
        classes.payment,
        'default-shadow',
        'bg-white',
        'p-4',
        'd-flex',
        'justify-content-between',
        direction === 'column' && 'flex-column'
      )}>
      <div className="flex-grow-1">
        <h2 className={classes.paymentTitle}>{t('choosePlan.paymentDetails')}</h2>
        <div className="position-relative">
          <form id="payment-form">
            <CardElement
              options={options}
              className={clsx(classes.paymentForm, 'px-2 py-3')}
              onChange={(event) => setFormComplete(event.complete)}
            />
          </form>
        </div>
        {direction === 'row' && (
          <div className={clsx(classes.formLabel, 'mt-2', 'd-flex', 'align-items-center')}>
            <img src={lockIcon} alt="" className="me-1" />
            {t('choosePlan.securePayment')}
          </div>
        )}
      </div>
      <div className={clsx(direction === 'row' ? clsx(classes.paymentInfo, 'ms-3') : 'mt-4')}>
        <h2 className={classes.paymentTitle}>{t('choosePlan.summary')}</h2>
        <div className={classes.price}>
          {paymentData.name}: {paymentData.location ? paymentData.location.title : ''}
        </div>
        <div className={classes.price}>
          <span className={classes.paymentAccent}>${getPrice()}</span>{' '}
          {paymentData.period === Plans.MONTH
            ? t('choosePlan.billedMonthly')
            : t('choosePlan.billedAnnually')}
          {direction === 'column' && (
            <div className={clsx(classes.current, 'mb-2')}>{t('upgradePlan.subscription')}</div>
          )}
        </div>

        {!subscriptionAndPlansStore.paymentProcessing && (
          <Button
            type="primary"
            onClick={handlePurchase}
            className={clsx(
              'px-3',
              'mt-2',
              classes.paymentButton,
              'opacity-100',
              'text-white',
              direction === 'row' ? classes.columnPaymentBtn : 'w-100'
            )}
            disabledClass={classes.disabledPaymentButton}
            disabled={!stripe || !elements || !formComplete}
            data-test-element="purchase-button">
            <b className="fw-normal">{t('choosePlan.purchase')}</b>
          </Button>
        )}
        {subscriptionAndPlansStore.paymentProcessing && (
          <div className="w-100 d-flex justify-content-center">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div
          className={clsx(
            classes.formLabel,
            'mt-2',
            'text-end',
            direction === 'column' && 'd-flex',
            direction === 'column' && 'justify-content-between'
          )}>
          {direction === 'column' && (
            <div className={clsx(classes.formLabel, 'd-flex', 'align-items-center')}>
              <img src={lockIcon} alt="" className="me-1" />
              {t('choosePlan.securePayment')}
            </div>
          )}
          <div>
            {paymentData.period === Plans.MONTH
              ? t('choosePlan.RenewMonth')
              : t('choosePlan.RenewYear')}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PaymentForm;
