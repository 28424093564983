import { IRow, Order } from 'components/Table/types';
import { IAxiosResponse, ObjectValues } from 'utils/types';
import { ILocation } from 'view/SearchProjects/types';

export const SAVED_SEARCH_RESULTS_REPO = 'SAVED_SEARCH_RESULTS_REPO';
export interface ISavedSearchResultsRepo {
  getDashboardData(
    userId: number,
    ids: ObjectValues<number>,
    page: number,
    orderParams: ObjectValues<string>
  ): Promise<IAxiosResponse<ISavedSearchResponse<ISavedSearchResultsProject[]>>>;
}

export const SAVED_SEARCH_RESULTS_SERVICE = 'SAVED_SEARCH_RESULTS_SERVICE';
export interface ISavedSearchResultsService {
  getDashboardData(
    userId: number,
    ids: number[],
    page: number,
    orders: Order[]
  ): Promise<ISavedSearchResponse<IRow[]>>;
}

export interface ISavedSearchResultsStore {
  getDashboardData(userId: number, ids: number[], page: number, orders: Order[]): void;
  totalPages: number;
  tableRows: IRow[];
}

export type Detail = {
  type: number;
  value: string;
};

export type Contacts = {
  id: number;
  type: number;
  name: string;
  details: Detail[];
  role: number;
};

export interface ISavedSearchResponse<T> {
  count: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  data: T;
}

export type Status = {
  id: number;
  name: string;
  color: string;
};

export interface ISavedSearchResultsProject {
  id: number;
  hasNotes: boolean;
  class: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  description: string;
  subType: {
    id: number;
    name: string;
  };
  status: Status;
  createdAt: string;
  value: number;
  location: ILocation;
  contacts: Contacts[];
  new: boolean;
  favourite: boolean;
}
