import { IEditProfileData } from './Settings/types';
import { IAxiosResponse } from 'utils/types';

export type UserData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatar?: IMedia;
  phone?: string;
  language?: string;
  timeZone?: string;
};

export interface IUserStore {
  user: UserData | null;
  getCurrentUser(): Promise<void>;
  editUserPassword(data: IChangePasswordData): Promise<void>;
  editUserInfo(data: IEditProfileData): Promise<void>;
  editUserEmail(data: IChangeEmailData): Promise<void>;
  confirmEmailToken(token: string): Promise<IChangeEmailResponse>;
  setUser(user: UserData | null): void;
  setAvatar(avatar: File): Promise<void>;
}

export const USER_SERVICE = 'USER_SERVICE';
export interface IUserService {
  getCurrentUser(): Promise<UserData>;
  editUserPassword(data: IChangePasswordData): Promise<void>;
  editUserInfo(data: IEditProfileData): Promise<UserData>;
  setAvatar(avatar: File): Promise<IMedia>;
  editUserEmail(data: IChangeEmailData): Promise<void>;
  confirmEmailToken(token: string): Promise<IChangeEmailResponse>;
}

export const USER_REPO = 'USER_REPO';
export interface IUserRepo {
  getCurrentUser(): Promise<IAxiosResponse<UserData>>;
  editUserPassword(data: IChangePasswordData): Promise<void>;
  editUserInfo(data: IEditProfileData): Promise<IAxiosResponse<UserData>>;
  setAvatar(data: FormData): Promise<IAxiosResponse<IMedia>>;
  editUserEmail(data: IChangeEmailData): Promise<void>;
  confirmEmailToken(token: string): Promise<IAxiosResponse<IChangeEmailResponse>>;
}

export interface IChangePasswordData {
  currentPassword: string;
  newPassword: string;
}

export type SubscriptionTableType = 'upgrade' | 'cancel' | 'renew';
export type MediaType = 'avatar';

export interface IMedia {
  userId: number;
  type: MediaType;
  hash: string;
  extension: string;
}
export interface IChangeEmailData {
  email: string;
}

export interface IChangeEmailResponse {
  token: string;
}
