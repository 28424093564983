import React, { FC } from 'react';
import { IIncreaseCard } from '../types';
import classes from '../IncreasePipeline.module.scss';
import { clsx } from 'utils/clsx';

const IncreaseCard: FC<IIncreaseCard> = ({ title, subTitle, Icon, color }) => {
  return (
    <div
      className={clsx(
        classes.cardWrapper,
        'bg-white',
        'd-flex',
        'flex-column',
        'align-items-center'
      )}
      style={{ borderBottomColor: color }}>
      <div className="mb-3 pb-2">
        <Icon />
      </div>
      <h4 className={clsx('text-center', 'mb-3', classes.cardTitle)}>{title}</h4>
      <p className={clsx('text-center', 'm-0', 'mt-auto', classes.subTitle)}>{subTitle}</p>
    </div>
  );
};

export default IncreaseCard;
