import React, { FC, useCallback, useEffect } from 'react';
import { IColumn, IRow, ICell, IAction, Order } from './types';
import { ColumnHeader } from './components/ColumnHeader';
import { Cell } from './components/Cell';
import { iconActiveList, iconList } from 'utils/icons';
import { clsx } from 'utils/clsx';
import classes from './Table.module.scss';
import { Tooltip } from 'bootstrap';
import { DOCUMENT, DOCUMENT_GREY, FAVORITE } from 'utils/constants';

interface Props {
  columns: IColumn[];
  rows: IRow[];
  orders?: Order[];
  actions?: IAction[];
  orderAction?: (field: string) => void;
  onScroll?: () => void;
  children?: JSX.Element;
  tableClass?: string;
  cellClass?: string;
  onTitleClick?: (id: number) => void;
}

const Table: FC<Props> = ({
  columns,
  rows,
  orders,
  actions,
  orderAction,
  onScroll,
  children,
  tableClass,
  cellClass,
  onTitleClick
}) => {
  useEffect(() => {
    Array.from(document.querySelectorAll('img[data-bs-toggle="tooltip"]')).forEach(
      (tooltipNode) => new Tooltip(tooltipNode)
    );
  }, []);

  const getIcon = useCallback((icon: string, row: IRow): string => {
    if (icon === FAVORITE && row.favorite) {
      return iconActiveList[icon];
    }
    return iconList[icon];
  }, []);

  return (
    <div className="position-relative">
      <div className={clsx(classes.wrapperTable, tableClass)} onScroll={onScroll}>
        <table className={clsx(classes.table, 'table', 'table-borderless', 'mb-0')}>
          <thead className={classes.tableHead}>
            <tr>
              {columns.map((column: IColumn, i: number) => (
                <th scope="col" key={`table-column-${i}`} className={classes.tableHeadCell}>
                  <ColumnHeader column={column} orders={orders} sortAction={orderAction} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {rows.map((row: IRow) => (
              <tr key={`table-row-${row.id}`} className="border-top border-light">
                {row.fields.map((fieldItem: ICell, index: number) => (
                  <td key={`cell-${index}`} className={`${classes.cell} py-4`}>
                    {!index && row.newItem && (
                      <div className="rounded-circle position-absolute new-notification"></div>
                    )}
                    <Cell
                      data={fieldItem}
                      cellClass={cellClass}
                      onTitleClick={
                        fieldItem.isTitle && onTitleClick
                          ? () => onTitleClick(Number(row.id))
                          : null
                      }
                    />
                  </td>
                ))}
                {actions && (
                  <td className={clsx(classes.actionCell, 'py-4', 'float-end', 'me-3')}>
                    {actions.map((action: IAction, index: number) => (
                      <span
                        className={clsx('position-relative', classes.actionButton)}
                        key={`action-icon-${index}`}>
                        <img
                          src={getIcon(action.icon, row)}
                          id={`table-action-${action.icon}-${row.id}`}
                          alt=""
                          className={'position-relative'}
                          onClick={() => action.callback(Number(row.id))}
                          role="button"
                          data-test-element="favorite-icon"
                        />
                        {(action.icon === DOCUMENT || action.icon === DOCUMENT_GREY) &&
                          row.hasNote && (
                            <span
                              className={clsx(
                                classes.noteMarker,
                                'position-absolute',
                                'top-0',
                                'start-0',
                                'rounded-circle',
                                'translate-middle-x'
                              )}
                            />
                          )}
                      </span>
                    ))}
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td colSpan={columns.length}>{children}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.shadow}></div>
    </div>
  );
};

export default Table;
