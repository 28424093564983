import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { clsx } from 'utils/clsx';
import moment from 'moment';
import classes from 'components/Table/Table.module.scss';
import subClasses from '../Subscription.module.scss';
import { ICell, IColumn } from 'components/Table/types';
import { ColumnHeader } from 'components/Table/components/ColumnHeader';
import { Button } from 'components';
import { SubscriptionTableType } from '../../types';
import { CANCEL, GREY_COLOR, RENEW, UPGRADE } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { ISubscriptionRow, SubscriptionStatus } from 'view/SubscriptionsAndPlans/types';
import { useStore } from 'storesProvider/storeContext';
import { axiosInstance } from 'utils/API';

interface IProps {
  columns: IColumn[];
  rows: ISubscriptionRow[];
  action: (id: number, type: SubscriptionTableType) => void;
  saveBtnShow: boolean;
  children?: JSX.Element;
  tableClass?: string;
}

const SubscriptionTable: FC<IProps> = ({
  columns,
  rows,
  action,
  saveBtnShow,
  children,
  tableClass
}) => {
  const { t } = useTranslation();
  const { subscriptionAndPlansStore } = useStore();

  // TODO: remove this code after testing
  const isShowcase =
    process.env.REACT_APP_API_URL === 'https://radar-showcase.dev.dnc.pp.ua/api/v1';
  const isDev = process.env.REACT_APP_API_URL === 'https://radar.dev.dnc.pp.ua/api/v1';

  // TODO: remove this code after testing
  const expired = async (id: number): Promise<void> => {
    await axiosInstance.get(`subscriptions/${id}/overdue`);
  };

  const getActions = useCallback((row: ISubscriptionRow): ReactNode => {
    const actions = {
      [SubscriptionStatus.UNDEFINED]: <></>,
      [SubscriptionStatus.ACTIVE]: (
        <>
          {saveBtnShow && (
            <Button
              type="primary"
              className={clsx('px-4 py-2', subClasses.tableBtn)}
              onClick={() => action(Number(row.id), UPGRADE)}>
              <span className="font-kraftig">{t('profile.subscription.save')}</span>
            </Button>
          )}
          <Button
            type="light"
            color={GREY_COLOR}
            className={clsx(subClasses.tableBtn, 'px-4 font-size-14')}
            onClick={() => action(Number(row.id), CANCEL)}>
            <span className={subClasses.cancelBtn}>{t('profile.subscription.cancel')}</span>
          </Button>
        </>
      ),
      [SubscriptionStatus.PAST_DUE]: pending,
      [SubscriptionStatus.UNPAID]: renewBtn(Number(row.id)),
      [SubscriptionStatus.CANCELED]: renewBtn(Number(row.id)),
      [SubscriptionStatus.INCOMPLETE]: pending,
      [SubscriptionStatus.INCOMPLETE_EXPIRED]: renewBtn(Number(row.id)),
      [SubscriptionStatus.TRIALING]: <></>,
      [SubscriptionStatus.RENEW_INCOMPLETE]: renewBtn(Number(row.id))
    };

    return actions[row.status] ? actions[row.status] : <></>;
  }, []);

  const pending = useMemo<ReactNode>(() => {
    return <p>Pending...</p>;
  }, []);

  const renewBtn = useCallback((id: number): ReactNode => {
    const endDate = moment(subscriptionAndPlansStore.getSubscriptionById(id)?.endsAt);
    const currentDate = moment().utc();
    const daysLeft = endDate.diff(currentDate, 'days');
    return (
      <Button
        type="primary"
        className={clsx('px-4 py-2', subClasses.tableBtn)}
        onClick={() => action(id, RENEW)}
        disabled={daysLeft > 0}>
        {t('profile.subscription.renew')}
      </Button>
    );
  }, []);

  return (
    <div className="position-relative">
      <div className={clsx(classes.wrapperTable, tableClass)}>
        <table className={clsx(classes.table, 'table', 'table-borderless', 'mb-0')}>
          <thead className={classes.tableHead}>
            <tr>
              {columns.map((column: IColumn, i: number) => (
                <th scope="col" key={`table-column-${i}`} className={classes.tableHeadCell}>
                  <ColumnHeader column={column} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: ISubscriptionRow) => (
              <tr key={`table-row-${row.id}`} className="border-top border-light">
                {row.fields.map((fieldItem: ICell, index: number) => (
                  <td key={`cell-${index}`} className={`${classes.cell} py-4`}>
                    {fieldItem.status && (
                      <span
                        className={clsx(classes.status, 'd-block', 'py-1', 'px-2')}
                        style={{
                          color: fieldItem.status.color
                        }}>
                        {fieldItem.status.name}
                      </span>
                    )}
                    {fieldItem.value && (
                      <>
                        {fieldItem.label && (
                          <label className={clsx(subClasses.label, 'font-size-14')}>
                            {fieldItem.label}
                          </label>
                        )}
                        <div className={clsx(subClasses.cell, 'd-flex', 'align-items-center')}>
                          <h6 className="m-0">{fieldItem.value}</h6>
                        </div>
                      </>
                    )}
                  </td>
                ))}
                <td className={clsx(subClasses.actionCell, 'py-4', 'float-end', 'me-3')}>
                  <div className="d-flex justify-content-end align-items-center position-relative">
                    {getActions(row)}
                    {/*TODO: remove this btn after testing*/}
                    {isShowcase ||
                      (isDev && (
                        <button
                          className="position-absolute"
                          onClick={() => expired(Number(row.id))}>
                          Expired
                        </button>
                      ))}
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={columns.length}>{children}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.shadow}></div>
    </div>
  );
};

export default SubscriptionTable;
