import React, { useCallback, useEffect, useRef, useState, FC, useMemo } from 'react';
import classes from 'components/Select/Select.module.scss';
import styles from '../SubHeader.module.scss';
import { clsx } from 'utils/clsx';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import { IOption } from 'components/Select/types';
import { DateRange } from 'react-date-range';
import { dateOptions } from 'view/SearchProjects/mock';
import { useTranslation } from 'react-i18next';
import { ICustomDate, IDateChangeEvent } from '../types';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { CUSTOM } from '../constants';
import { ENTER } from 'components/SearchList/constants';
import { childOf } from 'helper/childOf';

interface IProps {
  value: IOption | null;
  customValue: ICustomDate | null;
  onChange: (value: IOption, name: string) => void;
  onCustomChange: (date: ICustomDate) => void;
  onReset: () => void;
}

const DateDropdown: FC<IProps> = observer(
  ({ value, customValue, onChange, onReset, onCustomChange }) => {
    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
    const [dateState, setDateState] = useState([
      {
        startDate: customValue?.from ? new Date(customValue?.from) : null,
        endDate: customValue?.to ? new Date(customValue?.to) : null,
        key: 'selection'
      }
    ]);
    const selectRef = useRef<HTMLDivElement | null>(null);
    const childRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      setDateState([
        {
          startDate: customValue ? new Date(customValue.from) : null,
          endDate: customValue ? new Date(customValue.to) : null,
          key: 'selection'
        }
      ]);
    }, [customValue]);

    const toggleOpened = useCallback((e: React.MouseEvent): void => {
      const value = (e.target as HTMLElement).dataset.value;
      const { userStore } = useStore();

      if (
        (value === CUSTOM ||
          (childRef.current && e.nativeEvent.composedPath().includes(childRef.current))) &&
        userStore.user
      )
        return;
      setIsOpened((prev) => {
        if (prev) {
          setDatePickerVisible(false);
        }
        return !prev;
      });
    }, []);

    const clickOutside = (e: MouseEvent): void => {
      if (selectRef.current && !e.composedPath().includes(selectRef.current)) {
        setIsOpened(false);
        setDatePickerVisible(false);
      }
    };

    const handleReset = useCallback((event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsOpened(false);
      setDatePickerVisible(false);
      onReset();
    }, []);

    const handleSelectChange = (option: IOption, name: string) => {
      if (option.value === CUSTOM) {
        setDatePickerVisible((prev) => !prev);
      } else setDatePickerVisible(false);

      onChange(option, name);
    };

    const handleDateChange = useCallback((changeDateEvent: IDateChangeEvent) => {
      setDateState([changeDateEvent.selection]);
      onCustomChange({
        from: changeDateEvent.selection.startDate.getTime(),
        to: changeDateEvent.selection.endDate.getTime()
      });
    }, []);

    const handleFocusChange = useCallback((e: number[]) => {
      if (e.filter((item) => !item).length === 2) {
        setIsOpened(false);
      }
    }, []);

    const keyPress = useCallback(
      (e: KeyboardEvent): void => {
        if (e.code === ENTER) {
          if (
            !childOf(document.activeElement, selectRef.current) &&
            document.activeElement !== selectRef.current
          )
            setIsOpened(false);
          if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
        }
      },
      [isOpened]
    );

    const handlePressEnter = useCallback(
      (e: React.KeyboardEvent, option: IOption) => {
        if (e.code === ENTER && document.activeElement === e.target) {
          handleSelectChange(option, 'date');
          if (option.value !== CUSTOM) setIsOpened(false);
        }
      },
      [handleSelectChange]
    );

    const handleResetPressEnter = useCallback((e: React.KeyboardEvent) => {
      if (e.code === ENTER && document.activeElement === e.target) {
        setIsOpened(false);
        setDatePickerVisible(false);
        onReset();
      }
    }, []);

    const isActive = useMemo<boolean>(() => {
      return !!value || !!customValue;
    }, [value, customValue]);

    useEffect(() => {
      window.addEventListener('keydown', keyPress);
      if (!isOpened)
        return () => {
          window.removeEventListener('keydown', keyPress);
        };
      document.body.addEventListener('click', clickOutside);
      return () => {
        document.body.removeEventListener('click', clickOutside);
        window.removeEventListener('keydown', keyPress);
      };
    }, [isOpened]);

    return (
      <>
        <div
          className={clsx(
            classes.select,
            'position-relative',
            'd-flex',
            'align-items-center',
            'justify-content-between',
            isOpened && classes.activeSelect,
            styles.searchSelect,
            'me-2',
            isActive && styles.active
          )}
          tabIndex={0}
          data-test-element="search-projects-date-dropdown"
          role="button"
          onClick={toggleOpened}
          ref={selectRef}>
          <>
            <span className={clsx('d-block w-100', styles.text, 'font-kraftig')}>
              {value?.text || t('searchProject.date')}
            </span>
            &nbsp;
            {isOpened && (
              <div
                className={clsx(
                  classes.optionWrapper,
                  styles.dateWrapper,
                  'position-absolute',
                  'bg-white',
                  'd-flex',
                  'p-2'
                )}>
                <div className="w-100">
                  {dateOptions.map((option, idx) => (
                    <div
                      key={idx}
                      data-value={option.value}
                      className={clsx(
                        classes.option,
                        styles.dateOption,
                        'd-flex',
                        'default-color-text',
                        'justify-content-between',
                        'align-items-center',
                        idx !== dateOptions.length - 1 && 'mb-2',
                        value?.id === option.id && classes.active
                      )}
                      tabIndex={0}
                      onKeyDown={(e) => handlePressEnter(e, option)}
                      onClick={() => handleSelectChange(option, 'date')}>
                      {option.text}
                      {value?.id === option.id && (
                        <TickIcon data-value={option.value} className={classes.tickIcon} />
                      )}
                    </div>
                  ))}
                </div>
                <div ref={childRef}>
                  <div className={clsx(!datePickerVisible && styles.w0)}>
                    <DateRange
                      onChange={handleDateChange}
                      onRangeFocusChange={handleFocusChange}
                      months={1}
                      minDate={new Date(1900, 0, 1)}
                      maxDate={new Date()}
                      ranges={dateState}
                      showDateDisplay={false}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
          {value ? (
            <div onClick={handleReset} className="d-flex align-items-center">
              <CloseIcon
                className={clsx(classes.resetIcon, isOpened && classes.resetOpened)}
                tabIndex={0}
                onKeyDown={handleResetPressEnter}
              />
            </div>
          ) : (
            <div
              className={clsx(
                classes.icon,
                'position-absolute',
                'top-50',
                'd-flex',
                'align-items-center',
                isOpened && classes.active
              )}>
              <ArrowIcon />
            </div>
          )}
        </div>
      </>
    );
  }
);

export default DateDropdown;
