import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'storesProvider/storeContext';
import ContactList from '../ContactList';
import { FavoriteSubTypes } from 'view/AddToFavorites/types';
import { observer } from 'mobx-react';
import { ProjectList } from 'components';
import { IPageClickEvent } from 'view/ProjectInfoModal/types';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { Location } from '../../types';

interface IProps {
  onLoadMore: () => void;
  isLoading: boolean;
  closeFavorite: () => void;
  moreProjects: (page: number) => void;
  onLoadProjectInfo: () => void;
  handleUpdateProjects: () => void;
}

const RecentProjects: FC<IProps> = observer(
  ({
    onLoadMore,
    isLoading,
    closeFavorite,
    moreProjects,
    onLoadProjectInfo,
    handleUpdateProjects
  }) => {
    const { projectSearchStore } = useStore();

    const [activeId, setActiveId] = useState<null | number>(null);
    const [showPlan, setShowPlan] = useState<boolean>(false);
    const [planLocation, setPlanLocation] = useState<Location | null>(null);

    const onSelectItem = useCallback((id: number) => {
      setActiveId((prev) => (prev === id ? null : id));
    }, []);

    const closeList = useCallback(() => setActiveId(null), []);

    const handleUnlock = useCallback((location: Location | null) => {
      setShowPlan(true);
      setPlanLocation(location);
    }, []);

    useEffect(() => {
      setActiveId(null);
    }, [projectSearchStore.projectsData]);

    const onClickMore = useCallback((): void => {
      setActiveId(null);
      onLoadMore();
    }, []);

    const handlePageClick = ({ page }: IPageClickEvent) => {
      moreProjects(page);
    };

    const onUpdateProjects = useCallback(() => {
      handleUpdateProjects();
      closeList();
    }, [handleUpdateProjects]);

    const contactsLocation = useMemo<Location | null>(() => {
      return (
        projectSearchStore.recentProjects.data.find((project) => project.id === activeId)?.location
          .county || null
      );
    }, [activeId]);

    const isContactsLocked = useMemo<boolean>(() => {
      if (!activeId) return true;
      const index = projectSearchStore.recentProjects.data.findIndex(
        (item) => item.id === activeId
      );
      return !projectSearchStore.recentProjects.data[index].hasFullData;
    }, [activeId]);

    return (
      <>
        <div className="position-relative">
          <ProjectList
            isLoading={isLoading}
            onPageClick={handlePageClick}
            onTitleClick={onLoadProjectInfo}
            onContactsClick={onSelectItem}
            projectsData={projectSearchStore.recentProjects}
            type="recentProjects"
            activeId={activeId}
            onListScroll={closeFavorite}
            itemSize={185}
            onUpdateProjects={onUpdateProjects}
          />
          {activeId && (
            <ContactList
              type={FavoriteSubTypes.recent}
              projectId={activeId}
              position="left"
              onLoadMore={onClickMore}
              closeList={closeList}
              handleUnlock={() => handleUnlock(contactsLocation)}
              isLocked={isContactsLocked}
            />
          )}

          <ChoosePlanModal
            show={showPlan}
            location={planLocation}
            closeModal={() => setShowPlan(false)}
            onUpdate={handleUpdateProjects}
          />
        </div>
      </>
    );
  }
);

export default RecentProjects;
