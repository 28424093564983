import { IOptionsConfig } from '../../../types';
import { useStore } from 'storesProvider/storeContext';
import { useMemo } from 'react';

export const getConfigs = (): IOptionsConfig => {
  const { configStore } = useStore();

  return useMemo(
    () => ({
      projectClasses: configStore.enums?.propertySubTypes
        ? configStore.enums.propertyClasses.map((config) => ({
            value: String(config.id),
            text: config.name,
            id: config.id
          }))
        : [],
      projectStatuses: configStore.enums?.projectStatuses
        ? configStore.enums.projectStatuses.map((config) => ({
            value: String(config.id),
            text: config.name,
            id: config.id
          }))
        : [],
      projectTypes: configStore.enums?.propertyTypes
        ? configStore.enums.propertyTypes.map((config) => ({
            value: String(config.id),
            text: config.name,
            id: config.id
          }))
        : []
    }),
    []
  );
};
