import React, { FC, useCallback, useState, ReactNode, useRef, useId } from 'react';
import classes from './Input.module.scss';
import { clsx } from 'utils/clsx';
import { ReactComponent as SearchIcon } from 'assets/icons/search-black.svg';

interface IProps {
  type: string;
  label?: string;
  value: string;
  placeholder?: string;
  onInput: (name: string, val: string) => void;
  onBlur?: () => void;
  isError?: boolean;
  errorText?: string;
  children?: ReactNode;
  name: string;
  inputClass?: string;
  searchIcon?: boolean;
  [key: string]: unknown;
}

const Input: FC<IProps> = ({
  type,
  label,
  value,
  placeholder,
  onInput,
  isError,
  errorText,
  children,
  name,
  inputClass,
  searchIcon,
  onBlur,
  ...rest
}: IProps) => {
  const id = useId();

  const inputRef = useRef({} as HTMLInputElement);

  const [inputType, setInputType] = useState<string>(type);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const onInputChange = useCallback((event: React.SyntheticEvent): void => {
    onInput(name, (event.target as HTMLInputElement).value);
  }, []);

  const toggleType = useCallback((): void => {
    setInputType((prev: string) => (prev === 'password' ? 'text' : 'password'));
    handleInputFocus();
  }, []);

  const handleInputFocus = useCallback((): void => {
    inputRef.current.focus();
  }, []);

  const displayIconInInput = useCallback((): boolean => {
    if (searchIcon) return inputFocus || !!value;
    return false;
  }, [inputFocus, value]);

  const handleBlur = useCallback(() => {
    setInputFocus(false);
    onBlur && onBlur();
  }, [onBlur]);

  const handleFocus = useCallback(() => setInputFocus(true), []);

  return (
    <div className="w-100">
      {label && (
        <label htmlFor={id} className={classes.label}>
          {label}
        </label>
      )}
      <div className="position-relative" onClick={() => setInputFocus(true)}>
        <input
          type={inputType}
          id={id}
          className={clsx(
            classes.input,
            isError && classes.inputError,
            inputClass,
            'form-control',
            'position-relative',
            'py-2',
            searchIcon && 'ps-5'
          )}
          aria-describedby="passwordHelpBlock"
          value={value}
          onInput={onInputChange}
          placeholder={placeholder}
          ref={inputRef}
          role="button"
          name={name}
          onBlur={handleBlur}
          onFocusCapture={handleFocus}
          {...rest}
        />
        {children && !value.length && (
          <span
            className={clsx(
              classes.inputOverlay,
              'position-absolute',
              'top-50',
              'start-50',
              'translate-middle',
              'w-100',
              'd-flex',
              'align-items-center'
            )}
            onClick={handleInputFocus}
            role="button">
            {children}
          </span>
        )}

        {displayIconInInput() && (
          <SearchIcon
            className={clsx(
              classes.searchIcon,
              'position-absolute',
              inputFocus ? classes.searchIconDark : classes.searchIconLight
            )}
          />
        )}

        {/*TODO: update to react component icon*/}
        {type === 'password' && (
          <svg
            className={clsx('position-absolute top-50 end-0 translate-middle', classes.icon)}
            onClick={toggleType}
            role="button"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.7945 14.0581C19.9789 16.3398 16.7112 19.3 12.0001 19.3C7.28889 19.3 4.02128 16.3398 2.20558 14.0581C1.23265 12.8355 1.23265 11.1645 2.20558 9.94187C4.02128 7.6602 7.28889 4.7 12.0001 4.7C16.7112 4.7 19.9789 7.6602 21.7946 9.94187C22.7675 11.1645 22.7675 12.8355 21.7945 14.0581Z"
              fill="white"
              stroke="#2F3139"
              strokeWidth="1.4"
              className={clsx(inputType === 'text' && classes.filledEye)}
            />
            <circle
              cx="12"
              cy="12"
              r="2"
              fill="#2F3139"
              className={clsx(inputType === 'text' && classes.filledCircle)}
            />
          </svg>
        )}
        {isError && (
          <span className={clsx(classes.errorText, 'position-absolute')}>{errorText}</span>
        )}
      </div>
    </div>
  );
};

export default Input;
