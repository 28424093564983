import React, { FC } from 'react';
import { clsx } from 'utils/clsx';
import classes from './Alert.module.scss';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { Alert } from './components/Alert';

const Alerts: FC = observer(() => {
  const { alertStore } = useStore();

  return (
    <div className={clsx(classes.alertWrapper, 'position-fixed', 'start-50', 'translate-middle-x')}>
      {alertStore.alerts.map((alert, i) => (
        <Alert
          key={`alert-${i}`}
          text={alert.text}
          type={alert.type}
          closeAlert={() => alertStore.closeAlert(i)}
        />
      ))}
    </div>
  );
});

export default Alerts;
