import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from './UpgradePlanModal.module.scss';
import closeIcon from 'assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStore } from 'storesProvider/storeContext';
import { conversionMonthPrice, conversionPrice } from '../helper';
import { Skeleton } from 'components';
import { PaymentType, Plans } from '../types';
import Payment from 'view/PaymentForm';
import { ALL_COUNTRIES } from 'utils/constants';
import { Location } from 'view/SubscriptionsAndPlans/types';

interface IProps {
  show: boolean;
  type: PaymentType;
  location: Location | null;
  subscriptionId: number | null;
  closeModal: () => void;
  onUpdate?: () => void;
}

const UpgradePlanModal: FC<IProps> = observer(
  ({ show, type, location, subscriptionId, closeModal, onUpdate }) => {
    const { t } = useTranslation();
    const { subscriptionAndPlansStore, userStore } = useStore();
    const [loadPlans, setLoadPlans] = useState<boolean>(false);

    useEffect(() => {
      if (show) {
        (async () => {
          setLoadPlans(true);
          await initProductForModal();
          setLoadPlans(false);
        })();
      }
      return () => {
        subscriptionAndPlansStore.setUpgradeRenewProduct(null);
        subscriptionAndPlansStore.setPaymentData(null);
      };
    }, [show]);

    useEffect(() => {
      const product = subscriptionAndPlansStore.upgradeRenewProduct;
      if (product) {
        subscriptionAndPlansStore.createPaymentData(product, location);
      }
    }, [subscriptionAndPlansStore.upgradeRenewProduct]);

    const initProductForModal = useCallback(async (): Promise<void> => {
      if (subscriptionId && userStore.user) {
        await subscriptionAndPlansStore.initProductForUpgradeOrRenewModal(
          type,
          subscriptionId,
          userStore.user.id
        );
      }
    }, [subscriptionId, type]);

    const clickOutside = useCallback((e: MouseEvent) => {
      if (e.target === e.currentTarget && !subscriptionAndPlansStore.paymentProcessing)
        closeModal();
    }, []);

    const getPrice = useCallback((): string => {
      const product = subscriptionAndPlansStore.upgradeRenewProduct;
      if (product) {
        if (type === PaymentType.RENEW && product.period === Plans.MONTH) {
          return `$${conversionPrice(product.price)}`;
        }
        if (
          (type === PaymentType.RENEW && product.period === Plans.YEAR) ||
          type === PaymentType.UPGRADE
        ) {
          return `$${conversionMonthPrice(product.price)}`;
        }
      }
      return '';
    }, [type, subscriptionAndPlansStore.upgradeRenewProduct]);

    const getDiscount = useCallback((): string => {
      const product = subscriptionAndPlansStore.upgradeRenewProduct;
      if (product && product.discount) {
        if (type === PaymentType.UPGRADE) {
          return `$${conversionPrice(product.discount)} ${t('upgradePlan.saved')}`;
        }
      }
      return '';
    }, [type]);

    const getTitle = (): string => {
      const product = subscriptionAndPlansStore.upgradeRenewProduct;
      if (product) {
        if (product.unlimited) {
          return ALL_COUNTRIES;
        } else {
          return location ? location.title : product.title;
        }
      }
      return '';
    };

    const handlePurchase = (): void => {
      if (!onUpdate) {
        return;
      }

      onUpdate();
      closeModal();
    };

    return (
      <div
        className={clsx(
          'position-fixed',
          'top-0',
          'bottom-0',
          'start-0',
          'end-0',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          show ? 'd-block' : 'd-none',
          classes.modal
        )}
        onClick={clickOutside}>
        <div className={clsx(classes.body, 'position-relative')}>
          <div
            className={clsx(classes.close, 'position-absolute')}
            role="button"
            onClick={closeModal}>
            <img src={closeIcon} alt="" />
          </div>
          <h1 className={clsx(classes.title, 'text-center', 'mb-3')}>
            {type === PaymentType.UPGRADE
              ? t('upgradePlan.title')
              : t('profile.subscription.renew')}
          </h1>
          {loadPlans && (
            <div className="d-flex justify-content-center">
              <Skeleton type="plan" itemsToShow={1} />
            </div>
          )}
          {subscriptionAndPlansStore.upgradeRenewProduct && !loadPlans && (
            <div
              className={clsx('p-4', classes.plan)}
              data-plan-type={
                subscriptionAndPlansStore.upgradeRenewProduct.unlimited ? 'national' : 'city'
              }>
              <p className="mb-4">
                <span className={clsx(classes.planLabel, 'text-white', 'opacity-50')}>
                  {subscriptionAndPlansStore.upgradeRenewProduct.name}
                </span>
                <b
                  className={clsx(
                    'text-white',
                    classes.location,
                    'm-0',
                    'd-block',
                    'font-kraftig'
                  )}>
                  {getTitle()}
                </b>
              </p>
              <div className="text-white">
                <div className={clsx(classes.price, 'lh-1', 'text-white')}>
                  <b className={clsx(classes.accent, 'font-kraftig')}>{getPrice()}</b>/
                  {t('upgradePlan.mo')}
                </div>
                <span className={clsx(classes.price, 'text-white')}>{getDiscount()}</span>
              </div>
            </div>
          )}

          <div className="mt-3">
            {subscriptionAndPlansStore.paymentData ? (
              <Payment direction="column" type={type} onPurchase={handlePurchase} />
            ) : (
              <div className="w-100 d-flex justify-content-center">
                <div className="spinner-border text-secondary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default UpgradePlanModal;
