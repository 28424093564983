import {
  ANALYTICS_REPO,
  DataAnalyticsDiagram,
  IAnalyticsRepo,
  IAnalyticsService,
  Params,
  ParamsForPost
} from './types';
import { injector } from 'utils/injector';
import { BLUE_COLOR } from 'utils/constants';
import { ITableData, ObjectValues } from 'utils/types';
import { IRow } from 'components/Table/types';
import { tableAnalyticRowGeneration } from '../TableRowGeneration';

export class AnalyticsService implements IAnalyticsService {
  private _analyticsRepo: IAnalyticsRepo = injector.get<IAnalyticsRepo>(ANALYTICS_REPO);

  async getFavouriteAnalytics(userId: number, lists: number[]): Promise<DataAnalyticsDiagram> {
    const res = (await this._analyticsRepo.getFavouriteAnalytics(userId, lists)).data.result;
    return {
      labels: res.map((item) => item.name),
      values: res.map((item) => item.value),
      colors: [BLUE_COLOR].concat(
        Array.from(
          { length: res.length - 1 },
          () => '#' + (Math.random().toString(16) + '000000').substring(2, 8).toUpperCase()
        )
      )
    };
  }

  async getFavoriteAnalyticsData(
    userId: number,
    params: Params,
    colorTypes: ObjectValues<string>
  ): Promise<ITableData<IRow>> {
    let paramsForPost: Params | ParamsForPost = params;
    const orderParams: ObjectValues<string> = {};
    if (params.order.length) {
      // TODO: probably move to separate helper
      params.order.forEach((item, index) => {
        const keyField = `order[${index}][field]`;
        const keyOrder = `order[${index}][order]`;
        orderParams[keyField] = item.field;
        orderParams[keyOrder] = item.order;
      });
      paramsForPost = {
        ...params,
        order: orderParams
      };
    }
    const res = (await this._analyticsRepo.getFavoriteAnalyticsData(userId, paramsForPost)).data
      .result;
    return {
      ...res,
      data: tableAnalyticRowGeneration(res.data, colorTypes, res.currentPage)
    };
  }
}
