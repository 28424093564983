import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { clsx } from 'utils/clsx';
import classes from './Notification.module.scss';
import moment from 'moment';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { Button } from 'components';
import { BLUE_COLOR } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { INotification } from './types';

const Notification: FC = observer(() => {
  const { t } = useTranslation();
  const { notificationStore } = useStore();
  const [showNotificationList, setShowNotificationList] = useState<boolean>(false);

  const notificationList = useRef<HTMLDivElement | null>(null);
  const notificationBtn = useRef<SVGSVGElement | null>(null);

  const clickOutside = (e: MouseEvent): void => {
    const composed = e.composedPath();
    if (
      e.target !== notificationList.current &&
      e.target !== notificationBtn.current &&
      notificationList.current &&
      !composed.includes(notificationList.current)
    ) {
      setShowNotificationList(false);
      if (
        notificationStore.notifications.some((notification: INotification) => !notification.seenAt)
      )
        (async () => await notificationStore.markAsRead())();
    }
  };

  const toggleVisible = useCallback(() => {
    setShowNotificationList((prev) => {
      if (
        prev &&
        notificationStore.notifications.some((notification: INotification) => !notification.seenAt)
      )
        (async () => await notificationStore.markAsRead())();
      return !prev;
    });
  }, []);

  const handleClearNotifications = async () => {
    await notificationStore.clearNotifications();
    setShowNotificationList(false);
  };

  useEffect(() => {
    if (showNotificationList) {
      window.addEventListener('click', clickOutside);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [showNotificationList]);

  return (
    <>
      <div role="button" onClick={toggleVisible}>
        <BellIcon
          className={clsx(
            classes.bellBtn,
            showNotificationList && classes.bellIcon,
            'position-relative',
            'mx-4',
            'cursor-pointer'
          )}
          ref={notificationBtn}
        />
      </div>
      {showNotificationList && (
        <div
          className={clsx(
            classes.listWrapper,
            'position-absolute',
            'bg-white',
            'overlay-shadow',
            'z-index-20'
          )}
          ref={notificationList}>
          <div
            className={clsx(
              classes.header,
              'py-3 px-4 d-flex justify-content-between default-color-text'
            )}>
            <div>
              <h5 className="m-0">{t('notification.title')}</h5>
            </div>
            <div>
              <Button
                type="light"
                color={BLUE_COLOR}
                className="p-0"
                data-test-element="clear-all-button font-size-14"
                onClick={handleClearNotifications}>
                {t('notification.clearAll')}
              </Button>
            </div>
          </div>
          <div className={clsx(classes.body)} data-test-element="notification-list">
            {notificationStore.notifications.length ? (
              notificationStore.notifications.map((notification: INotification) => (
                <div
                  key={`notification-${notification.id}`}
                  className={clsx(
                    classes.notification,
                    'py-3 px-4 d-flex justify-content-between position-relative'
                  )}
                  data-test-element="notification-item">
                  <div>
                    {!notification.seenAt && (
                      <div className="new-notification rounded-circle position-absolute"></div>
                    )}
                    <p className="mb-1 font-size-14">
                      <span>{notification.data.savedSearchName}</span>{' '}
                      {t('notification.savedSearch')}
                    </p>
                    <h6 className="m-0 default-color-text">
                      {notification.data.newProjectsCount} {t('notification.newProjects')}
                    </h6>
                  </div>
                  <div className={clsx(classes.notificationDate, 'font-size-14')}>
                    {moment(notification.createdAt).fromNow()}
                  </div>
                </div>
              ))
            ) : (
              <div
                className={clsx(
                  'py-3',
                  'px-4',
                  'text-center',
                  'default-color-text',
                  classes.notification
                )}>
                {t('notification.emptyNotes')}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});

export default Notification;
