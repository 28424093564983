import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { IFullProject, IPageClickEvent } from './types';
import classes from './ProjectInfoModal.module.scss';
import { clsx } from 'utils/clsx';
import { useStore } from 'storesProvider/storeContext';
import logoDark from 'assets/icons/logo-light.svg';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { FavoriteRoles, FavoriteTypes, IFavoriteEntity } from '../AddToFavorites/types';
import AddToFavorites from '../AddToFavorites';
import { ITabsItems } from 'components/Tab/types';
import { Skeleton, Tab, UnderConstructionModal } from 'components';
import Details from './components/Details';
import { ProjectList } from 'components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { RelatedProjectType } from '../SearchProjects/types';
import { useSearchParams } from 'react-router-dom';

const ProjectInfoModal: FC = observer(() => {
  const { projectSearchStore, configStore } = useStore();
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const id = Number(params.get('details'));
  const [showFavorite, setShowFavorite] = useState<boolean>(false);
  const [isInfoLoading, setIsInfoLoading] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [project, setProject] = useState<IFullProject | null>(null);
  const [showConstruction, setShowConstruction] = useState<boolean>(false);
  const favoriteRef = useRef<null | SVGSVGElement>(null);
  const projectFavoriteRef = useRef<null | SVGSVGElement>(null);
  const [tabs, setTabs] = useState<ITabsItems[]>([
    {
      name: t('components.projectInfo.details'),
      active: true,
      tabId: 1
    },
    {
      name: t('components.projectInfo.open'),
      active: false,
      tabId: 2
    },
    {
      name: t('components.projectInfo.closed'),
      active: false,
      tabId: 3
    }
  ]);

  const entity = useMemo<IFavoriteEntity | null>(() => {
    if (!project) return null;
    const projectClass = configStore.enums?.propertyClasses.find(
      (cl) => cl.id === project.propertyClass.id
    );
    const projectType = configStore.enums?.propertySubTypes.find(
      (type) => type.id === project.propertySubType.id
    );
    const entity: IFavoriteEntity = {
      id: project.id,
      coordinates: {} as DOMRect,
      type: FavoriteTypes.Project,
      role: FavoriteRoles.Project,
      key: 'projects',
      name: `${projectClass?.name} - ${projectType?.name}`
    };
    return entity;
  }, [project]);

  const activeTab = useMemo<number | null>(() => {
    return tabs.find((tab) => tab.active)?.tabId || null;
  }, [tabs]);

  const clickOutside = (e: React.MouseEvent): void => {
    const favoriteModals = document.querySelector('#modal-root')?.children.length;
    if (!favoriteModals) e.stopPropagation();
    if (e.target === e.currentTarget) {
      e.stopPropagation();
      setShowFavorite(false);
      setParams('');
    }
  };

  const closeFavorite = useCallback(() => {
    setShowFavorite(false);
  }, []);

  const closeFavoriteInList = useCallback(() => {
    projectSearchStore.setActiveFavorite(null);
  }, [showFavorite]);

  const openConstructionModal = useCallback(() => {
    closeFavorite();
    setShowConstruction(true);
  }, []);

  const closeConstructionModal = useCallback(() => {
    setShowConstruction(false);
  }, []);

  const openFavoriteModal = useCallback(
    (e: React.MouseEvent, ref?: SVGSVGElement | null): void => {
      if (ref) {
        projectFavoriteRef.current = ref;
      } else {
        projectFavoriteRef.current = null;
      }
      const thisModal = document.getElementById(
        `favorite-${entity?.type}-${entity?.role}-${entity?.id}`
      );
      setShowFavorite(!thisModal);
      if (thisModal) {
        e.stopPropagation();
      }
      if (!entity) return;
      projectSearchStore.setActiveFavorite(
        thisModal ? null : { id: entity.id, type: 'fullInfoModal' }
      );
    },
    [entity]
  );

  const handleFavorite = useCallback(
    (isActive: boolean, id: number): void => {
      projectSearchStore.updateFavoriteProjects(isActive, id);
      setIsFavorite(isActive);
    },
    [project]
  );

  const isModalShown = useMemo<boolean>(() => {
    return (
      showFavorite &&
      projectSearchStore.activeFavorite?.id === id &&
      projectSearchStore.activeFavorite?.type === 'fullInfoModal'
    );
  }, [entity, projectSearchStore.activeFavorite, showFavorite]);

  const closeProjectFavoriteModal = useCallback(() => {
    if (projectFavoriteRef.current) {
      setShowFavorite(false);
    }
  }, []);

  const handleTabChange = useCallback((tabId: number) => {
    setTabs((prev) => prev.map((tab) => ({ ...tab, active: tab.tabId === tabId })));
  }, []);

  const handleTitleClick = useCallback(() => {
    setTabs((prev) => prev.map((tab) => ({ ...tab, active: tab.tabId === 1 })));
  }, []);

  const handlePageClick = useCallback(async ({ id, type, page }: IPageClickEvent) => {
    if (!id) return;
    await projectSearchStore.loadMore(id, type as RelatedProjectType, page);
  }, []);

  useLayoutEffect(() => {
    (async () => {
      try {
        setIsInfoLoading(true);
        const project = await projectSearchStore.getAllProjectInfo(id);
        setProject(project);
        setIsFavorite(project.favourite);
        await projectSearchStore.getRelatedProjects(id);
      } catch (e) {
        setParams('');
      } finally {
        setIsInfoLoading(false);
      }
    })();
  }, [id]);

  useLayoutEffect(() => {
    setTabs((prev) =>
      prev.map((tab) => {
        if (tab.tabId === 2) return { ...tab, badge: projectSearchStore.relatedOpenProjects.count };
        if (tab.tabId === 3)
          return { ...tab, badge: projectSearchStore.relatedClosedProjects.count };
        return tab;
      })
    );
  }, [projectSearchStore.relatedOpenProjects, projectSearchStore.relatedClosedProjects]);

  useEffect(() => {
    return () => projectSearchStore.resetRelatedProjects();
  }, []);

  return (
    <div
      className={clsx(
        'position-absolute',
        'top-0',
        'bottom-0',
        'start-0',
        'end-0',
        'd-flex',
        'justify-content-end',
        classes.wrapper
      )}
      onClick={clickOutside}>
      <div className={clsx(classes.body, 'vh-100', 'bg-white')}>
        <div className="d-flex p-3 justify-content-between">
          <img src={logoDark} alt="" />
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-4" role="button">
              <FavoriteIcon
                ref={favoriteRef}
                onClick={openFavoriteModal}
                className={clsx(classes.favoriteIcon, 'me-1', isFavorite && classes.filled)}
                data-test-element="favorite-icon"
              />
              <h4 onClick={openFavoriteModal} className={clsx(classes.iconText, 'mb-0')}>
                {t('components.projectInfo.save')}
              </h4>
            </div>
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={openConstructionModal}>
              <ShareIcon
                className={clsx('me-1', classes.shareIcon)}
                data-test-element="share-icon"
                role="button"
              />
              <h4 className={clsx(classes.iconText, 'mb-0')}>
                {t('components.projectInfo.share')}
              </h4>
            </div>
            <div className={clsx('lh-1', classes.close)}>
              <svg
                onClick={() => setParams('')}
                role="button"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11" stroke="#2F3139" strokeWidth="1.4" strokeLinejoin="round" />
                <path d="M11 1L1 11" stroke="#2F3139" strokeWidth="1.4" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div className={classes.divider} />
        {project && !isInfoLoading ? (
          <>
            <div className="p-3">
              <h2 className={classes.location}>
                {project.location.address.title},&nbsp;
                {project.location.city?.title && `${project.location.city.title}, `}
                {project.location.state.title}, {project.location.zip.title}
              </h2>
              <p className={clsx(classes.openPermits, 'm-0')}>
                {projectSearchStore.relatedOpenProjects.count}{' '}
                {t('components.projectInfo.openPermits')}
              </p>
            </div>
            <div className={classes.divider} />
            <Tab items={tabs} mode="light" action={handleTabChange} navClass="px-3" />
            <div className={classes.divider} />
            {activeTab === 1 && (
              <Details
                project={project}
                isFavorite={isFavorite}
                onFavorite={openFavoriteModal}
                onScroll={closeProjectFavoriteModal}
              />
            )}
            {activeTab === 2 && (
              <div>
                <ProjectList
                  type="openProjects"
                  onTitleClick={handleTitleClick}
                  projectId={id}
                  isLoading={false}
                  itemSize={155}
                  hideContacts
                  onPageClick={handlePageClick}
                  projectsData={projectSearchStore.relatedOpenProjects}
                  onListScroll={closeFavoriteInList}
                />
              </div>
            )}
            {activeTab === 3 && (
              <div>
                <ProjectList
                  type="closedProjects"
                  onTitleClick={handleTitleClick}
                  projectId={id}
                  isLoading={false}
                  itemSize={155}
                  hideContacts
                  onPageClick={handlePageClick}
                  projectsData={projectSearchStore.relatedClosedProjects}
                  onListScroll={closeFavoriteInList}
                />
              </div>
            )}
          </>
        ) : (
          <Skeleton type="company" itemsToShow={1} />
        )}
      </div>
      {isModalShown && entity && favoriteRef.current && (
        <AddToFavorites
          entity={{
            ...entity,
            coordinates: projectFavoriteRef.current
              ? projectFavoriteRef.current.getBoundingClientRect()
              : favoriteRef.current.getBoundingClientRect()
          }}
          closeModal={closeFavorite}
          toggleCallback={handleFavorite}
        />
      )}
      <UnderConstructionModal
        title="Share Project"
        subTitle=""
        closeModal={closeConstructionModal}
        show={showConstruction}
      />
    </div>
  );
});

export default ProjectInfoModal;
