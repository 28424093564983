import { makeAutoObservable } from 'mobx';
import { Alert, IAlertStore } from './types';
import { SUCCESS, ERROR, TIME_OUT_VIEW_ALERTS } from 'utils/constants';
import { ReactNode } from 'react';

export class AlertStore implements IAlertStore {
  constructor() {
    makeAutoObservable<AlertStore>(this);
  }

  alerts: Alert[] = [];
  pendingAlerts: Alert[] = [];

  successAlert(text: string | ReactNode): void {
    this.generateAlerts({ text, type: SUCCESS });
  }

  errorAlert(text: string | ReactNode): void {
    this.generateAlerts({ text, type: ERROR });
  }

  generateAlerts(alert: Alert): void {
    if (this.alerts.length === 3) {
      this.pendingAlerts.push(alert);
    } else {
      this.alerts.push(alert);
    }
    setTimeout(() => {
      this.alerts.shift();
      if (this.pendingAlerts.length) {
        this.alerts.push(this.pendingAlerts[0]);
        this.pendingAlerts.shift();
      }
    }, TIME_OUT_VIEW_ALERTS * (!this.pendingAlerts.length ? 1 : this.pendingAlerts.length));
  }

  closeAlert(idx: number): void {
    this.alerts.splice(idx, 1);
  }
}
