import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from 'components/Select/Select.module.scss';
import styles from '../SubHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { IInputChangeItem } from '../types';
import { ENTER } from 'components/SearchList/constants';
import { childOf } from 'helper/childOf';

interface IProps {
  value: {
    min: number | null;
    max: number | null;
  } | null;
  onSave(value: IInputChangeItem): void;
  onReset(): void;
}

const CountDropdown: FC<IProps> = ({ value, onSave, onReset }) => {
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [form, setForm] = useState<{ min: string; max: string }>({
    min: value?.min ? String(value.min) : '',
    max: value?.max ? String(value.max) : ''
  });

  const selectRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);

  const toggleSelect = useCallback((e: React.MouseEvent): void => {
    if (e.nativeEvent.composedPath().includes(formRef.current as EventTarget)) return;
    setIsOpened((prev) => !prev);
  }, []);

  const handleInput = useCallback((name: string, val: string) => {
    setForm((prev) => ({ ...prev, [name]: val.replace(/\D/g, '') }));
  }, []);

  const handleReset = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpened(false);
    onReset();
    setForm({ max: '', min: '' });
  }, []);

  const clickOutside = useCallback((e: MouseEvent): void => {
    if (!e.composedPath().includes(selectRef.current as EventTarget)) {
      setIsOpened(false);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    onSave({ min: form.min ? Number(form.min) : null, max: form.max ? Number(form.max) : null });
    setIsOpened(false);
  }, [form]);

  const keyPress = useCallback((e: KeyboardEvent): void => {
    if (e.code === ENTER) {
      if (
        !childOf(document.activeElement, selectRef.current) &&
        document.activeElement !== selectRef.current
      )
        setIsOpened(false);
      if (document.activeElement === selectRef.current) setIsOpened((prev) => !prev);
    }
  }, []);

  const handleResetPressEnter = useCallback((e: React.KeyboardEvent) => {
    if (e.code === ENTER && document.activeElement === e.target) {
      setIsOpened(false);
      onReset();
      setForm({ max: '', min: '' });
    }
  }, []);

  const isActive = useMemo<boolean>(() => {
    return !!value?.max || !!value?.min;
  }, [value]);

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    if (!isOpened)
      return () => {
        window.removeEventListener('keydown', keyPress);
      };
    document.body.addEventListener('click', clickOutside);
    return () => {
      document.body.removeEventListener('click', clickOutside);
    };
  }, [isOpened]);

  useEffect(() => {
    setForm({ min: value?.min ? String(value.min) : '', max: value?.max ? String(value.max) : '' });
  }, [value]);

  return (
    <div
      role="button"
      className={clsx(
        classes.select,
        'position-relative',
        'd-flex',
        'align-items-center',
        'justify-content-between',
        'me-2',
        isOpened && classes.activeSelect,
        styles.searchSelect,
        isActive && styles.active
      )}
      tabIndex={0}
      data-test-element="favorite-project-count-dropdown"
      onClick={toggleSelect}
      ref={selectRef}>
      <>
        <span role="button" className={clsx('d-block w-100', styles.text, 'font-kraftig')}>
          {t('favorites.projectCount')}
        </span>
        {isOpened && (
          <div
            className={clsx(classes.optionWrapper, styles.moreContent, 'position-absolute')}
            ref={formRef}>
            <div className="py-4">
              <div className="d-flex px-4 align-items-center">
                <h3 className={clsx(styles.moreTitle, 'm-0')}>{t('favorites.projectCount')}</h3>
                <div className={styles.countInput}>
                  <Input
                    type="text"
                    value={form.min}
                    onInput={handleInput}
                    name="min"
                    placeholder="No Min"
                    data-test-element="favorite-min-count"
                    inputClass={clsx('default-color-text', styles.countInput, 'ms-auto')}
                  />
                </div>

                <div className={styles.moreInputDelimiter} />
                <div className={styles.countInput}>
                  <Input
                    type="text"
                    value={form.max}
                    onInput={handleInput}
                    name="max"
                    placeholder="No Max"
                    data-test-element="favorite-max-count"
                    inputClass={clsx('default-color-text', styles.countInput, 'ms-auto')}
                  />
                </div>
              </div>
            </div>
            <div className={styles.divider} />
            <div className="d-flex justify-content-end p-4">
              <Button
                type="primary"
                className={clsx('px-4', styles.moreBtn)}
                onClick={handleSubmit}>
                {t('searchProject.done')}
              </Button>
            </div>
          </div>
        )}
      </>
      {value?.min || value?.max ? (
        <div onClick={handleReset} className="d-flex align-items-center" role="button">
          <CloseIcon
            className={clsx(classes.resetIcon, isOpened && classes.resetOpened)}
            tabIndex={0}
            onKeyDown={handleResetPressEnter}
          />
        </div>
      ) : (
        <div
          className={clsx(
            classes.icon,
            'position-absolute',
            'top-50',
            'd-flex',
            'align-items-center',
            isOpened && classes.active
          )}>
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};

export default CountDropdown;
