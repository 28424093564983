import { ASC, DESC } from 'utils/constants';
import { Order } from 'components/Table/types';

export const orderProcessing = (field: string, orders: Order[]): Order[] => {
  if (orders.length) {
    const currentOrder = orders.find((order) => order.field === field);
    if (currentOrder && currentOrder.order === DESC) {
      return orders.filter((order) => order.field !== field);
    }
    if (currentOrder) {
      return orders.map((item) => {
        if (item.field === field) {
          if (item.order === ASC) {
            return {
              field: item.field,
              order: DESC
            };
          }
        }
        return item;
      });
    }
    return addNewOrder(field, orders);
  }
  return addNewOrder(field, orders);
};

const addNewOrder = (field: string, orders: Order[]): Order[] => {
  const currentOrder: Order = {
    field,
    order: ASC
  };
  return [...orders, currentOrder];
};
