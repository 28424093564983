import React, { FC } from 'react';
import classes from '../CancelSubscriptionModal.module.scss';
import { clsx } from 'utils/clsx';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpgrade: () => void;
}

const UpgradePlan: FC<Props> = ({ onUpgrade }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.wrapperBlock, 'd-flex', 'flex-column')}>
      <div className="px-4 py-3">
        <h3 className={clsx(classes.titleUpgradePlan)}>{t('cancelSubscription.upgrade')}</h3>
        <p className={clsx(classes.textUpgradePlan, 'pt-2')}>
          {t('cancelSubscription.upgradeInfo')}
        </p>
        <Button
          type="primary"
          className={clsx(classes.btnPlans, 'w-100', 'mt-4')}
          onClick={onUpgrade}>
          <span className="font-kraftig">{t('cancelSubscription.upgradeBtn')}</span>
        </Button>
      </div>
    </div>
  );
};

export default UpgradePlan;
