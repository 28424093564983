import React, { FC } from 'react';

const UnderConstruction: FC = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <h1 className="display-4">This page is currently under construction</h1>
    </div>
  );
};

export default UnderConstruction;
