import { ISort } from '../LeftSidebar/types';
import { IOption } from 'components/Select/types';

export interface ISearchParams {
  permitTypes: number[];
  statuses: number[];
  classes: number[];
  types: number[];
  subTypes: number[];
  location: number | null;
  value: {
    min: number | null;
    max: number | null;
  };
  levels: {
    min: number | null;
    max: number | null;
  };
  units: {
    min: number | null;
    max: number | null;
  };
  date: {
    min: string | null;
    max: string | null;
  };
  onlyWithContacts?: boolean;
  order?: ISort[];
  page: number;
  area?: [number, number][];
  keywords: string;
}

type Omit<T, K extends keyof never> = Pick<T, Exclude<keyof T, K>>;

export type ISearchParamKey = keyof Omit<ISearchParams, 'onlyWithContacts'>;

export interface IInputChangeItem {
  min: number | null;
  max: number | null;
}

export type ISearchParamValue =
  | number[]
  | string
  | { min: string | number | null; max: string | number | null }
  | ISort[]
  | null
  | number
  | [number, number][];

export interface ICustomDate {
  from: number;
  to: number;
}

export interface IDateChangeEvent {
  selection: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

export interface IFilterStore {
  searchParams: ISearchParams;
  selectedDate: IOption | null;
  customDate: ICustomDate | null;
  setSearchParams(name: string, param: ISearchParamValue): void;
  resetSearchParams(): void;
  setSelectedDate(date: IOption | null): void;
  onSelectChange(option: IOption, field: string): void;
  onInputChange(inputChange: IInputChangeItem, field: string): void;
  onSelectReset(field: string): void;
  handleReset(): void;
}

export interface IMoreValues {
  units: IMinMax;
  levels: IMinMax;
  status: IOption | null;
  keywords: string;
}

export interface IMinMax {
  min: IOption | null;
  max: IOption | null;
}

export interface ITreeOption {
  label: string;
  value: number;
  children?: ITreeOption[];
  checked?: boolean;
  type: string;
}

export enum TreeOptionType {
  Class = 'class',
  Type = 'type',
  Subtype = 'subtype',
  PermitType = 'permitType'
}

export interface IDropCategories {
  classes: number[];
  types: number[];
  subTypes: number[];
}

export interface IDropdownEvent {
  activeClasses?: number[];
  activeTypes?: number[];
  activeSubtypes?: number[];
  activePermits?: number[];
}
