import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { observer } from 'mobx-react';
import { Table, UnderConstructionModal } from 'components';
import classes from './Analytics.module.scss';
import { clsx } from 'utils/clsx';
import Diagram from './Diagram';
import SubHeader from './components/SubHeader';
import { Params } from './types';
import { IColumn } from 'components/Table/types';
import { orderProcessing } from 'helper/orderProcessing';
import { useIntersectionObserverEffect } from 'hooks/useIntersectionObserverEffect';

const ProjectAnalytics = observer(() => {
  const { state } = useLocation();
  const { analyticsStore, userStore } = useStore();
  const [show, setShow] = useState<boolean>(false);
  const [analyticsParam, setAnalyticsParam] = useState<Params>({
    lists: [],
    order: [],
    page: 1
  });
  const [isMoreAnalyticsLoading] = useState<boolean>(false);

  const observableDiv = useRef<HTMLDivElement | null>(null);

  const closeModal = useCallback(() => setShow(false), []);
  const openModal = useCallback(() => setShow(true), []);

  const columns: IColumn[] = [
    {
      field: 'property',
      headerName: 'Details',
      sort: true
    },
    {
      field: 'buildingPermit',
      headerName: 'Type',
      sort: true
    },
    {
      field: 'value',
      headerName: 'Project Value',
      sort: true
    }
  ];

  useEffect(() => {
    (async () => {
      await initFavoriteAnalytics();
      await initFavoriteAnalyticsData();
    })();
    return () => {
      analyticsStore.diagramData = null;
    };
  }, []);

  useEffect(() => {
    if (analyticsStore.analyticsData.data.length) {
      (async () => {
        await initFavoriteAnalyticsData();
      })();
    }
  }, [analyticsParam]);

  const initFavoriteAnalytics = async (): Promise<void> => {
    if (!userStore.user) return;
    await analyticsStore.getFavouriteAnalytics(userStore.user.id, state as number[]);
  };

  const initFavoriteAnalyticsData = async (): Promise<void> => {
    if (!userStore.user) return;
    await analyticsStore.getFavoriteAnalyticsData(userStore.user.id, analyticsParam);
  };

  const handleOrder = (field: string): void => {
    setAnalyticsParam({
      ...analyticsParam,
      order: orderProcessing(field, analyticsParam.order),
      page: 1
    });
  };

  useIntersectionObserverEffect(
    observableDiv,
    (entry: IntersectionObserverEntry) => {
      setAnalyticsParam((prev) => {
        if (
          entry.isIntersecting &&
          prev.page < analyticsStore.analyticsData.totalPages &&
          !isMoreAnalyticsLoading
        ) {
          return {
            ...prev,
            page: prev.page + 1
          };
        }
        return prev;
      });
    },
    [observableDiv.current]
  );

  return (
    <div className={classes.wrapper}>
      <SubHeader totalValue={analyticsStore.totalProjectValue} onExport={openModal} />
      <div className="container-fluid d-flex justify-content-between mb-3">
        <div
          className={clsx(
            'd-flex',
            'align-items-center',
            'justify-content-center',
            'col-5',
            'me-4',
            'bg-white',
            ' py-3',
            classes.shadow
          )}>
          {analyticsStore.diagramData ? (
            <Diagram
              diagramData={analyticsStore.diagramData}
              totalValue={analyticsStore.totalProjectValue}
            />
          ) : null}
        </div>
        <div className={clsx('w-100', classes.shadow, classes.tableWrapper)}>
          <Table
            columns={columns}
            rows={analyticsStore.analyticsData.data}
            orders={analyticsParam.order}
            orderAction={handleOrder}
            cellClass="d-inline-block">
            <div>
              <div ref={observableDiv}></div>
              <div
                className={clsx(
                  'loader',
                  'd-flex',
                  'justify-content-center',
                  'py-3',
                  !isMoreAnalyticsLoading && 'loader-hidden'
                )}>
                <div />
              </div>
            </div>
          </Table>
        </div>
      </div>

      <UnderConstructionModal
        show={show}
        closeModal={closeModal}
        title="Favorite project"
        subTitle=""
      />
    </div>
  );
});

export default ProjectAnalytics;
