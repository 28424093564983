import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Tab, Select, Input } from 'components';
import { clsx } from 'utils/clsx';
import classes from '../Favorites.module.scss';
import searchIcon from 'assets/icons/search-dark.svg';
import projectAnalyticsIcon from 'assets/icons/project-analytics.svg';
import planeIcon from 'assets/icons/plane.svg';
import { IOption } from 'components/Select/types';
import { FavoriteFormValue, IFavoriteFilters } from '../types';
import { useTranslation } from 'react-i18next';
import { ITabsItems } from 'components/Tab/types';
import DateDropdown from 'view/SearchProjects/components/SubHeader/components/DateDropdown';
import ValuationDropdown from 'view/SearchProjects/components/SubHeader/components/ValuationDropdown';
import {
  ICustomDate,
  IDropdownEvent,
  IInputChangeItem
} from 'view/SearchProjects/components/SubHeader/types';
import { getDate } from 'view/SearchProjects/helpers/getDate';
import CountDropdown from 'view/SearchProjects/components/SubHeader/components/CountDropdown';
import { DATE, LOCATION, PROJECT_COUNT, PROJECT_VALUE, TOTAL_AMOUNT, TYPE } from 'utils/constants';
import { dateOptions } from '../../SearchProjects/mock';
import TreeDropdown from 'view/SearchProjects/components/SubHeader/components/TreeDropdown';
import { useStore } from 'storesProvider/storeContext';
import { numberWithSpaces } from 'helper/numberWithComma';
import { observer } from 'mobx-react';

interface Props {
  tabItems: ITabsItems[];
  favoriteFilters: IFavoriteFilters;
  activeFilters: (keyof IFavoriteFilters)[];
  totalProjectValue: number;
  locations: IOption[];
  onDropdownSave(name: keyof IFavoriteFilters, value: FavoriteFormValue): void;
  onDropdownReset(name: keyof IFavoriteFilters): void;
  onSearchInput: (name: string, value: string) => void;
  searchValue: string;
  onButtonClick: () => void;
  onTabClick: (tabId: number) => void;
  analyticsAction: () => void;
  onCategoryChange: (event: IDropdownEvent) => void;
}

const TableHeader: FC<Props> = observer(
  ({
    tabItems,
    favoriteFilters,
    activeFilters,
    totalProjectValue,
    onSearchInput,
    searchValue,
    onButtonClick,
    onTabClick,
    onDropdownSave,
    onDropdownReset,
    locations,
    analyticsAction,
    onCategoryChange
  }) => {
    const { t } = useTranslation();
    const { favoritesStore } = useStore();
    const [customDate, setCustomDate] = useState<ICustomDate | null>(null);
    const [selectedDate, setSelectedDate] = useState<IOption | null>(null);
    const [showSearch, setShowSearch] = useState<boolean>(false);

    const selectedLocation = useMemo<IOption | null>(() => {
      const [id] = favoriteFilters.location || [];
      return locations.find((location) => location.id === id) || null;
    }, [favoriteFilters.location]);

    const handleLocationChange = useCallback((option: IOption) => {
      onDropdownSave('location', [option.id]);
    }, []);

    const handleLocationReset = useCallback(() => {
      onDropdownSave('location', []);
    }, []);

    const handleDateChange = useCallback((option: IOption) => {
      if (option.value !== 'custom') {
        setSelectedDate(option);
        const dayInMs = 1000 * 60 * 60 * 24;
        const now = new Date(Date.now());
        const from = new Date(Date.now() - dayInMs * Number(option.value));
        const dateFrom = getDate(from);
        const dateTo = getDate(now);
        onDropdownSave('date', { min: dateFrom, max: dateTo });
      }
    }, []);

    const handleCustomDateChange = useCallback((date: ICustomDate) => {
      setCustomDate(date);
      setSelectedDate(dateOptions.find((option) => option.value === 'custom') || null);
      const dateFrom = getDate(new Date(date.from));
      const dateTo = getDate(new Date(date.to));
      onDropdownSave('date', { min: dateFrom, max: dateTo });
    }, []);

    const handleDateReset = useCallback(() => {
      onDropdownSave('date', null);
      setCustomDate(null);
      setSelectedDate(null);
    }, []);

    const handleCountChange = useCallback((value: IInputChangeItem) => {
      onDropdownSave('projectCount', value);
    }, []);

    const handleCountReset = useCallback(() => {
      onDropdownSave('projectCount', null);
    }, []);

    useEffect(() => {
      if (!favoriteFilters.date?.min && !favoriteFilters.date?.min) {
        setCustomDate(null);
        setSelectedDate(null);
      }
    }, [favoriteFilters.date]);

    const onSearch = (): void => {
      setShowSearch(!showSearch);
    };

    const handleTabChange = useCallback(
      (id: number) => {
        setSelectedDate(null);
        onTabClick(id);
      },
      [setSelectedDate]
    );

    return (
      <div>
        <Tab
          items={tabItems}
          mode="light"
          navClass={clsx(classes.tab, 'mb-4')}
          action={handleTabChange}
        />
        <div className="d-flex justify-content-between px-4">
          <div className="d-flex justify-content-center align-items-center">
            {activeFilters.includes(TYPE) && (
              <TreeDropdown
                data={favoritesStore.categories}
                onChange={onCategoryChange}
                placeholder={t('components.dropdown.placeholder')}
              />
            )}
            {activeFilters.includes(DATE) && (
              <DateDropdown
                customValue={customDate}
                onChange={handleDateChange}
                onCustomChange={handleCustomDateChange}
                onReset={handleDateReset}
                value={selectedDate}
              />
            )}
            {activeFilters.includes(LOCATION) && (
              <Select
                className={clsx(classes.select, 'me-2', !!selectedLocation && classes.active)}
                options={locations}
                value={selectedLocation}
                onChange={handleLocationChange}
                name="Location"
                placeholder="Location"
                optionWidth={250}
                onReset={handleLocationReset}
                data-test-element="favorite-location-select"
              />
            )}
            {activeFilters.includes(PROJECT_VALUE) && (
              <ValuationDropdown
                placeholder="Project Value"
                width={140}
                value={favoriteFilters.projectValue || null}
                onReset={() => onDropdownReset('projectValue')}
                onSave={(value) => onDropdownSave('projectValue', value)}
                attr="favorite-project-value-dropdown"
              />
            )}
            {activeFilters.includes(PROJECT_COUNT) && (
              <CountDropdown
                value={favoriteFilters.projectCount || null}
                onReset={handleCountReset}
                onSave={handleCountChange}
              />
            )}
            {activeFilters.includes(TOTAL_AMOUNT) && (
              <ValuationDropdown
                placeholder="Total Amount"
                value={favoriteFilters.totalAmount || null}
                width={140}
                onReset={() => onDropdownReset('totalAmount')}
                onSave={(value) => onDropdownSave('totalAmount', value)}
                attr="favorite-total-amount-dropdown"
              />
            )}
            {showSearch && (
              <div className={clsx(classes.searchWrapper, 'me-2')}>
                <Input
                  name=""
                  type="text"
                  value={searchValue}
                  onInput={onSearchInput}
                  inputClass={classes.searchWrapper}
                />
              </div>
            )}
            <Button
              type="light"
              onClick={onSearch}
              className={clsx('d-flex', 'align-items-center px-1', showSearch ? 'ms-0' : 'ms-2')}>
              <img src={searchIcon} alt="" />
            </Button>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <span className={clsx('me-3', 'font-kraftig', classes.value)}>
              {t('favorites.totalProjectValue')}: ${numberWithSpaces(totalProjectValue)}
            </span>
            <Button
              type="secondary"
              className={clsx(
                classes.subHeaderButton,
                'px-3',
                'me-2',
                'text-decoration-none',
                'default-color-text',
                'd-flex',
                'align-items-center'
              )}
              onClick={analyticsAction}>
              <span className="font-kraftig">{t('favorites.projectsAnalytics')}</span>
              <img src={projectAnalyticsIcon} className="ms-2" alt="project-analytics" />
            </Button>
            <Button
              type="secondary"
              className={clsx(classes.subHeaderButton, 'px-3')}
              onClick={onButtonClick}>
              <span className="font-kraftig">{t('favorites.marketToThisList')}</span>
              <img src={planeIcon} className="ms-2 mb-1" alt="export" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default TableHeader;
