import React, { FC, useCallback, useState } from 'react';
import classes from './IncreasePipeline.module.scss';
import { clsx } from 'utils/clsx';
import { IIncreaseCard } from './types';
import { ReactComponent as IncreaseIcon1 } from 'assets/icons/increaseIcon1.svg';
import { ReactComponent as IncreaseIcon2 } from 'assets/icons/increaseIcon2.svg';
import { ReactComponent as IncreaseIcon3 } from 'assets/icons/increaseIcon3.svg';
import IncreaseCard from './components/IncreaseCard';
import { Button, UnderConstructionModal } from 'components';
import { useTranslation } from 'react-i18next';

const cards: IIncreaseCard[] = [
  {
    id: 1,
    title: 'Get Real Time Construction Permit Data',
    subTitle: 'Be notified of all new projects that hit your market that fit your business.',
    Icon: IncreaseIcon1,
    color: '#A685EC'
  },
  {
    id: 2,
    title: 'Find Leads Before Your Competition',
    subTitle: "Project intelligence that your competitors don't have.",
    Icon: IncreaseIcon2,
    color: '#FBD07E'
  },
  {
    id: 3,
    title: 'Automate Winning New Projects',
    subTitle: 'Launch thoughtful done for you marketing campaigns to your ideal projects.',
    Icon: IncreaseIcon3,
    color: '#6DE0A9'
  }
];

const IncreasePipeline: FC = () => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const openModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => setModalVisible(false), []);

  return (
    <div className={clsx(classes.wrapper)}>
      <h2 className={clsx('text-center', 'mb-2', classes.title)}>{t('home.increasePipeline')}</h2>
      <p className={clsx('text-center', 'mb-4', 'pb-2', classes.subTitle)}>{t('home.spendLess')}</p>
      <div className="d-flex justify-content-between container align-items-stretch mb-4 pb-3">
        {cards.map((card) => (
          <IncreaseCard key={card.id} {...card} />
        ))}
      </div>
      <Button type="primary" className={clsx('m-auto', classes.exploreBtn)} onClick={openModal}>
        <span className="font-kraftig">{t('home.explore')}</span>
      </Button>
      <UnderConstructionModal
        show={modalVisible}
        closeModal={closeModal}
        title={t('home.explore')}
        subTitle=""
      />
    </div>
  );
};

export default IncreasePipeline;
