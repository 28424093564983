import { injector } from './injector';

import { AUTH_REPO, AUTH_SERVICE } from 'view/Auth/types';
import { SEARCH_REPO, SEARCH_SERVICE } from 'view/Search/types';
import { SEARCH_PROJECTS_REPO, SEARCH_PROJECTS_SERVICE } from 'view/SearchProjects/types';
import { CONFIG_REPO, CONFIG_SERVICE } from 'store/ConfigStore/types';
import { SAVED_SEARCH_REPO, SAVED_SEARCH_SERVICE } from 'view/SavedSearches/types';
import {
  SAVED_SEARCH_RESULTS_REPO,
  SAVED_SEARCH_RESULTS_SERVICE
} from 'view/SavedSearches/components/SavedSearchResults/types';
import { MAP_REPO, MAP_SERVICE } from 'view/SearchProjects/components/Map/types';
import { USER_REPO, USER_SERVICE } from 'view/Profile/types';
import { FAVORITES_REPO, FAVORITES_SERVICE } from 'view/Favorites/types';
import { ANALYTICS_REPO, ANALYTICS_SERVICE } from 'view/Favorites/Analytics/types';
import { NOTE_REPO, NOTE_SERVICE } from 'view/AddNoteModal/types';
import {
  SUBSCRIPTION_PLANS_REPO,
  SUBSCRIPTION_PLANS_SERVICE
} from 'view/SubscriptionsAndPlans/types';
import { NOTIFICATION_REPO, NOTIFICATION_SERVICE } from 'view/Notification/types';

import { AuthRepo } from 'view/Auth/Auth.api';
import { AuthService } from 'view/Auth/Auth.service';

import { SearchRepo } from 'view/Search/Search.api';
import { SearchService } from 'view/Search/Search.service';

import { SearchProjectsRepo } from 'view/SearchProjects/SearchProjects.api';
import { SearchProjectsService } from 'view/SearchProjects/SearchProjects.service';

import { ConfigRepo } from 'store/ConfigStore/Config.api';
import { ConfigService } from 'store/ConfigStore/Config.service';

import { SavedSearchManageRepo } from 'view/SavedSearches/SavedSearchManage.api';
import { SavedSearchManageService } from 'view/SavedSearches/SavedSearchManage.service';

import { SavedSearchResultsRepo } from 'view/SavedSearches/components/SavedSearchResults/SavedSearchResults.api';
import { SavedSearchResultsService } from 'view/SavedSearches/components/SavedSearchResults/SavedSearchResults.service';

import { MapRepo } from 'view/SearchProjects/components/Map/Map.api';
import { MapService } from 'view/SearchProjects/components/Map/Map.service';

import { UserRepo } from 'view/Profile/User.api';
import { UserService } from 'view/Profile/User.service';

import { FavoritesRepo } from 'view/Favorites/Favorites.api';
import { FavoritesService } from 'view/Favorites/Favorites.service';

import { AnalyticsRepo } from 'view/Favorites/Analytics/Analytics.api';
import { AnalyticsService } from 'view/Favorites/Analytics/Analytics.service';

import { NoteRepo } from 'view/AddNoteModal/Note.api';
import { NoteService } from 'view/AddNoteModal/Note.service';

import { SubscriptionAndPlansRepo } from 'view/SubscriptionsAndPlans/SubscriptionAndPlans.api';
import { SubscriptionAndPlansService } from 'view/SubscriptionsAndPlans/SubscriptionAndPlans.service';

import { NotificationRepo } from 'view/Notification/Notification.api';
import { NotificationService } from 'view/Notification/Notification.service';

injector.set(AUTH_REPO, new AuthRepo());
injector.set(AUTH_SERVICE, new AuthService());

injector.set(SEARCH_PROJECTS_REPO, new SearchProjectsRepo());
injector.set(SEARCH_PROJECTS_SERVICE, new SearchProjectsService());

injector.set(CONFIG_REPO, new ConfigRepo());
injector.set(CONFIG_SERVICE, new ConfigService());

injector.set(SEARCH_REPO, new SearchRepo());
injector.set(SEARCH_SERVICE, new SearchService());

injector.set(SAVED_SEARCH_REPO, new SavedSearchManageRepo());
injector.set(SAVED_SEARCH_SERVICE, new SavedSearchManageService());

injector.set(SAVED_SEARCH_RESULTS_REPO, new SavedSearchResultsRepo());
injector.set(SAVED_SEARCH_RESULTS_SERVICE, new SavedSearchResultsService());

injector.set(MAP_REPO, new MapRepo());
injector.set(MAP_SERVICE, new MapService());

injector.set(USER_REPO, new UserRepo());
injector.set(USER_SERVICE, new UserService());

injector.set(FAVORITES_REPO, new FavoritesRepo());
injector.set(FAVORITES_SERVICE, new FavoritesService());

injector.set(ANALYTICS_REPO, new AnalyticsRepo());
injector.set(ANALYTICS_SERVICE, new AnalyticsService());

injector.set(NOTE_REPO, new NoteRepo());
injector.set(NOTE_SERVICE, new NoteService());

injector.set(SUBSCRIPTION_PLANS_REPO, new SubscriptionAndPlansRepo());
injector.set(SUBSCRIPTION_PLANS_SERVICE, new SubscriptionAndPlansService());

injector.set(NOTIFICATION_REPO, new NotificationRepo());
injector.set(NOTIFICATION_SERVICE, new NotificationService());
