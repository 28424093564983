import React, { FC, useCallback } from 'react';
import classes from '../ChoosePlanModal.module.scss';
import { clsx } from 'utils/clsx';
import { Button } from 'components';
import { BLUE_COLOR } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { Plans, Product } from '../../types';
import { conversionMonthPrice, conversionPrice } from '../../helper';

interface IProps {
  active: boolean;
  product: Product;
  locationName: string;
  onToggle: (product: Product) => void;
}

const PlanCard: FC<IProps> = ({ active, product, locationName, onToggle }) => {
  const { t } = useTranslation();

  const handleButtonClick = useCallback(() => onToggle(product), []);

  const getPrice = useCallback((): string => {
    return product.period === Plans.YEAR
      ? conversionMonthPrice(product.price)
      : conversionPrice(product.price);
  }, []);

  const getDiscount = useCallback((): string => {
    if (product.discount) {
      return `$${conversionPrice(product.discount)}`;
    }
    return '';
  }, []);

  return (
    <div className={clsx(classes.plan, 'd-flex', 'flex-column')}>
      <div
        className={clsx('p-4', active && classes.planActive)}
        data-plan-type={product.unlimited ? 'national' : 'city'}>
        <p className={clsx(classes.planLabel, active && 'text-white', 'opacity-50')}>
          {product.name}
        </p>
        <div className="text-white">
          <div className={clsx(classes.price, 'lh-1', active && 'text-white')}>
            <span className={clsx(classes.accent, 'font-kraftig')}>${getPrice()}</span>/
            {t('choosePlan.mo')}
          </div>
          <span className={clsx(classes.price, 'd-block', active && 'text-white')}>
            {product.period === Plans.YEAR && `${getDiscount()} ${t('choosePlan.saved')}`}
          </span>
        </div>
      </div>
      <div className={classes.divider} />
      <div className="py-3 px-4 flex-grow-1 d-flex flex-column justify-content-between">
        {product.unlimited ? (
          <div>
            <span className={clsx(classes.planText, 'd-block', 'mb-3')}>
              <span className={classes.planText}>{product.title}</span>
            </span>
            <span className={classes.planText}>
              <span
                className={classes.planText}
                dangerouslySetInnerHTML={{ __html: product.description }}></span>
            </span>
          </div>
        ) : (
          <div>
            <h3 className={clsx(classes.planTitle, 'mb-3')}>{locationName}</h3>
            <span
              className={classes.planText}
              dangerouslySetInnerHTML={{ __html: product.description }}></span>
          </div>
        )}
        <Button
          type={active ? 'primary' : 'secondary'}
          className={clsx('w-100 mt-2', classes.planBtn)}
          background={active ? '' : BLUE_COLOR}
          onClick={handleButtonClick}
          color={active ? '' : BLUE_COLOR}
          data-test-element="select-plan-button">
          <span className="font-kraftig">
            {t(active ? 'choosePlan.selected' : 'choosePlan.select')}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default PlanCard;
