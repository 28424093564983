import { ConfigType } from 'store/ConfigStore/types';
import { IAxiosResponse } from 'utils/types';

export type FoundLocation = {
  id: number;
  title: string;
  targetTitle?: string;
  type?: number | ConfigType;
  canonicalTitle: string;
  stateCode: string;
  focused?: boolean;
};

export type SearchProjects = {
  count: number;
  subscriptionCount: number;
  data: FoundLocation[];
  subscriptionData: FoundLocation[];
};

export interface ISearchStore {
  setFoundLocations(locations: SearchProjects | null): void;
  setSelectedLocation(location: FoundLocation | null): void;
  searchProjects(value: string): Promise<void>;
  lookupLocation(state: string, title: string): Promise<void>;
}

export const SEARCH_SERVICE = 'SEARCH_SERVICE';
export interface ISearchService {
  searchProjects(value: string): Promise<SearchProjects>;
  lookupLocation(state: string, title: string): Promise<FoundLocation>;
}

export const SEARCH_REPO = 'SEARCH_REPO';
export interface ISearchRepo {
  searchProjects(value: string): Promise<IAxiosResponse<SearchProjects>>;
  lookupLocation(state: string, title: string): Promise<IAxiosResponse<FoundLocation>>;
}
