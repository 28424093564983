import React, { FC, ReactNode, useEffect, useRef } from 'react';
import classes from './Modal.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Button from '../Button';
import { IModalActions } from './types';
import { clsx } from 'utils/clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  title: string;
  subTitle?: string;
  deleteBtn?: boolean;
  closeModal: () => void;
  onSubmit?: (e?: React.MouseEvent) => void;
  onDelete?: () => void;
  hideFooter?: boolean;
  actions?: IModalActions;
  children: ReactNode;
  [key: string]: unknown;
}

const Modal: FC<Props> = ({
  show,
  title,
  subTitle,
  deleteBtn,
  actions,
  closeModal,
  onSubmit,
  children,
  onDelete,
  hideFooter,
  ...rest
}) => {
  const modalWrapper = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      window.addEventListener('click', clickOutside);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [show]);

  const clickOutside = (e: MouseEvent): void => {
    if (e.target === modalWrapper.current) {
      closeModal();
    }
  };
  return (
    <div
      className={clsx('modal', show ? 'd-block' : 'd-none', classes.modal)}
      aria-hidden="true"
      ref={modalWrapper}
      {...rest}>
      <div className="modal-dialog modal-dialog-centered justify-content-center">
        <div className={`modal-content border-0 ${classes.modalContent}`}>
          <div className="modal-header border-0 ps-4 pe-2 d-flex align-items-sm-start w-100">
            <div className={classes.modalTitleWrapper}>
              <h5 className={clsx('default-color-text', classes.modalTitle, 'm-0')}>{title}</h5>
              {subTitle && (
                <p className={`default-color-text m-0 ${classes.modalSubTitle}`}>{subTitle}</p>
              )}
            </div>
            <CloseIcon
              className={clsx('position-relative', classes.close)}
              onClick={closeModal}
              role="button"
            />
          </div>
          {children && <div className="modal-body px-4 py-3">{children}</div>}
          {!hideFooter && (
            <div className="modal-footer flex-nowrap p-4 border-light">
              {deleteBtn && (
                <Button type="light" color="red" onClick={onDelete}>
                  <span className="font-kraftig">{t('components.modal.delete')}</span>
                </Button>
              )}
              <div className="d-flex w-100 justify-content-end m-0">
                {actions && (
                  <>
                    <Button type="secondary" className="px-4 mx-3" onClick={closeModal}>
                      <span className="font-kraftig">{actions.negative}</span>
                    </Button>
                    <Button type="primary" className="px-4" onClick={onSubmit}>
                      <span className="font-kraftig">{actions.positive}</span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
