import {
  ISearchProjectsRepo,
  IKeyword,
  IContact,
  ICompanyResponse,
  IProjectsData,
  RelatedProjectKey,
  IRecentProjectsData,
  Statistics
} from './types';
import { ISearchParams } from './components/SubHeader/types';

import { axiosInstance } from 'utils/API';
import { IFullProject } from 'view/ProjectInfoModal/types';
import { IAxiosResponse } from 'utils/types';

export class SearchProjectsRepo implements ISearchProjectsRepo {
  async getKeywords(keyword: string): Promise<IAxiosResponse<IKeyword[]>> {
    return axiosInstance.get(`/keywords?title=${keyword}`);
  }

  async getProjects(
    params: ISearchParams,
    controller?: AbortController
  ): Promise<IAxiosResponse<IProjectsData>> {
    return axiosInstance.post(
      '/projects',
      params,
      controller
        ? {
            signal: controller.signal
          }
        : {}
    );
  }

  async getStatistics(locationId: number): Promise<IAxiosResponse<Statistics>> {
    return axiosInstance.get(`locations/${locationId}/statistics`);
  }

  async getContacts(id: number): Promise<IAxiosResponse<IContact[]>> {
    return axiosInstance.get(`projects/${id}/contacts`);
  }

  async getCompany(
    projectId: number,
    role: string,
    contactId: number
  ): Promise<IAxiosResponse<ICompanyResponse>> {
    return await axiosInstance.get(`/projects/${projectId}/${role}/company/${contactId}`);
  }

  async getRecentProjects(
    contactType: string,
    contactId: number,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>> {
    return await axiosInstance.get(`/contacts/${contactType}/${contactId}/projects`, {
      params: { page }
    });
  }

  async getAllProjectInfo(projectId: number): Promise<IAxiosResponse<IFullProject>> {
    return await axiosInstance.get(`projects/${projectId}`);
  }

  async getRelatedProjects(
    projectId: number,
    type: RelatedProjectKey,
    page: number
  ): Promise<IAxiosResponse<IRecentProjectsData>> {
    return await axiosInstance.get(`projects/${projectId}/other/${type}`, {
      params: { page }
    });
  }
}
