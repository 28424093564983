import {
  IFavoriteData,
  FavoritesList,
  IFavoritesRepo,
  IFavoriteDataBody,
  FavoriteLocation,
  IFavoriteDataRes,
  IFavoriteLocationBody,
  FavoriteData
} from './types';
import { axiosInstance } from 'utils/API';
import { IAxiosResponse } from 'utils/types';

export class FavoritesRepo implements IFavoritesRepo {
  async createFavoritesList(id: number, name: string): Promise<IAxiosResponse<FavoritesList>> {
    return await axiosInstance.post(`users/${id}/favourites/lists`, { name });
  }

  async addToFavorites(id: number, data: IFavoriteData): Promise<void> {
    return await axiosInstance.post(`users/${id}/favourites`, data);
  }
  async deleteFromFavorites(id: number, data: IFavoriteData): Promise<void> {
    return await axiosInstance.delete(`users/${id}/favourites`, { data: data });
  }

  async getListsByEntity(
    id: number,
    type: string,
    entityId: number,
    role: string
  ): Promise<IAxiosResponse<FavoritesList[]>> {
    return await axiosInstance.get(`users/${id}/favourites/${type}/${role}/${entityId}/lists`);
  }

  async getFavoritesList(userId: number): Promise<IAxiosResponse<FavoritesList[]>> {
    return await axiosInstance.get(`/users/${userId}/favourites/lists`);
  }

  async getFavoritesProjectsTotalValue(
    userId: number,
    systemType: string,
    lists: number[]
  ): Promise<IAxiosResponse<number>> {
    return await axiosInstance.post(`users/${userId}/favourites/${systemType}/total`, { lists });
  }

  async deleteFavoriteList(userId: number, listId: number): Promise<void> {
    await axiosInstance.delete(`users/${userId}/favourites/lists/${listId}`);
  }

  async editFavoriteList(
    userId: number,
    listId: number,
    name: string
  ): Promise<IAxiosResponse<FavoritesList>> {
    return await axiosInstance.patch(`users/${userId}/favourites/lists/${listId}`, { name });
  }

  async getFavoritesData(
    userId: number,
    systemType: string,
    body: IFavoriteDataBody
  ): Promise<IAxiosResponse<IFavoriteDataRes<FavoriteData>>> {
    return await axiosInstance.post(`users/${userId}/favourites/${systemType}`, body);
  }

  async getFavoriteLocations(
    userId: number,
    systemType: string,
    body: IFavoriteLocationBody
  ): Promise<IAxiosResponse<FavoriteLocation[]>> {
    return await axiosInstance.post(`users/${userId}/favourites/${systemType}/location`, body);
  }
}
