import { IOption } from 'components/Select/types';
import { IMoreValues } from './types';

export const minValues: IOption[] = [
  { value: '0', text: '0', id: 1 },
  { value: '100000', text: '100,000', id: 2 },
  { value: '200000', text: '200,000', id: 3 },
  { value: '300000', text: '300,000', id: 4 },
  { value: '400000', text: '400,000', id: 5 },
  { value: '500000', text: '500,000', id: 6 },
  { value: '600000', text: '600,000', id: 7 },
  { value: '700000', text: '700,000', id: 8 },
  { value: '800000', text: '800,000', id: 9 },
  { value: '900000', text: '900,000', id: 10 }
];
export const maxValues: IOption[] = [
  { value: '500000', text: '500,000', id: 1 },
  { value: '600000', text: '600,000', id: 2 },
  { value: '700000', text: '700,000', id: 3 },
  { value: '800000', text: '800,000', id: 4 },
  { value: '900000', text: '900,000', id: 5 },
  { value: '1000000', text: '1M', id: 6 },
  { value: '1250000', text: '1.25M', id: 7 },
  { value: '1500000', text: '1.5M', id: 8 },
  { value: '1750000', text: '1.75M', id: 9 },
  { value: null, text: 'Any Price', id: 10 }
];

export const initMoreValues: IMoreValues = {
  units: {
    min: null,
    max: null
  },
  levels: {
    min: null,
    max: null
  },
  status: null,
  keywords: ''
};

export const minOptions: IOption[] = [
  { value: null, text: 'Any', id: 0 },
  { value: '1', text: '1', id: 1 },
  { value: '2', text: '2', id: 2 },
  { value: '4', text: '4', id: 4 },
  { value: '5', text: '5', id: 5 },
  { value: '10', text: '10', id: 10 },
  { value: '25', text: '25', id: 25 },
  { value: '50', text: '50', id: 50 },
  { value: '100', text: '100', id: 100 },
  { value: '200', text: '200', id: 200 }
];

export const maxOptions: IOption[] = [
  { value: '2', text: '2', id: 2 },
  { value: '4', text: '4', id: 4 },
  { value: '5', text: '5', id: 5 },
  { value: '10', text: '10', id: 10 },
  { value: '25', text: '25', id: 25 },
  { value: '50', text: '50', id: 50 },
  { value: '100', text: '100', id: 100 },
  { value: '200', text: '200', id: 200 },
  { value: '300', text: '300', id: 300 },
  { value: null, text: 'Any', id: 6 }
];
