import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useStore } from 'storesProvider/storeContext';
import classes from './LeftSidebar.module.scss';
import { clsx } from 'utils/clsx';
import ProjectInfo from '../ProjectInfo';
import ContactList from '../ContactList';
import { observer } from 'mobx-react';
import { FixedSizeList as List } from 'react-window';
import { IListItem } from './types';
import { skeletonsToShow } from './mock';
import { Skeleton } from 'components';
import { useListWidth } from 'hooks/useListWidth';
import LeftSidebarHeader from './components/LeftSidebarHeader';
import { useListHeight } from 'hooks/useListHeight';
import { FavoriteSubTypes } from 'view/AddToFavorites/types';
import { IListRef, Location } from '../../types';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import { numberWithSpaces } from 'helper/numberWithComma';

interface IProps {
  onLoadMore: () => void;
  isProjectsLoading: boolean;
  handleUnlock: (location: Location | null) => void;
}

const LeftSidebar: FC<IProps> = observer(({ onLoadMore, isProjectsLoading, handleUnlock }) => {
  const { projectSearchStore, filtersStore, searchStore } = useStore();
  const [, setParams] = useSearchParams();

  const listWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const projectList = useRef() as MutableRefObject<IListRef>;

  const [activeId, setActiveId] = useState<null | number>(null);

  const listWidth = useListWidth(listWrapperRef);
  const listHeight = useListHeight(listWrapperRef);

  const onSelectItem = useCallback((id: number) => {
    setActiveId((prev) => {
      if (prev === id) {
        return null;
      }
      projectSearchStore.setSelectedProjectOnTheMap(null);
      return id;
    });
  }, []);

  const closeContacts = useCallback(() => setActiveId(null), []);

  useEffect(() => {
    if (projectSearchStore.selectedProjectOnTheMap !== null && projectList.current) {
      projectList.current.scrollToItem(projectSearchStore.selectedProjectOnTheMap, 'start');
    }
  }, [projectSearchStore.selectedProjectOnTheMap]);

  // TODO: remove this function if it is not needed
  // const handlePageClick = (event: ClickEvent) => {
  //   if (!userStore.user) {
  //     setParams('auth=sign-up&extended=true');
  //     return;
  //   }
  //   filtersStore.setSearchParams('page', event.selected + 1);
  // };

  const onUnlock = useCallback(
    (location: Location | null) => {
      handleUnlock(location);
      closeContacts();
    },
    [handleUnlock]
  );

  const replacePagesWithCommas = useCallback(() => {
    const pages = document.querySelectorAll('.page-link');
    pages.forEach((pageEl) => {
      const content = numberWithSpaces(pageEl.textContent || '');
      if (Number(pageEl.textContent) && !Number.isNaN(Number(pageEl.textContent))) {
        pageEl.textContent = content;
      }
    });
  }, []);

  const isContactsLocked = useMemo<boolean>(() => {
    if (!activeId) return true;
    return !projectSearchStore.projectsData.hasSubscription;
  }, [activeId]);

  const contactsLocation = useMemo<Location | null>(() => {
    return (
      projectSearchStore.projectsData.data.find((project) => project.id === activeId)?.location
        .county || null
    );
  }, [activeId]);

  useEffect(() => {
    closeContacts();
    replacePagesWithCommas();
  }, [filtersStore.searchParams, projectSearchStore.projectsData]);

  useEffect(() => {
    const btns = document.querySelectorAll('.page-item');
    const handleClick = (e: Event) => {
      if (projectSearchStore.projectsData.hasSubscription) return;
      e.stopPropagation();
      onUnlock(searchStore.selectedLocation as unknown as Location);
    };
    btns.forEach((btn) => btn.addEventListener('click', handleClick));
    return () => {
      btns.forEach((btn) => btn.removeEventListener('click', handleClick));
    };
  }, [projectSearchStore.projectsData]);

  const nextPage = useCallback((): void => {
    filtersStore.setSearchParams('page', filtersStore.searchParams.page + 1);
  }, []);

  const prevPage = useCallback((): void => {
    filtersStore.setSearchParams('page', filtersStore.searchParams.page - 1);
  }, []);

  return (
    <div className={clsx(classes.wrapper, 'position-relative')}>
      <LeftSidebarHeader />
      <div className={classes.projectsWrapper} ref={listWrapperRef}>
        {isProjectsLoading ? (
          <Skeleton type="project" itemsToShow={skeletonsToShow} />
        ) : projectSearchStore.projectsData.data.length ? (
          <List
            className={classes.projectsList}
            height={listHeight}
            itemCount={projectSearchStore.projectsData.data.length}
            itemSize={185}
            width={listWidth || 0}
            ref={projectList}>
            {({ index, style }: IListItem) => (
              <ProjectInfo
                style={style}
                key={projectSearchStore.projectsData.data[index].id}
                project={projectSearchStore.projectsData.data[index]}
                active={activeId === projectSearchStore.projectsData.data[index].id}
                onSelectItem={onSelectItem}
                index={index}
                listRef={projectList}
                isLocked={projectSearchStore.projectsData.data[index].isLocked}
                handleUnlock={!projectSearchStore.projectsData.hasSubscription ? onUnlock : null}
                type="projects"
              />
            )}
          </List>
        ) : (
          <div className={clsx('p-2', 'text-center', 'bg-white', classes.emptyResults)}>
            There isn&apos;t any project matches the specified filter
          </div>
        )}
      </div>
      <div className="position-fixed bottom-0 bg-white z-index-10" style={{ width: listWidth }}>
        <div className="d-flex p-2 pagination-block justify-content-center">
          <button
            className="page-item mx-1 cursor-pointer"
            disabled={filtersStore.searchParams.page === 1}
            onClick={prevPage}>
            <ArrowIcon data-direction="left" />
          </button>
          <button
            className="page-item mx-1 cursor-pointer"
            disabled={!projectSearchStore.projectsData.hasMore}
            onClick={nextPage}>
            <ArrowIcon data-direction="right" />
          </button>
        </div>
      </div>
      {activeId && (
        <ContactList
          projectId={activeId}
          position="right"
          onLoadMore={onLoadMore}
          closeList={closeContacts}
          type={FavoriteSubTypes.regular}
          handleUnlock={() => handleUnlock(contactsLocation)}
          isLocked={isContactsLocked}
        />
      )}
    </div>
  );
});

export default LeftSidebar;
