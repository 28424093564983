import React, { FC, useCallback, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from 'storesProvider/storeContext';
import { clsx } from 'utils/clsx';
import classes from './Home.module.scss';
import { Button, Input, SearchList } from 'components';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as IllustrationIcon } from 'assets/icons/home-illustration.svg';
import getContactIcon from 'assets/img/home/add-contact.svg';
import stayAheadIcon from 'assets/img/home/stay-ahead.svg';
import projectsIcon from 'assets/img/home/projects.svg';
import integrateIcon from 'assets/img/home/integrate.svg';
import { FoundLocation, SearchProjects } from '../Search/types';
import { useDebounce } from 'hooks/useDebounce';
import { BLUE_COLOR, DEBOUNCE_DELAY, GREY_LIGHT_COLOR, WHITE_COLOR } from 'utils/constants';
import Logo from 'assets/icons/logo-light.svg';
import TrustedBy from './components/TrustedBy';
import IncreasePipeline from './components/IncreasePipeline';
import { ISimpleBlock } from './components/SimpleBlock/types';
import SimpleBlock from './components/SimpleBlock';
import Reports from './components/Reports';
import Footer from './components/Footer';
import { useKeyboardCallbacks } from 'hooks/useKeyboardCallbacks';
import { usePrevious } from 'hooks/usePrevious';

const simpleBlocks: ISimpleBlock[] = [
  {
    id: 1,
    title: 'Get Contact Information For Projects in Your Area in Real Time',
    subTitle: 'The best ROI and lowest cost per lead.',
    img: getContactIcon,
    verticalPaddings: 115
  },
  {
    id: 2,
    title: 'Stay ahead of your competitors',
    img: stayAheadIcon,
    verticalPaddings: 64
  },
  {
    id: 3,
    title: 'Win better jobs faster with less work',
    img: projectsIcon,
    verticalPaddings: 100
  },
  {
    id: 4,
    title: 'Seamlessly integrate all your marketing software',
    img: integrateIcon,
    verticalPaddings: 95
  }
];

const Home: FC = () => {
  // TODO: Search input move to separate component
  const { t } = useTranslation();
  const [, setParams] = useSearchParams();
  // TODO: Move duplicate code to separate component
  const navigate = useNavigate();
  const { alertStore, searchStore, userStore } = useStore();
  const [params] = useSearchParams();
  const [searchString, setSearchString] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchProjects | null>(null);
  const [showSearchList, setShowSearchList] = useState<boolean>(false);
  const [lastInputValue, setLastInputValue] = useState<string>('');
  const [canLoadLocations, setCanLoadLocations] = useState<boolean>(true);
  const debouncedSearchTerm = useDebounce(searchString, DEBOUNCE_DELAY);
  const id = useId();
  const prevTerm = usePrevious(debouncedSearchTerm);

  const setValueWithoutFetching = useCallback((value: string) => {
    setCanLoadLocations(false);
    setSearchString(value);
  }, []);

  const { handleKeyUp, handleKeyDown, handleKeyEnter, handleKeyEscape } = useKeyboardCallbacks({
    searchResults,
    setSearchResults,
    lastInputValue,
    setLastInputValue,
    setValueWithoutFetching,
    setShowSearchList
  });

  const inputSearch = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleInput = useCallback((name: string, value: string) => {
    setCanLoadLocations(true);
    setSearchString(value);
    setLastInputValue(value);
  }, []);

  useEffect(() => {
    if (userStore.user) {
      const steps = ['confirm-current-email', 'reset', 'verify-email', 'reset-password'];
      const authStep = params.get('auth');
      if (!steps.includes(String(authStep))) {
        navigate(`/saved-search`, { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (!canLoadLocations) return;
    (async () => await loadLocations())();
  }, [debouncedSearchTerm, canLoadLocations]);

  const loadLocations = useCallback(
    async (loadAnyway = false) => {
      if (!prevTerm.length && !debouncedSearchTerm.length && !loadAnyway) return;
      await searchStore.searchProjects(debouncedSearchTerm);
      setSearchResults(searchStore.foundLocations);
      setShowSearchList(true);
      if (!canLoadLocations && !loadAnyway) {
        setCanLoadLocations(true);
        setShowSearchList(false);
      }
    },
    [debouncedSearchTerm, canLoadLocations, prevTerm]
  );

  const handleInputFocus = useCallback(async () => {
    setCanLoadLocations(true);
    await loadLocations(true);
  }, [loadLocations]);

  const onSearchListChange = (item: FoundLocation): void => {
    navigate(`/search-projects/${item.stateCode.toLowerCase()}/${item.canonicalTitle}`, {
      replace: true
    });
  };

  const handleCloseList = (): void => {
    setShowSearchList(false);
    setSearchResults(null);
  };

  const searchProjectsAction = (): void => {
    // TODO: output alert in this page or fix after routing update
    alertStore.errorAlert(t('home.searchBtnError'));
    const currentInput = inputSearch.current.querySelector('input');
    if (currentInput) {
      currentInput.focus();
    }
  };
  const openLogin = useCallback(() => setParams('auth=sign-in'), []);
  const openRegister = useCallback(() => setParams('auth=sign-up'), []);

  return (
    <div className={clsx(classes.home)}>
      <div className="container d-flex justify-content-between pt-3">
        <div>
          <img src={Logo} alt="close" className="position-relative" role="button" />
        </div>
        <div className="d-flex">
          <Button
            type="secondary"
            background="#DBDFEA"
            color="#2F3139"
            className="px-4 me-3"
            onClick={openLogin}>
            <span className="font-kraftig">{t('auth.login')}</span>
          </Button>
          <Button
            type="secondary"
            background={BLUE_COLOR}
            color={BLUE_COLOR}
            className="px-4"
            onClick={openRegister}>
            <span className="font-kraftig">{t('home.getStarted')}</span>
          </Button>
        </div>
      </div>
      <div className="mb-5 mt-5 position-relative">
        <IllustrationIcon className="m-auto d-block" />
        <div
          className={clsx(
            'position-absolute',
            'start-50',
            'translate-middle-x',
            classes.introWrapper
          )}>
          <div className={clsx(classes.intro, 'text-center')}>
            <h1 className="default-color-text mb-3">{t('home.introTitle')}</h1>
            <p>{t('home.introSubTitle')}</p>
          </div>
          <div className={clsx(classes.searchWrapper, 'p-3')}>
            <div className={clsx(classes.searchInputWrapper, 'd-flex')}>
              <div className="flex-grow-1 position-relative" ref={inputSearch}>
                <Input
                  data-input-id={id}
                  name="search"
                  type="text"
                  autoComplete="off"
                  value={searchString}
                  onInput={handleInput}
                  onFocus={handleInputFocus}
                  inputClass="w-100 px-4 py-3"
                  data-test-element="dashboard-search-input">
                  <div
                    data-input-id={id}
                    className={clsx(
                      'd-flex',
                      'w-100',
                      'align-items-center',
                      'h-100',
                      'px-4',
                      'py-3',
                      classes.inputSearchOverlayText
                    )}>
                    <div className="me-2 d-flex align-items-center">
                      <SearchIcon className={classes.searchIcon} />
                    </div>
                    &nbsp;{t('savedSearch.inputText')}
                  </div>
                </Input>
                <SearchList
                  listResponse={searchResults}
                  selectedLocation={searchStore.selectedLocation}
                  onChange={onSearchListChange}
                  styleTop="58"
                  show={showSearchList}
                  closeList={handleCloseList}
                  inputId={id}
                  onKeyUp={handleKeyUp}
                  onKeyDown={handleKeyDown}
                  onKeyEnter={handleKeyEnter}
                  onKeyEscape={handleKeyEscape}
                />
              </div>
              <Button type="primary" className="px-4 py-3 ms-2" onClick={searchProjectsAction}>
                <span className="font-kraftig">{t('savedSearch.searchForProjectsNow')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TrustedBy />
      <IncreasePipeline />
      {simpleBlocks.map((block, index) => (
        <SimpleBlock
          {...block}
          key={block.id}
          isReversed={!!(index % 2)}
          background={index % 2 ? GREY_LIGHT_COLOR : WHITE_COLOR}
        />
      ))}
      <Reports />
      <Footer />
    </div>
  );
};

export default Home;
