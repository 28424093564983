import {
  IChangeEmailData,
  IChangeEmailResponse,
  IChangePasswordData,
  IMedia,
  IUserRepo,
  UserData
} from './types';
import { axiosInstance } from 'utils/API';
import { IEditProfileData } from './Settings/types';
import { IAxiosResponse } from 'utils/types';

export class UserRepo implements IUserRepo {
  async editUserPassword(data: IChangePasswordData): Promise<void> {
    return await axiosInstance.patch('/profile/password', data);
  }

  async getCurrentUser(): Promise<IAxiosResponse<UserData>> {
    return await axiosInstance.get('/profile');
  }

  async editUserInfo(data: IEditProfileData): Promise<IAxiosResponse<UserData>> {
    return await axiosInstance.patch('/profile', data);
  }

  //TODO: would be moved to a new api if it was more media
  async setAvatar(data: FormData): Promise<IAxiosResponse<IMedia>> {
    return await axiosInstance.post('/media', data);
  }

  async editUserEmail(data: IChangeEmailData): Promise<void> {
    return await axiosInstance.patch('/profile/email', data);
  }

  async confirmEmailToken(token: string): Promise<IAxiosResponse<IChangeEmailResponse>> {
    return await axiosInstance.post('/profile/change-email-confirmation', { token });
  }
}
