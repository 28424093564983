import React, { FC, useCallback, useEffect, useState } from 'react';
import { clsx } from 'utils/clsx';
import classes from './SubHeader.module.scss';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';
import SaveSearchButton from './components/SaveSearchButton';
import { observer } from 'mobx-react';
import DateDropdown from './components/DateDropdown';
import ValuationDropdown from './components/ValuationDropdown';
import MoreDropdown from './components/MoreDropdown';
import { IDropCategories, IDropdownEvent, IInputChangeItem } from './types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TreeDropdown from './components/TreeDropdown';
import { useDebounce } from 'hooks/useDebounce';
import { DROPDOWN_DEBOUNCE_DELAY } from 'utils/constants';
import { IOption } from 'components/Select/types';
import styles from './SubHeader.module.scss';
import { Button } from 'components';

interface Props {
  onSaveSearch: (name: string) => void;
}

const SubHeader: FC<Props> = observer(({ onSaveSearch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();

  const { alertStore, filtersStore, userStore } = useStore();
  const [saveSearchVisible, setSaveSearchVisible] = useState<boolean>(false);
  const [categories, setCategories] = useState<IDropCategories>({
    classes: filtersStore.searchParams.classes,
    types: filtersStore.searchParams.types,
    subTypes: filtersStore.searchParams.subTypes
  });
  const [permitTypes, setPermitTypes] = useState<number[]>(filtersStore.searchParams.permitTypes);
  const debouncedCategories = useDebounce(categories, DROPDOWN_DEBOUNCE_DELAY);
  const debouncedPermitTypes = useDebounce(permitTypes, DROPDOWN_DEBOUNCE_DELAY);
  const [newSearchName, setNewSearchName] = useState<string>('');

  const handleSearchNameInput = useCallback(
    (name: string, value: string) => setNewSearchName(value),
    []
  );
  const onSaveClick = useCallback(() => {
    if (!userStore.user) {
      navigate('?auth=sign-up&extended=true');
      return;
    }
    setSaveSearchVisible((prev) => !prev);
  }, []);
  const hideSaveSearch = useCallback(() => {
    setSaveSearchVisible(false);
    setNewSearchName('');
  }, []);

  const handleSaveSearch = useCallback(() => {
    if (newSearchName.length < 3) {
      alertStore.errorAlert('This name is too short. It should have 3 characters or more.');
      return;
    }
    onSaveSearch(newSearchName);
    hideSaveSearch();
  }, [newSearchName]);

  const handleSaveValue = useCallback((value: IInputChangeItem) => {
    if (!userStore.user) {
      setParams('auth=sign-up&extended=true');
      filtersStore.onInputChange({ min: null, max: null }, 'value');
      return;
    }
    filtersStore.onInputChange(value, 'value');
  }, []);

  const handleResetValue = useCallback(() => {
    filtersStore.onInputChange({ min: null, max: null }, 'value');
  }, []);

  const handleResetDate = useCallback(() => {
    filtersStore.onSelectReset('date');
    filtersStore.setCustomDate(null);
  }, []);

  const handleCategoryChange = useCallback(
    ({ activeClasses, activeTypes, activeSubtypes }: IDropdownEvent) => {
      if (!userStore.user) {
        setParams('auth=sign-up&extended=true');
        return;
      }
      setCategories((prev) => ({
        classes: activeClasses || prev.classes,
        types: activeTypes || prev.types,
        subTypes: activeSubtypes || prev.subTypes
      }));
    },
    []
  );

  const handlePropertyChangeChange = useCallback(({ activePermits }: IDropdownEvent) => {
    if (!userStore.user) {
      setParams('auth=sign-up&extended=true');
      return;
    }
    if (activePermits) {
      setPermitTypes([...activePermits]);
    }
  }, []);

  const handleDateChange = useCallback((option: IOption, field: string) => {
    if (!userStore.user) {
      setParams('auth=sign-up&extended=true');
      return;
    }
    filtersStore.onSelectChange(option, field);
  }, []);

  useEffect(() => {
    filtersStore.onCategoryChange(categories.classes, categories.types, categories.subTypes);
  }, [debouncedCategories]);

  useEffect(() => {
    filtersStore.onPermitsChange(permitTypes);
  }, [debouncedPermitTypes]);

  const handleReset = useCallback(() => {
    filtersStore.handleReset();
    const treeFilters = document.querySelectorAll('.dropdown-trigger.arrow');
    treeFilters.forEach((filter) => filter.removeAttribute('aria-labelledby'));
  }, []);

  return (
    <div
      className={clsx(
        'default-shadow',
        'default-color-text',
        'px-3',
        'py-2',
        'bg-white',
        'position-relative',
        classes.wrapper
      )}>
      <div className="d-flex justify-content-between py-1">
        <div className="d-flex align-items-center">
          <DateDropdown
            customValue={filtersStore.customDate}
            onChange={handleDateChange}
            onCustomChange={filtersStore.onCustomDateChange}
            onReset={handleResetDate}
            value={filtersStore.selectedDate}
          />
          <TreeDropdown
            data={filtersStore.categories}
            onChange={handleCategoryChange}
            placeholder={t('components.dropdown.placeholder')}
          />
          <TreeDropdown
            data={filtersStore.permitTypes}
            placeholder={t('components.dropdown.placeholderPermitType')}
            onChange={handlePropertyChangeChange}
            dropdownClass="permit-types"
          />
          <ValuationDropdown
            onReset={handleResetValue}
            onSave={handleSaveValue}
            value={filtersStore.searchParams.value}
            placeholder={t('searchProject.valuation')}
          />
          <MoreDropdown />
          <SaveSearchButton
            handleSaveSearch={handleSaveSearch}
            hideSaveSearch={hideSaveSearch}
            onInput={handleSearchNameInput}
            onSaveClick={onSaveClick}
            saveSearchVisible={saveSearchVisible}
            value={newSearchName}
          />
          {filtersStore.isActiveFilters() && (
            <Button
              type="light"
              color="#0087FF"
              className={clsx(styles.moreBtn, 'mx-4')}
              onClick={handleReset}>
              <span className="font-kraftig">{t('searchProject.resetAll')}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export default SubHeader;
