import { ISearchParams } from 'view/SearchProjects/components/SubHeader/types';
import { DetailInfo } from 'view/SearchProjects/components/Map/types';
import { FoundLocation } from 'view/Search/types';
import { IAxiosResponse } from 'utils/types';

export interface IModalData {
  title: string;
  subtitle: string;
  negativeAction: string;
  positiveAction: string;
}

export interface ISavedSearch {
  id: number;
  name: string;
  filters: ISearchParams;
  viewedAt: string;
  newProjects: number;
  description: string;
}

export interface ISavedSearchStore {
  newProjectsCount: number;
  saveSearch(id: number, name: string, filters: ISearchParams): Promise<ISavedSearch>;
  getSavedSearches(id: number): Promise<void>;
  editSavedSearches(userId: number, searchId: number, name: string): Promise<void>;
  deleteSavedSearches(userId: number, searchId: number): Promise<void>;
  getNewProjectCount(userId: number): Promise<void>;
  savedSearches: ISavedSearch[];
  setSavedSearch: (searches: ISavedSearch[]) => void;
  getLocationInfo(locationId: number): Promise<FoundLocation>;
}

export const SAVED_SEARCH_SERVICE = 'SAVED_SEARCH_SERVICE';
export interface ISavedSearchService {
  saveSearch(id: number, name: string, filters: ISearchParams): Promise<ISavedSearch>;
  getSavedSearches(id: number): Promise<ISavedSearch[]>;
  editSavedSearches(userId: number, searchId: number, name: string): Promise<ISavedSearch>;
  deleteSavedSearches(userId: number, searchId: number): Promise<void>;
  getNewProjectCount(userId: number): Promise<number>;
  getLocationInfo(locationId: number): Promise<FoundLocation>;
}

export const SAVED_SEARCH_REPO = 'SAVED_SEARCH_REPO';
export interface ISavedSearchRepo {
  saveSearch(
    id: number,
    name: string,
    filters: ISearchParams
  ): Promise<IAxiosResponse<ISavedSearch>>;
  getSavedSearches(id: number): Promise<IAxiosResponse<ISavedSearch[]>>;
  editSavedSearches(
    userId: number,
    searchId: number,
    name: string
  ): Promise<IAxiosResponse<ISavedSearch>>;
  deleteSavedSearches(userId: number, searchId: number): Promise<void>;
  getNewProjectCount(userId: number): Promise<IAxiosResponse<number>>;
  getLocationInfo(locationId: number): Promise<IAxiosResponse<DetailInfo>>;
}
