import { FoundLocation, ISearchRepo, SearchProjects } from './types';
import { axiosInstance } from 'utils/API';
import { IAxiosResponse } from 'utils/types';

export class SearchRepo implements ISearchRepo {
  async searchProjects(value: string): Promise<IAxiosResponse<SearchProjects>> {
    return await axiosInstance.get(`/locations/autocomplete?query=${value}`);
  }

  async lookupLocation(state: string, title: string): Promise<IAxiosResponse<FoundLocation>> {
    return await axiosInstance.get(`locations/lookup`, { params: { state, title } });
  }
}
