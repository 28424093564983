import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Button, PortalModal } from 'components';
import classes from './AddNoteModal.module.scss';
import { INoteEntity } from './types';
import { clsx } from 'utils/clsx';
import { useStore } from 'storesProvider/storeContext';
import { useTranslation } from 'react-i18next';

interface IProps {
  entity: INoteEntity;
  closeModal: () => void;
  onNoteChange: (id: number, isActive: boolean) => void;
}

const AddNoteModal: FC<IProps> = ({ entity, closeModal, onNoteChange }) => {
  const { alertStore, noteStore } = useStore();
  const { t } = useTranslation();
  const [note, setNote] = useState<string>('');
  const [isNoteLoading, setIsNoteLoading] = useState<boolean>(false);
  const [isNoteSending, setIsNoteSending] = useState<boolean>(false);

  const handleInputChange = useCallback(
    (e: SyntheticEvent) => setNote((e.target as HTMLTextAreaElement).value),
    []
  );

  const handleSaveNote = useCallback(async () => {
    try {
      if (!entity.type || !entity.id) return;
      setIsNoteSending(true);
      await noteStore.addNote(entity.id, entity.type, note);
      alertStore.successAlert(t('addNote.successAddNote'));
      onNoteChange(entity.id, !!note);
      closeModal();
    } finally {
      setIsNoteSending(false);
    }
  }, [note]);

  useEffect(() => {
    (async () => {
      if (!entity.type || !entity.id) return;
      try {
        setIsNoteLoading(true);
        setNote((await noteStore.getNote(entity.id, entity.type))?.content || '');
      } finally {
        setIsNoteLoading(false);
      }
    })();
  }, [entity]);

  if (!entity.coordinates || !entity.id) return <></>;

  return (
    <PortalModal closeModal={closeModal} coordinates={entity.coordinates}>
      <div id={`note-${entity.id}`}>
        <div className="p-4">
          <h1 className={clsx(classes.title, 'mb-1', !entity.description && 'mb-4')}>
            {t('addNote.noteAbout')} {entity.name}
          </h1>
          {entity.description && (
            <p className={clsx(classes.subtitle, 'mb-4', 'pb-2')}>{entity.description}</p>
          )}
          <h4 className={classes.note}>{t('addNote.note')}</h4>
          <div className="position-relative">
            <textarea
              className={clsx(
                classes.textarea,
                'd-block',
                'w-100',
                'py-2',
                isNoteLoading && 'opacity-50'
              )}
              rows={4}
              name="note"
              onInput={handleInputChange}
              value={note}
              placeholder={isNoteLoading ? '' : t('addNote.placeholder')}
            />
            {isNoteLoading && (
              <div
                className={clsx(
                  'position-absolute',
                  'top-0',
                  'start-0',
                  'bottom-0',
                  'end-0',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  classes.overlay
                )}>
                <div className="loader">
                  <div />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.divider} />
        <div className="d-flex p-4 justify-content-end align-items-center">
          <Button className="px-4 me-3" type="secondary" onClick={closeModal}>
            <span className="font-kraftig">{t('addNote.cancel')}</span>
          </Button>
          <Button
            className="px-4"
            type="primary"
            onClick={handleSaveNote}
            isLoading={isNoteSending}>
            <span className="font-kraftig">{t('addNote.save')}</span>
          </Button>
        </div>
      </div>
    </PortalModal>
  );
};

export default AddNoteModal;
