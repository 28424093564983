import { injector } from 'utils/injector';
import { FoundLocation, ISearchRepo, ISearchService, SEARCH_REPO, SearchProjects } from './types';

export class SearchService implements ISearchService {
  private _searchRepo: ISearchRepo = injector.get<ISearchRepo>(SEARCH_REPO);

  async searchProjects(value: string): Promise<SearchProjects> {
    const res = (await this._searchRepo.searchProjects(value)).data.result;
    return {
      ...res,
      subscriptionData: res.subscriptionData.map((item) => ({ ...item, focused: false })),
      data: res.data.map((item) => ({ ...item, focused: false }))
    };
  }

  async lookupLocation(state: string, title: string): Promise<FoundLocation> {
    return (await this._searchRepo.lookupLocation(state, title)).data.result;
  }
}
