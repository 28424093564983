import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IEditEmailFieldData } from 'view/Profile/Settings/types';
import { Button, Input } from 'components';
import { UserData } from 'view/Profile/types';
import { useStore } from 'storesProvider/storeContext';
import { useFormik } from 'formik';
import { ChangePasswordSchema } from 'utils/Formik/validation';
import InfoModal from 'view/Auth/components/InfoModal';
import { useTranslation } from 'react-i18next';
import closeIcon from 'assets/icons/close.svg';

interface IProps {
  user: UserData;
  titleClassName: string;
}

const EditEmailForm: FC<IProps> = ({ user, titleClassName }) => {
  const { userStore } = useStore();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleEditEmail = useCallback(async (formData: IEditEmailFieldData) => {
    await userStore.editUserEmail({ email: formData.new });
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => setShowModal(false), []);

  const formik = useFormik<IEditEmailFieldData>({
    onSubmit: handleEditEmail,
    initialValues: {
      current: user.email,
      new: ''
    },
    validationSchema: ChangePasswordSchema,
    validate: (values: IEditEmailFieldData) => {
      if (values.current !== user.email) {
        return {
          current: t('auth.wrongEmail')
        };
      }
    }
  });

  const onInputChange = useCallback((name: string, value: string): void => {
    formik.setFieldTouched(name);
    formik.handleChange({ target: { value, name } });
  }, []);

  const isFormDisabled = useMemo<boolean>(
    () => !!Object.values(formik.errors).length,
    [formik.values, formik.errors]
  );

  useEffect(() => {
    formik.setValues({ ...formik.values, current: user.email });
  }, [user.email]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <h2 className={titleClassName}>Edit Email Address</h2>
        <div className="mb-4">
          <Input
            type="email"
            value={formik.values.current}
            onInput={onInputChange}
            isError={!!formik.errors.current && formik.touched.current}
            errorText={formik.errors.current}
            name="current"
            label={t('profile.currentEmail')}
            data-test-element="edit-current-email-input"
          />
        </div>
        <div className="mb-4">
          <Input
            type="email"
            value={formik.values.new}
            onInput={onInputChange}
            isError={!!formik.errors.new && formik.touched.new}
            errorText={formik.errors.new}
            name="new"
            label={t('profile.newEmail')}
            data-test-element="edit-new-email-input"
            placeholder="eg. mail-to@domain.com"
          />
        </div>
        <Button
          type="primary"
          className="px-5"
          disabled={isFormDisabled}
          data-test-element="edit-email-submit-button">
          <span className="font-kraftig">Save</span>
        </Button>
        {showModal && (
          <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center bg-modal">
            <div className="position-relative">
              <InfoModal
                info={t('auth.changeEmailSubtitle')}
                buttonText={t('auth.continue')}
                onBtnClick={closeModal}
                title={t('auth.changeEmailTitle')}
              />
              <img
                src={closeIcon}
                alt="close"
                className="position-absolute top-0 end-0 p-2"
                onClick={closeModal}
                role="button"
              />
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default EditEmailForm;
