import React, { useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AuthModal from './components/AuthModal';
import { SignUp } from './components/SignUp';
import { SignIn } from './components/SignIn';
import ResetConfirm from './components/ResetConfirm';
import ConfirmAccount from './components/ConfirmAccount';
import Reset from './components/Reset';
import ConfirmEmailChange from './components/ConfirmEmailChange';
import { AuthStep, EmailType } from './types';

const AuthContainer = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const authStep = params.get('auth');

  const handleClose = useCallback(() => {
    navigate(location.pathname);
  }, [location]);

  return (
    <>
      <AuthModal show={!!authStep} onClose={handleClose}>
        <>
          {authStep === AuthStep.SignUp && <SignUp />}
          {authStep === AuthStep.SignIn && <SignIn />}
          {authStep === AuthStep.Reset && <Reset />}
          {authStep === AuthStep.ResetPassword && <ResetConfirm />}
          {authStep === AuthStep.VerifyEmail && <ConfirmAccount />}
          {authStep === AuthStep.ConfirmCurrentEmail && (
            <ConfirmEmailChange emailType={EmailType.Current} />
          )}
          {authStep === AuthStep.ConfirmNewEmail && (
            <ConfirmEmailChange emailType={EmailType.New} />
          )}
        </>
      </AuthModal>
      <Outlet />
    </>
  );
};

export default AuthContainer;
