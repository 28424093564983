import React, { FC } from 'react';
import { clsx } from 'utils/clsx';
import classes from './TrustedBy.module.scss';
import { ITrustedItem } from './types';
import { ReactComponent as DepotLogo } from 'assets/icons/home-depot.svg';
import { ReactComponent as LowersLogo } from 'assets/icons/lower_s.svg';
import { ReactComponent as GafLogo } from 'assets/icons/GAF.svg';
import { ReactComponent as KohlerLogo } from 'assets/icons/kohler.svg';
import { ReactComponent as FergusonLogo } from 'assets/icons/ferguson.svg';
import { useTranslation } from 'react-i18next';

const trusted: ITrustedItem[] = [
  {
    id: 1,
    name: 'The Home Depot',
    Icon: DepotLogo
  },
  {
    id: 2,
    name: "Lower's",
    Icon: LowersLogo
  },
  {
    id: 3,
    name: 'GAF',
    Icon: GafLogo
  },
  {
    id: 4,
    name: 'Kohler',
    Icon: KohlerLogo
  },
  {
    id: 5,
    name: 'Build With Ferguson',
    Icon: FergusonLogo
  }
];

const TrustedBy: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.container, 'm-auto', 'mb-5')}>
      <h3 className={clsx('text-center', classes.title, 'mb-4', 'pb-2')}>
        {t('home.trustedTitle')}
      </h3>
      <div className={clsx('d-flex', 'align-items-center', 'justify-content-between')}>
        {trusted.map((Trusted) => (
          <div key={`${Trusted.name}-${Trusted.id}`}>
            <Trusted.Icon />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustedBy;
