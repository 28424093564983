import React, { FC } from 'react';
import { clsx } from '../../utils/clsx';

interface IProps {
  progress: number;
  thin?: boolean;
}

const ProgressBar: FC<IProps> = ({ progress, thin }) => {
  return (
    <div className={clsx('progress bg-transparent position-relative', thin && 'thin')}>
      <div
        className={'progress-bar bg-secondary d-flex align-items-center flex-row position-relative'}
        role="progressbar"
        style={{ width: `${progress}%` }}>
        <span className="font-kraftig">&nbsp;</span>
        <span className="font-halbfett">&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </div>
  );
};

export default ProgressBar;
