import React, { useCallback, useEffect, useState } from 'react';
import SubHeader from './components/SubHeader';
import { useStore } from 'storesProvider/storeContext';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import MapProjects from './components/Map';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import ProjectInfoModal from 'view/ProjectInfoModal';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ChoosePlanModal from 'view/SubscriptionsAndPlans/ChoosePlanModal';
import { usePrevious } from 'hooks/usePrevious';
import { ISearchParams } from './components/SubHeader/types';
import { Location } from './types';

const SearchProjects = observer(() => {
  const {
    projectSearchStore,
    alertStore,
    savedSearchesStore,
    filtersStore,
    searchStore,
    userStore
  } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams = filtersStore.searchParams;
  const prevParams = usePrevious<ISearchParams>(searchParams);
  const [params, setParams] = useSearchParams();
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [showRightSidebar, setShowRightSidebar] = useState<boolean>(false);
  const [showPlan, setShowPlan] = useState<boolean>(false);
  const [planLocation, setPlanLocation] = useState<Location | null>(null);
  const { pathname } = useLocation();

  const details = params.get('details');

  useEffect(() => {
    return () => {
      searchStore.setSelectedLocation(null);
      filtersStore.handleReset();
    };
  }, []);

  useEffect(() => {
    const path = pathname.split('/');
    (async () => {
      try {
        await searchStore.lookupLocation(path[2], path[3]);
      } catch (e) {
        navigate('/', { replace: true });
      }
    })();
  }, [pathname]);

  useEffect(() => {
    if (searchStore.selectedLocation) {
      filtersStore.setSearchParams('location', searchStore.selectedLocation.id);
    }
  }, [searchStore.selectedLocation]);

  useEffect(() => {
    if (prevParams.page === searchParams.page) filtersStore.setSearchParams('page', 1);
    if (searchParams.location && searchParams.area) {
      const controller = new AbortController();
      loadProjects(controller).then();
      return () => {
        controller.abort();
      };
    }
  }, [searchParams]);

  const loadProjects = useCallback(
    async (controller?: AbortController) => {
      try {
        setIsProjectsLoading(true);
        await projectSearchStore.getProjects(searchParams, controller);
      } finally {
        setIsProjectsLoading(false);
      }
    },
    [searchParams]
  );

  const onLoadMore = useCallback(() => setShowRightSidebar(true), []);

  const onCloseRightSidebar = (): void => {
    setShowRightSidebar(false);
  };

  const handleSaveSearch = useCallback(
    async (name: string) => {
      if (!userStore.user) return;
      const params = { ...searchParams };
      delete params.area;
      const result = await savedSearchesStore.saveSearch(userStore.user.id, name, params);
      alertStore.successAlert(
        <>
          <span className="font-kraftig">{result.name}</span> {t('searchProject.listCreated')}{' '}
          <span className="font-kraftig">{result.description}</span> {t('searchProject.savedTo')}{' '}
          <Link to={`/saved-search/${result.id}`} className="font-kraftig">
            {result.name}
          </Link>
          .
        </>
      );
      await savedSearchesStore.getSavedSearches(userStore.user.id);
    },
    [searchParams]
  );

  const handleUnlock = useCallback((location: Location | null) => {
    if (!userStore.user) {
      setParams('auth=sign-up&extended=true');
      return;
    }
    setShowPlan(true);
    setPlanLocation(location);
  }, []);

  const handleUpdateProjects = async (): Promise<void> => {
    await loadProjects();
    await getStatistics();
    // check project and show modal
  };

  useEffect(() => {
    (async () => await handleUpdateProjects())();
  }, [userStore.user]);

  useEffect(() => {
    if (!projectSearchStore.isProjectsLoadingHeader) return;
    (async () => await loadProjects())();
  }, [projectSearchStore.isProjectsLoadingHeader]);

  useEffect(() => {
    (async () => await getStatistics())();
  }, [filtersStore.searchParams.location]);

  const getStatistics = async (): Promise<void> => {
    if (filtersStore.searchParams.location) {
      await projectSearchStore.getStatistics(filtersStore.searchParams.location);
    }
  };

  return (
    <div className="h-100">
      <SubHeader onSaveSearch={handleSaveSearch} />
      <div className="d-flex">
        <div className="col-3 pe-0">
          <LeftSidebar
            onLoadMore={onLoadMore}
            isProjectsLoading={isProjectsLoading}
            handleUnlock={handleUnlock}
          />
        </div>
        <div className="col-9 p-0">
          <MapProjects />
        </div>
        <RightSidebar
          show={showRightSidebar}
          closeAction={onCloseRightSidebar}
          onLoadMore={onLoadMore}
        />
      </div>

      <ChoosePlanModal
        show={showPlan}
        location={planLocation}
        closeModal={() => setShowPlan(false)}
        onUpdate={handleUpdateProjects}
      />

      {details && <ProjectInfoModal />}
    </div>
  );
});

export default SearchProjects;
