import { ISavedSearchRepo, ISavedSearch } from './types';
import { ISearchParams } from '../SearchProjects/components/SubHeader/types';
import { axiosInstance } from 'utils/API';
import { DetailInfo } from '../SearchProjects/components/Map/types';
import { IAxiosResponse } from 'utils/types';

export class SavedSearchManageRepo implements ISavedSearchRepo {
  async saveSearch(
    id: number,
    name: string,
    filters: ISearchParams
  ): Promise<IAxiosResponse<ISavedSearch>> {
    return await axiosInstance.post(`users/${id}/saved-searches`, {
      filters: { ...filters },
      name
    });
  }

  async getSavedSearches(id: number): Promise<IAxiosResponse<ISavedSearch[]>> {
    return await axiosInstance.get(`users/${id}/saved-searches`);
  }

  async editSavedSearches(
    userId: number,
    searchId: number,
    name: string
  ): Promise<IAxiosResponse<ISavedSearch>> {
    return await axiosInstance.patch(`users/${userId}/saved-searches/${searchId}`, { name });
  }
  async deleteSavedSearches(userId: number, searchId: number): Promise<void> {
    return await axiosInstance.delete(`users/${userId}/saved-searches/${searchId}`);
  }

  async getNewProjectCount(userId: number): Promise<IAxiosResponse<number>> {
    return await axiosInstance.get(`/users/${userId}/dashboard/new-items/count`);
  }

  async getLocationInfo(locationId: number): Promise<IAxiosResponse<DetailInfo>> {
    return axiosInstance.get(`/locations/${locationId}`);
  }
}
